import { PropsWithChildren } from 'react';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  className?: string;
  title?: string;
}

const ReportCard = ({ children, className, title }: PropsWithChildren<Props>) => (
  <S.ReportCard className={className}>
    {isNonEmptyString(title) && <S.CardTitle>{title}</S.CardTitle>}
    {children}
  </S.ReportCard>
);

export default ReportCard;
