import { default as BaseEducationComplete } from 'src/components/Education/EducationComplete';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const Bar = styled.div`
  width: var(--width);
  height: 1.25rem;
  background-color: var(--purple-400);
  border-radius: 999rem;
`;

export const EducationComplete = styled(BaseEducationComplete)`
  margin-top: var(--space-3);
`;

export const EducationProgressBar = styled.div``;

export const Label = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;
  margin-top: var(--space-2);
  ${TEXT_STYLES.flagSmall}
  color: var(--gray-800);
`;

export const Track = styled.div`
  height: 1.25rem;
  background-color: var(--gray-100);
  border-radius: 999rem;
`;
