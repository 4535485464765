import Button from 'src/components/Buttons/Button';
import ModalDialog from 'src/components/ModalDialog';

import * as S from './styles';

interface Props {
  close: () => void;
  onSendReminders: () => void;
}

const AssignmentEmailReminderConfirmation = ({ close, onSendReminders }: Props) => {
  const handleClosePress = () => {
    close();
  };

  const handleConfirmPress = () => {
    onSendReminders();
    close();
  };

  return (
    <S.ModalOverlay>
      <ModalDialog
        data-cy="assignment-email-reminder-confirmation"
        isDismissable
        isOpen
        onClose={handleClosePress}
        title="Would you like to send assignment reminders to all users who haven't completed this assignment?"
      >
        <S.Text>We'll send them an email reminder to get started on their assignments.</S.Text>
        <S.ButtonWrapper>
          <Button
            data-cy="close-button"
            onPress={handleClosePress}
            size="large"
            variant="outline"
          >
            Close
          </Button>
          <Button
            data-cy="send-reminder-button"
            onPress={handleConfirmPress}
            size="large"
            variant="primary"
          >
            Send reminders
          </Button>
        </S.ButtonWrapper>
      </ModalDialog>
    </S.ModalOverlay>
  );
};

export default AssignmentEmailReminderConfirmation;
