import { ReactComponent as KeyboardArrowDownIcon } from '@material-design-icons/svg/round/keyboard_arrow_down.svg';
import clsx from 'clsx';
import { FC, Key, SVGProps, useRef, useState } from 'react';
import { AriaComboBoxProps, Filter, useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

import ListBox from './ListBox';
import * as S from './styles';

interface Props extends AriaComboBoxProps<object> {
  caption?: string;
  className?: string;
  filter?: Filter['contains'] | Filter['endsWith'] | Filter['startsWith'];
  icon?: FC<SVGProps<SVGSVGElement>>;
  selectedKeys?: Set<Key>;
  showDropdownButton?: boolean;
}

const MultiComboBox = ({
  caption,
  className,
  filter,
  icon,
  selectedKeys,
  showDropdownButton = false,
  ...ariaComboBoxProps
}: Props) => {
  const { description, errorMessage, isDisabled, isRequired = false, label } = ariaComboBoxProps;

  const { startsWith } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({ ...ariaComboBoxProps, defaultFilter: filter ?? startsWith });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { buttonProps, descriptionProps, errorMessageProps, inputProps, labelProps, listBoxProps } =
    useComboBox(
      {
        ...ariaComboBoxProps,
        buttonRef: showDropdownButton ? buttonRef : undefined,
        inputRef,
        listBoxRef,
        popoverRef
      },
      state
    );

  const [size, setSize] = useState<Size>({
    height: undefined,
    width: undefined
  });

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    box: 'border-box',
    onResize,
    ref: inputRef
  });

  return (
    <S.ComboBox className={clsx(className, { 'is-disabled': isDisabled })}>
      {isNonEmptyString(label) && (
        <S.Label {...labelProps}>
          {label}
          {isRequired && '*'}
          {isNonEmptyString(caption) && <S.Caption>{caption}</S.Caption>}
        </S.Label>
      )}
      <S.Container>
        <S.Input
          {...inputProps}
          ref={inputRef}
          data-hj-allow
          onFocus={() => state.open()}
        />
        {icon && (
          <S.Icon
            aria-hidden="true"
            as={icon}
          />
        )}
        {errorMessage !== undefined ? (
          <S.ErrorMessage {...errorMessageProps}>
            {typeof errorMessage === 'function'
              ? errorMessage(state.realtimeValidation)
              : errorMessage}
          </S.ErrorMessage>
        ) : (
          isNonEmptyString(description) && (
            <S.Description {...descriptionProps}>{description}</S.Description>
          )
        )}
        {showDropdownButton && (
          <S.DropdownButton
            {...buttonProps}
            ref={buttonRef}
            className={clsx({ 'is-disabled': isDisabled })}
          >
            <KeyboardArrowDownIcon aria-hidden="true" />
          </S.DropdownButton>
        )}
        {state.isOpen && (
          <S.Popover
            $width={size.width}
            isNonModal
            placement="bottom start"
            popoverRef={popoverRef}
            shouldFlip={false} // TODO: delete this line once https://github.com/adobe/react-spectrum/issues/1924 is fixed.
            state={state}
            triggerRef={inputRef}
          >
            <ListBox
              data-cy="combobox-options"
              // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
              //@ts-expect-error
              selectedKeys={selectedKeys}
              selectionMode="multiple"
              {...listBoxProps}
              listBoxRef={listBoxRef}
              state={state}
            />
          </S.Popover>
        )}
      </S.Container>
    </S.ComboBox>
  );
};

export default MultiComboBox;
