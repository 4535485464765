import * as S from './styles';

interface Props {
  datum: {
    x: string; // name
    y: number; // percentage
  };
  height: number;
  width: number;
}

const Label = ({ datum, height, width }: Props) => {
  const x = width + 20;
  const y = height * 0.2;

  /*
    x = "[NUMBER] [TEXT]"
      NUMBER => numeric equivalent of y percentage
      TEXT   => descriptor, will only print one word
  */
  const nameArray = datum['x'].split(' ');
  const value = datum['y'];
  const percentageText = ` (${value.toFixed(0)}%) `;

  return (
    <g transform={`translate(${x}, ${y})`}>
      {nameArray[0] !== '' && (
        <g>
          <text
            textAnchor="start"
            x={0}
            y={0}
          >
            <S.LabelNumber>
              {nameArray[0]}
              <S.PercentageText>{percentageText}</S.PercentageText>
            </S.LabelNumber>
            {nameArray[1] !== '' && (
              <tspan
                dy="18"
                x={0}
              >
                <S.LabelText>{nameArray[1]}</S.LabelText>
              </tspan>
            )}
          </text>
        </g>
      )}
    </g>
  );
};

export default Label;
