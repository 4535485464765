import ComboBox from 'src/components/FormFields/ComboBox';
import LocationsFilter from 'src/components/FormFields/LocationsFilter';
import Spinner from 'src/components/Spinner';
import { DESKTOP_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

import NetworkOrganizationFilter from '../../../Components/NetworkOrganizationFilter';

export const CheckboxWrapper = styled.div`
  align-items: center;
  column-gap: 1.25rem;
  display: flex;
`;

export const FiltersAndActionsWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const FilterSectionTitle = styled.h3`
  margin: 0.5rem 0 0;
  color: var(--gray-800);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 133.333% */
  grid-column: span 2;
`;

export const OnVioletWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  padding: 1rem;
  grid-column: span 2;
  height: 3rem;
`;

export const SmallSpinner = styled(Spinner)`
  fill: none !important;
`;

export const WideComboBox = styled(ComboBox)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-column: span 2;
  }
`;

export const WideLocationsFilter = styled(LocationsFilter)`
  grid-column: span 2;
`;

export const WideNetworkOrganizationFilter = styled(NetworkOrganizationFilter)`
  grid-column: span 2;
`;
