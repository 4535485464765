export const getHoursAndMinutes = (
  duration?: string | null
): { hours: number; minutes: number } => {
  const hoursAndMinutes =
    duration !== null && duration !== undefined ? duration.split('.') : ['0', '0'];
  const hours = hoursAndMinutes[0] === '0' ? 0 : parseInt(hoursAndMinutes[0]);
  const fractionalHours = parseFloat(`0.${hoursAndMinutes[1]}`);
  const minutes = isNaN(fractionalHours) ? 0 : Math.round(fractionalHours * 60);

  return { hours, minutes };
};
