import { ReactComponent as CheckIcon } from '@material-symbols/svg-400/rounded/check.svg';
import { useEffect, useState } from 'react';
import { AriaMeterProps, useMeter, useNumberFormatter } from 'react-aria';
import PopoverTrigger from 'src/components/PopoverTrigger';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaMeterProps {
  currentTotal?: number;
  goalValue?: number;
  isLocked?: boolean;
  labelPopoverText?: string;
  outOfTotal?: number;
  subTitle?: string;
}

const MeterWithGoal = ({
  currentTotal,
  goalValue,
  isLocked = false,
  labelPopoverText,
  outOfTotal,
  subTitle,
  ...props
}: Props) => {
  const { label, maxValue = 100, minValue = 0, value = 0 } = props;
  const { labelProps, meterProps } = useMeter(props);
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });
  const [isMet, setIsMet] = useState<boolean>(goalValue !== undefined ? goalValue <= value : false);

  // Calculate the width of the progress bar as a percentage
  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  useEffect(() => {
    if (goalValue === undefined) return;
    setIsMet(goalValue <= value);
  }, [goalValue, value]);

  return (
    <S.MeterContainer {...meterProps}>
      <S.Meter>
        <S.Bar>
          {goalValue !== undefined && (
            <S.GoalIndicator
              $goalValue={goalValue}
              $isLocked={isLocked}
              $isMet={isMet}
            />
          )}
          <S.ValueBar
            $isLocked={isLocked}
            style={{ width: barWidth }}
          />
        </S.Bar>
        {goalValue !== undefined && !isLocked && (
          <S.StatusIndicator
            $checked={isMet}
            className={isMet ? 'checked' : 'unchecked'}
            data-cy="goal-indicator"
          >
            {isMet && <CheckIcon />}
          </S.StatusIndicator>
        )}
        {isLocked && (
          <S.LockIcon
            aria-hidden="true"
            role="img"
          />
        )}
      </S.Meter>
      <S.MeterLabelWrapper>
        <S.Label {...labelProps}>
          {label}
          {isNonEmptyString(labelPopoverText) && (
            <PopoverTrigger
              content={<S.PopoverContent>{labelPopoverText}</S.PopoverContent>}
              showBorder
            >
              <S.PopoverButton aria-label="Help">
                <S.HelpIcon
                  aria-hidden="true"
                  role="img"
                />
              </S.PopoverButton>
            </PopoverTrigger>
          )}
        </S.Label>
        <S.TotalValue>
          {commaNumFormatter.format(currentTotal ?? 0)}
          {outOfTotal !== undefined ? `/${commaNumFormatter.format(outOfTotal)}` : ''}
          <S.PercentValue>{Math.round(value)}%</S.PercentValue>
        </S.TotalValue>
      </S.MeterLabelWrapper>
    </S.MeterContainer>
  );
};

export default MeterWithGoal;
