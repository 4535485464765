import Badge from 'src/components/Badge';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

export type DataCardHeaderTitleVariantTypes = 'h3-report' | 'h3-scorecard' | 'h3';

interface Props {
  badgeColor?: 'gray' | 'green' | 'yellow';
  badgeText?: string;
  description?: string;
  title: string;
  titleVariant?: DataCardHeaderTitleVariantTypes;
}

const DataCardHeader = ({
  badgeColor = 'green',
  badgeText,
  description,
  title,
  titleVariant = 'h3'
}: Props) => (
  <S.TitleHeader>
    <S.H3CardTitle $titleVariant={titleVariant}>
      {title}
      {isNonEmptyString(badgeText) && (
        <Badge
          color={badgeColor}
          size="small"
        >
          {badgeText}
        </Badge>
      )}
    </S.H3CardTitle>
    {isNonEmptyString(description) && (
      <S.Description $titleVariant={titleVariant}>{description}</S.Description>
    )}
  </S.TitleHeader>
);

export default DataCardHeader;
