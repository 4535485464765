import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  grid-area: button;
  margin-left: auto;
  align-self: center;
  display: flex;
  gap: 0.5rem;
`;

export const Description = styled.p`
  grid-area: description;
  color: var(--gray-800);
  ${TEXT_STYLES.bodyCopyRegular}
  margin: 0;

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 75%;
  }
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  margin-bottom: 1rem;

  /* Scroll margin to prevent the title from being hidden by the sticky header */
  scroll-margin-top: 6.5rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-areas:
      'title'
      'description'
      'button';
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-areas:
      'title       button'
      'description description';
  }
`;

const TitleSharedStyles = css`
  display: inline-block;
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  font-weight: 400;
  color: var(--purple-400);
  grid-area: title;
  margin: 0;
`;

export const TitleH1 = styled.h1`
  ${TitleSharedStyles}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const TitleH2 = styled.h2`
  ${TitleSharedStyles}
`;
