import { ReactComponent as CheckIcon } from '@material-design-icons/svg/outlined/check_circle.svg';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import ErrorModalDialog from 'src/components/ErrorModalDialog';
import { useQuery } from 'src/utils/useQuery';

import * as S from './styles';

const RequestInvite = () => {
  const {
    close: closeErrorModalDialog,
    isOpen: errorModalDialogIsOpen,
    open: openErrorModalDialog
  } = useOverlayTriggerState({});

  const [isSending, setIsSending] = useState<boolean>(false);
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);

  const query = useQuery();
  const encryptedUserId = query.get('encrypted_user_id');

  useEffect(() => {
    const pageSpinner = document.querySelector<HTMLDivElement>('#page-spinner');
    const pageSpinnerStyles = document.querySelector<HTMLStyleElement>('#page-spinner-styles');

    // If the App component is mounted a second time, which happens during development because of React Fast Refresh, the page spinner and its styles will already have been removed.
    if (pageSpinner && pageSpinnerStyles) {
      pageSpinner.remove();
      pageSpinnerStyles.remove();
    }
  }, []);

  const handleConfirmEmail = async () => {
    setIsSending(true);

    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile/send_invitation/${encryptedUserId}`;

    const options: RequestInit = {
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`${response.status} (${response.statusText})`);
      }

      setEmailSuccess(true);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }
    setIsSending(false);
  };

  return (
    <S.Invitation>
      <S.Icon />
      <S.Hero
        copy="Protecting your information is important to us. To keep your account secure, we need to send you an email with a new link to join Violet and complete your profile."
        header="For your security, we’ll need to confirm your email address."
        level={2}
        subcopy={
          <>
            Didn’t receive a new email? Please reach out to{' '}
            <a href="mailto:support@joinviolet.com">support@joinviolet.com</a>
            {emailSuccess ? (
              <S.Success>
                <CheckIcon />
                <S.GreenText>Sent!</S.GreenText> Please check your inbox.
              </S.Success>
            ) : (
              <S.SendButton
                background="dark"
                isLoading={isSending}
                onPress={handleConfirmEmail}
                variant="primary"
              >
                Send secure invite link
              </S.SendButton>
            )}
          </>
        }
      />
      {errorModalDialogIsOpen && <ErrorModalDialog close={closeErrorModalDialog} />}
    </S.Invitation>
  );
};

export default RequestInvite;
