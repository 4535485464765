import { Placement } from 'react-aria';
import { default as BasePopover } from 'src/components/Popover';
import styled from 'styled-components';

interface PopoverProps {
  $hasBorder: boolean;
  $placement: Placement;
  $theme: 'dark' | 'light';
  $width?: number;
}

export const Popover = styled(BasePopover)<PopoverProps>`
  ${({ $theme }) => {
    if ($theme === 'dark') {
      return `
        color: var(--white-light);

        .arrow {
          background-color: var(--gray-900);
        }
      `;
    } else {
      return `
        color: var(--gray-700);

        .arrow {
          background-color: white;
        }
      `;
    }
  }}

  ${({ $width }) => {
    if ($width !== undefined) {
      return `
        width: ${$width}px;
      `;
    }
  }}

  ${({ $hasBorder, $placement }) => {
    if ($hasBorder) {
      let arrowStyles = ``;
      if ($placement === 'top') {
        arrowStyles = `
          border-bottom: 1px solid var(--gray-200);
          border-right: 1px solid var(--gray-200);
        `;
      } else if ($placement === 'right') {
        arrowStyles = `
          border-bottom: 1px solid var(--gray-200);
          border-left: 1px solid var(--gray-200);
        `;
      } else if ($placement === 'bottom') {
        arrowStyles = `
          border-top: 1px solid var(--gray-200);
          border-left: 1px solid var(--gray-200);
        `;
      } else if ($placement === 'left') {
        arrowStyles = `
          border-top: 1px solid var(--gray-200);
          border-right: 1px solid var(--gray-200);
        `;
      }
      return `
        div[role="dialog"] {
          border: 1px solid var(--gray-200);
          border-radius: 1rem;
        }

        .arrow {
          ${arrowStyles}
        }
    `;
    }
  }}
`;
