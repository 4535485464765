import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

const inputBorderWidth = 1;
const inputSidePadding = 1 - inputBorderWidth / 16;
const inputFocusedBorderWidth = 2;
const inputFocusedSidePadding = 1 - inputFocusedBorderWidth / 16;
const unitWidth = 1.625;

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

export const Group = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0 ${inputSidePadding + unitWidth}rem 0 ${inputSidePadding}rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: #000;
  background-color: var(--white-light);
  border: ${inputBorderWidth}px solid var(--gray-5);
  border-radius: 0.75rem;
  outline: none;

  &:focus-visible {
    border: 2px solid var(--brand-bright);
    padding: 0 ${inputFocusedSidePadding + unitWidth}rem 0 ${inputFocusedSidePadding}rem;
  }

  &.is-invalid {
    border-color: var(--error-500);
  }
`;

export const Label = styled.label`
  display: block;
  padding-bottom: 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
`;

export const NumberField = styled.div`
  width: 100%;
`;
