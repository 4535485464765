import { NumberFormatOptions } from '@internationalized/number';
import { useRef } from 'react';
import { AriaSliderProps, useNumberFormatter, useSlider } from 'react-aria';
import { useSliderState } from 'react-stately';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';
import Thumb from './Thumb';

interface Props extends AriaSliderProps {
  className?: string;
  'data-cy'?: string;
  formatOptions?: NumberFormatOptions;
  isRequired?: boolean;
  labelType?: 'copy-header' | 'form-header';
}

const Slider = ({
  className,
  isRequired = false,
  labelType = 'copy-header',
  ...sliderProps
}: Props) => {
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const trackRef = useRef<HTMLDivElement>(null);

  const {
    'data-cy': dataCy,
    formatOptions = { style: 'decimal' },
    label,
    maxValue = 100,
    minValue = 0
  } = sliderProps;

  const numberFormatter = useNumberFormatter(formatOptions);

  const state = useSliderState({ ...sliderProps, numberFormatter });

  const { groupProps, labelProps, outputProps, trackProps } = useSlider(
    sliderProps,
    state,
    trackRef
  );

  const gridLength = maxValue - minValue + 1;

  return (
    <S.Slider
      {...groupProps}
      className={className}
    >
      {isNonEmptyString(label) && isInDesktopBreakpointRange && (
        <S.Label
          {...labelProps}
          $type={labelType}
        >
          {label}
          {isRequired ? '*' : ''}
        </S.Label>
      )}
      <S.SliderRow>
        <S.Output {...outputProps}>{state.getThumbValueLabel(0)}</S.Output>
        <S.Group
          {...trackProps}
          ref={trackRef}
          $length={gridLength}
        >
          <S.Track />
          <S.SelectionTrack
            $length={gridLength}
            $trackWidth={trackRef.current?.clientWidth}
            $value={state.values[0]}
          />
          <Thumb
            aria-errormessage={state.values[0] === 0 ? 'Please select a value.' : undefined}
            data-cy={dataCy}
            index={0}
            maxValue={maxValue}
            state={state}
            trackRef={trackRef}
            /*
              This is deprecated, but leaving for now to not break current behavior
              To better validate, need to validate at the page/form level or maybe
              a hidden input for validation? TBD
            */
            validationState={state.values[0] === 0 ? 'invalid' : 'valid'}
          />
        </S.Group>
        <S.Values
          $length={gridLength}
          $type={labelType}
        >
          {Array.from(Array(maxValue - minValue + 1).keys())
            .map((_, index) => index + minValue)
            .map(value => (
              <S.Value
                key={value}
                $isFirst={value === minValue}
                $isLast={value === maxValue}
                $isSelected={value === state.values[0]}
              >
                {isInMobileBreakpointRange
                  ? value === minValue || value === maxValue
                    ? value
                    : ''
                  : value}
              </S.Value>
            ))}
        </S.Values>
      </S.SliderRow>
    </S.Slider>
  );
};

export { Slider };
