import styled from 'styled-components';

export const UnstyledButton = styled.button`
  all: unset;
  box-sizing: border-box;

  &:not(.is-disabled) {
    cursor: pointer;
  }

  &.is-focused {
    outline: revert;
  }
`;
