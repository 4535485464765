import { ReactComponent as QuestionMarkIcon } from '@material-design-icons/svg/round/help_outline.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import { FOCUS_STYLES, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const Bar = styled.div`
  width: 100%;
  background: var(--gray-100);
  height: 1.25rem;
  border-radius: 5.4375rem;
  margin-left: 0.25rem;
`;

interface LabelProps {
  $isReport?: boolean;
}

export const HelpIcon = styled(QuestionMarkIcon)`
  display: block;
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
`;

export const Label = styled.div<LabelProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.375rem;
  color: var(--gray-800);

  ${({ $isReport }) => {
    if ($isReport === true) {
      return `
        ${TEXT_STYLES.buttonTextSmallScreenLarge}
        width: 3.125rem;
        margin-right: 0.75rem;
      `;
    } else {
      return `
        ${TEXT_STYLES.bodyCopyMedium}
        width: 3.9rem;
      `;
    }
  }}
`;

interface PercentValueProps {
  $isReport?: boolean;
}

export const Meter = styled.div`
  display: flex;
  width: 100%;
`;

export const PercentValue = styled.div<PercentValueProps>`
  align-items: flex-start;
  color: var(--purple-400);
  margin-left: 0.875rem;
  display: flex;
  justify-content: end;

  ${({ $isReport }) => {
    if ($isReport === true) {
      return `
        ${TEXT_STYLES.ctaBaseRegular}
        min-width: 3.1rem;
      `;
    } else {
      return `
        ${TEXT_STYLES.ctaBaseSmall}
        min-width: 2.55rem;
      `;
    }
  }}
`;

export const PopoverButton = styled(UnstyledButton)`
  &.is-focused {
    ${FOCUS_STYLES}
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-700);
  white-space: nowrap;
`;

/* There is no standard text style for this combination */
export const Subtitle = styled.div`
  color: var(--gray-700);
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
`;

export const ValueBar = styled.div`
  background: var(--purple-400);
  height: 1.25rem;
  border-radius: 3.1875rem;
`;
