import { VisuallyHidden as BaseVisuallyHidden } from 'react-aria';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

interface ThumbProps {
  $isActive: boolean;
  $isFocused: boolean;
  $maxValue: number;
  $thumbIndex: number;
  $valueIsSelected: boolean;
}

export const Thumb = styled.div<ThumbProps>`
  position: relative;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid var(--purple-400);
  background: #fff;
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  justify-self: center;
  cursor: grab;

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${({ $maxValue, $thumbIndex }) => {
      if ($thumbIndex === 0) {
        return `
        left: -1.5rem;
        grid-column: ${$thumbIndex + 1};
      `;
      } else if ($thumbIndex === $maxValue) {
        return `
        left: 1.75rem;
        grid-column: ${$thumbIndex + 1};
      `;
      } else {
        return `
        grid-column: ${$thumbIndex + 1};
    `;
      }
    }}
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${({ $maxValue, $thumbIndex }) => {
      if ($thumbIndex === $maxValue) {
        return `
        left: 0.25rem;
        grid-column: ${$thumbIndex + 1};
      `;
      } else {
        return `
        grid-column: ${$thumbIndex + 1};
      `;
      }
    }}

    ${({ $isActive }) => {
      if ($isActive) {
        return `
        border-color: var(--bright-purple-400);
        cursor: grabbing;
      `;
      }
    }}

  ${({ $isFocused }) =>
      $isFocused &&
      `
    outline: 0.125rem solid var(--bright-purple-400);
  `}
  }
`;

export const VisuallyHidden = styled(BaseVisuallyHidden)`
  bottom: -0.25rem;
  left: calc(50% - 0.0625rem);

  & > input {
    width: 0.0625rem;
    height: 0.0625rem;
  }
`;
