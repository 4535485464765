import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface ContextValue {
  bearerToken: string;
  refetchUser: () => Promise<void>;
  setUser: Dispatch<SetStateAction<User>>;
  user: User;
}

// @ts-expect-error
export const UserContext = createContext<ContextValue>(null);

const useUser = () => useContext(UserContext);

export default useUser;
