const canvas = document.createElement('canvas');
const context = canvas.getContext('2d')!;
context.font = 'normal 18px niveau-grotesk, sans-serif';

export const generateStyledBgIcon = (icon: string, color: string, css: string) =>
  fetch(icon)
    .then(response => response.text())
    .then(
      text =>
        `url('data:image/svg+xml;utf8,${text.replace(
          '<svg',
          `<svg style="${css}" fill="${color.replace('#', '%23')}"`
        )}')`
    );
