import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const EmptyState = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
`;

export const ExpandCollapseDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
`;

export const ExpandCollapseTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const ExpandCollapseTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
  margin: 0;
`;

export const TableWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  padding: 1.5rem;
  margin-bottom: 1rem;
`;
