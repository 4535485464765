import styled from 'styled-components';

export const ListBox = styled.ul`
  z-index: 998;
  margin: 0.5rem 0;
  width: 100%;
  display: grid;
  row-gap: 0;
  padding: 0.5rem;
  background-color: white;
  list-style-type: none;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  max-height: 11rem;
  overflow: auto;
`;
