import PageTitle from 'src/components/PageTitle';
import CompletedCollectionsTable from 'src/pages/components/CompletedCollectionsTable';
import EducationExperienceForm from 'src/pages/components/EducationExperienceForm';

import * as S from './styles';

const Certifications = () => (
  <>
    <PageTitle
      title="Completed collections"
      titleVariant="h1"
    />
    <CompletedCollectionsTable />
    <S.Title
      title="Other education"
      titleVariant="h1"
    />
    <EducationExperienceForm />
  </>
);

export default Certifications;
