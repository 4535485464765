import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg';
import { transparentize } from 'polished';
import { GRAY_800_COLOR } from 'src/components/GlobalStyle';
import UnstyledButton from 'src/components/UnstyledButton';
import { FOCUS_STYLES, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import { ReactComponent as BaseBenchmarkingGraphicImage } from './images/benchmarking-graphic.svg';

export const BenchmarkingGraphicImage = styled(BaseBenchmarkingGraphicImage)`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const Body = styled.div`
  padding: 1.25rem 1.5rem 1.5rem;
`;

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-800);
  `,
  Root: styled(UnstyledButton)`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    &.is-focused {
      ${FOCUS_STYLES}
    }
  `
};

export const Dialog = styled.div`
  position: relative;
  max-width: 33.1875rem;
  max-height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 1.5rem;
  overflow-y: auto;
  outline: none;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding: 1.5rem;
  border-top: 0.125rem solid var(--gray-light-light);
`;

export const Header = styled.div`
  padding: 3.75rem 1.625rem 2rem;
  background-color: var(--gray-50);
`;

export const Text = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: ${transparentize(0.2, GRAY_800_COLOR)};

  & > p {
    margin-bottom: 0;
  }

  & > ul {
    margin-bottom: 0;
    padding-left: 1.375rem;
  }
`;

export const Title = styled.h3`
  margin-bottom: 1rem;
  ${TEXT_STYLES.headlineSectionLevel2Bold}
  color: var(--gray-800);
`;
