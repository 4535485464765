import EducationExperienceForm from 'src/pages/components/EducationExperienceForm';

import OnboardingLayout from '../components/OnboardingLayout';

import * as S from './styles';

const EducationExperience = () => (
  <OnboardingLayout progressBarValue={3}>
    <S.OnboardingHero
      copy={
        <>
          To provide you with the most accurate Benchmarks we'll need to learn more about any
          completed educational training specifically related to BIPOC, LGBQ, and TGNC communities.
          If you don't have any relevant education, please{' '}
          <S.SkipLink to="/onboarding/work-experience">skip this step</S.SkipLink>
          .
          <br />
          <S.WhatToAddPopover />
        </>
      }
      graphicType="education"
      header="Education history."
    />
    <EducationExperienceForm />
  </OnboardingLayout>
);

export default EducationExperience;
