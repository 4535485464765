import isEqual from 'lodash/isEqual';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useIsFirstRender } from 'usehooks-ts';

import { generateNetworkProviders, NetworkProvider } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchNetworkProviders = (
  filters?: NetworkProviderFilters,
  skipSettingIsFetching?: boolean
) => Promise<void>;

export interface NetworkProviderFilters {
  'benchmark_communities[]'?: string[];
  order_by?: {
    column: 'full_name' | 'organization_name';
    dir: 'asc' | 'desc';
  };
  org_is_on_violet?: boolean;
  'organizations[]'?: string[];
  page?: number;
  per_page?: number;
  'specialties[]'?: string[];
  'states[]'?: string[];
  verified_inclusive?: boolean;
}

interface UseGetNetworkProviders {
  (filters?: NetworkProviderFilters): {
    isFetching: boolean;
    networkProviders: NetworkProvider[] | undefined;
    networkProvidersTotalPages: number;
    updateNetworkProviderFilters: (newFilters: Partial<NetworkProviderFilters>) => void;
  };
}

const useGetNetworkProviders: UseGetNetworkProviders = (filters = {}) => {
  const isFirstRender = useIsFirstRender();
  const [networkProviders, setNetworkProviders] = useState<NetworkProvider[] | undefined>();
  const [networkProvidersTotalPages, setNetworkProvidersTotalPages] = useState<number>(1);
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState<NetworkProviderFilters>(filters);
  const { getRequest, reportError } = useApiRequest();

  const fetchNetworkProviders: FetchNetworkProviders = useCallback(
    async filters => {
      setIsFetching(true);

      const params = filtersToParams(filters ?? {});

      const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/networks/providers${
        params ? `?${params}` : ''
      }`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APINetworksProviders['data'];
          meta?: APINetworksProviders['meta'];
        };
        setNetworkProvidersTotalPages(meta !== undefined ? meta.total_pages : 1);
        setNetworkProviders(
          data !== undefined ? data.map(fields => generateNetworkProviders(fields)) : undefined
        );
      } catch (error) {
        reportError(error);
      }

      setIsFetching(false);
    },
    [getRequest, reportError]
  );

  useDeepCompareEffect(() => {
    if (!isFirstRender) return;
    (async () => {
      await fetchNetworkProviders(filters);
    })();
  }, [fetchNetworkProviders, filters]);

  const updateNetworkProviderFilters = (newFilters: Partial<NetworkProviderFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkProviderFilters] === undefined
        ? delete newFilters[key as keyof NetworkProviderFilters]
        : {}
    );
    if (isEqual(newFilters, previousFilters)) return;
    fetchNetworkProviders({ ...newFilters });
    setPreviousFilters(newFilters);
  };

  return {
    isFetching,
    networkProviders,
    networkProvidersTotalPages,
    updateNetworkProviderFilters
  };
};

export default useGetNetworkProviders;
