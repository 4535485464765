import { ReactComponent as ArticleIcon } from '@material-design-icons/svg/round/article.svg';
import { ReactComponent as ELearningIcon } from '@material-design-icons/svg/round/computer.svg';
import { ReactComponent as LiveIcon } from '@material-design-icons/svg/round/group.svg';
import { ReactComponent as AudioIcon } from '@material-design-icons/svg/round/headphones.svg';
import { ReactComponent as VideoIcon } from '@material-design-icons/svg/round/videocam.svg';
import { FC, SVGProps } from 'react';

const getContentMediumIcon = (contentMedium: string) => {
  let Icon: FC<SVGProps<SVGSVGElement>>;

  if (contentMedium === 'Video') {
    Icon = VideoIcon;
  } else if (contentMedium === 'Audio') {
    Icon = AudioIcon;
  } else if (contentMedium === 'Written') {
    Icon = ArticleIcon;
  } else if (contentMedium === 'e-learning') {
    Icon = ELearningIcon;
  } else if (contentMedium === 'Live') {
    Icon = LiveIcon;
  } else {
    throw new Error('Invalid `contentMedium` value.');
  }
  return Icon;
};

export default getContentMediumIcon;
