import { ReactComponent as QuestionMarkIcon } from '@material-design-icons/svg/round/help_outline.svg';
import { ReactComponent as Lock } from '@material-design-icons/svg/round/lock.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import { FOCUS_STYLES, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const Bar = styled.div`
  flex-grow: 1;
  background: var(--gray-100);
  height: 0.75rem;
  border-radius: 6.25rem;
  position: relative;
  overflow: hidden;
`;

export const GoalIndicator = styled.div<{
  $goalValue: number;
  $isLocked: boolean;
  $isMet: boolean;
}>`
  position: absolute;
  top: 0;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  ${({ $goalValue }) => {
    if ($goalValue - 20 < 0) {
      return `left: calc(${$goalValue}% - 0.25rem);`;
    } else {
      return `left: calc(${$goalValue}% - 0.75rem);`;
    }
  }};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 50%;
    background: var(--green-400);
  }

  ${({ $isLocked, $isMet }) => {
    if ($isMet) {
      const bgColor = $isLocked ? 'var(--gray-400)' : 'var(--purple-400)';
      return `
        background: #fff;

        &:after {
          background: ${bgColor};
        }
      `;
    } else {
      return `
        background: var(--gray-300);

        &:after {
          background: var(--gray-100);
        }
      `;
    }
  }}
`;

export const HelpIcon = styled(QuestionMarkIcon)`
  display: block;
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 0.375rem;
  color: var(--gray-600);
  ${TEXT_STYLES.bodyCopyMedium}
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.0088rem;
  margin-left: 0.25rem;
`;

interface PercentValueProps {
  $isReport?: boolean;
}

export const LockIcon = styled(Lock)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--gray-400);
`;

export const Meter = styled.div`
  display: flex;
  width: 100%;
`;

export const MeterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.3125rem;
`;

export const MeterLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PercentValue = styled.div<PercentValueProps>`
  align-items: flex-start;
  color: var(--purple-400);
  margin-left: 0.25rem;
  font-weight: 700;
`;

export const PopoverButton = styled(UnstyledButton)`
  &.is-focused {
    ${FOCUS_STYLES}
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-700);
  max-width: 18.75rem;
`;

export const StatusIndicator = styled.div<{ $checked: boolean }>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  ${({ $checked }) =>
    $checked
      ? `
        border: 2px solid var(--success-600);
        background: var(--success-600);`
      : `
        border: 2px solid var(--gray-400);
        background: #fff;
      `};
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #fff;
  }
`;

export const TotalValue = styled.div`
  margin-left: 0.875rem;
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-600);
  display: flex;
  justify-content: end;
`;

export const ValueBar = styled.div<{ $isLocked: boolean }>`
  background: var(--purple-400);
  height: 0.75rem;
  border-radius: 6.25rem;

  ${({ $isLocked }) => {
    if ($isLocked) {
      return `background: var(--gray-400);`;
    } else {
      return `background: var(--purple-400);`;
    }
  }}
`;
