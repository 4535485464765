import { useRef } from 'react';
import { AriaMenuProps, useMenu } from 'react-aria';
import { useTreeState } from 'react-stately';

import MenuItem from './MenuItem';
import MenuSection from './MenuSection';
import * as S from './styles';

const Menu = (props: AriaMenuProps<object>) => {
  const state = useTreeState(props);
  const ref = useRef<HTMLUListElement>(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <S.MenuList
      {...menuProps}
      ref={ref}
    >
      {Array.from(state.collection).map(item =>
        item.type === 'section' ? (
          <MenuSection
            key={item.key}
            section={item}
            state={state}
          />
        ) : (
          <MenuItem
            key={item.key}
            item={item}
            state={state}
          />
        )
      )}
    </S.MenuList>
  );
};

export default Menu;
