import { useEffect, useRef, useState } from 'react';
import { AriaListBoxProps, useListBox } from 'react-aria';
import { Key, OverlayTriggerState, useListState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import ListBoxSection from '../ListBoxSection';
import Option from '../Option';

import * as S from './styles';

interface Props extends AriaListBoxProps<object> {
  className?: string;
  filterText?: string;
  popoverState: OverlayTriggerState;
}

const ListBox = ({ className, filterText, popoverState, ...props }: Props) => {
  const state = useListState(props);
  const ref = useRef(null);
  const { listBoxProps } = useListBox(props, state, ref);
  const { collection } = state;

  const [visibleItems, setVisibleItems] = useState<Key[]>(
    Array.from(collection).map(item => item.key)
  );

  useEffect(() => {
    if (filterText !== undefined && isNonEmptyString(filterText)) {
      const filteredItems = Array.from(collection)
        .filter(
          item =>
            item.type === 'section' ||
            item.textValue.toUpperCase().includes(filterText.toUpperCase())
        )
        .map(item => String(item.key));
      setVisibleItems(filteredItems);
      if (filteredItems.length === 0) {
        popoverState.close();
      }
    } else {
      setVisibleItems(Array.from(collection).map(item => item.key));
    }
  }, [filterText, collection, popoverState]);

  return (
    <S.ListBox
      className={className}
      data-cy="list-box-options"
      {...listBoxProps}
      ref={ref}
    >
      {Array.from(state.collection).map(item =>
        item.type === 'section' ? (
          <ListBoxSection
            key={item.key}
            section={item}
            state={state}
          />
        ) : (
          <Option
            key={item.key}
            aria-hidden={!visibleItems.includes(item.key)}
            className={visibleItems.includes(item.key) ? 'visible' : 'hidden'}
            item={item}
            state={state}
          />
        )
      )}
    </S.ListBox>
  );
};

export default ListBox;
