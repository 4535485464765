import { ReactComponent as ForwardIcon } from '@material-design-icons/svg/round/arrow_forward.svg';
import { FormEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import Logotype from 'src/components/Logotype';
import { useLocalStorage } from 'usehooks-ts';

import useUser from '../../../hooks/useUser';

import * as S from './styles';

const Submitted = () => {
  const { user } = useUser();
  const [isHeadwayLms] = useLocalStorage<false | true>('is_headway_lms', false);
  const [loginRedirect] = useLocalStorage<string | null>('redirect_after_onboarding_url', null);

  const navigate = useNavigate();

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (isHeadwayLms && loginRedirect !== null) {
      navigate({
        pathname: loginRedirect,
        search: `internal_referral=onboarding&is_clinical=${user.is_clinical}&org_role=${user.member_roles.join(',')}`
      });
    } else {
      navigate({
        pathname: '/dashboard',
        search: `internal_referral=onboarding&is_clinical=${
          user.is_clinical
        }&org_role=${user.member_roles.join(',')}`
      });
    }
  };

  return (
    <S.Page>
      <S.Layout>
        <S.Header>
          <Logotype
            color="light"
            size="regular"
          />
        </S.Header>
        <S.Main>
          <S.Container>
            <S.GradientCircleCheck>
              <S.Checkmark />
            </S.GradientCircleCheck>
            <S.Title>{user.is_clinical ? 'Profile submitted!' : 'Profile submitted.'}</S.Title>
            {isHeadwayLms ? (
              <S.Paragraph data-cy="submitted-copy">
                Thank you for completing your profile. You can now access your CE/CME certificate by
                answering a few questions about what you’ve learned, along with access to Violet’s
                full library of education.
                <br />
                <br />
                Look out for an email from us containing your BIPOC, LGBQ, and TGNC Benchmarks.
              </S.Paragraph>
            ) : user.is_clinical ? (
              <S.Paragraph data-cy="submitted-copy">
                Thanks for completing your profile. Now you can access all of our educational
                resources from your dashboard.
                <br />
                <br />
                You will receive an email soon with your new cultural competence Benchmarks.
              </S.Paragraph>
            ) : (
              <S.Paragraph data-cy="submitted-copy">
                Thanks for completing your profile. Now you can access all of our educational
                resources from your dashboard.
              </S.Paragraph>
            )}
            <S.Form onSubmit={handleFormSubmit}>
              <S.ViewDashboardButton
                background="dark"
                data-cy="view-dashboard-button"
                size="large"
                trailingIcon={ForwardIcon}
                type="submit"
                variant="primary"
              >
                {isHeadwayLms && loginRedirect !== null ? 'Get your CE/CME' : 'View dashboard'}
              </S.ViewDashboardButton>
            </S.Form>
          </S.Container>
        </S.Main>
      </S.Layout>
    </S.Page>
  );
};

export default Submitted;
