import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MOBILE_BREAKPOINT_RANGE } from '../../../constants';
import { default as BaseButton } from '../../Buttons/Button';
import {
  RESOURCE_CARD_MAX_WIDTH,
  RESOURCE_CARD_MIN_WIDTH,
  RESOURCE_CARD_STYLES
} from '../resource_card_styles';

export const Button = styled(BaseButton)``;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;

  a {
    width: auto;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    gap: 0.5rem;
    grid-template-columns: 1fr;
  }
`;

export const CardContainer = styled(motion.li)`
  display: flex;
  width: 100%;
  max-width: ${RESOURCE_CARD_MAX_WIDTH}px;
  min-width: ${RESOURCE_CARD_MIN_WIDTH}px;

  ${MOBILE_BREAKPOINT_RANGE} {
    justify-self: center;
  }
`;

export const CardContent = styled.div`
  ${RESOURCE_CARD_STYLES.cardContent}
`;

export const CardLabel = styled.div`
  ${RESOURCE_CARD_STYLES.cardLabel}
  background-color: var(--success-25);
  color: var(--gray-800);
`;

export const Description = styled.p`
  ${RESOURCE_CARD_STYLES.cardDescription}
`;

export const OtherCard = styled.div`
  display: flex;
  min-width: 0.75rem;
  width: 0.75rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  border-left: 1px solid var(--gray-200);
  background: var(--gray-50);
  margin: 0.75rem 0;
`;

export const PathwayCard = styled.div`
  ${RESOURCE_CARD_STYLES.card}
  max-width: unset;
  min-width: unset;
  flex-grow: 1;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const ProgressLabel = styled.div`
  height: 1rem;
`;

export const Tag = styled.li`
  ${RESOURCE_CARD_STYLES.cardTagItem}

  &.progress-tag {
    justify-content: space-between;
  }
`;

export const Tags = styled.ul`
  ${RESOURCE_CARD_STYLES.cardTags}
`;

export const Title = styled(Link)`
  ${RESOURCE_CARD_STYLES.cardTitle}
`;
