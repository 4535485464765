import { OrganizationAssignmentDetails } from 'src/pages/Dashboard/utils';
import { getHoursAndMinutes } from 'src/utils/getHoursAndMinutes';

import * as S from './styles';

interface Props {
  assignment: OrganizationAssignmentDetails;
}

const AssignmentMetaData = ({ assignment }: Props) => {
  const { hours, minutes } = getHoursAndMinutes(assignment.hours);

  return (
    <S.MetaData>
      <S.MetaDataItem
        className="start"
        data-cy="assignment-time"
      >
        <S.AssignmentTimeIcon
          aria-hidden="true"
          role="img"
        />
        {(hours > 0 || minutes > 0) && (
          <>
            {hours > 0 && `${hours} hour${hours === 1 ? '' : 's'}`}
            {hours > 0 && minutes > 0 && ' '}
            {minutes > 0 && `${minutes} min${minutes === 1 ? '' : 's'}`}
          </>
        )}
      </S.MetaDataItem>
      <S.MetaDataItem
        className="middle"
        data-cy="assignment-type"
      >
        <S.AssignmentTypeIcon
          aria-hidden="true"
          role="img"
        />
        {assignment.resourceType === 'CourseCollection' ? 'Collection' : 'Course'}
      </S.MetaDataItem>
      <S.MetaDataItem
        className="middle"
        data-cy="assignment-start"
      >
        <S.StartIcon
          aria-hidden="true"
          role="img"
        />
        Start: {assignment.startDate?.toLocaleString('en-US')}
      </S.MetaDataItem>
      <S.MetaDataItem
        className="end"
        data-cy="assignment-end"
      >
        <S.EndIcon
          aria-hidden="true"
          role="img"
        />
        End: {assignment.endDate !== undefined ? assignment.endDate.toLocaleString('en-US') : 'N/A'}
      </S.MetaDataItem>
    </S.MetaData>
  );
};

export default AssignmentMetaData;
