import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  direction?: 'horizontal' | 'vertical';
  layout?:
    | 'fifths'
    | 'fifty-fifty'
    | 'quarters'
    | 'single'
    | 'thirds-last-wide'
    | 'thirds'
    | 'two-thirds';
}

const DataReportRow = ({
  children,
  className,
  direction = 'horizontal',
  layout = 'single'
}: PropsWithChildren<Props>) => (
  <S.DataReportRow
    $direction={direction}
    $layout={layout}
    className={clsx(className, 'data-row')}
  >
    {children}
  </S.DataReportRow>
);

export default DataReportRow;
