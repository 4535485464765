import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const DateErrorMessage = styled.div`
  display: block;
  width: 100%;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
  grid-area: 2 / 1 / 3 / 4;
`;

export const DateSegment = styled.div`
  display: grid;

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: 3.75rem 1rem 3.8rem repeat(2, 0);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr 0.25fr 1fr repeat(2, 0);

    div:nth-child(3) {
      margin-top: 1.5rem;
    }
  }

  span {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-self: start;
    height: 2.2rem;

    ${MOBILE_BREAKPOINT_RANGE} {
      height: 3rem;
      margin-top: 1.5rem;
    }
  }
`;
