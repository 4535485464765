import { ToastState, useToastState } from '@react-stately/toast';
import { ReactNode } from 'react';

import ToastRegion from './ToastRegion';

export interface VioletToast {
  cancelable?: boolean;
  customIcon?: ReactNode;
  description?: string;
  title?: string;
  type?: 'error' | 'info' | 'success' | 'warning';
}

interface ToastProviderProps {
  children: (state: ToastState<VioletToast>) => ReactNode;
  placement?: 'bottom' | 'top';
}

const ToastProvider = ({ children, placement, ...props }: ToastProviderProps) => {
  const state = useToastState<VioletToast>({
    /* TODO: reenable when animation does not break closing of toast */
    // hasExitAnimation: true,
    maxVisibleToasts: 5
  });

  return (
    <>
      {children(state)}
      {state.visibleToasts.length > 0 && (
        <ToastRegion
          {...props}
          placement={placement ?? 'bottom'}
          state={state}
        />
      )}
    </>
  );
};

export default ToastProvider;
