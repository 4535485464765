// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import { ChangeEvent, forwardRef, MutableRefObject, useState } from 'react';
import {
  AriaTextFieldOptions,
  AriaTextFieldProps,
  mergeProps,
  Overlay,
  TextFieldAria,
  TooltipTriggerProps,
  useFocusRing,
  useHover,
  useTextField,
  useTooltipTrigger
} from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';
import Tooltip from 'src/components/TooltipTrigger/Tooltip';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaTextFieldProps {
  caption?: string;
  className?: string;
  displayTooltip?: boolean;
  hideRequiredIndicator?: boolean;
  isExpandableMultiline?: boolean;
  isMultiline?: boolean;
  size?: number;
}

const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      caption,
      className,
      displayTooltip = false,
      hideRequiredIndicator = false,
      isExpandableMultiline = false,
      isMultiline = false,
      size,
      ...ariaTextFieldProps
    },
    forwardedRef
  ) => {
    const {
      description,
      errorMessage,
      isDisabled,
      isRequired = false,
      label,
      value
    } = ariaTextFieldProps;

    const ref = useObjectRef(forwardedRef);

    const {
      descriptionProps,
      errorMessageProps,
      inputProps,
      isInvalid,
      labelProps,
      validationErrors
    } = useTextField<'input' | 'textarea'>(
      ariaTextFieldProps as AriaTextFieldOptions<'input' | 'textarea'>,
      ref
    );
    const { hoverProps, isHovered } = useHover({ isDisabled: ariaTextFieldProps.isDisabled });
    const { focusProps, isFocused } = useFocusRing({ isTextInput: true });

    const tooltipTriggerProps: TooltipTriggerProps = { isDisabled, isOpen: isHovered };
    const state = useTooltipTriggerState(tooltipTriggerProps);
    const { tooltipProps } = useTooltipTrigger(tooltipTriggerProps, state, ref);

    const [textAreaValue, setTextAreaValue] = useState<string>('');

    const onTextAreaChange: React.ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = event => {
      setTextAreaValue(event.target.value);
      if (inputProps.onChange !== undefined) {
        inputProps.onChange(
          event as ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>
        );
      }
    };

    return (
      <S.TextField
        className={clsx(className, {
          'is-disabled': isDisabled,
          'is-focused': isFocused
        })}
      >
        {isNonEmptyString(label) && (
          <S.Label {...labelProps}>
            {label}
            {isRequired && !hideRequiredIndicator && '*'}
            {isNonEmptyString(caption) && <S.Caption>{caption}</S.Caption>}
          </S.Label>
        )}
        {displayTooltip && isNonEmptyString(value) && !isFocused && state.isOpen && (
          <Overlay>
            <Tooltip
              content={<S.TooltipContent>{value!}</S.TooltipContent>}
              hideArrow={false}
              placement="top"
              state={state}
              targetRef={ref}
              theme="dark"
              {...tooltipProps}
            />
          </Overlay>
        )}
        {isMultiline ? (
          isExpandableMultiline ? (
            <S.GrowthWrapper data-replicated-value={textAreaValue}>
              <S.TextArea
                ref={ref as MutableRefObject<HTMLTextAreaElement>}
                className={clsx(className, { 'is-focused': isFocused, 'is-invalid': isInvalid })}
                data-hj-allow
                {...mergeProps(focusProps, inputProps as TextFieldAria<'textarea'>['inputProps'], {
                  required: isRequired
                })}
                onChange={onTextAreaChange}
                rows={1}
              />
            </S.GrowthWrapper>
          ) : (
            <S.TextArea
              ref={ref as MutableRefObject<HTMLTextAreaElement>}
              className={clsx(className, { 'is-focused': isFocused, 'is-invalid': isInvalid })}
              data-hj-allow
              {...mergeProps(focusProps, inputProps as TextFieldAria<'textarea'>['inputProps'], {
                required: isRequired
              })}
            />
          )
        ) : (
          <S.Input
            ref={ref as MutableRefObject<HTMLInputElement>}
            className={clsx(className, { 'is-focused': isFocused, 'is-invalid': isInvalid })}
            data-hj-allow
            size={size}
            {...mergeProps(focusProps, hoverProps, inputProps as TextFieldAria['inputProps'], {
              required: isRequired
            })}
          />
        )}
        {isInvalid ? (
          <S.ErrorMessage
            {...errorMessageProps}
            className={isMultiline ? 'multi' : 'single'}
            data-cy="error-message"
          >
            {typeof errorMessage === 'function' ? null : errorMessage}
            {validationErrors.join(' ')}
          </S.ErrorMessage>
        ) : (
          isNonEmptyString(description) && (
            <S.Description {...descriptionProps}>{description}</S.Description>
          )
        )}
      </S.TextField>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
