import styled from 'styled-components';

import Checkbox from '../../../components/FormFields/Checkbox';
import CheckboxGroup from '../../../components/FormFields/CheckboxGroup';
import { default as BaseLanguageSearch } from '../../../components/FormFields/LanguageSearch';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const Anchor = styled.a`
  text-decoration: unset;
  color: var(--purple-400);

  &:focus-visible {
    outline-offset: 0.0625rem;
    outline: 0.125rem solid var(--brand-bright);
    border-radius: 0.25rem;
  }
`;

export const DisabledText = styled.span`
  color: var(--gray-dark-dark);
`;

export const Label = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
  margin-bottom: 0.75rem;
`;

export const LanguageSearch = styled(BaseLanguageSearch)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-7);
  }
`;

export const PrivacyPolicyTocAgreementCheckbox = styled(Checkbox)``;

export const PronounsCheckboxGroup = styled(CheckboxGroup)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-7);
  }
`;

export const SmsConsentCheckbox = styled(Checkbox)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-5);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-4);
  }
`;

export const YourInformationTextFields = {
  Header: styled.div`
    ${TEXT_STYLES.headlineSectionLevel2Large}
    color: var(--purple-400);

    ${MOBILE_BREAKPOINT_RANGE} {
      margin-bottom: var(--space-5);
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      margin-bottom: var(--space-6);
    }
  `,
  Root: styled.div`
    margin-bottom: 1rem;
  `,
  TextFields: styled.div`
    display: grid;

    ${MOBILE_BREAKPOINT_RANGE} {
      row-gap: var(--space-5);
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      column-gap: var(--space-4);
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    > div {
      margin-bottom: auto;
    }

    &.addtl-row {
      margin-top: 1rem;
    }
  `
};
