import clsx from 'clsx';
import { useRef } from 'react';
import { useOption } from 'react-aria';
import { Node, SelectState } from 'react-stately';

interface Props {
  className?: string;
  item: Node<object>;
  state: SelectState<object>;
}

const Option = ({ className, item, state }: Props) => {
  const ref = useRef<HTMLLIElement>(null);

  const { isFocused, isSelected, optionProps } = useOption({ key: item.key }, state, ref);

  return (
    <li
      {...optionProps}
      ref={ref}
      className={clsx(className, { 'is-focused': isFocused }, { 'is-selected': isSelected })}
    >
      {item.rendered}
    </li>
  );
};

export default Option;
