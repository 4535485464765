import { Item } from 'react-stately';
import ButtonWithMenu from 'src/components/Buttons/ButtonWithMenu';

import * as S from './styles';

interface Props {
  onDownloadClick: (downloadType: number | string) => Promise<void>;
  reportName: string;
}

export const ReportDownloadBar = ({ onDownloadClick, reportName }: Props) => (
  <S.DownloadBar>
    <ButtonWithMenu
      data-cy={`export-report-${reportName}-button`}
      label="Export"
      matchButtonWidth
      onAction={onDownloadClick}
    >
      <Item key="pdf">PDF</Item>
      <Item key="png">PNG</Item>
    </ButtonWithMenu>
  </S.DownloadBar>
);
