import { ReactComponent as BaseInfoIcon } from '@material-design-icons/svg/round/info.svg';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../constants';

export const InfoIcon = styled(BaseInfoIcon)`
  flex-shrink: 0;
  width: 1.125rem;
  height: auto;
  margin-right: var(--space-2);
`;

interface InlineDialogProps {
  $isDisabled: boolean;
  $situation: 'alert' | 'helper' | 'message';
  $state: 'error' | 'info' | 'success' | 'warning' | undefined;
}

export const InlineDialog = styled.div<InlineDialogProps>`
  ${TEXT_STYLES.bodyCopySmall}
  display: inline-flex;
  align-items: center;

  ${({ $situation }) => {
    if ($situation !== 'helper') {
      return `
        font-style: unset;
      `;
    }
  }}

  ${({ $isDisabled, $state }) => {
    if ($isDisabled) {
      return `
        color: var(--gray-dark-light);

        ${InfoIcon} {
          fill: var(--gray-dark-light);
        }
      `;
    } else if ($state === 'error') {
      return `
        color: var(--app-status-error);
      `;
    } else {
      return `
        color: var(--gray-600);

        ${InfoIcon} {
          fill: var(--gray-800);
        }
      `;
    }
  }}
`;
