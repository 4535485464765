import Table from 'src/components/Table';
import styled from 'styled-components';

interface LocationTableProps {
  $showAllColumns: boolean;
}

export const LocationTable = styled(Table)<LocationTableProps>`
  tr {
    display: grid;

    ${({ $showAllColumns }) => {
      if ($showAllColumns) {
        return 'grid-template-columns: 1.75fr 0.75fr 1fr 1fr 1fr 1fr;';
      } else {
        return 'grid-template-columns: 1fr 1fr 1fr 1fr 1fr;';
      }
    }}
  }

  tbody {
    display: grid;
    gap: 0.5rem;

    tr {
      border-radius: 0.5rem;
      height: 3.75rem; /* 60px */
      align-items: center;
    }
  }

  tr th:nth-child(${({ $showAllColumns }) => ($showAllColumns ? 3 : 2)}) {
    > div {
      justify-content: end;
    }
  }

  tr th:nth-child(${({ $showAllColumns }) => ($showAllColumns ? 4 : 3)}) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    > div {
      justify-content: end;
    }
  }

  tr th:nth-child(${({ $showAllColumns }) => ($showAllColumns ? 5 : 4)}) {
    background-color: var(--light-purple-50);

    > div {
      justify-content: end;
    }
  }

  tr th:nth-child(${({ $showAllColumns }) => ($showAllColumns ? 6 : 5)}) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    > div {
      justify-content: end;
    }
  }
`;
