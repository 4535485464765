import { createContext, useContext } from 'react';

interface ContextValue {
  bearerToken: string;
}

// @ts-expect-error
export const ReportAuthContext = createContext<ContextValue>(null);

const useReportAuthContext = () => useContext(ReportAuthContext);

export default useReportAuthContext;
