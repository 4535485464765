import { transparentize } from 'polished';
import { useProgressBar } from 'react-aria';

import { BRAND_BRIGHT_COLOR } from '../../components/GlobalStyle';

import * as S from './styles';

interface Props {
  backgroundColor?: string;
  className?: string;
  color?: string;
  withWrapper?: boolean;
}

const Spinner = ({
  backgroundColor = transparentize(0.8, BRAND_BRIGHT_COLOR),
  className,
  color = BRAND_BRIGHT_COLOR,
  withWrapper = false
}: Props) => {
  const { progressBarProps } = useProgressBar({
    'aria-label': 'Loading...',
    isIndeterminate: true
  });

  const length = 48;
  const center = 16;
  const strokeWidth = 4;
  const r = 16 - strokeWidth;
  const c = 2 * r * Math.PI;
  const offset = c - (1 / 4) * c;

  const spinnerSvg = (
    <svg
      {...progressBarProps}
      className={className}
      fill="none"
      height={length}
      strokeWidth={strokeWidth}
      viewBox="0 0 32 32"
      width={length}
    >
      <circle
        cx={center}
        cy={center}
        r={r}
        role="presentation"
        stroke={backgroundColor}
      />
      <circle
        cx={center}
        cy={center}
        r={r}
        role="presentation"
        stroke={color}
        strokeDasharray={c}
        strokeDashoffset={offset}
      >
        <animateTransform
          attributeName="transform"
          begin="0s"
          dur="1s"
          from="0 16 16"
          repeatCount="indefinite"
          to="360 16 16"
          type="rotate"
        />
      </circle>
    </svg>
  );

  return withWrapper ? <S.Wrapper>{spinnerSvg}</S.Wrapper> : spinnerSvg;
};

export default Spinner;
