import Alert from 'src/components/Alert';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const AlertBar = styled(Alert)`
  margin-bottom: 1rem;
`;

export const Cards = styled.ul`
  display: grid;
  row-gap: var(--space-7);
  margin-bottom: var(--space-7);
  padding-left: 0;

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.375rem;
  }
`;

export const Subtitle = styled.h2`
  ${TEXT_STYLES.headlineStandardLevel2Regular}
  color: var(--purple-400);

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-5);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-4);
  }
`;
