import { FC, PropsWithChildren, SVGProps } from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  color: 'gray' | 'green' | 'red' | 'yellow';
  'data-cy'?: string;
  leadingIcon?: FC<SVGProps<SVGSVGElement>>;
  leadingIconAriaLabel?: string;
  size: 'large' | 'small';
}

const Badge = ({
  children,
  className,
  color,
  'data-cy': dataCy,
  leadingIcon,
  leadingIconAriaLabel,
  size
}: PropsWithChildren<Props>) => (
  <S.Badge
    $color={color}
    $size={size}
    className={className}
    data-cy={dataCy}
    role="presentation"
  >
    {leadingIcon && (
      <S.LeadingIcon
        aria-label={leadingIconAriaLabel}
        as={leadingIcon}
        role="img"
      />
    )}
    {children}
  </S.Badge>
);

export default Badge;
