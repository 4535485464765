import { Temporal } from '@js-temporal/polyfill';
import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { AriaComboBoxOptions } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import Alert from 'src/components/Alert';
import PageTitle from 'src/components/PageTitle';
import DemographicGraph from 'src/components/Reporting/DemographicGraph';
import TagGroup from 'src/components/TagGroup';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import { downloadXlsx } from 'src/utils/downloadXlsx';
import { capitalize } from 'src/utils/stringTransformations';
import { useUpdateEffect } from 'usehooks-ts';

import Button from '../../../../components/Buttons/Button';
import Spinner from '../../../../components/Spinner';
import useUser from '../../../../hooks/useUser';
import useGetOrganizationDemographics from '../../hooks/useGetOrganizationDemographics';
import { USER_TYPES } from '../../hooks/useGetOrganizationUsers';

import * as S from './styles';

const Demographics = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/vnd.ms-excel'
    }
  };
  const organization = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization;
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedUserType, setSelectedUserType] = useState<string>(
    searchParams.get('clinical') === 'true'
      ? 'clinical'
      : searchParams.get('clinical') === 'false'
        ? 'non-clinical'
        : 'all'
  );

  const {
    isFetching: isFetchingOrganizationDemographics,
    organizationDemographics,
    updateOrganizationDemographicsFilters
  } = useGetOrganizationDemographics(organization?.id ?? '', {
    clinical: selectedUserType !== 'all' ? selectedUserType === 'clinical' : undefined,
    completed_onboarding: true,
    status: 'activated'
  });

  const [isExportingXlsx, setIsExportingXlsx] = useState(false);

  const handleExportXlsxButtonPress = async () => {
    if (!organizationDemographics || !organization) {
      return;
    }
    setIsExportingXlsx(true);

    const exportParams = new URLSearchParams(searchParams);

    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/organizations/${
      organization.id
    }/users/demographics/export?status=activated&completed_onboarding=true${
      exportParams.size === 0 ? '' : `&${exportParams}`
    }`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { organization } = user.organization_memberships.find(
        membership => membership.member_role === 'superuser'
      )!;

      const formattedDate = Temporal.Now.plainDateISO().toLocaleString('en-US');
      const organizationName = organization.name.replace(/\s/g, '');

      await downloadXlsx(response, `Violet_Demographics_${organizationName}_${formattedDate}.xlsx`);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setIsExportingXlsx(false);
  };

  // Update URL with params
  useUpdateEffect(() => {
    updateOrganizationDemographicsFilters({
      clinical: selectedUserType !== 'all' ? selectedUserType === 'clinical' : undefined,
      completed_onboarding: true,
      status: 'activated'
    });
    //Update filters if URL changes
    setSelectedUserType(
      searchParams.get('clinical') === 'true'
        ? 'clinical'
        : searchParams.get('clinical') === 'false'
          ? 'non-clinical'
          : 'all'
    );
  }, [searchParams]);

  const handleUserTypeSelectionChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const value = key as string;
    setSelectedUserType(value);

    setSearchParams(searchParams => {
      if (value === 'all') {
        searchParams.delete('clinical');
      } else {
        searchParams.set('clinical', value === 'clinical' ? 'true' : 'false');
      }
      searchParams.sort();
      return searchParams;
    });
  };

  return (
    <>
      <PageTitle
        title="Demographics"
        titleVariant="h1"
      />
      <S.ActionWrapper>
        <S.UserTypeSelect
          aria-label="Filter by user type"
          data-cy="user-type-select"
          onSelectionChange={handleUserTypeSelectionChange}
          placeholder="All users"
          selectedKey={selectedUserType}
        >
          {USER_TYPES.map(userType => (
            <Item key={userType}>{`${capitalize(userType)} users`}</Item>
          ))}
        </S.UserTypeSelect>
        <Button
          data-cy="export-demographics-btn"
          isDisabled={!organizationDemographics}
          isLoading={isExportingXlsx}
          onPress={handleExportXlsxButtonPress}
          size="regular"
          trailingIcon={ExportIcon}
          variant="primary"
        >
          Export
        </Button>
      </S.ActionWrapper>
      {isFetchingOrganizationDemographics ? (
        <S.SpinnerWrapper>
          <Spinner />
        </S.SpinnerWrapper>
      ) : (
        <S.Cards>
          <S.ChartCard data-cy="race-ethnicity-data">
            <S.ChartTitle>Race & ethnicity</S.ChartTitle>
            {organizationDemographics ? (
              <DemographicGraph
                data={organizationDemographics.raceEthnicity}
                type="raceEthnicity"
              />
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-race-ethnicity-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
          <S.ChartCard data-cy="gender-identity-data">
            <S.ChartTitle>Gender identity</S.ChartTitle>
            {organizationDemographics ? (
              <DemographicGraph
                data={organizationDemographics.genderIdentity}
                type="genderIdentity"
              />
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-gender-identity-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
          <S.ChartCard data-cy="sexual-orientation-data">
            <S.ChartTitle>Sexual orientation</S.ChartTitle>
            {organizationDemographics ? (
              <DemographicGraph
                data={organizationDemographics.sexualOrientation}
                type="sexualOrientation"
              />
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-sexual-orientation-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
          <S.ChartCard data-cy="language-data">
            <S.ChartTitle>Care delivery languages</S.ChartTitle>
            {organizationDemographics ? (
              organizationDemographics.language.length > 0 ? (
                <>
                  <S.LanguageDescription id="language-description">
                    The number of team members delivering care in each of the following languages:
                  </S.LanguageDescription>
                  <TagGroup
                    aria-labelledby="language-description"
                    data-cy="languages-data"
                    variant="light"
                  >
                    {organizationDemographics.language.map(item => (
                      <Item
                        key={item.label}
                        textValue={`${item.percentage}% ${item.label}`}
                      >
                        <S.LanguagePercentage>{item.percentage}%</S.LanguagePercentage>
                        {item.label}
                      </Item>
                    ))}
                  </TagGroup>
                </>
              ) : (
                <S.LanguageDescription data-cy="languages-data">
                  No known team members speak a language other than English.
                </S.LanguageDescription>
              )
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-languages-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
        </S.Cards>
      )}
    </>
  );
};

export default Demographics;
