import Logotype from 'src/components/Logotype';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const Logo = styled(Logotype)`
  height: 1.625rem;
`;

export const ReportDescription = styled.div`
  ${TEXT_STYLES.bodyCopyLarge}
  color: var(--gray-800);
  margin-bottom: 5.5rem;
`;

interface ReportTitleProps {
  $hasDesciption?: boolean;
}

export const ReportTitle = styled.h1<ReportTitleProps>`
  font-family: 'Recoleta Alt', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  line-height: 140%;

  /* identical to box height, or 90px */
  letter-spacing: 0.01em;

  ${({ $hasDesciption }) => {
    if ($hasDesciption === true) {
      return 'margin: 2.5rem 0 0.69rem 0';
    } else {
      return 'margin: 2.5rem 0;';
    }
  }}
`;

export const ReportUpperHeader = styled.div`
  padding: 1.625rem 0 2rem;
  color: var(--green);
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 135%;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
