import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import { PropsWithChildren, StrictMode, useEffect } from 'react';
import { OverlayProvider } from 'react-aria';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType
} from 'react-router-dom';

import 'modern-normalize';

import App from './App';
import ErrorState from './components/ErrorState';
import GlobalStyle from './components/GlobalStyle';
import { AnalyticsProvider } from './hooks/useAnalytics';
import Reports from './pages/Reports';
import InclusivityScorecardReportWrapper from './pages/Reports/InclusivityScorecard';
import NetworkOverviewReportWrapper from './pages/Reports/Network';
import UsersInvitation from './pages/Users/Invitation';
import UsersRequestInvite from './pages/Users/RequestInvite';
import UsersSignUp from './pages/Users/SignUp';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://aca98b21da194d0981fb411c1cd05b7d@o527013.ingest.sentry.io/5803324',
  enabled: process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'production',
  environment: process.env.REACT_APP_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType
    })
  ],
  tracesSampleRate: 1.0
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const handleRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = appState => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        redirect_uri: window.location.origin,
        scope: process.env.REACT_APP_AUTH0_SCOPE
      }}
      cacheLocation="localstorage"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      onRedirectCallback={handleRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <GlobalStyle />
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={postHogOptions}
    >
      <AnalyticsProvider writeKey={process.env.REACT_APP_SEGMENT_WRITE_KEY}>
        <Sentry.ErrorBoundary fallback={<ErrorState />}>
          <OverlayProvider>
            <BrowserRouter>
              <SentryRoutes>
                <Route
                  element={<UsersInvitation />}
                  path="users/invitation"
                />
                <Route
                  element={<UsersRequestInvite />}
                  path="users/request-invite"
                />
                <Route
                  element={
                    <Auth0ProviderWithNavigate>
                      <UsersSignUp />
                    </Auth0ProviderWithNavigate>
                  }
                  path="sign-up"
                />
                <Route
                  element={
                    <Auth0ProviderWithNavigate>
                      <Reports />
                    </Auth0ProviderWithNavigate>
                  }
                  path="reports"
                >
                  <Route
                    element={<NetworkOverviewReportWrapper />}
                    path="network"
                  />
                  <Route
                    element={<NetworkOverviewReportWrapper />}
                    path="network/:organizationId"
                  />
                  <Route
                    element={<InclusivityScorecardReportWrapper />}
                    path="provider/scorecard"
                  />
                  <Route
                    element={<InclusivityScorecardReportWrapper />}
                    path="provider/:organizationId/scorecard"
                  />
                </Route>
                <Route
                  element={
                    <Auth0ProviderWithNavigate>
                      <App />
                    </Auth0ProviderWithNavigate>
                  }
                  path="*"
                />
              </SentryRoutes>
            </BrowserRouter>
          </OverlayProvider>
        </Sentry.ErrorBoundary>
      </AnalyticsProvider>
    </PostHogProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
