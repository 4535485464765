import { ReactComponent as WarningIcon } from '@material-design-icons/svg/round/warning_amber.svg';
import LeadingHero from 'src/components/heroes/LeadingHero';
import styled from 'styled-components';

export const ErrorState = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  height: 100dvh;
  padding-left: var(--space-6);
  padding-right: var(--space-6);
  text-align: center;
  background-color: var(--purple-600);
`;

export const Hero = styled(LeadingHero)`
  max-width: 34rem;

  h1,
  h2,
  p,
  a {
    color: var(--white-light);
  }
`;

export const Icon = styled(WarningIcon)`
  width: 3rem;
  height: auto;
  margin-bottom: var(--space-4);
  fill: var(--white-light);
`;
