import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const ButtonRadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
`;

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

export const Label = styled.span``;
