import { useRef } from 'react';
import { AriaNumberFieldProps, useLocale, useNumberField } from 'react-aria';
import { useNumberFieldState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props
  extends Pick<
    AriaNumberFieldProps,
    'aria-label' | 'isRequired' | 'label' | 'maxValue' | 'minValue' | 'onChange' | 'value'
  > {
  className?: string;
  'data-cy'?: string;
  labelType?: 'copy-header' | 'form-header';
}

const NumberField = ({
  'aria-label': ariaLabel,
  className,
  'data-cy': dataCy,
  isRequired = false,
  label,
  labelType = 'copy-header',
  maxValue,
  minValue,
  onChange,
  value
}: Props) => {
  const { locale } = useLocale();
  const state = useNumberFieldState({
    isRequired,
    label,
    locale,
    maxValue,
    minValue,
    onChange,
    value
  });
  const ref = useRef<HTMLInputElement>(null);
  const {
    decrementButtonProps,
    errorMessageProps,
    groupProps,
    incrementButtonProps,
    inputProps,
    isInvalid,
    labelProps,
    validationErrors
  } = useNumberField(
    { 'aria-label': ariaLabel, isRequired, label, maxValue, minValue, onChange, value },
    state,
    ref
  );

  return (
    <S.NumberField className={className}>
      {isNonEmptyString(label) && (
        <S.Label
          {...labelProps}
          $type={labelType}
        >
          {label}
          {isRequired && '*'}
        </S.Label>
      )}
      <S.Group {...groupProps}>
        <S.Button {...decrementButtonProps}>
          <S.ArrowCircleLeftIcon />
        </S.Button>
        <S.Input
          {...inputProps}
          ref={ref}
          $isNull={value === minValue}
          data-cy={dataCy}
        />
        <S.Button {...incrementButtonProps}>
          <S.ArrowCircleRightIcon />
        </S.Button>
      </S.Group>
      {isInvalid && (
        <S.ErrorMessage {...errorMessageProps}>{validationErrors.join(' ')}</S.ErrorMessage>
      )}
    </S.NumberField>
  );
};

export default NumberField;
