import { RefObject, useRef } from 'react';
import { AriaListBoxOptions, useListBox } from 'react-aria';
import { SelectState } from 'react-stately';

import * as S from './styles';

interface Props extends AriaListBoxOptions<unknown> {
  className?: string;
  listBoxRef?: RefObject<HTMLUListElement>;
  state: SelectState<object>;
}

const ListBox = ({ className, ...props }: Props) => {
  const ref = useRef<HTMLUListElement>(null);

  const { listBoxRef = ref, state } = props;

  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <S.List
      {...listBoxProps}
      ref={listBoxRef}
      className={className}
      data-cy="select-options"
    >
      {Array.from(state.collection).map(item => (
        <S.Option
          key={item.key}
          item={item}
          state={state}
        />
      ))}
    </S.List>
  );
};

export { ListBox };
