import { Cell, Column, Row, SortDescriptor, TableBody, TableHeader } from 'react-stately';
import Badge from 'src/components/Badge';
import SingleBenchmark from 'src/components/SingleBenchmark';
import { NetworkProvider } from 'src/pages/Dashboard/utils';

import * as Shared from '../styles';

import * as S from './styles';

interface Props {
  currentPageProviders: number;
  handleProvidersTableSortChange?: (descriptor: SortDescriptor) => void;
  handleSetPageProviders: (page: number) => void;
  networkProviders: NetworkProvider[];
  networkProvidersTotalPages: number;
  sortDescriptorProviders?: SortDescriptor;
}

export const NetworkProvidersTable = ({
  currentPageProviders,
  handleProvidersTableSortChange,
  handleSetPageProviders,
  networkProviders,
  networkProvidersTotalPages,
  sortDescriptorProviders
}: Props) => (
  <>
    <S.ProviderTable
      aria-label="Table of network providers"
      data-cy="network-providers-table"
      onSortChange={handleProvidersTableSortChange}
      sortDescriptor={sortDescriptorProviders}
    >
      <TableHeader>
        <Column
          key="full_name"
          allowsSorting
        >
          Name
        </Column>
        <Column
          key="organization_name"
          allowsSorting
        >
          Organization
        </Column>
        <Column>Specialty</Column>
        <Column>
          <Shared.CenterColumn>On Violet</Shared.CenterColumn>
        </Column>
        <Column>
          <Shared.CenterColumn>BIPOC</Shared.CenterColumn>
        </Column>
        <Column>
          <Shared.CenterColumn>LGBQ</Shared.CenterColumn>
        </Column>
        <Column>
          <Shared.CenterColumn>TGNC</Shared.CenterColumn>
        </Column>
      </TableHeader>
      <TableBody>
        {networkProviders.map(provider => {
          const bipocBenchmark = provider.benchmarks.find(
            benchmark => benchmark.scoreType === 'bipoc'
          );
          const lgbqBenchmark = provider.benchmarks.find(
            benchmark => benchmark.scoreType === 'lgbq'
          );
          const tgncBenchmark = provider.benchmarks.find(
            benchmark => benchmark.scoreType === 'tgnc'
          );
          return (
            <Row
              key={provider.id}
              data-cy="network-provider-row"
            >
              <Cell>{provider.name}</Cell>
              <Cell>
                <Shared.TextOverflowWrapper
                  title={provider.organizations.map(organization => organization.name).join(', ')}
                >
                  {provider.organizations.map(organization => organization.name).join(', ')}
                </Shared.TextOverflowWrapper>
              </Cell>
              <Cell>
                <Shared.TextOverflowWrapper title={provider.specialties.join(', ')}>
                  {provider.specialties.join(', ')}
                </Shared.TextOverflowWrapper>
              </Cell>
              <Cell>
                <Shared.CenterColumn>
                  <Badge
                    color={provider.onViolet ? 'green' : 'red'}
                    size="small"
                  >
                    {provider.onViolet ? 'Yes' : 'No'}
                  </Badge>
                </Shared.CenterColumn>
              </Cell>
              <Cell>
                <Shared.CenterColumn>
                  {bipocBenchmark ? (
                    <SingleBenchmark
                      benchmark={bipocBenchmark}
                      data-cy="bipoc-benchmark"
                    />
                  ) : (
                    <S.BenchmarkEmptyState />
                  )}
                </Shared.CenterColumn>
              </Cell>
              <Cell>
                <Shared.CenterColumn>
                  {lgbqBenchmark ? (
                    <SingleBenchmark
                      benchmark={lgbqBenchmark}
                      data-cy="lgbq-benchmark"
                    />
                  ) : (
                    <S.BenchmarkEmptyState />
                  )}
                </Shared.CenterColumn>
              </Cell>
              <Cell>
                <Shared.CenterColumn>
                  {tgncBenchmark ? (
                    <SingleBenchmark
                      benchmark={tgncBenchmark}
                      data-cy="tgnc-benchmark"
                    />
                  ) : (
                    <S.BenchmarkEmptyState />
                  )}
                </Shared.CenterColumn>
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </S.ProviderTable>
    <Shared.TablePagination
      currentPage={currentPageProviders}
      setPage={handleSetPageProviders}
      totalPages={networkProvidersTotalPages}
    />
  </>
);
