import { Link } from 'react-router-dom';
import WhatEducationToAddPopover from 'src/pages/components/EducationExperienceForm/WhatEducationToAddPopover';
import styled from 'styled-components';

import { default as BaseOnboardingHero } from '../../../components/heroes/OnboardingHero';
import {
  DESKTOP_BREAKPOINT_RANGE,
  FOCUS_STYLES,
  MOBILE_BREAKPOINT_RANGE
} from '../../../constants';

export const OnboardingHero = styled(BaseOnboardingHero)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: calc(-1 * (var(--space-8) - var(--space-6)));
  }
`;

export const SkipLink = styled(Link)`
  text-decoration: none;
  color: var(--purple-400);

  &:focus-visible {
    ${FOCUS_STYLES}
  }
`;

export const WhatToAddPopover = styled(WhatEducationToAddPopover)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-top: 0.75rem;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-top: 1rem;
  }
`;
