import styled from 'styled-components';

export const BodyRow = styled.tr`
  background-color: var(--gray-50);

  &.is-selected,
  &:focus-within {
    background-color: var(--blue-25);
  }

  &.is-pressed,
  &:active {
    background-color: var(--blue-50);
  }

  &.has-links.is-hovered {
    background-color: var(--gray-100);
  }

  &.has-row-action.is-hovered {
    cursor: pointer;
  }

  &.is-focused,
  &:focus-visible {
    outline-color: var(--bright-purple-400);
    outline-offset: 0;
    outline-width: 0.125rem;
  }

  td:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  td:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;
