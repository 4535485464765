import { ReactComponent as BaseCheckCircleIcon } from '@material-design-icons/svg/round/check_circle.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import { Bar, ProgressBar as BaseProgressBar, Track } from 'src/components/UnstyledProgressBar';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

const badgeWrapperLengthInRem = '3.75rem';

export const Benchmark = {
  Acronym: styled.div`
    display: inline-block;
    ${TEXT_STYLES.headlineSectionLevel2Regular}
    color: var(--gray-800);

    ${MOBILE_BREAKPOINT_RANGE} {
      margin-right: var(--space-3);
      margin-bottom: var(--space-5);
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      margin-right: var(--space-2);
      margin-bottom: var(--space-4);
    }
  `,
  Badge: styled.img``,
  BadgeLabel: styled.div`
    position: absolute;
    bottom: 0;
    ${TEXT_STYLES.bodyCopySmall}
    text-align: center;
    color: var(--gray-600);
    translate: calc(-50% + ${badgeWrapperLengthInRem} / 2) calc(100% + 0.3125rem);
  `,
  BadgeWrapper: styled(UnstyledButton)`
    position: relative;
    right: calc(-1 * ${badgeWrapperLengthInRem} / 2);
    display: grid;
    place-content: center;
    width: ${badgeWrapperLengthInRem};
    height: ${badgeWrapperLengthInRem};
    background-color: white;
    border-radius: 100%;
    box-shadow:
      0 0 10px rgba(16, 24, 40, 0.1),
      0 6.6667px 10px -3.3333px rgba(16, 24, 40, 0.03);
  `,
  Expansion: styled.div`
    display: inline-block;
    color: var(--gray-800);

    ${MOBILE_BREAKPOINT_RANGE} {
      margin-bottom: var(--space-5);
      ${TEXT_STYLES.bodyCopySmall}
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      margin-bottom: var(--space-4);
      ${TEXT_STYLES.bodyCopyMedium}
    }
  `,
  Popover: {
    CheckCircleIcon: styled(BaseCheckCircleIcon)`
      fill: #61cf5f;
    `,
    EarnedAtDate: styled.div`
      display: flex;
      align-items: center;

      ${MOBILE_BREAKPOINT_RANGE} {
        column-gap: var(--space-2);
      }

      ${DESKTOP_BREAKPOINT_RANGE} {
        column-gap: var(--space-1);
      }
    `,
    Header: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    `,
    Root: styled.div`
      max-width: 21rem;
      font-size: 0.8125rem;
      line-height: 1.4;
      letter-spacing: 0.01em;
      color: var(--gray-700);
    `,
    Text: styled.p`
      margin-bottom: 0;
    `,
    Title: styled.div`
      font-weight: 700;
    `
  },
  Progress: {
    ProgressBar: styled(BaseProgressBar)`
      position: absolute;
      width: 100%;
      padding-right: 0.0625rem;
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
      overflow: hidden;

      ${Bar} {
        width: var(--width);
        height: 100%;
        background-image: linear-gradient(to right, var(--brand-bright) 34.73%, #f6b60d 94.56%);
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
      }

      ${Track} {
        background-color: var(--gray-200);

        ${MOBILE_BREAKPOINT_RANGE} {
          height: var(--space-5);
        }

        ${DESKTOP_BREAKPOINT_RANGE} {
          height: var(--space-4);
        }
      }
    `,
    Root: styled.div`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${badgeWrapperLengthInRem};
    `
  },
  Root: styled.div`
    background-color: var(--gray-25);
    border-radius: 2rem;

    ${MOBILE_BREAKPOINT_RANGE} {
      padding: var(--space-7) calc(var(--space-7) + ${badgeWrapperLengthInRem} / 2) 3.0625rem
        var(--space-7);
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      padding: var(--space-6) calc(var(--space-6) + ${badgeWrapperLengthInRem} / 2) 2.5625rem
        var(--space-6);
    }
  `
};
