import isNonEmptyString from 'src/utils/isNonEmptyString';

import useBreakpointRange from '../../../hooks/useBreakpointRange';
import CommunityIndicators from '../../CommunityIndicators';

import { ReactComponent as BenchmarkingCommunities } from './benchmarking/benchmarking-communities.svg';
import { ReactComponent as BenchmarkingEducation } from './benchmarking/benchmarking-education.svg';
import { ReactComponent as BenchmarkingIdentities } from './benchmarking/benchmarking-identities.svg';
import { ReactComponent as BenchmarkingSelfEfficacy } from './benchmarking/benchmarking-self-efficacy.svg';
import { ReactComponent as BenchmarkingWorkExperience } from './benchmarking/benchmarking-work-experience.svg';
import * as S from './styles';

interface Props {
  className?: string;
  community?: 'BIPOC' | 'LGBQ' | 'TGNC' | null;
  copy: JSX.Element | string;
  graphicType?: 'communities' | 'education' | 'identities' | 'self-efficacy' | 'work experience';
  header: string;
  isClinical?: boolean;
}

const OnboardingHero = ({
  className,
  community,
  copy,
  graphicType,
  header,
  isClinical = true
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  return (
    <S.OnboardingHero
      $hasGraphic={isNonEmptyString(graphicType)}
      className={className}
    >
      <S.TextBlock $hasGraphic={isNonEmptyString(graphicType)}>
        {community && (
          <CommunityIndicators
            community={community}
            direction="row"
            size={isInMobileBreakpointRange ? 'small' : 'regular'}
          />
        )}
        <S.Header data-cy="onboarding-hero-header">{header}</S.Header>
        <S.Copy>{copy}</S.Copy>
      </S.TextBlock>
      {graphicType && (
        <S.Graphic>
          {isClinical && graphicType === 'communities' && <BenchmarkingCommunities />}
          {isClinical && graphicType === 'education' && <BenchmarkingEducation />}
          {isClinical && graphicType === 'self-efficacy' && <BenchmarkingSelfEfficacy />}
          {isClinical && graphicType === 'identities' && <BenchmarkingIdentities />}
          {isClinical && graphicType === 'work experience' && <BenchmarkingWorkExperience />}
        </S.Graphic>
      )}
    </S.OnboardingHero>
  );
};
export default OnboardingHero;
