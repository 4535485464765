import { useCallback, useMemo } from 'react';
import useUser from 'src/hooks/useUser';
import useApiRequest, { filtersToParams } from 'src/pages/Dashboard/hooks/useApiRequest';

import { OrganizationAssignmentUsersFilters } from './useGetOrganizationAssignmentUsers';

type OrganizationAssignmentUsersCsvFilters = Omit<
  OrganizationAssignmentUsersFilters,
  'order_by' | 'page' | 'per_page'
>;

interface UseGetOrganizationAssignmentUsersCsv {
  (): {
    fetchAssignmentUsersCsv: (
      assignmentId: string,
      filters: OrganizationAssignmentUsersCsvFilters,
      organizationId: string
    ) => Promise<Response | undefined>;
  };
}

const useGetOrganizationAssignmentUsersCsv: UseGetOrganizationAssignmentUsersCsv = () => {
  const { reportError } = useApiRequest();
  const { bearerToken } = useUser();
  const fetchOptions: RequestInit = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }),
    [bearerToken]
  );

  const fetchAssignmentUsersCsv = useCallback(
    async (
      assignmentId: string,
      filters: OrganizationAssignmentUsersCsvFilters,
      organizationId: string
    ) => {
      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/organizations/${organizationId}/assignments/${assignmentId}/users/export${params ? `?${params}` : ''}`;

      try {
        return await fetch(url, fetchOptions);
      } catch (error) {
        reportError(error);
      }
    },
    [reportError, fetchOptions]
  );

  return {
    fetchAssignmentUsersCsv
  };
};

export default useGetOrganizationAssignmentUsersCsv;
