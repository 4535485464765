import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const PercentageText = styled.tspan``;

/* eslint-disable sort-exports/sort-exports */
export const LabelNumber = styled.tspan`
  fill: var(--purple-400);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 1;
  text-transform: uppercase;

  & ${PercentageText} {
    fill: var(--gray-500);
    ${TEXT_STYLES.ctaBaseXSmall}
  }
`;

export const LabelText = styled.tspan`
  fill: var(--purple-400);
  font-size: 0.875rem;
  font-weight: 550;
  letter-spacing: 0.01em;
  line-height: ${20 / 14};
`;
