import { Cacheable, OAuthError, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { AnimatePresence, motion, MotionGlobalConfig } from 'framer-motion';
import { usePostHog } from 'posthog-js/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  matchPath,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';

import ErrorModalDialog from './components/ErrorModalDialog';
import ErrorState from './components/ErrorState';
import ForbiddenModalDialog from './components/ForbiddenModalDialog';
import DashboardWrapper from './components/Navigation';
import SignedOutModalDialog from './components/SignedOutModalDialog';
import { FADE_IN_TRANSITION_DURATION_MS, FADE_OUT_TRANSITION_DURATION_MS } from './constants';
import useAnalytics from './hooks/useAnalytics';
import { FeatureFlagsContext } from './hooks/useFeatureFlags';
import { ErrorModalDialogContext } from './hooks/useOpenErrorModalDialog';
import { OpenForbiddenModalDialogContext } from './hooks/useOpenForbiddenModalDialog';
import { SignedOutModalDialogContext } from './hooks/useOpenSignedOutModalDialog';
import { UserContext } from './hooks/useUser';
import Dashboard from './pages/Dashboard';
import DashboardEducationAllEducation from './pages/Dashboard/Education/AllEducation';
import DashboardEducationCollections from './pages/Dashboard/Education/Collections';
import DashboardEducationCollection from './pages/Dashboard/Education/Collections/Collection';
import DashboardEducationCollectionsCollectionAccreditationCertificate from './pages/Dashboard/Education/Collections/Collection/AccreditationCertificate';
import DashboardEducationCollectionsCollectionAccreditationSurvey from './pages/Dashboard/Education/Collections/Collection/AccreditationSurvey';
import DashboardEducationWrapper from './pages/Dashboard/Education/components/Wrapper';
import DashboardEducationEducationalResource from './pages/Dashboard/Education/EducationalResource';
import DashboardEducationPathways from './pages/Dashboard/Education/Pathways';
import DashboardEducationPathway from './pages/Dashboard/Education/Pathways/Pathway';
import DashboardMyNetwork from './pages/Dashboard/MyNetwork';
import DashboardMyNetworkEducationEngagement from './pages/Dashboard/MyNetwork/EducationEngagement';
import DashboardMyNetworkInclusivity from './pages/Dashboard/MyNetwork/Inclusivity';
import DashboardMyNetworkDiversity from './pages/Dashboard/MyNetwork/Inclusivity/Diversity';
import DashboardMyNetworkInclusivityOverview from './pages/Dashboard/MyNetwork/Inclusivity/Overview';
import DashboardMyNetworkInclusivityServiceAreas from './pages/Dashboard/MyNetwork/Inclusivity/ServiceAreas';
import DashboardMyNetworkWrapper from './pages/Dashboard/MyNetwork/Wrapper';
import DashboardMyOrganization from './pages/Dashboard/MyOrganization';
import DashboardMyOrganizationAssignments from './pages/Dashboard/MyOrganization/Assignments';
import DashboardMyOrganizationWrapper from './pages/Dashboard/MyOrganization/components/Wrapper';
import DashboardMyOrganizationDemographics from './pages/Dashboard/MyOrganization/Demographics';
import DashboardMyOrganizationEducationEngagement from './pages/Dashboard/MyOrganization/EducationEngagement';
import DashboardMyOrganizationInclusivity from './pages/Dashboard/MyOrganization/Inclusivity';
import DashboardProfileAdditionalExperience from './pages/Dashboard/Profile/AdditionalExperience';
import DashboardProfileCertifications from './pages/Dashboard/Profile/Certifications';
import DashboardProfileWrapper from './pages/Dashboard/Profile/components/Wrapper';
import DashboardProfileCulturalCompetencies from './pages/Dashboard/Profile/CulturalCompetencies';
import DashboardProfileInformation from './pages/Dashboard/Profile/Information';
import DashboardProfileWorkExperience from './pages/Dashboard/Profile/WorkExperience';
import NotFound from './pages/NotFound';
import OnboardingCulturalCompetencies from './pages/Onboarding/CulturalCompetencies';
import OnboardingEducationExperience from './pages/Onboarding/EducationExperience';
import OnboardingFurtherExperience from './pages/Onboarding/FurtherExperience';
import OnboardingIdentity from './pages/Onboarding/Identity';
import OnboardingInformation from './pages/Onboarding/Information';
import OnboardingSubmitted from './pages/Onboarding/Submitted';
import OnboardingWelcome from './pages/Onboarding/Welcome';
import OnboardingWorkExperience from './pages/Onboarding/WorkExperience';
import getPageTitleAndCategory from './utils/getPageTitleAndCategory';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
if (window.Cypress) {
  // Disable animations in Cypress tests for better reliable tests
  MotionGlobalConfig.skipAnimations = true;
}

const MotionDiv = () => (
  <motion.div
    animate={{
      opacity: 1,
      transition: {
        delay: FADE_OUT_TRANSITION_DURATION_MS / 1000,
        duration: FADE_IN_TRANSITION_DURATION_MS / 1000
      }
    }}
    exit={{
      opacity: 0,
      transition: { duration: FADE_OUT_TRANSITION_DURATION_MS / 1000 }
    }}
    initial={{ opacity: 0 }}
  >
    <Outlet />
  </motion.div>
);

// eslint-disable-next-line react/no-multi-comp
const App = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const accessTokenRef = useRef<string>();
  const isFirstRunRef = useRef(true);

  const { analytics } = useAnalytics();
  const posthog = usePostHog();
  const [error, setError] = useState<Error>();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [featureFlags, setFeatureFlags] = useState<string[]>([]);
  const [wasIdentified, setWasIdentified] = useState(false);

  const {
    error: auth0Error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout
  } = useAuth0();

  const {
    close: closeErrorModalDialog,
    isOpen: errorModalDialogIsOpen,
    open: openErrorModalDialog
  } = useOverlayTriggerState({});

  const [networkParams, setNetworkParams] = useState('');
  const [isNetworkManager, setIsNetworkManager] = useState(false);
  const [isSuperuserOfNetworkOrg, setIsSuperuserOfNetworkOrg] = useState(false);

  const { isOpen: signedOutModalDialogIsOpen, open: openSignedOutModalDialog } =
    useOverlayTriggerState({});
  const {
    close: closeForbiddenModalDialog,
    isOpen: forbiddenModalDialogIsOpen,
    open: openForbiddenModalDialog
  } = useOverlayTriggerState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenErrorModalDialog = useCallback(openErrorModalDialog, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenSignedOutModalDialog = useCallback(openSignedOutModalDialog, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenForbiddenModalDialog = useCallback(openForbiddenModalDialog, []);

  const handleHeadwayLmsRedirect = async () =>
    new Promise(resolve => {
      if (searchParams.get('source') === 'headway_lms') {
        localStorage.setItem('is_headway_lms', 'true');
        localStorage.setItem('redirect_after_onboarding_url', `"${location.pathname}"`);
      }
      resolve(true);
    });

  const refetchUser = useCallback(async () => {
    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${accessTokenRef.current}`
      }
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data: user } = (await response.json()) as APIProfile;

      setUser(user);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }
  }, [openErrorModalDialog, openSignedOutModalDialog]);

  useEffect(() => {
    if (isLoading) return;

    if (auth0Error) {
      setError(auth0Error);
      return;
    }

    const auth0Cypress = localStorage.getItem('auth0Cypress');
    const cypressAuth0Login =
      auth0Cypress === null ? null : (JSON.parse(auth0Cypress) as Cacheable);
    const redirectLoginOptions: RedirectLoginOptions = {
      appState: { returnTo: `${window.location.pathname}${window.location.search}` }
    };
    if (!isAuthenticated && !cypressAuth0Login) {
      handleHeadwayLmsRedirect().then(() => {
        loginWithRedirect(redirectLoginOptions);
      });
      return;
    }

    (async () => {
      try {
        if (cypressAuth0Login) {
          // @ts-expect-error
          accessTokenRef.current = cypressAuth0Login;
        } else {
          accessTokenRef.current = await getAccessTokenSilently({
            authorizationParams: { audience: process.env.REACT_APP_AUTH0_API_AUDIENCE }
          });
        }
      } catch (error_) {
        const error = error_ as OAuthError;

        // This handles the case where the user opens the web app and their session has expired.
        // Since @auth0/auth0-react v2, `isAuthenticated` remains `true` even after the user's session has expired.
        if (error.error === 'login_required') {
          handleHeadwayLmsRedirect().then(() => {
            loginWithRedirect(redirectLoginOptions);
          });
          return;
        }

        setError(error);
        return;
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${accessTokenRef.current}`
        }
      };

      try {
        const response = await fetch(url, options);
        const json = (await response.json()) as APIProfile | { errors?: string[] };

        if (!response.ok) {
          const { errors } = json as { errors?: string[] };
          if (response.status === 401 && !(errors?.includes('SSO Error') ?? false)) {
            openSignedOutModalDialog();
            setError(new Error('401: Unauthenticated'));
          } else {
            throw new Error(`${response.status} (${errors![0] || response.statusText})`);
          }
        } else {
          const { data: user } = json as APIProfile;

          Sentry.setUser({
            email: user.email,
            id: user.id,
            username: `${user.first_name} ${user.last_name}`
          });

          setUser(user);

          const { status: userStatus } = user;

          if (userStatus === 'created') {
            const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile/activate`;

            const options: RequestInit = {
              headers: {
                Authorization: `Bearer ${accessTokenRef.current}`,
                'Content-Type': 'application/json'
              },
              method: 'POST'
            };

            const response = await fetch(url, options);

            if (!response.ok) {
              if (response.status === 401) {
                openSignedOutModalDialog();
              } else {
                throw new Error(`${response.status} (${response.statusText})`);
              }
            }

            const updatedUser = { ...user, status: 'activated' as User['status'] };

            setUser(updatedUser);

            if (user.member_roles.includes('network_manager')) {
              const networkParamsString = `internal_referral=onboarding&is_clinical=${user.is_clinical}&org_role=network_manager`;
              setNetworkParams(networkParamsString);
              if (!window.location.href.includes('internal_referral')) {
                window.history.replaceState(
                  {},
                  '',
                  `${window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}?${networkParamsString}`
                );
              }
            }
          }
        }
      } catch (error) {
        setError(error as Error);
        return;
      }

      const featureFlagUrl: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/feature_flags`;

      try {
        const response = await fetch(featureFlagUrl, options);

        if (!response.ok) {
          const { errors } = (await response.json()) as { errors: string[] };

          throw new Error(`${response.status} (${errors[0] || response.statusText})`);
        }

        const { data } = (await response.json()) as APIFeatureFlags;
        setFeatureFlags(data);
      } catch (error) {
        setError(error as Error);
        return;
      }

      setPageIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Error, getAccessTokenSilently, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (error) {
      setPageIsLoading(false);
      Sentry.captureException(error);
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (!analytics || !user || wasIdentified) return;

    analytics.identify(user.id, {
      account_status: user.status,
      completed_onboarding_at: user.completed_onboarding_at,
      created_at: user.created_at,
      email: user.email,
      first_name: user.first_name,
      hubspot_id: user.hubspot_id,
      is_clinical: user.is_clinical,
      last_name: user.last_name,
      npi: user.user_info.npi,
      primary_role: user.member_roles.includes('network_manager')
        ? 'network_manager'
        : user.member_roles.includes('superuser')
          ? 'superuser'
          : 'member',
      sign_up_source: user.sign_up_source
    });

    analytics.group(user.organization_memberships[0].organization.id, {
      name: user.organization_memberships[0].organization.name
    });

    setWasIdentified(true);
  }, [analytics, user, wasIdentified]);

  useEffect(() => {
    if (!user) {
      return;
    }
    posthog.identify(user.id);
  }, [user, posthog]);

  useEffect(() => {
    if (!pageIsLoading) {
      const pageSpinner = document.querySelector<HTMLDivElement>('#page-spinner');
      const pageSpinnerStyles = document.querySelector<HTMLStyleElement>('#page-spinner-styles');

      // If the App component is mounted a second time, which happens during development because of React Fast Refresh, the page spinner and its styles will already have been removed.
      if (pageSpinner && pageSpinnerStyles) {
        pageSpinner.remove();
        pageSpinnerStyles.remove();
      }
    }
  }, [pageIsLoading]);

  useEffect(() => {
    if (isFirstRunRef.current) {
      isFirstRunRef.current = false;
      return;
    }

    const isOnboarding = location.pathname.split('/')[1] === 'onboarding';

    if (isOnboarding) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, FADE_OUT_TRANSITION_DURATION_MS);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const category = getPageTitleAndCategory(location.pathname);
    analytics?.page(category, document.title);
  }, [location.pathname, analytics]);

  useEffect(() => {
    if (!user) return;

    const networkManagerMembership = user.organization_memberships.find(
      membership => membership.member_role === 'network_manager'
    );
    setIsNetworkManager(networkManagerMembership !== undefined);
    setIsSuperuserOfNetworkOrg(
      user.organization_memberships.some(
        membership =>
          membership.member_role === 'superuser' &&
          membership.organization_id === networkManagerMembership?.organization_id
      )
    );
    if (networkParams && !window.location.href.includes('internal_referral')) {
      window.history.replaceState({}, '', `${window.location.pathname}?${networkParams}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const memoizedUserContextValue = useMemo(
    () => ({
      bearerToken: accessTokenRef.current!,
      refetchUser,
      setUser: setUser as Dispatch<SetStateAction<User>>,
      user: user!
    }),
    [refetchUser, user]
  );

  if (pageIsLoading) return null;

  if (error)
    return (
      <SignedOutModalDialogContext.Provider value={memoizedOpenSignedOutModalDialog}>
        <OpenForbiddenModalDialogContext.Provider value={memoizedOpenForbiddenModalDialog}>
          <ErrorState error={error} />
          {signedOutModalDialogIsOpen && <SignedOutModalDialog />}
          {forbiddenModalDialogIsOpen && <ForbiddenModalDialog close={closeForbiddenModalDialog} />}
        </OpenForbiddenModalDialogContext.Provider>
      </SignedOutModalDialogContext.Provider>
    );

  if (matchPath('/sign-out', window.location.pathname) !== null) {
    logout();
    return null;
  }

  const userHasCompletedOnboarding = user!.completed_onboarding;

  const isEmbeddedEducationPage =
    matchPath('/dashboard/education/:educationalResourceId/view', window.location.pathname) !==
    null;

  return (
    <AnimatePresence
      initial={false}
      mode="wait"
    >
      <SentryRoutes
        key={location.pathname}
        location={location}
      >
        <Route
          element={
            <UserContext.Provider value={memoizedUserContextValue}>
              <FeatureFlagsContext.Provider value={featureFlags}>
                <ErrorModalDialogContext.Provider value={memoizedOpenErrorModalDialog}>
                  <SignedOutModalDialogContext.Provider value={memoizedOpenSignedOutModalDialog}>
                    <OpenForbiddenModalDialogContext.Provider
                      value={memoizedOpenForbiddenModalDialog}
                    >
                      <SentryRoutes location={location}>
                        <Route
                          element={
                            isNetworkManager ? (
                              /*
                              Network managers do not have an onboarding flow
                              If they are also a superuser of the same network org,
                              they should be redirected to the org dashboard
                            */
                              <Navigate
                                to={
                                  isSuperuserOfNetworkOrg
                                    ? `/dashboard/my-organization${networkParams.length > 0 ? `/?${networkParams}` : ''}`
                                    : `/dashboard/my-network${networkParams.length > 0 ? `/?${networkParams}` : ''}`
                                }
                              />
                            ) : (
                              <Navigate
                                to={userHasCompletedOnboarding ? 'dashboard' : 'onboarding/welcome'}
                              />
                            )
                          }
                          path=""
                        />
                        <Route
                          element={
                            userHasCompletedOnboarding || isNetworkManager ? (
                              <DashboardWrapper isHidden={isEmbeddedEducationPage} />
                            ) : (
                              <Navigate to="/onboarding/welcome" />
                            )
                          }
                          path="dashboard/*"
                        >
                          <Route
                            element={
                              isNetworkManager && !location.pathname.includes('my-') ? (
                                <Navigate
                                  to={
                                    isSuperuserOfNetworkOrg
                                      ? `/dashboard/my-organization${networkParams.length > 0 ? `/?${networkParams}` : ''}`
                                      : `/dashboard/my-network${networkParams.length > 0 ? `/?${networkParams}` : ''}`
                                  }
                                />
                              ) : (
                                <Dashboard />
                              )
                            }
                            path=""
                          />
                          <Route path="education/collections/:collectionId/*">
                            <Route
                              element={
                                <DashboardEducationCollectionsCollectionAccreditationCertificate />
                              }
                              path="accreditation-certificate"
                            />
                            <Route
                              element={
                                <DashboardEducationCollectionsCollectionAccreditationSurvey />
                              }
                              path="accreditation-survey"
                            />
                          </Route>
                          <Route
                            element={
                              <DashboardEducationWrapper isHidden={isEmbeddedEducationPage} />
                            }
                            path="education/*"
                          >
                            <Route
                              element={<Navigate to="collections" />}
                              path=""
                            />
                            <Route
                              element={<DashboardEducationAllEducation />}
                              path="all-education/*"
                            >
                              <Route
                                element={<DashboardEducationAllEducation />}
                                path=":courseId"
                              />
                            </Route>
                            <Route path="collections/*">
                              <Route
                                element={<DashboardEducationCollections />}
                                path=""
                              />
                              <Route
                                element={<DashboardEducationCollection />}
                                path=":collectionId/*"
                              />
                              <Route
                                element={<DashboardEducationCollection />}
                                path=":collectionId/:courseId/*"
                              />
                            </Route>
                            <Route path="pathways/*">
                              <Route
                                element={<DashboardEducationPathways />}
                                path=""
                              />
                              <Route
                                element={<DashboardEducationPathway />}
                                path=":pathwayId/*"
                              />
                              <Route
                                element={<DashboardEducationPathway />}
                                path=":pathwayId/:courseId/*"
                              />
                            </Route>
                            <Route
                              element={<DashboardEducationEducationalResource />}
                              path=":educationalResourceId/view"
                            />
                          </Route>
                          <Route
                            element={<DashboardMyNetworkWrapper />}
                            path="my-network/*"
                          >
                            <Route
                              element={<DashboardMyNetwork />}
                              path=""
                            />
                            <Route
                              element={<Navigate to="inclusivity/diversity" />}
                              path="demographics"
                            />
                            <Route
                              element={<DashboardMyNetworkEducationEngagement />}
                              path="education-engagement"
                            />
                            <Route
                              element={<DashboardMyNetworkInclusivity />}
                              path="inclusivity/*"
                            >
                              <Route
                                element={<DashboardMyNetworkInclusivityOverview />}
                                path=""
                              />
                              <Route
                                element={<DashboardMyNetworkInclusivityServiceAreas />}
                                path="service-areas"
                              />
                              <Route
                                element={<DashboardMyNetworkDiversity />}
                                path="diversity"
                              />
                            </Route>
                          </Route>
                          <Route
                            element={<DashboardMyOrganizationWrapper />}
                            path="my-organization/*"
                          >
                            <Route
                              element={<DashboardMyOrganization />}
                              path=""
                            />
                            <Route
                              element={<Navigate to="/dashboard/my-organization/inclusivity" />}
                              path="cultural-competencies"
                            />
                            <Route
                              element={<DashboardMyOrganizationInclusivity />}
                              path="inclusivity"
                            />
                            <Route
                              element={<DashboardMyOrganizationDemographics />}
                              path="demographics"
                            />
                            <Route
                              element={<DashboardMyOrganizationEducationEngagement />}
                              path="education-engagement"
                            />
                            <Route
                              element={<DashboardMyOrganizationAssignments />}
                              path="assignments"
                            >
                              <Route
                                element={<DashboardMyOrganizationAssignments />}
                                path=":assignmentId/*"
                              />
                            </Route>
                          </Route>
                          <Route
                            element={<DashboardProfileWrapper />}
                            path="profile/*"
                          >
                            <Route
                              element={<Navigate to="information" />}
                              path=""
                            />
                            <Route
                              element={<DashboardProfileCertifications />}
                              path="certifications"
                            />
                            <Route
                              element={<DashboardProfileCulturalCompetencies />}
                              path="cultural-competencies"
                            />
                            <Route
                              element={<DashboardProfileInformation />}
                              path="information"
                            />
                            <Route
                              element={<DashboardProfileWorkExperience />}
                              path="work-experience"
                            />
                            <Route
                              element={<DashboardProfileAdditionalExperience />}
                              path="additional-experience"
                            />
                          </Route>
                        </Route>
                        <Route
                          /*
                           * Auth0 by default redirects to /login which does not exist
                           * We have updated our redirect URL to not do so
                           * But in case of regression, a FE failsafe!
                           */
                          element={
                            <Navigate
                              to={userHasCompletedOnboarding ? '/dashboard' : '/onboarding/welcome'}
                            />
                          }
                          path="login"
                        />
                        <Route
                          element={
                            userHasCompletedOnboarding &&
                            window.location.pathname !== '/onboarding/submitted' ? (
                              <Navigate to="/dashboard" />
                            ) : undefined
                          }
                          path="onboarding/*"
                        >
                          <Route
                            element={<Navigate to="welcome" />}
                            path=""
                          />
                          <Route element={<MotionDiv />}>
                            <Route
                              element={<OnboardingCulturalCompetencies />}
                              path="cultural-competencies"
                            />
                            <Route
                              element={<OnboardingEducationExperience />}
                              path="education-experience"
                            />
                            <Route
                              element={<OnboardingFurtherExperience />}
                              path="further-experience"
                            />
                            <Route
                              element={<OnboardingIdentity />}
                              path="identity"
                            />
                            <Route
                              element={<OnboardingInformation />}
                              path="information"
                            />
                            <Route
                              element={<OnboardingSubmitted />}
                              path="submitted"
                            />
                            <Route
                              element={<OnboardingWelcome />}
                              path="welcome"
                            />
                            <Route
                              element={<OnboardingWorkExperience />}
                              path="work-experience"
                            />
                          </Route>
                        </Route>
                        <Route
                          element={<NotFound />}
                          path="*"
                        />
                      </SentryRoutes>
                      {errorModalDialogIsOpen && <ErrorModalDialog close={closeErrorModalDialog} />}
                      {signedOutModalDialogIsOpen && <SignedOutModalDialog />}
                      {forbiddenModalDialogIsOpen && (
                        <ForbiddenModalDialog close={closeForbiddenModalDialog} />
                      )}
                    </OpenForbiddenModalDialogContext.Provider>
                  </SignedOutModalDialogContext.Provider>
                </ErrorModalDialogContext.Provider>
              </FeatureFlagsContext.Provider>
            </UserContext.Provider>
          }
          path="*"
        />
      </SentryRoutes>
    </AnimatePresence>
  );
};

export default App;
