import Logotype from 'src/components/Logotype';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const DataCell = styled.td`
  ${TEXT_STYLES.bodyCopyLarge}
  align-items: center;
  background: var(--white-light);
  border: 1px solid var(--gray-500);
  color: var(--gray-800);
  display: flex;
  flex-shrink: 0;
  gap: 0.625rem;
  justify-content: center;
  letter-spacing: 0.0125rem;
  line-height: 1.75rem;

  /* This is the min width of 3 digits, which is technically possible */
  min-width: 5.8rem;
  padding: 0.75rem 1.25rem;
`;

export const InfoRow = styled.tr`
  display: flex;
  justify-content: space-between;
  width: 100%;

  td:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  td:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

export const LabelCell = styled.td`
  align-items: center;
  background: var(--white-light);
  border: 1px solid var(--gray-500);
  color: var(--gray-800);
  display: flex;
  flex-shrink: 0;
  font-family: 'Recoleta Alt', serif;
  font-size: 1.25rem;
  font-weight: 600;
  gap: 0.625rem;
  justify-content: center;
  letter-spacing: 0.0125rem;
  line-height: 1.75rem;
`;

export const LabelTextWrapping = styled.div`
  padding: 0.8125rem 1.25rem;
`;

export const Logo = styled(Logotype)`
  display: inline-block;
  width: 12.75rem;
  height: 3.8125rem;
`;

export const OrganizationNameDataCell = styled(DataCell)`
  flex-grow: 1;
  justify-content: flex-start;
  min-width: 30rem;
`;

export const ReportHeader = styled.div`
  padding: 2.5rem 0 0;
`;

export const ReportTitle = styled.h1`
  display: inline-block;
  font-family: 'Recoleta Alt', serif;
  font-weight: 400;
  font-size: 4.375rem;
  line-height: 1.4;

  letter-spacing: 0.04rem;

  /* The Report Title is 14.25rem offset of the left, and the logo is 12.75rem wide */
  padding-left: 1.5rem;
  white-space: nowrap;
  height: 6.125rem;
  width: 41.6875rem;
`;

export const ReportTitleContainer = styled.div`
  height: 6.13rem;
  width: 55.9rem;
`;

export const Table = styled.table`
  box-sizing: border-box;
  width: 100%;
`;
