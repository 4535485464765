import { AriaProgressBarProps } from 'react-aria';

import * as S from './styles';

interface Props extends AriaProgressBarProps {
  completed?: number;
  'data-cy'?: string;
  percentage: number;
  total?: number;
}

const MiniPercentageProgressBar = ({
  completed,
  'data-cy': dataCy,
  percentage,
  total,
  ...args
}: Props) => {
  const hasTotals = completed !== undefined && total !== undefined && total > 0;

  return (
    <S.MiniProgressBarWrapper
      className={hasTotals ? 'vertical' : 'horizontal'}
      data-cy={dataCy}
    >
      <S.ProgressBar
        {...args}
        className={percentage === 100 ? 'completed' : undefined}
        value={percentage}
      />
      {percentage === 100 && (
        <S.CheckMarkIcon
          aria-hidden
          role="img"
        />
      )}
      {hasTotals && (
        <div>
          {completed}/{total} completed
        </div>
      )}
    </S.MiniProgressBarWrapper>
  );
};

export default MiniPercentageProgressBar;
