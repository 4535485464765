import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import { Fragment, Key, useState } from 'react';
import { AriaComboBoxProps, AriaSelectProps, AriaTagGroupProps } from 'react-aria';
import { Item } from 'react-stately';
import useUser from 'src/hooks/useUser';
import { LANGUAGES } from 'src/pages/constants';
import { capitalize } from 'src/utils/stringTransformations';

import ContentContainer from '../../ContentContainer';

import ProficiencyPopover from './ProficiencyPopover';
import * as S from './styles';

const NORTHWELL_ORG_ID = 'c841c54a-983b-41e2-887a-71327d1ffe33';

const proficiencyOptions = [
  { description: 'Fully fluent; highly skilled with medical terms.', id: 1, name: 'Excellent' },
  { description: 'Fluent; rare issues with medical terms.', id: 2, name: 'Very good' },
  { description: 'Sufficient ability; some difficulty with medical terms.', id: 3, name: 'Good' },
  { description: 'Basic ability; difficulty with medical terms.', id: 4, name: 'Fair' },
  { description: 'Limited ability; cannot convey medical terms.', id: 5, name: 'Elementary' }
];

const proficiencyOptionsWithUnknown = proficiencyOptions.concat([
  {
    description: 'Option to accomodate for existing language records without a proficiency',
    id: 6,
    name: 'UNKNOWN'
  }
]);

export interface Props {
  addLanguage: (value: { id?: string; language: Key | null; proficiency: Key }) => void;
  className?: string;
  copy: string;
  deleteLanguage: (value: { id?: string; language: Key | null; proficiency: Key }) => void;
  header: string;
  selectedLanguages: {
    id?: string;
    language: Key | null;
    proficiency: Key;
  }[];
}

const LanguageSearch = ({
  addLanguage,
  className,
  copy,
  deleteLanguage,
  header,
  selectedLanguages
}: Props) => {
  const { user } = useUser();
  const [language, setLanguage] = useState<Key | null>();
  const [proficiency, setProficiency] = useState<Key>();

  const languageOptions = LANGUAGES.filter(
    lang => selectedLanguages.findIndex(selection => selection.language === lang) < 0
  ).map((lang, index) => ({ id: index + 1, language: lang }));

  const updateLanguage: AriaComboBoxProps<object>['onSelectionChange'] = value =>
    setLanguage(value);
  const updateProficiency: AriaSelectProps<object>['onSelectionChange'] = value =>
    setProficiency(value);
  const addLanguageProficiency = () => {
    if (proficiency !== undefined && language !== undefined) {
      addLanguage({ language, proficiency });
    }
    setLanguage(undefined);
    setProficiency(undefined);
  };
  const deleteLanguageProficiency: AriaTagGroupProps<object>['onRemove'] = value => {
    deleteLanguage(
      selectedLanguages.find(
        selectedLanguage => selectedLanguage.language === Array.from(value)[0]
      )!
    );
  };

  const selectedLanguageDisplay = proficiencyOptionsWithUnknown.map(proficiency => {
    const languageTags = selectedLanguages.filter(lang => lang.proficiency === proficiency.name);
    if (languageTags.length > 0) {
      return (
        <Fragment key={proficiency.id}>
          <S.ProficiencyHeader>{capitalize(proficiency.name)}</S.ProficiencyHeader>
          <S.LanguagesTagGroup
            aria-label={`List of languages user is ${proficiency.name} at.`}
            data-cy="language-tags"
            onRemove={deleteLanguageProficiency}
            size="small"
            variant="light"
          >
            {languageTags.map(lang => (
              <Item key={lang.language}>{lang.language?.toString()}</Item>
            ))}
          </S.LanguagesTagGroup>
        </Fragment>
      );
    } else {
      return null;
    }
  });

  const isNorthwellUser = user.organization_memberships.some(
    org => org.organization_id === NORTHWELL_ORG_ID
  );

  return (
    <ContentContainer
      className={className}
      data-cy="language-search-container"
      size="small"
    >
      <S.LanguageHero
        copy={
          isNorthwellUser
            ? `${copy} Please refer to Northwell Health Policy 100.27 for information on communication with Limited English Proficient patients.`
            : copy
        }
        title={header}
      />
      <S.SelectionContainer>
        <S.SelectorColumn>
          <S.LanguageComboBox
            aria-label="Search for a language"
            data-cy="language-combobox"
            icon={SearchIcon}
            onSelectionChange={updateLanguage}
            placeholder="Search for a language"
            // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
            //@ts-expect-error
            selectedKey={language ?? null}
          >
            {languageOptions.map(({ language }) => (
              <Item key={language}>{language}</Item>
            ))}
          </S.LanguageComboBox>
          <S.ProficiencySelect
            aria-label="Select your proficiency in the selected language"
            data-cy="proficiency-select"
            isDisabled={language === undefined}
            items={proficiencyOptions}
            onSelectionChange={updateProficiency}
            placeholder="Select proficiency"
            // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
            //@ts-expect-error
            selectedKey={proficiency ?? null}
          >
            {proficiencyOptions.map(({ description, name }) => (
              <S.ProficiencyItem
                key={name}
                textValue={name}
              >
                <S.ProficiencyItemName>{name}</S.ProficiencyItemName>
                <S.ProficiencyItemDesc>{description}</S.ProficiencyItemDesc>
              </S.ProficiencyItem>
            ))}
          </S.ProficiencySelect>
          <ProficiencyPopover />
          <S.SubmitButton
            data-cy="add-language-button"
            isDisabled={language === undefined || proficiency === undefined}
            onPress={addLanguageProficiency}
            variant="outline"
          >
            Add language
          </S.SubmitButton>
        </S.SelectorColumn>
        <S.SelectedContainer className={selectedLanguages.length > 0 ? 'has-selections' : 'empty'}>
          {selectedLanguageDisplay}
        </S.SelectedContainer>
      </S.SelectionContainer>
    </ContentContainer>
  );
};

export default LanguageSearch;
