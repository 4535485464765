export const BREAKPOINT = 768;

export const DESKTOP_BREAKPOINT_RANGE = `@media (min-width: ${BREAKPOINT}px)`;

export const FADE_IN_TRANSITION_DURATION_MS = 600;

export const FADE_OUT_TRANSITION_DURATION_MS = 300;

export const FOCUS_STYLES = `
  outline-offset: 0.25rem;
  outline: 0.125rem solid var(--brand-bright);
  border-radius: 0.25rem;
`;

export const MOBILE_BREAKPOINT_RANGE = `@media (max-width: ${BREAKPOINT - 1}px)`;

const sharedBodyCopyStyles = `
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
`;

const sharedCtaBaseStyles = `
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`;

const sharedDisplayHeadlineLevel1Styles = `
  font-weight: 700;
  line-height: 1.6;
`;

const sharedDisplayHeadlineLevel2Styles = `
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
`;

const sharedFormsLabelStyles = `
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
`;

const sharedHeadlineSectionStyles = `
  line-height: 1.2;
  letter-spacing: 0.01em;
`;

const sharedHeadlineStandardStyles = `
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.01em;
`;

const sharedTagStyles = `
  font-weight: 500;
  letter-spacing: 0.01em;
`;

const sharedTitleStyles = `
  font-size: 0.8125rem;
  line-height: 1.125rem;
  letter-spacing: 0.01em;
`;

export const TEXT_STYLES = {
  bodyCopyLarge: `
    ${sharedBodyCopyStyles}
    font-size: 1.25rem;
  `,
  bodyCopyMedium: `
    ${sharedBodyCopyStyles}
    font-size: 0.875rem;
  `,
  bodyCopyRegular: `
    ${sharedBodyCopyStyles}
    font-size: 1rem;
  `,
  bodyCopySmall: `
    ${sharedBodyCopyStyles}
    font-size: 0.75rem;
  `,
  bodyCopyXXSmall: `
    ${sharedBodyCopyStyles}
    font-size: 0.5rem;
  `,
  buttonTextLarge: `
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  `,
  buttonTextLargeScreen: `
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
  `,
  buttonTextRegular: `
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  `,
  buttonTextSmall: `
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  `,
  buttonTextSmallScreen: `
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 20px;
  `,
  buttonTextSmallScreenLarge: `
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 20px;
  `,
  buttonTextXSmall: `
    font-size: 0.5rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  `,
  ctaBaseLarge: `
    ${sharedCtaBaseStyles}
    font-size: 1.5rem;
  `,
  ctaBaseRegular: `
    ${sharedCtaBaseStyles}
    font-size: 1.25rem;
  `,
  ctaBaseSmall: `
    ${sharedCtaBaseStyles}
    font-size: 1rem;
  `,
  ctaBaseXLarge: `
    ${sharedCtaBaseStyles}
    font-size: 2rem;
  `,
  ctaBaseXSmall: `
    ${sharedCtaBaseStyles}
    font-size: 0.75rem;
  `,
  ctaBaseXxsmall: `
    ${sharedCtaBaseStyles}
    font-size: 0.5rem;
  `,
  displayHeadlineLevel1Large: `
    ${sharedDisplayHeadlineLevel1Styles}
    font-size: 6rem;
  `,
  displayHeadlineLevel1Regular: `
    ${sharedDisplayHeadlineLevel1Styles}
    font-size: 4.5rem;
  `,
  displayHeadlineLevel2Large: `
    ${sharedDisplayHeadlineLevel2Styles}
    font-size: 4rem;
  `,
  displayHeadlineLevel2Regular: `
    ${sharedDisplayHeadlineLevel2Styles}
    font-size: 3rem;
  `,
  displaySubheadlineRegBold: `
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.01em;
  `,
  flagSmall: `
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
  `,
  formsLabelLarge: `
    ${sharedFormsLabelStyles}
    font-size: 1.25rem;
  `,
  formsLabelLargeCaps: `
    ${sharedFormsLabelStyles}
    font-size: 1.25rem;
    text-transform: uppercase;
  `,
  formsLabelMediumSmallCaps: `
    ${sharedFormsLabelStyles}
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
  `,
  formsLabelRegular: `
    ${sharedFormsLabelStyles}
    font-size: 0.875rem;
  `,
  formsLabelRegularCaps: `
    ${sharedFormsLabelStyles}
    font-size: 1rem;
    text-transform: uppercase;
  `,
  formsLabelSmall: `
    ${sharedFormsLabelStyles}
    font-size: 0.75rem;
  `,
  formsLabelSmallCaps: `
    ${sharedFormsLabelStyles}
    font-size: 0.75rem;
    text-transform: uppercase;
  `,
  headlineSectionLevel1Large: `
    ${sharedHeadlineSectionStyles}
    font-size: 2.5rem;
    font-weight: 700;
  `,
  headlineSectionLevel1Regular: `
    ${sharedHeadlineSectionStyles}
    font-size: 2rem;
    font-weight: 700;
  `,
  headlineSectionLevel2Bold: `
    ${sharedHeadlineSectionStyles}
    font-size: 1.25rem;
    font-weight: 700;
  `,
  headlineSectionLevel2Large: `
    ${sharedHeadlineSectionStyles}
    font-size: 1.5rem;
    font-weight: 500;
  `,
  headlineSectionLevel2Med: `
    ${sharedHeadlineSectionStyles}
    font-size: 1.5rem;
    font-weight: 500;
  `,
  headlineSectionLevel2Regular: `
    ${sharedHeadlineSectionStyles}
    font-size: 1.25rem;
    font-weight: 500;
  `,
  headlineStandardLevel1Large: `
    ${sharedHeadlineStandardStyles}
    font-size: 4rem;
  `,
  headlineStandardLevel1Regular: `
    ${sharedHeadlineStandardStyles}
    font-size: 3rem;
  `,
  headlineStandardLevel2BoldLarge: `
    ${sharedHeadlineStandardStyles}
    font-size: 2.5rem;
  `,
  headlineStandardLevel2BoldRegular: `
    ${sharedHeadlineStandardStyles}
    font-size: 2rem;
  `,
  headlineStandardLevel2Regular: `
    ${sharedHeadlineStandardStyles}
    font-weight: 400;
    font-size: 2rem;
  `,
  tagLarge: `
    ${sharedTagStyles}
    font-size: 0.875rem;
    line-height: ${20 / 14};
  `,
  tagSmall: `
    ${sharedTagStyles}
    font-size: 0.6875rem;
    line-height: ${16 / 11};
  `,
  tooltipText: `
    ${sharedTitleStyles}
    font-weight: 400;
  `,
  tooltipTitle: `
    ${sharedTitleStyles}
    font-weight: 500;
  `
};
