import { ReactComponent as SearchImage } from '@material-design-icons/svg/round/search.svg';
import { transparentize } from 'polished';
import { PURPLE_400_COLOR } from 'src/components/GlobalStyle';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const EmptyState = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #f9f9f9;
  border-radius: 0.625rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    column-gap: 1.125rem;
    padding: 2.25rem 1.25rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    column-gap: 1.75rem;
    padding: 2.75rem 3.0625rem;
  }
`;

export const Image = styled(SearchImage)`
  background-color: ${transparentize(0.6, PURPLE_400_COLOR)};
  fill: var(--white-light);
  border-radius: 100%;
  padding: 1rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 5rem;
    height: 5rem;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    width: 4rem;
    height: 4rem;
  }
`;

export const Text = styled.p`
  ${TEXT_STYLES.bodyCopyLarge}
  margin-bottom: 0;
`;

export const Title = styled.p`
  ${TEXT_STYLES.headlineStandardLevel2BoldRegular}

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 0.3125rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: 0.75rem;
  }
`;
