import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import capitalize from 'lodash/capitalize';
import { Key, useEffect, useRef, useState } from 'react';
import { AriaComboBoxProps, AriaSearchFieldProps } from 'react-aria';
import { Item, Section, useAsyncList } from 'react-stately';
import MultiComboBox from 'src/components/FormFields/MultiComboBox';
import useUser from 'src/hooks/useUser';
import { STATES } from 'src/pages/constants';
import isNonEmptyString from 'src/utils/isNonEmptyString';

interface Props {
  ariaLabel?: string;
  className?: string;
  dataCy?: string;
  handleSelectionChange: (newValues: Iterable<Key> | null) => void;
  label?: string;
  placeholder?: string;
  selectedKeys?: Set<Key>;
}

interface LocationObject {
  state_code: string;
  type?: string;
  value: string;
}

interface LocationSection {
  children: LocationObject[];
  name: string;
}

const LocationsFilter = ({
  ariaLabel,
  className,
  dataCy,
  handleSelectionChange: handleUpstreamSelectionChange,
  label,
  placeholder,
  selectedKeys: initialSelectedKeys,
  ...args
}: Props) => {
  const { bearerToken } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };

  const [selectedKeys, setSelectedKeys] = useState<Set<Key>>(new Set(initialSelectedKeys));
  const [searchAndSelectionItems, setSearchAndSelectionItems] = useState<LocationSection[]>([]);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const handleSelectionChange: AriaComboBoxProps<string>['onSelectionChange'] = (
    value: Key | null
  ) => {
    if (value === null) return;
    if (value === 'all') {
      handleUpstreamSelectionChange(new Set([]));
      setSelectedKeys(new Set([]));
    } else {
      const newSelected = new Set(selectedKeys);
      if (newSelected.has(value)) {
        newSelected.delete(value);
      } else {
        newSelected.add(value);
      }
      setSelectedKeys(newSelected);
      handleUpstreamSelectionChange(newSelected);
    }
  };

  const handleSearchValueChange: AriaSearchFieldProps['onChange'] = value => {
    locationList.setFilterText(value);
    if (!isNonEmptyString(value)) {
      handleSelectionChange(null);
      locationList.setFilterText('');
    }
  };

  const selectedKeyToLocationObject = (key: Key) => {
    // States are Type|StateCode
    // All others are Type|Value|StateCode
    const [childType, childValue, childStateCode] = key.toString().split('|') as [
      string,
      string,
      string | undefined
    ];
    return {
      state_code: childStateCode ?? childValue,
      type: childType,
      value:
        childType === 'State'
          ? STATES.find(state => state.abbreviation === childValue)?.name
          : childValue
    };
  };

  const locationList = useAsyncList({
    async load({ filterText, signal }) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      return new Promise(resolve => {
        timeoutRef.current = setTimeout(async () => {
          try {
            const queryString = isNonEmptyString(filterText) ? `query=${filterText}` : '';
            const res = await fetch(
              `${process.env.REACT_APP_API_V2_BASE_PATH}/user_locations${
                queryString ? `?${queryString}` : ''
              }`,
              { ...fetchOptions, signal }
            );

            const json = (await res.json()) as
              | { data: APIUserLocations['data'] | undefined }
              | undefined;
            if (json === undefined || json.data === undefined) {
              resolve({ items: [] });
              return;
            }

            const resources = json.data;
            const allResourceItems = [
              { children: resources['cities'], name: 'cities' },
              { children: resources['counties'], name: 'counties' },
              { children: resources['states'], name: 'states' },
              { children: resources['zips'], name: 'zips' }
            ] as LocationSection[];

            resolve({
              items: allResourceItems
            });
          } catch (error) {
            if (!signal.aborted) {
              console.error('Error fetching locations:', error);
            }
            resolve({ items: [] });
          }
        }, 500);
      });
    }
  });

  useEffect(() => {
    // Create selected items section only if there are selected keys
    if (selectedKeys.size > 0) {
      const selectedItems = {
        children: Array.from(selectedKeys).map(value => selectedKeyToLocationObject(value)),
        name: 'Selected'
      };
      // Update items array immutably
      const updatedItems = [
        selectedItems,
        ...locationList.items.filter(item => (item as LocationSection).name !== 'Selected')
      ];
      setSearchAndSelectionItems(updatedItems as LocationSection[]);
    } else {
      // Remove selected items section if there are no selected keys
      const updatedItems = locationList.items.filter(
        item => (item as LocationSection).name !== 'Selected'
      );
      setSearchAndSelectionItems(updatedItems as LocationSection[]);
    }
  }, [locationList.items, selectedKeys]);

  return (
    <MultiComboBox
      allowsCustomValue
      aria-label={ariaLabel}
      className={className}
      data-cy={dataCy}
      icon={SearchIcon}
      inputValue={locationList.filterText}
      items={searchAndSelectionItems as Iterable<LocationSection>}
      label={label}
      onInputChange={handleSearchValueChange}
      onSelectionChange={(value: Key | null) => {
        // @ts-expect-error
        handleSelectionChange(value);
        locationList.setFilterText('');
      }}
      placeholder={`${placeholder} ${Array.from(selectedKeys).length > 0 ? `(${Array.from(selectedKeys).length} location${Array.from(selectedKeys).length > 1 ? 's' : ''} selected)` : ''}`}
      selectedKeys={selectedKeys}
      {...args}
    >
      {item => (
        <Section
          key={`section-${(item as LocationSection).name}`}
          title={capitalize((item as LocationSection).name)}
        >
          {(item as LocationSection).children.map((child: LocationObject) => {
            const typeToMatch = (item as LocationSection).name;
            const childType =
              child.type !== undefined
                ? child.type
                : typeToMatch === 'cities'
                  ? 'City'
                  : typeToMatch === 'counties'
                    ? 'County'
                    : typeToMatch === 'states'
                      ? 'State'
                      : 'Zip';
            return (
              <Item
                key={
                  childType === 'State'
                    ? `${childType}|${child.state_code}`
                    : `${childType}|${child.value}|${child.state_code}`
                }
                textValue={
                  childType === 'State' ? child.state_code : `${child.value}, ${child.state_code}`
                }
              >
                {child.value}
                {childType !== 'State' && `, ${child.state_code.toUpperCase()}`}
              </Item>
            );
          })}
        </Section>
      )}
    </MultiComboBox>
  );
};

export default LocationsFilter;
