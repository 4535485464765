import { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  'data-cy'?: string;
}

const DataCard = ({ children, ...args }: PropsWithChildren<Props>) => (
  <S.DataCard {...args}>{children}</S.DataCard>
);

export default DataCard;
