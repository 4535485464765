import { ReactComponent as ScheduleIcon } from '@material-design-icons/svg/round/schedule.svg';
import { ReactComponent as VerifiedIcon } from '@material-design-icons/svg/round/verified.svg';
import { Item } from 'react-stately';
import { EducationalResource } from 'src/pages/Dashboard/utils';
import getContentMediumTag from 'src/utils/getContentMediumTag';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  collectionId?: string;
  course: EducationalResource;
  nextCoursePath?: string;
}

const MiniCourseCard = ({ collectionId, course, nextCoursePath }: Props) => (
  <S.MiniCourseCard.Container
    data-cy="mini-course-card"
    to={
      isNonEmptyString(nextCoursePath)
        ? nextCoursePath
        : isNonEmptyString(collectionId)
          ? `/dashboard/education/collections/${collectionId}/${course.id}`
          : `/dashboard/education/all-education/${course.id}`
    }
  >
    <S.MiniCourseCard.Title data-cy="mini-course-card-title">{course.title}</S.MiniCourseCard.Title>
    <S.MiniCourseCard.Tags
      aria-label="Content medium, number of credits, and duration"
      data-cy="mini-course-card-tags"
      variant="light"
    >
      {getContentMediumTag(course.contentMedium)}
      <Item textValue={course.credits}>
        <VerifiedIcon
          aria-hidden="true"
          role="img"
        />
        {course.credits}
      </Item>
      <Item textValue={course.length}>
        <ScheduleIcon
          aria-hidden="true"
          role="img"
        />
        {course.length}
      </Item>
    </S.MiniCourseCard.Tags>
  </S.MiniCourseCard.Container>
);

export default MiniCourseCard;
