import camelCase from 'lodash/camelCase';

type CamelizeObjectKeys = (obj?: object[] | object) => object[] | object | undefined;

export const camelizeObjectKeys: CamelizeObjectKeys = obj => {
  if (Array.isArray(obj)) {
    return (obj as object[]).map(arrayItem => camelizeObjectKeys(arrayItem));
  } else if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeObjectKeys(obj[key as keyof typeof obj])
      }),
      {}
    );
  }
  return obj;
};
