import { Fragment, useEffect, useRef } from 'react';
import { useProgressBar } from 'react-aria';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import * as S from './styles';

interface Props {
  className?: string;
  currentStep: number;
  labels: string[];
}

const ProgressBar = ({ className, currentStep, labels }: Props) => {
  const progressBar = useRef<HTMLDivElement>(null);
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const { progressBarProps } = useProgressBar({
    'aria-label': 'Onboarding progress',
    maxValue: labels.length,
    minValue: 0
  });

  useEffect(() => {
    const currentElement = progressBar.current;
    currentElement?.scrollTo({
      behavior: 'smooth',
      left: (progressBar.current?.scrollWidth ?? 1) * (currentStep / 7)
    });
  }, [currentStep, progressBar]);

  return (
    <S.ProgressBar
      {...progressBarProps}
      ref={progressBar}
      className={className}
    >
      {labels.map((label, index) => (
        <Fragment key={`progress-block-${index}`}>
          <S.ProgressItem key={`step-${index}`}>
            <S.ProgressCircle
              className={
                index < currentStep
                  ? 'complete'
                  : index > currentStep
                    ? 'incomplete'
                    : 'in-progress'
              }
            >
              {index < currentStep && <S.CheckIcon />}
            </S.ProgressCircle>
            <S.Label>{label}</S.Label>
          </S.ProgressItem>
          {index < labels.length - 1 && isInDesktopBreakpointRange && (
            <S.Divider
              key={`divider-${index}`}
              className={index <= currentStep ? 'green' : 'gray'}
            />
          )}
        </Fragment>
      ))}
    </S.ProgressBar>
  );
};

export default ProgressBar;
