export const downloadCsv = async (response: Response, fileName: string) => {
  const csvStream = await response.blob();
  const csvContent = new Blob([csvStream]);

  const csvUrl = window.URL.createObjectURL(csvContent);

  const link = document.createElement('a');
  link.href = csvUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(csvUrl);
};
