import { ReactComponent as CreditIcon } from '@material-design-icons/svg/filled/verified.svg';
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as CommunitiesIcon } from '@material-design-icons/svg/round/group.svg';
import { ReactComponent as ScheduleIcon } from '@material-design-icons/svg/round/schedule.svg';
import { ReactComponent as AccreditationIcon } from '@material-design-icons/svg/round/school.svg';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useState } from 'react';
import { Item, useOverlayTriggerState } from 'react-stately';
import TagGroup from 'src/components/TagGroup';
import useUser from 'src/hooks/useUser';
import { Collection } from 'src/pages/Dashboard/utils';
import getLevelTag from 'src/utils/getLevelTag';
import getSpecialtyTag from 'src/utils/getSpecialityTag';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import BeginEvaluationModalDialog from '../BeginEvaluationModalDialog';

import * as S from './styles';

interface Props {
  collection: Collection;
}

const OldCollectionCard = ({ collection }: Props) => {
  const {
    close: closeBeginEvaluationModalDialog,
    isOpen: beginEvaluationModalDialogIsOpen,
    open: openBeginEvaluationModalDialog
  } = useOverlayTriggerState({});

  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken } = useUser();

  const {
    bookmarks,
    canBeEvaluated,
    certificates,
    communities,
    credits,
    description,
    duration,
    evaluation,
    id,
    isAccredited,
    isNew,
    isPopular,
    level,
    progress,
    specialties,
    title
  } = collection;

  const downloadUrl =
    certificates.length > 0
      ? `${process.env.REACT_APP_BASE_PATH}${certificates[0]?.file.url}`
      : undefined;

  const assignments = bookmarks.filter(bookmark => bookmark.assigned_by_name !== null);
  const isAssigned = assignments.length > 0;
  const bookmark = bookmarks.find(
    bookmark => bookmark.user_id !== null && bookmark.assigned_by_name === null
  );

  const [bookmarkId, setBookmarkId] = useState(bookmark ? bookmark.id : undefined);
  const [bookmarkIsDisabled, setBookmarkIsDisabled] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(bookmark !== undefined);

  const handleStartAssessmentButtonPress = () => {
    openBeginEvaluationModalDialog();
  };

  const handleBookmarkPress = async () => {
    setBookmarkIsDisabled(true);

    if (isBookmarked) {
      setIsBookmarked(false);

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/bookmarks/${bookmarkId}`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        },
        method: 'DELETE'
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        setBookmarkId(undefined);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
        setIsBookmarked(true);
      }
    } else {
      setIsBookmarked(true);

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/bookmarks`;

      const options: RequestInit = {
        body: JSON.stringify({
          bookmark: {
            resource_id: id,
            resource_type: 'CourseCollection'
          }
        }),
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data } = (await response.json()) as APIBookmarks;
        setBookmarkId(data.id);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
        setIsBookmarked(false);
      }
    }

    setBookmarkIsDisabled(false);
  };

  const showAccreditationAssessment = canBeEvaluated && isAccredited;
  const showDownloadButton = isAccredited && downloadUrl !== undefined && progress === 100;

  const communitiesTagText = communities.map(community => community.toUpperCase()).join('/');

  return (
    <>
      <S.CollectionCard
        data-cy={clsx('collection-card', { 'accredited-collection-card': isAccredited })}
      >
        <S.Header>
          {(isNew || isPopular) && (
            <S.EducationTagGroup
              aria-label="Education tags"
              data-cy={`education-${isNew ? 'new' : 'popular'}-tag`}
              variant={isNew ? 'green' : 'blue'}
            >
              {isNew ? (
                <Item textValue="New">New</Item>
              ) : (
                <Item textValue="Trending">Trending</Item>
              )}
            </S.EducationTagGroup>
          )}
          {isAssigned && <S.Assigned assignments={assignments} />}
          <S.Bookmark
            isDisabled={bookmarkIsDisabled}
            isSelected={isBookmarked}
            onPress={handleBookmarkPress}
          />
        </S.Header>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        <S.Tags data-cy="collection-tags">
          {isAccredited && (
            <TagGroup
              aria-label="CE/CME accredited"
              data-cy="accredited-tag"
              variant="light"
            >
              <Item textValue="CE/CME accredited">
                <AccreditationIcon
                  aria-hidden="true"
                  role="img"
                />
                CE/CME accredited
              </Item>
            </TagGroup>
          )}
          {credits && (
            <TagGroup
              aria-label="Credits"
              data-cy="credits-tag"
              variant="light"
            >
              <Item textValue={credits}>
                <CreditIcon
                  aria-hidden="true"
                  role="img"
                />
                {credits}
              </Item>
            </TagGroup>
          )}
          <TagGroup
            aria-label="Duration"
            data-cy="duration-tag"
            variant="light"
          >
            <Item textValue={`${duration} hour${duration === 1 ? '' : 's'}`}>
              <ScheduleIcon
                aria-hidden="true"
                role="img"
              />
              {`${duration} hour${duration === 1 ? '' : 's'}`}
            </Item>
          </TagGroup>
          {isNonEmptyString(communitiesTagText) && (
            <TagGroup
              aria-label="Community tags"
              data-cy="communities-tag"
              variant="light"
            >
              <Item textValue={communitiesTagText}>
                <CommunitiesIcon
                  aria-hidden="true"
                  role="img"
                />
                {communitiesTagText}
              </Item>
            </TagGroup>
          )}
          {level && isNonEmptyString(level) && (
            <TagGroup
              aria-label="Level"
              data-cy="level-tag"
              variant="light"
            >
              {getLevelTag(level)}
            </TagGroup>
          )}
          {specialties.length > 0 && (
            <TagGroup
              aria-label="Specialties"
              data-cy="specialty-tags"
              variant="light"
            >
              {specialties.map(specialty => getSpecialtyTag(specialty))}
            </TagGroup>
          )}
        </S.Tags>
        <S.ProgressBar value={progress} />
        <S.ButtonWrapper
          $isAccredited={showAccreditationAssessment || showDownloadButton || evaluation !== null}
        >
          {showAccreditationAssessment && (
            <S.Button
              data-cy="claim-ce-cme-credit-button"
              fullWidth
              onPress={handleStartAssessmentButtonPress}
              size="large"
              variant="primary"
            >
              Claim CE/CME credit
            </S.Button>
          )}
          {showDownloadButton && (
            <S.DownloadButton
              data-cy="download-certificate-button"
              fullWidth
              href={downloadUrl}
              size="large"
              target="_blank"
              trailingIcon={DownloadIcon}
              variant="primary"
            >
              Download certificate
            </S.DownloadButton>
          )}
          {isAccredited && evaluation && downloadUrl === undefined && (
            <S.DownloadButton
              data-cy="generate-certificate-button"
              fullWidth
              size="large"
              to={`/dashboard/education/collections/${id}/accreditation-certificate`}
              variant="primary"
            >
              Generate certificate
            </S.DownloadButton>
          )}
          {progress < 100 && (
            <S.Button
              fullWidth
              size="large"
              to={`/dashboard/education/collections/${id}`}
              variant={progress === 0 ? 'primary' : 'outline'}
            >
              {progress === 0 ? 'Start' : 'Resume'}
            </S.Button>
          )}
          {progress === 100 && (
            <S.Button
              data-cy="view-button"
              fullWidth
              size="large"
              to={`/dashboard/education/collections/${id}`}
              variant="outline"
            >
              View
            </S.Button>
          )}
        </S.ButtonWrapper>
      </S.CollectionCard>
      {beginEvaluationModalDialogIsOpen && (
        <BeginEvaluationModalDialog
          close={closeBeginEvaluationModalDialog}
          collectionId={id}
        />
      )}
    </>
  );
};

export default OldCollectionCard;
