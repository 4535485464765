import { VisuallyHidden as BaseVisuallyHidden } from 'react-aria';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../constants';

const gapBetweenVisualSelectionIndicatorAndErrorBubble = 0.25;
const hiddenInputWidth = 0.0625;
const visualSelectionIndicatorLength = {
  regular: 1.25,
  small: 1
};

export const Input = styled.input`
  width: ${hiddenInputWidth}rem;
  height: ${hiddenInputWidth}rem;
`;

interface RadioProps {
  $size: 'regular' | 'small';
}

export const Radio = styled.label<RadioProps>`
  position: relative;
  display: inline-flex;
  column-gap: var(--space-2);
  align-items: center;
  color: var(--gray-800);

  ${({ $size }) => {
    if ($size === 'small') {
      return `
        ${TEXT_STYLES.bodyCopySmall}
      `;
    } else {
      return `
        ${TEXT_STYLES.bodyCopyRegular}
      `;
    }
  }}
`;

interface VisuallyHiddenProps {
  $size: 'regular' | 'small';
}

export const VisuallyHidden = styled(BaseVisuallyHidden)<VisuallyHiddenProps>`
  ${({ $size }) => {
    if ($size === 'small') {
      return `
        top: ${
          visualSelectionIndicatorLength.small + gapBetweenVisualSelectionIndicatorAndErrorBubble
        }rem;
        left: ${(visualSelectionIndicatorLength.small - hiddenInputWidth) / 2}rem;
      `;
    }
  }}

  ${({ $size }) => {
    if ($size === 'regular') {
      return `
        top: ${
          visualSelectionIndicatorLength.regular + gapBetweenVisualSelectionIndicatorAndErrorBubble
        }rem;
        left: ${(visualSelectionIndicatorLength.regular - hiddenInputWidth) / 2}rem;
      `;
    }
  }}
`;

interface VisualSelectionIndicatorProps {
  $isFocused: boolean;
  $isSelected: boolean;
  $size: 'regular' | 'small';
}

export const VisualSelectionIndicator = styled.div<VisualSelectionIndicatorProps>`
  background-color: var(--white-light);
  border: 1px solid var(--purple-400);
  border-radius: 100%;

  ${({ $size }) => {
    if ($size === 'small') {
      return `
        min-width: ${visualSelectionIndicatorLength.small}rem;
        height: ${visualSelectionIndicatorLength.small}rem;
      `;
    } else {
      return `
        min-width: ${visualSelectionIndicatorLength.regular}rem;
        height: ${visualSelectionIndicatorLength.regular}rem;
      `;
    }
  }}

  ${({ $isFocused }) =>
    $isFocused &&
    `
    border-width: 2px;
    border-color: var(--brand-bright);
  `}

  ${({ $isSelected }) =>
    $isSelected &&
    `
    background-color: var(--purple-400);
  `}
`;
