import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaComboBoxProps,
  AriaSearchFieldProps,
  useNumberFormatter,
  VisuallyHidden
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import {
  Cell,
  Column,
  Item,
  Key,
  Row,
  SortDescriptor,
  TableBody,
  TableHeader
} from 'react-stately';
import Button from 'src/components/Buttons/Button';
import FilterDropdown from 'src/components/FilterDropdown';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import ComboBox from 'src/components/FormFields/ComboBox';
import SearchField from 'src/components/FormFields/SearchField';
import PageTitle from 'src/components/PageTitle';
import Pagination from 'src/components/Pagination';
import PopoverTrigger from 'src/components/PopoverTrigger';
import CommunityGraphs from 'src/components/Reporting/CommunityGraphs';
import DataCard from 'src/components/Reporting/DataCard';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Table from 'src/components/Table';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import UnstyledButton from 'src/components/UnstyledButton';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { LANGUAGES } from 'src/pages/constants';
import { filtersObjectToParams, sortFilterParams } from 'src/utils/filterUtils';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { sortedParams } from 'src/utils/sortedParams';

import Spinner from '../../../../components/Spinner';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';
import { downloadCsv } from '../../../../utils/downloadCsv';
import TableEmptyState from '../../components/TableEmptyState';
import useGetOrganizationOverview from '../../hooks/useGetOrganizationOverview';
import useGetOrganizationUsers from '../../hooks/useGetOrganizationUsers';
import { Organization, OrganizationUser } from '../../utils';
import { organizationFiltersToSearch, organizationSearchToFilters } from '../organizationFilters';

import * as S from './styles';

const Inclusivity = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();
  const featureFlags = useFeatureFlags();
  const [searchParams, setSearchParams] = useSearchParams();

  const organizationId = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization_id;
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization.name;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('order_by[column]') ?? 'full_name',
    direction: searchParams.get('order_by[dir]') === 'asc' ? 'ascending' : 'descending'
  });
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  const draftState = useRef<{
    full_name: string;
    has_scored: string[];
    language: string;
  }>({
    full_name: searchParams.get('full_name') ?? '',
    has_scored: [],
    language: searchParams.get('language') ?? ''
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .flatMap(([key, value]) =>
          value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
        )
    )
  );
  const { isFetchingOrgUsers, totalUserPages, updateOrganizationFilters, users } =
    useGetOrganizationUsers(
      {
        clinical: true,
        completed_onboarding: true,
        full_name: searchParams.get('full_name') ?? undefined,
        has_scored: searchParams.get('has_scored')?.split(',') ?? [],
        language: searchParams.get('language') ?? undefined,
        order_by: {
          column: searchParams.get('order_by[column]') ?? 'full_name',
          dir: (searchParams.get('order_by[dir]') ?? 'asc') as 'asc' | 'desc'
        },
        page: parseInt(searchParams.get('page') ?? '1')
      },
      organizationId
    );
  const {
    isFetching: isFetchingOrgOverview,
    organizationOverview,
    updateOrganizationOverviewFilters
  } = useGetOrganizationOverview(
    {
      clinical: true,
      completed_onboarding: true,
      full_name: searchParams.get('full_name') ?? undefined,
      'has_scored[]':
        searchParams
          .get('has_scored')
          ?.split(',')
          .map(community => community.toLowerCase()) ?? [],
      language: searchParams.get('language') ?? undefined
    },
    organizationId ?? ''
  );

  const [isExporting, setIsExporting] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [canExportCSV, setCanExportCSV] = useState(false);

  useEffect(() => {
    if (user.organization_memberships.length > 0) {
      setOrganization(
        user.organization_memberships.find(org => org.member_role === 'superuser')?.organization
      );
    }
  }, [user.organization_memberships]);

  useEffect(() => {
    if (!organization) {
      return;
    }
    setCanExportCSV(organization.permissions.includes('users.export'));
  }, [organization, featureFlags]);

  const handleExportCsvButtonPress = async () => {
    if (!organization) {
      return;
    }

    setIsExporting(true);

    const educationExportUrl: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/organizations/${organization.id}/users/export?integrated_license=true`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    try {
      const response = await fetch(educationExportUrl, options);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      await downloadCsv(
        response,
        `Violet_CulturalCompetencies_${organizationName?.replaceAll(' ', '')}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    draftState.current.has_scored = [...communities].sort();
  };

  const handleSearchByNameFieldChange: AriaSearchFieldProps['onChange'] = value => {
    draftState.current.full_name = value;
  };

  const handleLanguagesSelectionChange: AriaComboBoxProps<object>['onSelectionChange'] = key => {
    draftState.current.language = key as string;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSearchParams(sortedParams('page', page > 1 ? String(page) : ''));
  };

  const preserveChanges = () => {
    const communityFilters = draftState.current.has_scored.map(
      community => `Community:${community.toString()}`
    );
    const updatedFilters = communityFilters
      .concat([
        `Provider:${draftState.current.full_name}`,
        `Language:${draftState.current.language}`
      ])
      .filter(
        entry =>
          !entry.includes('null') &&
          !entry.includes('undefined') &&
          isNonEmptyString(entry.split(':')[1])
      );
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    draftState.current = {
      full_name: '',
      has_scored: [],
      language: ''
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Provider:')) {
        draftState.current.full_name = '';
      } else if (keyString.includes('Language:')) {
        draftState.current.language = '';
      } else if (keyString.includes('Community:')) {
        const community = keyString.split(':')[1];
        draftState.current.has_scored = draftState.current.has_scored.filter(
          item => item !== community
        );
      }
    });
    setCurrentPage(1);
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    const finalParams = filtersObjectToParams(organizationFiltersToSearch, updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['order_by[column]', sortDescriptor.column as string],
      ['order_by[dir]', sortDescriptor.direction === 'ascending' ? 'asc' : 'desc'],
      ['page', '1']
    ];
    const sortedParams = sortFilterParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  const mappedBadgeCells = (badges: NonNullable<OrganizationUser['badges']>) => {
    const bipocBenchmark = badges.find(benchmark => benchmark.community?.toLowerCase() === 'bipoc');
    const lgbqBenchmark = badges.find(benchmark => benchmark.community?.toLowerCase() === 'lgbq');
    const tgncBenchmark = badges.find(benchmark => benchmark.community?.toLowerCase() === 'tgnc');
    return [bipocBenchmark, lgbqBenchmark, tgncBenchmark].map((badge, index) =>
      badge ? (
        <PopoverTrigger
          key={badge.title}
          content={
            <S.Badges.PopoverText>
              <S.Badges.Title>{badge.title}</S.Badges.Title>
              {isNonEmptyString(badge.assessedOn) && (
                <S.Badges.AssessedOn>Assessed on: {badge.assessedOn}</S.Badges.AssessedOn>
              )}
              {isNonEmptyString(badge.dateEarned) && (
                <S.Badges.DateEarned>Date earned: {badge.dateEarned}</S.Badges.DateEarned>
              )}
            </S.Badges.PopoverText>
          }
          theme="light"
        >
          <UnstyledButton>
            <S.Badges.Badge
              alt={badge.imageAltText}
              src={badge.imageSrc}
              tabIndex={0}
            />
          </UnstyledButton>
        </PopoverTrigger>
      ) : (
        <div key={`placeholder-${index}`}>&nbsp;</div>
      )
    );
  };

  useEffect(() => {
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
          .flatMap(([key, value]) =>
            value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
          )
      )
    );

    draftState.current = {
      full_name: searchParams.get('full_name') ?? '',
      has_scored: searchParams.get('has_scored')?.split(',') ?? [],
      language: searchParams.get('language') ?? ''
    };

    setCurrentPage(
      searchParams.get('page') !== null ? parseInt(searchParams.get('page') ?? '1') : 1
    );
    setSortDescriptor({
      column: searchParams.get('order_by[column]') ?? 'full_name',
      direction: searchParams.get('order_by[dir]') === 'asc' ? 'ascending' : 'descending'
    });
  }, [searchParams]);

  useEffect(() => {
    updateOrganizationFilters({
      clinical: true,
      completed_onboarding: true,
      full_name: searchParams.get('full_name') ?? undefined,
      has_scored: searchParams.get('has_scored')?.split(',') ?? [],
      language: searchParams.get('language') ?? undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'full_name',
        dir: (searchParams.get('order_by[dir]') ?? 'asc') as 'asc' | 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1')
    });

    updateOrganizationOverviewFilters({
      clinical: true,
      completed_onboarding: true,
      full_name: searchParams.get('full_name') ?? undefined,
      'has_scored[]':
        searchParams
          .get('has_scored')
          ?.split(',')
          .map(community => community.toLowerCase()) ?? [],
      language: searchParams.get('language') ?? undefined
    });
  }, [searchParams, updateOrganizationFilters, updateOrganizationOverviewFilters]);

  return (
    <>
      <PageTitle
        title="Inclusivity"
        titleVariant="h1"
      />
      <S.FiltersAndActionsWrapper>
        <FilterDropdown>
          <FilterDropdown.Filters onApplyChanges={preserveChanges}>
            <SearchField
              aria-label="Search by user name"
              data-cy="name-filter"
              defaultValue={draftState.current.full_name}
              onChange={handleSearchByNameFieldChange}
              placeholder="User name"
            />
            <ComboBox
              aria-label="Languages"
              data-cy="language-filter"
              defaultSelectedKey={draftState.current.language as Key}
              icon={SearchIcon}
              onSelectionChange={handleLanguagesSelectionChange}
              placeholder="Language"
            >
              {LANGUAGES.map(language => (
                <Item key={language}>{language}</Item>
              ))}
            </ComboBox>

            <CommunityFilter filterLabelText="Community Benchmark">
              <CheckboxGroup
                aria-labelledby="filter-label"
                defaultValue={draftState.current.has_scored}
                direction="horizontal"
                onChange={handleFilterByCommunityChange}
                size="regular"
              >
                <GroupCheckbox
                  key="BIPOC"
                  data-cy="community-filter-bipoc"
                  value="BIPOC"
                >
                  BIPOC
                </GroupCheckbox>
                <GroupCheckbox
                  key="LGBQ"
                  data-cy="community-filter-lgbq"
                  value="LGBQ"
                >
                  LGBQ
                </GroupCheckbox>
                <GroupCheckbox
                  key="TGNC"
                  data-cy="community-filter-tgnc"
                  value="TGNC"
                >
                  TGNC
                </GroupCheckbox>
              </CheckboxGroup>
            </CommunityFilter>
          </FilterDropdown.Filters>
          <FilterDropdown.Tags
            onClear={handleClearFilters}
            onRemove={handleRemoveFilter}
          >
            {Array.from(appliedFilters).map(key => {
              const label = key.toString().split(':')[0];
              let value = key.toString().split(':')[1];
              if (label.toLowerCase().includes('community')) {
                value = value.toUpperCase();
              }
              return (
                <Item key={key}>
                  {label}
                  {value !== 'true' && (
                    <>
                      {': '}
                      {value}
                    </>
                  )}
                </Item>
              );
            })}
          </FilterDropdown.Tags>
        </FilterDropdown>
        {canExportCSV ? (
          <Button
            data-cy="export-csv-button"
            isLoading={isExporting}
            onPress={handleExportCsvButtonPress}
            size="regular"
            trailingIcon={ExportIcon}
          >
            Export CSV
          </Button>
        ) : undefined}
      </S.FiltersAndActionsWrapper>

      {isFetchingOrgOverview ? (
        <Spinner withWrapper />
      ) : organizationOverview !== undefined ? (
        <S.InclusivityOverviewWrapper>
          <DataReportRow layout="thirds">
            <DataHighlight
              data-cy="organization-onboarded-providers"
              detailText="Onboarded providers"
              numberHighlight={commaNumFormatter.format(
                organizationOverview.userMetrics.onboarded.clinical
              )}
              popoverContent={
                <>
                  Total number of providers who have completed their Violet profiles to receive
                  their Benchmarks.
                </>
              }
              variant="small"
            />
            <DataHighlight
              data-cy="organization-verified-inclusive-providers"
              detailText="Verified Inclusive Providers"
              numberHighlight={commaNumFormatter.format(
                organizationOverview.userMetrics.benchmarked.total
              )}
              popoverContent={
                <>
                  Total number of onboarded providers who have achieved an Awareness Benchmark or
                  greater in at least one community (BIPOC, LGBQ, or TGNC). This requirement will
                  appear locked if your organization has not met the threshold for onboarded
                  providers.
                </>
              }
              variant="small"
            />
            <DataHighlight
              data-cy="organization-inclusive-providers-percentage"
              detailText="Inclusive Providers"
              numberHighlight={`
                  ${organizationOverview.userMetrics.benchmarked.percentage > 0 ? organizationOverview.userMetrics.benchmarked.percentage.toFixed(0) : 0}%
                `}
              popoverContent={
                <>
                  Percentage of onboarded providers who have achieved an Awareness Benchmark or
                  greater in at least one community (BIPOC, LGBQ, or TGNC).
                </>
              }
              variant="small"
            />
          </DataReportRow>
          <DataReportRow layout="single">
            <DataCard>
              <DataCardHeader
                description="This chart displays the percentage of your team's providers that have achieved awareness Benchmarks or above in each community."
                title="Cultural competencies"
                titleVariant="h3"
              />
              <CommunityGraphs
                data={organizationOverview.providerInclusivityPercentages}
                dataType="benchmarks"
              />
            </DataCard>
          </DataReportRow>
        </S.InclusivityOverviewWrapper>
      ) : null}

      {isFetchingOrgUsers ? (
        <S.SpinnerWrapper>
          <Spinner />
        </S.SpinnerWrapper>
      ) : users.length === 0 ? (
        <TableEmptyState />
      ) : (
        <>
          <Table
            aria-label="Table listing all providers and the badges they've each obtained"
            data-cy="cultural-competencies-table"
            onSortChange={({ column, direction }) => {
              setSortDescriptor({ column, direction });
              setCurrentPage(1);
              refreshURLparams(appliedFilters);
            }}
            sortDescriptor={sortDescriptor}
          >
            <TableHeader>
              <Column
                key="full_name"
                allowsSorting
              >
                NAME
              </Column>
              <Column
                key="email"
                allowsSorting
              >
                EMAIL
              </Column>
              <Column>LANGUAGES</Column>
              <Column>
                <S.BenchmarksColumn>
                  <div key="BIPOC">BIPOC</div>
                  <div key="LGBQ">LGBQ</div>
                  <div key="TGNC">TGNC</div>
                </S.BenchmarksColumn>
              </Column>
            </TableHeader>
            <TableBody>
              {users.map(user => (
                <Row
                  key={user.email}
                  data-cy="my-org-cultural-competence-user-row"
                >
                  <Cell>
                    <S.TruncateLongText title={user.name}>{user.name}</S.TruncateLongText>
                  </Cell>
                  <Cell>
                    <S.TruncateLongText title={user.email}>{user.email}</S.TruncateLongText>
                  </Cell>
                  <Cell>
                    <S.TruncateLongText title={user.languages.join(', ')}>
                      {user.languages.join(', ')}
                    </S.TruncateLongText>
                  </Cell>
                  <Cell>
                    {user.badges ? (
                      <S.Badges.Root>{mappedBadgeCells(user.badges)}</S.Badges.Root>
                    ) : (
                      <VisuallyHidden>No benchmarks</VisuallyHidden>
                    )}
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </Table>
          <Pagination
            currentPage={currentPage}
            setPage={handlePageChange}
            totalPages={totalUserPages}
          />
        </>
      )}
    </>
  );
};

export default Inclusivity;
