import Spinner from 'src/components/Spinner';

import * as S from './styles';

const LoadingState = () => (
  <S.LoadingState>
    <Spinner />
  </S.LoadingState>
);

export default LoadingState;
