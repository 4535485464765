import styled from 'styled-components';

interface CommunityCirclesProps {
  $direction: string;
  $size: string;
}
export const CommunityCircles = styled.div<CommunityCirclesProps>`
  margin-bottom: var(--space-5);
  display: inline-grid;

  div {
    border-radius: 100%;
  }

  ${({ $direction }) => {
    if ($direction === 'row') {
      return 'grid-auto-flow: column;';
    } else {
      return 'grid-auto-flow: row;';
    }
  }}

  ${({ $size }) => {
    if ($size === 'small') {
      return `
        grid-gap: var(--space-2);

        div {
          width: 1rem;
          height: 1rem;
        }
      `;
    } else if ($size === 'regular') {
      return `
        grid-gap: var(--space-3);

        div {
          width: 1.5rem;
          height: 1.5rem;
        }
      `;
    } else {
      return `
        grid-gap: var(--space-4);

        div {
          width: 2rem;
          height: 2rem;
        }
      `;
    }
  }}
`;
