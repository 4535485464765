import { Key } from 'react';
import { AriaListBoxSectionProps, useListBoxSection } from 'react-aria';
import { ComboBoxState, Node } from 'react-stately';

import NoResultsOption from '../NoResultsOption';
import Option from '../Option';

import * as S from './styles';

interface Props extends AriaListBoxSectionProps {
  section: Node<object>;
  selectedKeys: Iterable<Key> | null;
  state: ComboBoxState<object>;
}

const ListBoxSection = ({ section, selectedKeys, state }: Props) => {
  const { groupProps, headingProps, itemProps } = useListBoxSection({
    'aria-label': section['aria-label'],
    heading: section.rendered
  });

  const children = state.collection.getChildren?.(section.key) ?? [];

  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <S.SectionListItem {...itemProps}>
      {section.rendered !== undefined && (
        <S.SectionHeading {...headingProps}>{section.rendered}</S.SectionHeading>
      )}
      <S.List
        {...groupProps}
        style={{
          listStyle: 'none',
          padding: 0
        }}
      >
        {Array.from(children).length > 0 ? (
          Array.from(section.childNodes).map(node => (
            <Option
              key={node.key}
              isSelected={
                selectedKeys === 'all' ||
                (selectedKeys instanceof Set
                  ? selectedKeys.has(node.key)
                  : Array.from(selectedKeys ?? []).includes(node.key))
              }
              item={node}
              state={state}
            />
          ))
        ) : (
          <NoResultsOption>No items found</NoResultsOption>
        )}
      </S.List>
    </S.SectionListItem>
  );
};

export default ListBoxSection;
