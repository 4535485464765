import { useContext, useRef } from 'react';
import { AriaRadioProps, useFocusRing, useRadio } from 'react-aria';

import { RadioContext } from '..';

import * as S from './styles';

interface Props extends AriaRadioProps {
  className?: string;
}

const Radio = ({ className, ...ariaRadioProps }: Props) => {
  const { children } = ariaRadioProps;
  const { size, state } = useContext(RadioContext)!;
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected } = useRadio(ariaRadioProps, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <S.Radio
      $size={size}
      className={className}
    >
      <S.VisuallyHidden $size={size}>
        <S.Input
          {...inputProps}
          {...focusProps}
          ref={ref}
          required={state.isRequired}
        />
      </S.VisuallyHidden>
      <S.VisualSelectionIndicator
        $isFocused={isFocusVisible}
        $isSelected={isSelected}
        $size={size}
      />
      {children}
    </S.Radio>
  );
};

export default Radio;
