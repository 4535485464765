import { PropsWithChildren, useRef } from 'react';
import { AriaDialogProps, useDialog } from 'react-aria';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaDialogProps {
  theme: 'dark' | 'light';
  title?: string;
}

const Dialog = ({ children, theme, title, ...ariaDialogProps }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps, titleProps } = useDialog(ariaDialogProps, ref);

  return (
    <S.Dialog
      {...dialogProps}
      ref={ref}
      $theme={theme}
    >
      {isNonEmptyString(title) && <h3 {...titleProps}>{title}</h3>}
      {children}
    </S.Dialog>
  );
};

export default Dialog;
