import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useUser from '../../../hooks/useUser';
import { CourseCollectionCompletion, generateCollectionCompletion } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

interface Filters {
  page?: number;
  per_page?: number;
}

type FetchCollectionCompletions = (filters: Filters) => Promise<void>;

interface UseGetCollectionCompletions {
  (filters: Filters): {
    collectionCompletions: CourseCollectionCompletion[];
    isFetching: boolean;
    totalCompletionPages: number;
    updateCollectionCompletionsFilters: (newFilters: Partial<Filters>) => void;
  };
}

const useGetCollectionCompletions: UseGetCollectionCompletions = filters => {
  const { user } = useUser();
  const { getRequest, reportError } = useApiRequest();

  const [collectionCompletions, setCollectionCompletions] = useState<CourseCollectionCompletion[]>(
    []
  );
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState<Filters>(filters);
  const [totalCompletionPages, setTotalCompletionPages] = useState(1);

  const fetchCollectionCompletions: FetchCollectionCompletions = useCallback(
    async filters => {
      setIsFetching(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/course_collection_completions${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APICourseCollectionCompletions['data'];
          meta?: APICourseCollectionCompletions['meta'];
        };
        setCollectionCompletions(
          data !== undefined ? data.map(fields => generateCollectionCompletion(fields, user)) : []
        );
        if (meta !== undefined) {
          setTotalCompletionPages(Number(meta.total_pages));
        }
      } catch (error) {
        reportError(error);
      }

      setIsFetching(false);
    },
    [getRequest, user, reportError]
  );

  const updateCollectionCompletionsFilters = (newFilters: Partial<Filters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof Filters] === undefined ? delete newFilters[key as keyof Filters] : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    fetchCollectionCompletions(newFilters);
  };

  useDeepCompareEffect(() => {
    (async () => {
      await fetchCollectionCompletions(filters);
    })();
  }, [fetchCollectionCompletions, filters]);

  return {
    collectionCompletions,
    isFetching,
    totalCompletionPages,
    updateCollectionCompletionsFilters
  };
};

export default useGetCollectionCompletions;
