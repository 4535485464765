import { useCallback } from 'react';

/*
  In principle, this hook is similar to useApiRequest,
  but it is used for pages not requiring authentication
  (aka for users not logged in).
  Since we rely on the user context for useApiRequest,
  which does not exist for unauthenticated users,
  we need to create a separate hook.
*/

const apiOptions = () =>
  ({
    headers: {
      'Content-Type': 'application/json'
    }
  }) as RequestInit;

const useUnauthenticatedApiRequest = () => {
  const validateResponse = useCallback(async (response: Response) => {
    if (!response.ok) {
      const body = (await response.json()) as { error: string[] } | null;
      throw new Error(
        body !== null ? body.error.join(' | ') : `${response.status} (${response.statusText})`
      );
    }
  }, []);

  const getRequest = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(url, apiOptions());

        await validateResponse(response);

        return await response.json();
      } catch (error) {
        reportError(error);
        return { data: undefined, meta: undefined };
      }
    },
    [validateResponse]
  );

  const postRequest = useCallback(
    async (url: string, requestBody: object) => {
      try {
        const options = apiOptions();
        const response = await fetch(url, {
          body: JSON.stringify(requestBody),
          method: 'POST',
          ...options
        });

        await validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        throw new Error(`${error}`);
      }
    },
    [validateResponse]
  );

  return { getRequest, postRequest, reportError };
};

export default useUnauthenticatedApiRequest;
