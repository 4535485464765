import { useRef } from 'react';
import { useDateSegment } from 'react-aria';
import { DateFieldState } from 'react-stately';

import * as S from '../styles';

interface Props {
  segment: DateFieldState['segments'][0];
  state: DateFieldState;
}

const DateSegment = ({ segment, state }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <S.Segment
      {...segmentProps}
      ref={ref}
      className={`${segment.isPlaceholder ? 'placeholder' : ''}`}
    >
      {segment.type === 'month' || segment.type === 'year'
        ? segment.text.padStart(segment.placeholder.length, '0')
        : segment.text}
    </S.Segment>
  );
};

export default DateSegment;
