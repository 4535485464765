import { Temporal } from '@js-temporal/polyfill';

import * as S from './styles';
interface Props {
  organization: string;
  reportTitle: string;
  /* eslint-disable typescript-sort-keys/interface */
  bipocPercentage: number;
  lgbqPercentage: number;
  tgncPercentage: number;
  /* eslint-enable typescript-sort-keys/interface */
}

const InclusivityScorecardHeader = ({
  organization,
  reportTitle = 'Inclusivity Scorecard',
  /* eslint-disable sort-destructure-keys/sort-destructure-keys */
  bipocPercentage,
  lgbqPercentage,
  tgncPercentage /* eslint-enable sort-destructure-keys/sort-destructure-keys */
}: Props) => {
  const todaysDate = Temporal.Now.plainDateISO();
  return (
    <S.ReportHeader>
      <S.ReportTitleContainer>
        <S.Logo
          color="green"
          hideReserved
          size="regular"
        />
        <S.ReportTitle>{reportTitle}</S.ReportTitle>
      </S.ReportTitleContainer>

      <S.Table>
        <tbody>
          <S.InfoRow>
            <S.LabelCell>
              <S.LabelTextWrapping>Org Name:</S.LabelTextWrapping>
            </S.LabelCell>
            <S.OrganizationNameDataCell>{organization}</S.OrganizationNameDataCell>
            <S.LabelCell>
              <S.LabelTextWrapping>Generated:</S.LabelTextWrapping>
            </S.LabelCell>
            <S.DataCell>
              <span>
                {todaysDate.month}/{todaysDate.day}/{todaysDate.year}
              </span>
            </S.DataCell>
            <S.LabelCell>
              <S.LabelTextWrapping>BIPOC Inclusivity:</S.LabelTextWrapping>
            </S.LabelCell>
            <S.DataCell>{bipocPercentage}%</S.DataCell>
            <S.LabelCell>
              <S.LabelTextWrapping>LBGQ Inclusivity:</S.LabelTextWrapping>
            </S.LabelCell>
            <S.DataCell>{lgbqPercentage}%</S.DataCell>
            <S.LabelCell>
              <S.LabelTextWrapping>TGNC Inclusivity:</S.LabelTextWrapping>
            </S.LabelCell>
            <S.DataCell>{tgncPercentage}%</S.DataCell>
          </S.InfoRow>
        </tbody>
      </S.Table>
    </S.ReportHeader>
  );
};

export default InclusivityScorecardHeader;
