import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const NestedList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SectionHeader = styled.div`
  ${TEXT_STYLES.formsLabelSmallCaps}
  font-weight: 500;
  color: var(--purple-400);
  padding: 0 0.5rem;
`;

export const SectionItem = styled.li``;

export const Separator = styled.li`
  border-top: 1px solid var(--gray-300);
  margin: 0.5rem 0 1rem;
`;
