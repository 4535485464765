import clsx from 'clsx';
import { RefObject, useRef } from 'react';
import { AriaButtonProps, mergeProps, useButton, useFocusRing, useHover } from 'react-aria';
import { Link, LinkProps } from 'react-router-dom';

import * as S from './styles';

interface Props extends AriaButtonProps {
  caption: string;
  title: string;
  to: LinkProps['to'];
}

const CallToActionLink = ({ caption, title, to, ...ariaButtonProps }: Props) => {
  const ref = useRef<HTMLAnchorElement | HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(
    { ...ariaButtonProps, isDisabled: ariaButtonProps.isDisabled },
    ref
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  const { hoverProps, isHovered } = useHover({ isDisabled: ariaButtonProps.isDisabled });
  const sharedProps = {
    ...mergeProps(buttonProps, focusProps, hoverProps),
    className: clsx(
      { 'is-focused': isFocusVisible },
      { 'is-hovered': isHovered },
      { 'is-pressed': isPressed }
    )
  };
  return (
    <S.CallToActionLink.Root
      ref={ref as RefObject<HTMLAnchorElement>}
      as={Link}
      to={to}
      {...sharedProps}
    >
      <S.CallToActionLink.Header>{title}</S.CallToActionLink.Header>
      <S.CallToActionLink.Text>{caption}</S.CallToActionLink.Text>
      <S.CallToActionLink.IconWrapper>
        <S.CallToActionLink.Icon />
      </S.CallToActionLink.IconWrapper>
    </S.CallToActionLink.Root>
  );
};

export default CallToActionLink;
