import { AnimatePresence } from 'framer-motion';
import { CSSProperties } from 'react';
import { AriaProgressBarProps, useProgressBar } from 'react-aria';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaProgressBarProps {
  animated?: boolean;
  className?: string;
  'data-cy'?: string;
}

const ProgressBar = ({ animated = false, 'data-cy': dataCy, ...props }: Props) => {
  const { className, label, maxValue = 100, minValue = 0, value = 0 } = props;

  const { labelProps, progressBarProps } = useProgressBar(props);

  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <AnimatePresence>
      <div
        className={className}
        {...progressBarProps}
      >
        <S.LabelAndValueContainer>
          {isNonEmptyString(label) && <S.Label {...labelProps}>{label}</S.Label>}
          {isNonEmptyString(label) && <S.Value>{progressBarProps['aria-valuetext']}</S.Value>}
        </S.LabelAndValueContainer>
        <S.Track>
          {animated ? (
            <S.Bar
              animate={{ width: `${barWidth}` }}
              data-cy={dataCy}
              data-test-value={value}
              initial={{ width: '0' }}
              style={{ '--width': barWidth } as CSSProperties}
              transition={{ duration: 1.75, ease: 'easeIn' }}
            />
          ) : (
            <S.Bar style={{ '--width': barWidth } as CSSProperties} />
          )}
        </S.Track>
      </div>
    </AnimatePresence>
  );
};

const { Bar, LabelAndValueContainer, Track } = S;

export { Bar, LabelAndValueContainer, ProgressBar, Track };
