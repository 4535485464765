import { forwardRef, Ref } from 'react';
import { AriaSelectProps } from 'react-aria';

import * as S from './styles';

interface Props extends AriaSelectProps<object> {
  className?: string;
  hideRequiredIndicator?: boolean;
  maxListBoxHeight?: string;
}

const TableSelect = forwardRef<HTMLButtonElement & HTMLInputElement, Props>(
  (props, ref: Ref<HTMLButtonElement & HTMLInputElement>) => (
    <S.TableSelect
      {...props}
      ref={ref}
    />
  )
);

TableSelect.displayName = 'TableSelect';

export default TableSelect;
