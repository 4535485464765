import ViewDetailsButton from 'src/components/Buttons/ViewDetailsButton';
import { NetworkOverview, OrganizationOverview } from 'src/pages/Dashboard/utils';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import Meter from '../Meter';

import * as S from './styles';

interface Props {
  data:
    | NetworkOverview['networkDiversityPercentages']
    | NetworkOverview['networkInclusivityPercentages']
    | OrganizationOverview['providerDiversityPercentages']
    | OrganizationOverview['providerInclusivityPercentages']
    | undefined;
  dataType: 'benchmarks' | 'demographics';
  isReport?: boolean;
  linkToPage?: string;
}

const CommunityGraphs = ({ data, dataType, isReport = false, linkToPage }: Props) => {
  /*  [dataType]     ==>    [subTitle]
      benchmarks     ==>    inclusive
      demographics   ==>    identifying
  */
  const subTitle = dataType === 'benchmarks' ? 'Inclusive' : 'Identifying';

  return (
    <S.MeterWrapper className="report-community-graph-wrapper">
      <Meter
        data-cy={`meter-${dataType}-bipoc`}
        isReport={isReport}
        label="BIPOC"
        labelPopoverText="Black, Indigenous, and People of Color"
        subTitle={subTitle}
        value={data?.bipoc}
      />
      <Meter
        data-cy={`meter-${dataType}-lgbq`}
        isReport={isReport}
        label="LGBQ"
        labelPopoverText="Lesbian, Gay, Bisexual, and Queer"
        subTitle={subTitle}
        value={data?.lgbq}
      />
      <Meter
        data-cy={`meter-${dataType}-tgnc`}
        isReport={isReport}
        label="TGNC"
        labelPopoverText="Transgender and Gender Non-Conforming"
        subTitle={subTitle}
        value={data?.tgnc}
      />
      {linkToPage !== undefined && isNonEmptyString(linkToPage) && (
        <ViewDetailsButton
          data-cy="community-graphs-view-details-button"
          linkToPage={linkToPage}
        />
      )}
    </S.MeterWrapper>
  );
};

export default CommunityGraphs;
