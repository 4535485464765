import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';

interface GroupProps {
  $length: number;
}

export const Group = styled.div<GroupProps>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0;
  cursor: pointer;
  width: 100%;
  margin: 0;

  ${({ $length }) =>
    `
      grid-template-columns: repeat(${$length}, minmax(0, 1fr));
    `}
`;

interface LabelProps {
  $type: string;
}

export const Label = styled.label<LabelProps>`
  display: inline-block;
  color: var(--gray-500);
  margin: 0 0 1.25rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyRegular}
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyMedium}
  }
`;

export const Output = styled.output`
  display: none;
`;

export const SelectionTrack = styled.div<SelectionTrackProps>`
  position: absolute;
  height: 0.5rem;
  background-color: var(--purple-400);
  border-radius: 1.5rem;
  align-self: center;
  max-width: 100%;

  ${({ $length, $trackWidth, $value }) => {
    if (
      $value !== undefined &&
      $trackWidth !== undefined &&
      $value !== 0 &&
      $value !== $length - 1
    ) {
      const gridWidth = $trackWidth / $length;
      const halfGridWidth = gridWidth / 2;
      return `width: ${gridWidth * $value + halfGridWidth}px`;
    } else if ($value === $length - 1) {
      return `width: 100%`;
    } else {
      return `width: 0%`;
    }
  }};
`;

export const Slider = styled.div`
  position: relative;
  touch-action: none;
  width: 100%;
`;

export const SliderRow = styled.div`
  position: relative;
`;

interface SelectionTrackProps {
  $length: number;
  $trackWidth?: number;
  $value?: number;
}

export const Track = styled.div`
  position: absolute;
  align-self: center;
  height: 0.5rem;
  background-color: var(--gray-200);
  border-radius: 1.5rem;
  width: 100%;
`;

interface ValueProps {
  $isFirst: boolean;
  $isLast: boolean;
  $isSelected: boolean;
}

export const Value = styled.div<ValueProps>`
  ${({ $isSelected }) =>
    $isSelected &&
    `
    color: var(--purple-400);
  `}
  text-align: center;

  ${({ $isFirst }) =>
    $isFirst &&
    `text-align: left;
    padding-left: 0.5rem;
  `}

  ${({ $isLast }) =>
    $isLast &&
    `text-align: right;
    padding-right: 0.25rem;
  `}
`;

interface ValuesProps {
  $length: number;
  $type: string;
}

export const Values = styled.div<ValuesProps>`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-600);
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0;
  height: 1rem;
  width: 100%;
  margin: 0.25rem 0 0;

  ${({ $length }) => `grid-template-columns: repeat(${$length}, minmax(0, 1fr));`}
`;
