import Button from 'src/components/Buttons/Button';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import { MOBILE_HEADER_HEIGHT } from '../../../../../constants';

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 18.75rem;
  }
`;

export const BackToEducationButton = styled(Button)`
  margin-top: 1rem;
`;

export const Container = styled.div`
  display: grid;
  place-content: center;
  padding-right: 2.5rem;
  padding-left: 2.5rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    min-height: calc(100dvh - ${MOBILE_HEADER_HEIGHT}px);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-height: 100dvh;
  }
`;

export const Description = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
  margin-bottom: var(--space-6);

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 43.1875rem;
  }
`;

export const DownloadButton = styled(Button)``;

// headlineStandardLevel2Regular corresponds to StandardSubheadlineReg
export const Title = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel2Regular}
  color: var(--purple-600);
`;
