import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as ArrowRightIcon } from '@material-design-icons/svg/round/arrow_forward.svg';
import { ReactComponent as DiversityIcon } from '@material-symbols/svg-400/outlined/diversity_1-fill.svg';
import { ReactComponent as PsychiatryIcon } from '@material-symbols/svg-400/outlined/psychiatry.svg';
import { ReactComponent as StethoscopeIcon } from '@material-symbols/svg-400/outlined/stethoscope.svg';
import * as Sentry from '@sentry/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import { AriaRadioGroupProps, AriaTextFieldProps } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import ButtonRadioGroup from 'src/components/FormFields/ButtonRadioGroup';
import RadioButton from 'src/components/FormFields/ButtonRadioGroup/RadioButton';
import HelpTooltipTrigger from 'src/components/HelpTooltipTrigger';
import useAnalytics from 'src/hooks/useAnalytics';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useUnauthenticatedApiRequest from 'src/pages/Dashboard/hooks/useUnauthenticatedApiRequest';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

const SignUpForm = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const { analytics } = useAnalytics();
  const { loginWithRedirect } = useAuth0();
  const { postRequest } = useUnauthenticatedApiRequest();
  const [searchParams] = useSearchParams();

  const [pageState, setPageState] = useState<'createAccount' | 'signUp' | 'success'>('signUp');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [isClinical, setIsClinical] = useState<boolean | undefined>(undefined);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [npi, setNpi] = useState<string>('');
  const [npiIsValid, setNpiIsValid] = useState<boolean>(true);
  const [npiErrorMessage, setNpiErrorMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleFirstNameChange: AriaTextFieldProps['onChange'] = value => {
    setFirstName(value);
  };

  const handleLastNameChange: AriaTextFieldProps['onChange'] = value => {
    setLastName(value);
  };

  const handleEmailAddressChange: AriaTextFieldProps['onChange'] = value => {
    setErrorMessage(undefined);
    setEmailAddress(value);
  };

  const handleNpiChange: AriaTextFieldProps['onChange'] = value => {
    setNpi(value);
  };

  const changeIsClinical: AriaRadioGroupProps['onChange'] = (value: string) => {
    setIsClinical(value === 'licensed');
  };

  useEffect(() => {
    const npiRegexPattern = new RegExp('^[0-9]{10}$');
    if (isClinical === true) {
      if (isNonEmptyString(npi)) {
        setNpiIsValid(npiRegexPattern.test(npi));
        setNpiErrorMessage('NPI should be a 10-digit number.');
      } else {
        setNpiIsValid(true);
        setNpiErrorMessage(undefined);
      }
    } else {
      setNpiIsValid(true);
      setNpiErrorMessage(undefined);
    }
  }, [npi, isClinical]);

  const handleSignUpButton: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    setHasAttemptedSubmit(true);

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    if (!emailAddress || emailAddress.trim() === '') {
      setErrorMessage('Please enter an email address.');
      return;
    }

    const emailRegexPattern = new RegExp('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$');
    if (!emailRegexPattern.test(emailAddress.trim())) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    setIsSending(true);

    const url: RequestInfo = `${
      process.env.REACT_APP_API_V2_BASE_PATH
    }/profile/sign_up?email=${emailAddress.trim().replaceAll('+', '%2B')}`;

    try {
      const response = (await postRequest(url, {})) as {
        email: string;
        email_sent?: boolean; // only when status is 'created'
        error?: string; // only when status is 'not_found' or email is invalid
        status: 'activated' | 'created' | 'not_found';
      };

      if (response.status === 'activated') {
        // User already exists and has an active account
        setErrorMessage(
          'This email is associated with an existing account, please log in or reach out to support@joinviolet.com.'
        );
      } else if (response.status === 'created' && response.email_sent !== undefined) {
        // Email was resent
        setPageState('success');
      } else if (response.status === 'not_found') {
        // User does not exist and can create account
        setPageState('createAccount');
      } else if (response.error !== undefined) {
        if (response.error === 'User not in correct status') {
          // user is deactivated
          setErrorMessage(
            'This email is associated with an existing account, please reach out to support@joinviolet.com.'
          );
        } else {
          // email error
          setErrorMessage(response.error);
        }
      }
    } catch (error) {
      openErrorModalDialog();
      Sentry.captureException(error);
    }

    setIsSending(false);
  };

  const handleCreateUser: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    if (isClinical === undefined || !npiIsValid) {
      return;
    }

    setIsSending(true);

    analytics?.identify(undefined, {
      email: emailAddress.trim(),
      first_name: firstName,
      is_clinical: isClinical,
      last_name: lastName,
      npi
    });

    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile/create_account`;

    try {
      const response = (await postRequest(url, {
        anonymous_id: analytics?.user().anonymousId(),
        user: {
          email: emailAddress.trim(),
          first_name: firstName,
          is_clinical: isClinical,
          last_name: lastName,
          npi: isNonEmptyString(npi) ? npi : undefined,
          referral_org:
            searchParams.get('referral_org') !== null ? searchParams.get('referral_org') : undefined
        }
      })) as { account_created: boolean; errors?: string[] };
      if (response.account_created) {
        setPageState('success');
      } else {
        throw new Error(response.errors?.join(' | '));
      }
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'message' in error) {
        const err = error as { message?: string };
        if (err.message!.includes('npi has already been taken')) {
          setNpiIsValid(false);
          setNpiErrorMessage(
            'This NPI is associated with an existing account, please log in or reach out to support@joinviolet.com.'
          );
        } else {
          openErrorModalDialog();
          Sentry.captureException(error);
        }
      } else {
        openErrorModalDialog();
        Sentry.captureException(error);
      }
    }

    setIsSending(false);
  };

  switch (pageState) {
    case 'createAccount':
      return (
        <motion.form
          key="create-account-form"
          animate={{ opacity: 1 }}
          data-cy="create-account-form"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          onSubmit={handleCreateUser}
          transition={{
            duration: 1,
            ease: 'easeInOut'
          }}
        >
          <S.Title>Let's get to know you.</S.Title>
          <S.BodyText>Select the option that best matches your role.*</S.BodyText>
          <S.ButtonContainer>
            <ButtonRadioGroup onChange={changeIsClinical}>
              <RadioButton
                data-cy="licensed-btn"
                icon={StethoscopeIcon}
                tooltipContent={
                  <>
                    <strong>Licensed Clinicians or Clinical Care Providers</strong>
                    <ul>
                      <li>Primary care provider</li>
                      <li>Behavioral health provider</li>
                      <li>Registered nurse (RN)</li>
                      <li>Utilization management clinician</li>
                      <li>Clinical leader</li>
                      <li>Pre-licensure clinical trainee or intern</li>
                    </ul>
                  </>
                }
                value="licensed"
              >
                Licensed Clinicians or Clinical Care Providers
              </RadioButton>
              <RadioButton
                data-cy="care-support-btn"
                icon={DiversityIcon}
                tooltipContent={
                  <>
                    <strong>Care Support Roles</strong>
                    <ul>
                      <li>Peer support or coach</li>
                      <li>Utilization management staff</li>
                      <li>Care coordinator or case manager</li>
                      <li>Customer support representative or concierge</li>
                      <li>Medical assistant</li>
                    </ul>
                  </>
                }
                value="care-team"
              >
                Care Support Team
              </RadioButton>
              <RadioButton
                data-cy="other-role-btn"
                icon={PsychiatryIcon}
                tooltipContent={
                  <>
                    <strong>Other Professionals</strong>
                    <ul>
                      <li>Claims processor</li>
                      <li>Human resources professional</li>
                      <li>Engineer</li>
                      <li>Non-clinical corporate staff</li>
                    </ul>
                  </>
                }
                value="other"
              >
                Other Professionals (Internal)
              </RadioButton>
            </ButtonRadioGroup>
          </S.ButtonContainer>
          <AnimatePresence>
            {isClinical === true && (
              <motion.div
                key="npi-input"
                animate={{
                  height: npiIsValid ? '6.25rem' : '7.75rem',
                  marginTop: '1.5rem',
                  opacity: 1
                }}
                exit={{ height: 0, marginTop: 0, opacity: 0 }}
                initial={{ height: 0, opacity: 0 }}
                transition={{
                  duration: 0.5,
                  ease: 'easeInOut'
                }}
              >
                <S.BodyTextWithTooltip>
                  Please enter your NPI, if available.
                  <HelpTooltipTrigger
                    delay={0}
                    inline
                  >
                    Optional - you will have an opportunity to enter your NPI when you complete your
                    profile. If you do not have an NPI, leave this field blank.
                  </HelpTooltipTrigger>
                </S.BodyTextWithTooltip>
                <S.InputField
                  aria-label="NPI (National Provider Identifier)"
                  data-cy="npi-field"
                  errorMessage={npiErrorMessage}
                  isInvalid={!npiIsValid}
                  onChange={handleNpiChange}
                  placeholder="NPI (National Provider Identifier)"
                  validationBehavior="native"
                  value={npi}
                />
              </motion.div>
            )}
            <motion.div
              key="create-submit-button"
              animate={{ height: '3.25rem', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              initial={{ height: 0, opacity: 0 }}
              transition={{
                duration: 0.5,
                ease: 'easeInOut'
              }}
            >
              <S.SubmitButton
                data-cy="submit-button"
                fullWidth
                isDisabled={isClinical === undefined}
                isLoading={isSending}
                trailingIcon={ArrowRightIcon}
                type="submit"
                variant="primary"
              >
                Submit
              </S.SubmitButton>
            </motion.div>
          </AnimatePresence>
        </motion.form>
      );
    case 'signUp':
      return (
        <motion.form
          key="sign-up-form"
          ref={formRef}
          animate={{ opacity: 1 }}
          data-cy="start-account-form"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          noValidate
          onSubmit={handleSignUpButton}
          transition={{
            duration: 1,
            ease: 'easeInOut'
          }}
        >
          <S.Title>Create your account</S.Title>
          <S.BodyText>
            Get your Benchmarks and help build health equity through cultural competence training
            and inclusive care matching.
          </S.BodyText>
          <S.InputField
            aria-label="First name"
            data-cy="first-name-field"
            isRequired
            onChange={handleFirstNameChange}
            placeholder="First name"
            validationBehavior="native"
            value={firstName}
          />
          <S.InputField
            aria-label="Last name"
            data-cy="last-name-field"
            isRequired
            onChange={handleLastNameChange}
            placeholder="Last name"
            validationBehavior="native"
            value={lastName}
          />
          <S.InputField
            aria-label="Work email address"
            data-cy="email-field"
            errorMessage={
              hasAttemptedSubmit
                ? !isNonEmptyString(emailAddress)
                  ? 'Please fill out this field.'
                  : errorMessage
                : undefined
            }
            isInvalid={
              hasAttemptedSubmit ? Boolean(errorMessage) || !isNonEmptyString(emailAddress) : false
            }
            isRequired
            onChange={handleEmailAddressChange}
            placeholder="Work email address"
            type="email"
            validationBehavior="native"
            value={emailAddress}
          />
          <S.SubmitButton
            data-cy="submit-button"
            fullWidth
            isLoading={isSending}
            trailingIcon={ArrowRightIcon}
            type="submit"
            variant="primary"
          >
            Submit
          </S.SubmitButton>
          <S.SmallText>
            Already have an account?{' '}
            <S.LoginLink onPress={() => loginWithRedirect({})}>Log in</S.LoginLink> now.
          </S.SmallText>
        </motion.form>
      );
    case 'success':
      return (
        <motion.div
          key="success-page"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 1,
            ease: 'easeInOut'
          }}
        >
          <S.Title>Check your inbox.</S.Title>
          <S.BodyText data-cy="success-response">
            Your security comes first, so we’ve sent a link to {emailAddress}. If you don’t see it,
            be sure to check your spam folder.
          </S.BodyText>
        </motion.div>
      );
  }
};

export default SignUpForm;
