import PopoverTrigger from 'src/components/PopoverTrigger';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import * as S from './styles';

export interface Props {
  badges: {
    imageSrc: string;
    level: 'Awareness' | 'Excellence' | 'Proficiency';
    notYetAwarded: boolean;
  }[];
  communityAcronym: 'BIPOC' | 'LGBQ' | 'TGNC';
  earnedAtDate: string;
  score: number;
}

const Benchmark = ({ badges, communityAcronym, earnedAtDate, score }: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  return (
    <S.Benchmark.Root>
      <S.Benchmark.Acronym>{communityAcronym}</S.Benchmark.Acronym>
      <S.Benchmark.Expansion>
        {
          {
            BIPOC: 'Black, Indigenous, and People of Color',
            LGBQ: 'Lesbian, Gay, Bisexual, and Queer',
            TGNC: isInMobileBreakpointRange
              ? 'Transgender & Gender Non-Conforming'
              : 'Transgender and Gender Non-Conforming'
          }[communityAcronym]
        }
      </S.Benchmark.Expansion>
      <S.Benchmark.Progress.Root>
        <S.Benchmark.Progress.ProgressBar
          aria-label={`${communityAcronym} Benchmark progress`}
          maxValue={100}
          minValue={0}
          value={score}
        />
        <div />
        {badges.map(badge => (
          <PopoverTrigger
            key={badge.level}
            content={
              <S.Benchmark.Popover.Root>
                {badge.notYetAwarded ? (
                  <>
                    <S.Benchmark.Popover.Header>
                      <S.Benchmark.Popover.Title>Not yet awarded</S.Benchmark.Popover.Title>
                    </S.Benchmark.Popover.Header>
                    <S.Benchmark.Popover.Text>
                      Based on the information you’ve input, you have not met the minimum
                      requirements to achieve the {badge.level} Benchmark. Complete additional
                      Violet collections or add relevant experience to your profile.
                    </S.Benchmark.Popover.Text>
                  </>
                ) : (
                  <>
                    <S.Benchmark.Popover.Header>
                      <S.Benchmark.Popover.Title>{badge.level}</S.Benchmark.Popover.Title>
                      <S.Benchmark.Popover.EarnedAtDate>
                        <S.Benchmark.Popover.CheckCircleIcon />
                        {`Earned ${earnedAtDate}`}
                      </S.Benchmark.Popover.EarnedAtDate>
                    </S.Benchmark.Popover.Header>
                    <S.Benchmark.Popover.Text>
                      {badge.level === 'Awareness' &&
                        `
                        This Benchmark is awarded to providers in the top 50% of their specialty.
                        Culturally aware providers recognize inequities in health care for various identities, socio-economic statuses, and communities.
                        These providers are committed to building health equity by acknowledging that identities and culture deeply matter in health care.
                      `}
                      {badge.level === 'Proficiency' &&
                        `
                        This Benchmark is awarded to providers in the top 25% of their specialty.
                        Culturally proficient providers are able to speak to identities and cultures in their care delivery.
                        They are actively building health equity by creating safer environments and guiding patients on their unique health journeys.
                      `}
                      {badge.level === 'Excellence' &&
                        `
                        This Benchmark is awarded to providers in the top 5% of their specialty.
                        Culturally excellent providers are advocates for communities.
                        They have extensive professional experience working directly with patients with specific and intersecting cultural identities.
                        Culturally excellent providers are generally pioneers and leading the industry in building health equity for the communities they specialize in.
                      `}
                    </S.Benchmark.Popover.Text>
                  </>
                )}
              </S.Benchmark.Popover.Root>
            }
            placement="top left"
          >
            <S.Benchmark.BadgeWrapper>
              <S.Benchmark.Badge
                alt={
                  badge.notYetAwarded
                    ? 'Benchmark not yet awarded'
                    : `${communityAcronym} ${badge.level} Benchmark`
                }
                height={50}
                src={badge.imageSrc}
              />
              <S.Benchmark.BadgeLabel>{badge.level}</S.Benchmark.BadgeLabel>
            </S.Benchmark.BadgeWrapper>
          </PopoverTrigger>
        ))}
      </S.Benchmark.Progress.Root>
    </S.Benchmark.Root>
  );
};

export default Benchmark;
