import { useFeatureFlagVariantKey } from 'posthog-js/react';

import useBreakpointRange from '../../../hooks/useBreakpointRange';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';

import * as S from './styles';

interface Props {
  backLabel?: string;
  backTo: string;
  includeSkip?: boolean;
  isDisabled?: boolean;
  nextIsLoading?: boolean;
  nextLabel?: string;
  skipLabel?: string;
  skipTo?: string;
}

const BackNext = ({
  backLabel,
  backTo,
  includeSkip = false,
  isDisabled = false,
  nextIsLoading = false,
  nextLabel,
  skipLabel,
  skipTo
}: Props) => {
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();

  const edHistorySkipVariant = useFeatureFlagVariantKey('education_history_skip');

  const backButton = (
    <Button
      data-cy="back-button"
      size="large"
      to={backTo}
      variant="outline"
    >
      {backLabel ?? 'Back'}
    </Button>
  );

  const nextButton = (
    <Button
      data-cy="next-button"
      isDisabled={isDisabled}
      isLoading={nextIsLoading}
      size="large"
      type="submit"
    >
      {nextLabel ?? 'Next'}
    </Button>
  );

  const skipButton = (
    <S.SkipButton
      data-cy="skip-button"
      isLoading={nextIsLoading}
      size="large"
      to={skipTo}
      variant="outline"
    >
      {skipLabel ?? 'Skip this step'}
    </S.SkipButton>
  );

  return (
    <S.BackNext>
      {isInMobileBreakpointRange && (
        <ButtonGroup
          align="left"
          direction="column"
          size="large"
        >
          {nextButton}
          {includeSkip ? skipButton : <> </>}
          {backButton}
        </ButtonGroup>
      )}
      {isInDesktopBreakpointRange && (
        <ButtonGroup
          align={edHistorySkipVariant === 'test' ? 'space-between' : 'right'}
          direction="row"
          size="large"
        >
          {backButton}
          {includeSkip ? (
            <div>
              {skipButton}
              {nextButton}
            </div>
          ) : (
            nextButton
          )}
        </ButtonGroup>
      )}
    </S.BackNext>
  );
};

export default BackNext;
