import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import useElementIsVisible from 'src/hooks/useElementIsVisible';

interface ObservedTargetProps {
  setIsIntersecting: Dispatch<SetStateAction<boolean>>;
}

const ObservedTarget = ({ setIsIntersecting }: ObservedTargetProps) => {
  const intersectionObserverRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useElementIsVisible(intersectionObserverRef);

  useEffect(() => {
    setIsIntersecting(isIntersecting);
  }, [isIntersecting, setIsIntersecting]);

  return <div ref={intersectionObserverRef} />;
};

export default ObservedTarget;
