import * as S from './styles';

interface Props {
  children: JSX.Element | string;
  className?: string;
  isDisabled?: boolean;
  situation?: 'alert' | 'helper' | 'message';
  state?: 'error' | 'info' | 'success' | 'warning';
}

const InlineDialog = ({
  children,
  className,
  isDisabled = false,
  situation = 'message',
  state
}: Props) => {
  const displayIcons = situation === 'alert';
  return (
    <S.InlineDialog
      $isDisabled={isDisabled}
      $situation={situation}
      $state={state}
      className={className}
    >
      {displayIcons && state === 'info' && <S.InfoIcon aria-hidden="true" />}
      {/*
      TODO: add icons for statuses
            success, warning, and error
            once they exist (pending design)
      */}
      {children}
    </S.InlineDialog>
  );
};

export default InlineDialog;
