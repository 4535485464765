import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useTableRowGroup } from 'react-aria';

interface Props {
  type: 'tbody' | 'tfoot' | 'thead';
}

const RowGroup = ({ children, type: Element }: PropsWithChildren<Props>) => {
  const { rowGroupProps } = useTableRowGroup();
  return (
    <Element
      {...rowGroupProps}
      className={clsx({ 'table-body': Element === 'tbody' })}
    >
      {children}
    </Element>
  );
};

export default RowGroup;
