import { transparentize } from 'polished';
import Button from 'src/components/Buttons/Button';
import { GRAY_400_COLOR } from 'src/components/GlobalStyle';
import Table from 'src/components/Table';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

/*
 ** TODO: get designs for mobile
 */
export const DownloadButton = styled(Button)``;

export const EducationTable = styled(Table)`
  border-color: var(--gray-200);
`;

export const NotAvailableText = styled.div`
  display: inline-flex;
  align-items: center;
  height: 2.25rem;
  ${TEXT_STYLES.tooltipTitle}
  color: ${transparentize(0.2, GRAY_400_COLOR)};
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface TableHeaderCellProps {
  $align?: 'end' | 'start';
}

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
  text-transform: uppercase;

  ${({ $align = 'start' }) =>
    $align === 'end' &&
    `
    margin-left: auto;
    padding-right: 1.25rem;
  `}
`;
