import { createContext, PropsWithChildren } from 'react';
import { AriaRadioGroupProps, useRadioGroup } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

export interface GroupContext {
  isRequired?: boolean;
  state: RadioGroupState;
}

interface Props extends AriaRadioGroupProps {
  className?: string;
}

const RadioGroup = ({ children, className, ...otherProps }: PropsWithChildren<Props>) => {
  const state = useRadioGroupState(otherProps);
  const { labelProps, radioGroupProps } = useRadioGroup(otherProps, state);

  const { label } = otherProps;

  return (
    <div
      {...radioGroupProps}
      className={className}
    >
      {isNonEmptyString(label) && <span {...labelProps}>{label}</span>}
      <RadioContext.Provider value={{ state }}>{children}</RadioContext.Provider>
    </div>
  );
};

export { RadioGroup as Group };

export const RadioContext = createContext<GroupContext | null>(null);
