import { Temporal } from '@js-temporal/polyfill';
// TODO: import `clamp` from `'react-aria'` once it stops throwing a TS error.
import { clamp } from '@react-aria/utils';
import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useLocation } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import BenchmarkSection from 'src/components/BenchmarkSection';
import EducationCarousel from 'src/components/Education/EducationCarousel';
import PathwayAnnouncementModalDialog from 'src/components/PathwayAnnouncementModalDialog';
import { useEffectOnce } from 'usehooks-ts';

import Benchmark, { Props as BenchmarkInterface } from '../../components/Benchmark';
import Button from '../../components/Buttons/Button';
import Spinner from '../../components/Spinner';
import useBreakpointRange from '../../hooks/useBreakpointRange';
import useOpenErrorModalDialog from '../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../hooks/useUser';

import OldDashboardEducation from './components/OldDashboardEducation';
import * as S from './styles';

const Dashboard = () => {
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const { hash } = useLocation();
  const newDashboardEnabled = useFeatureFlagEnabled('new_education_dashboard');
  const {
    close: closePathwaysAnnouncementModal,
    isOpen: pathwaysAnnouncementModalIsOpen,
    open: openPathwaysAnnouncementModal
  } = useOverlayTriggerState({});
  const posthog = usePostHog();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();

  const [benchmarks, setBenchmarks] = useState<BenchmarkInterface[] | null>(null);

  useEffect(() => {
    (async () => {
      try {
        let url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/users/dashboard/badge_criteria`;

        let options: RequestInit = {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        };

        let response = await fetch(url, options);

        const { data: data1 } = (await response.json()) as APIUsersDashboardBadgeCriteria;

        url = `${process.env.REACT_APP_API_BASE_PATH}/users/dashboard`;

        options = {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        };

        response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data: data2 } = (await response.json()) as APIUsersDashboard;

        setBenchmarks(
          cloneDeep(data2)
            .sort((benchmark1, benchmark2) =>
              benchmark1.score_type === 'bipoc' ||
              (benchmark1.score_type === 'lgbq' && benchmark2.score_type === 'tgnc')
                ? -1
                : 1
            )
            .filter(benchmark => benchmark.is_active)
            .map(benchmark => {
              const communityAcronym = benchmark.score_type.toUpperCase() as
                | 'BIPOC'
                | 'LGBQ'
                | 'TGNC';
              const level = `${benchmark.score_level[0].toUpperCase()}${benchmark.score_level.slice(
                1
              )}`;
              const earnedAtDate = Temporal.PlainDate.from(
                benchmark.earned_at[benchmark.earned_at.length - 1] === 'Z'
                  ? benchmark.earned_at.slice(0, -1)
                  : benchmark.earned_at
              ).toLocaleString('en-US', { dateStyle: 'medium' });

              const awarenessBadgeIsNotYetAwarded =
                level !== 'Excellence' && level !== 'Proficiency' && level !== 'Awareness';
              const proficiencyBadgeIsNotYetAwarded =
                level !== 'Excellence' && level !== 'Proficiency';
              const excellenceBadgeIsNotYetAwarded = level !== 'Excellence';

              const criteria = data1
                .find(({ score_type }) => score_type === benchmark.score_type)!
                .criteria.sort((a, b) => a.low_score - b.low_score);

              return {
                badges: [
                  {
                    imageSrc: `${process.env.REACT_APP_BASE_PATH}${
                      criteria[awarenessBadgeIsNotYetAwarded ? 0 : 1].image.url
                    }`,
                    level: 'Awareness',
                    notYetAwarded: awarenessBadgeIsNotYetAwarded
                  },
                  {
                    imageSrc: `${process.env.REACT_APP_BASE_PATH}${
                      criteria[proficiencyBadgeIsNotYetAwarded ? 0 : 2].image.url
                    }`,
                    level: 'Proficiency',
                    notYetAwarded: proficiencyBadgeIsNotYetAwarded
                  },
                  {
                    imageSrc: `${process.env.REACT_APP_BASE_PATH}${
                      criteria[excellenceBadgeIsNotYetAwarded ? 0 : 3].image.url
                    }`,
                    level: 'Excellence',
                    notYetAwarded: excellenceBadgeIsNotYetAwarded
                  }
                ],
                communityAcronym,
                earnedAtDate,
                score: Math.round(
                  ((clamp(
                    (benchmark.total_score - criteria[0].low_score) /
                      (criteria[0].high_score - criteria[0].low_score),
                    0,
                    1
                  ) +
                    clamp(
                      (benchmark.total_score - criteria[1].low_score) /
                        (criteria[1].high_score - criteria[1].low_score),
                      0,
                      1
                    ) +
                    clamp(
                      (benchmark.total_score - criteria[2].low_score) /
                        (criteria[2].high_score - criteria[2].low_score),
                      0,
                      1
                    )) /
                    3) *
                    100
                )
              };
            })
        );
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    })();
  }, [bearerToken, openErrorModalDialog, openSignedOutModalDialog]);

  const setPathwayAnnouncementModalAsViewed = () => {
    // We set an event so that feature flag viewed_pathways_announcement
    // is set to true based on this user property
    posthog.capture('Viewed pathways announcement model', {
      $set_once: { viewed_pathways_announcement: true }
    });
    posthog.identify(user.id, { viewed_pathways_announcement: true });
  };

  const isLoading = benchmarks === null;

  useEffect(() => {
    if (hash === '' || isLoading) return;

    const element = document.querySelector(hash);

    if (element) {
      element.scrollIntoView();
    }
  }, [hash, isLoading]);

  useEffectOnce(() => {
    // on initial load, after feature flags are loaded
    posthog.onFeatureFlags(function () {
      if (
        posthog.isFeatureEnabled('education_pathways_mvp') === true &&
        posthog.isFeatureEnabled('viewed_pathways_announcement') !== true &&
        !window.location.search.includes('internal_referral=onboarding')
      ) {
        openPathwaysAnnouncementModal();
      }
    });
  });

  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Main data-cy="main">
      {newDashboardEnabled !== true && <S.Title data-cy="title">Hi {user.first_name},</S.Title>}
      {isInDesktopBreakpointRange && newDashboardEnabled !== true && benchmarks.length === 0 && (
        <S.YourNextStepLink.Root
          href="https://www.joinviolet.com/get-started/achieving-health-equity"
          target="_blank"
        >
          <div>
            <S.YourNextStepLink.Title>Your next step</S.YourNextStepLink.Title>
            <S.YourNextStepLink.Text>
              Quickly start learning with Violet via this short, interactive guide.
            </S.YourNextStepLink.Text>
          </div>
          <S.YourNextStepLink.StartNow>
            <S.YourNextStepLink.StartNowText>Start now</S.YourNextStepLink.StartNowText>
            <S.YourNextStepLink.StartNowIconWrapper>
              <S.YourNextStepLink.StartNowIcon />
            </S.YourNextStepLink.StartNowIconWrapper>
          </S.YourNextStepLink.StartNow>
        </S.YourNextStepLink.Root>
      )}
      {newDashboardEnabled === true ? (
        <BenchmarkSection
          benchmarks={benchmarks}
          isClinical={user.is_clinical}
        />
      ) : !user.is_clinical ? (
        <S.YourBenchmarksDescription.Root>
          We know that half of the American population will be culturally diverse by 2045. To
          promote health equity, it’s essential for all health care professionals to take the
          necessary steps to deliver culturally competent care at every stage of a patient’s
          experience. Contribute to this goal by learning with Violet.
        </S.YourBenchmarksDescription.Root>
      ) : (
        <>
          <S.Subtitle>Benchmarks</S.Subtitle>
          {benchmarks.length === 0 ? (
            isInMobileBreakpointRange ? (
              <S.EmptyState.Root>
                <S.EmptyState.Image1 />
                <S.EmptyState.Text>
                  Thank you for submitting your information. We’re currently reviewing your
                  information and generating your Benchmarks. We’ll email you once this is complete.{' '}
                  <S.EmptyState.Anchor
                    href="https://www.joinviolet.com/faq/benchmarks"
                    target="_blank"
                  >
                    You can also learn more about our benchmarking process here
                  </S.EmptyState.Anchor>
                  .
                </S.EmptyState.Text>
                <S.EmptyState.Image2 />
              </S.EmptyState.Root>
            ) : (
              <S.YourBenchmarksDescription.Root>
                Your Benchmarks are awarded based on a combination of lived experience, education,
                work experience, and more. Continue to update your profile with new information or
                complete Violet education to keep progressing to the next Benchmark.{' '}
                <S.YourBenchmarksDescription.Anchor
                  href="https://www.joinviolet.com/faq/benchmarks"
                  rel="noreferrer"
                  target="_blank"
                >
                  Read more
                </S.YourBenchmarksDescription.Anchor>{' '}
                about Violet Benchmarks.
              </S.YourBenchmarksDescription.Root>
            )
          ) : (
            <S.Benchmarks.Root>
              <S.Benchmarks.Description.Root>
                Your Benchmarks are determined based on your work experience, education, lived
                experience, and confidence administering care within each community. You may
                increase your Benchmarks by adding more experience and completing Violet
                collections.{' '}
                <S.Benchmarks.Description.Anchor
                  href="https://www.joinviolet.com/faq/benchmarks"
                  rel="noreferrer"
                  target="_blank"
                >
                  Read more
                </S.Benchmarks.Description.Anchor>{' '}
                about Violet Benchmarks.
              </S.Benchmarks.Description.Root>
              {benchmarks.map(benchmark => (
                <Benchmark
                  key={benchmark.communityAcronym}
                  badges={benchmark.badges}
                  communityAcronym={benchmark.communityAcronym}
                  earnedAtDate={benchmark.earnedAtDate}
                  score={benchmark.score}
                />
              ))}
              <S.Benchmarks.KeepGrowing.Root>
                <S.Benchmarks.KeepGrowing.Text>Keep growing</S.Benchmarks.KeepGrowing.Text>
                <S.Benchmarks.KeepGrowing.Buttons>
                  <Button to="/dashboard/profile/work-experience">Add experience</Button>
                  <Button
                    to="/dashboard/education/collections"
                    variant="outline"
                  >
                    Complete education
                  </Button>
                </S.Benchmarks.KeepGrowing.Buttons>
              </S.Benchmarks.KeepGrowing.Root>
            </S.Benchmarks.Root>
          )}
        </>
      )}
      {newDashboardEnabled === true ? <EducationCarousel /> : <OldDashboardEducation />}
      <S.ExploreMoreCollectionsLink.Root to="/dashboard/education/collections">
        <S.ExploreMoreCollectionsLink.CtaText>
          <S.ExploreMoreCollectionsLink.Title>
            Explore all collections
          </S.ExploreMoreCollectionsLink.Title>
          <S.ExploreMoreCollectionsLink.Subtitle>
            See all available Violet education collections.
          </S.ExploreMoreCollectionsLink.Subtitle>
        </S.ExploreMoreCollectionsLink.CtaText>
        <S.ExploreMoreCollectionsLink.ViewAllIconWrapper>
          <VisuallyHidden>View all</VisuallyHidden>
          <S.ExploreMoreCollectionsLink.ViewAllIcon />
        </S.ExploreMoreCollectionsLink.ViewAllIconWrapper>
      </S.ExploreMoreCollectionsLink.Root>
      {pathwaysAnnouncementModalIsOpen && (
        <PathwayAnnouncementModalDialog
          close={closePathwaysAnnouncementModal}
          setPathwayAnnouncementModalAsViewed={setPathwayAnnouncementModalAsViewed}
        />
      )}
    </S.Main>
  );
};

export default Dashboard;
