import { PropsWithChildren, useRef } from 'react';
import {
  AriaDialogProps,
  AriaOverlayProps,
  FocusScope,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll
} from 'react-aria';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaDialogProps, AriaOverlayProps {
  className?: string;
  title?: string;
}

const ModalDialogFullscreen = ({
  children,
  className,
  title,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { isDismissable = false, onClose } = otherProps;

  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(otherProps, ref);

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps, titleProps } = useDialog(otherProps, ref);

  return (
    <S.ModalDialogFullscreen {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
          className={className}
        >
          <S.ModalHeader>
            {isDismissable && (
              <S.CloseButton
                data-cy="back-btn"
                onPress={onClose}
              >
                <S.BackButtonIcon
                  aria-label="Back"
                  role="img"
                />
              </S.CloseButton>
            )}
            {isNonEmptyString(title) && <S.Title {...titleProps}>{title}</S.Title>}
            {isDismissable && (
              <S.CloseButton
                data-cy="close-btn"
                onPress={onClose}
              >
                <S.CloseButtonIcon
                  aria-label="Close"
                  role="img"
                />
              </S.CloseButton>
            )}
          </S.ModalHeader>
          {children}
        </S.Dialog>
      </FocusScope>
    </S.ModalDialogFullscreen>
  );
};

export default ModalDialogFullscreen;
