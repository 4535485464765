import { Temporal } from '@js-temporal/polyfill';

export const COMMUNITIES = ['BIPOC', 'LGBQ', 'TGNC'];

export const CURRENT_YEAR = Temporal.Now.plainDateISO().year;

// Taken from https://cloud.google.com/translate/docs/languages.
// Added "American Sign Language".
// Replaced "Chinese (Simplified)" and "Chinese (Traditional)" with "Cantonese" and "Mandarin".
export const LANGUAGES = [
  'Afrikaans',
  'Albanian',
  'American Sign Language',
  'Amharic',
  'Arabic',
  'Arabic (Egyptian)',
  'Arabic (Gulf)',
  'Arabic (Levantine)',
  'Arabic (Maghrebi)',
  'Armenian',
  'Azerbaijani',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bosnian',
  'Bulgarian',
  'Cantonese',
  'Catalan',
  'Cebuano',
  'Corsican',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Esperanto',
  'Estonian',
  'Finnish',
  'French',
  'Frisian',
  'Galician',
  'Georgian',
  'German',
  'Greek',
  'Gujarati',
  'Haitian Creole',
  'Hausa',
  'Hawaiian',
  'Hebrew',
  'Hindi',
  'Hmong',
  'Hungarian',
  'Icelandic',
  'Igbo',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kazakh',
  'Khmer',
  'Kinyarwanda',
  'Korean',
  'Kurdish',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Luxembourgish',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Mandarin',
  'Maori',
  'Marathi',
  'Mongolian',
  'Myanmar (Burmese)',
  'Nepali',
  'Norwegian',
  'Nyanja (Chichewa)',
  'Odia (Oriya)',
  'Pashto',
  'Persian',
  'Polish',
  'Portuguese (Portugal, Brazil)',
  'Punjabi',
  'Romanian',
  'Russian',
  'Samoan',
  'Scots Gaelic',
  'Serbian',
  'Sesotho',
  'Shona',
  'Sindhi',
  'Sinhala (Sinhalese)',
  'Slovak',
  'Slovenian',
  'Somali',
  'Spanish',
  'Sundanese',
  'Swahili',
  'Swedish',
  'Tagalog (Filipino)',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Turkish',
  'Turkmen',
  'Ukrainian',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zulu'
];

export const NPI_SPECIALTIES = [
  'Acupuncturist',
  'Advanced Practice Midwife',
  'Allergy & Immunology',
  'Anesthesiology',
  'Anesthesiology, Critical Care Medicine',
  'Anesthesiology, Pain Medicine',
  'Anesthesiology, Pediatric Anesthesiology',
  'Art Therapist',
  'Assistant Behavior Analyst',
  'Behavior Technician',
  'Case Management',
  'Case Manager/Care Coordinator',
  'Chiropractor',
  'Clinical Neuropsychologist',
  'Clinical Nurse Specialist, Family Health',
  'Clinic/Center, Adult Mental Health',
  'Clinic/Center, Developmental Disabilities',
  'Clinic/Center, Medical Specialty',
  'Clinic/Center, Primary Care',
  'Clinic/Center, Rehabilitation, Substance Use Disorder',
  'Colon & Rectal Surgery',
  'Community/Behavioral Health',
  'Community Health Worker',
  'Contractor',
  'Counselor',
  'Counselor, Addiction (Substance Use Disorder)',
  'Counselor, Mental Health',
  'Counselor, Pastoral',
  'Counselor, Professional',
  'Counselor, School',
  'Dermatology',
  'Developmental Therapist',
  'Dietitian, Registered',
  'Dietitian, Registered, Nutrition, Obesity and Weight Management',
  'Dietitian, Registered, Nutrition, Pediatric',
  'Dietitian, Registered, Nutrition, Renal',
  'Doula',
  'Drama Therapist',
  'Early Intervention Provider Agency',
  'Emergency Medicine',
  'Emergency Medicine, Emergency Medical Services',
  'Emergency Medicine, Medical Toxicology',
  'Emergency Medicine, Pediatric Emergency Medicine',
  'Emergency Medicine, Sports Medicine',
  'Emergency Medicine, Undersea and Hyperbaric Medicine',
  'Family Medicine',
  'Family Medicine, Addiction Medicine',
  'Family Medicine, Adolescent Medicine',
  'Family Medicine, Adult Medicine',
  'Family Medicine, Bariatric Medicine',
  'Family Medicine, Geriatric Medicine',
  'Family Medicine, Hospice and Palliative Medicine',
  'Family Medicine, Sports Medicine',
  'General Acute Care Hospital',
  'General Acute Care Hospital, Children',
  'General Practice',
  'Health Educator',
  'Health & Wellness Coach',
  'Home Health Aide',
  'Hospitalist',
  'Internal Medicine',
  'Internal Medicine, Addiction Medicine',
  'Internal Medicine, Adolescent Medicine',
  'Internal Medicine, Advanced Heart Failure and Transplant Cardiology',
  'Internal Medicine, Allergy & Immunology',
  'Internal Medicine, Cardiovascular Disease',
  'Internal Medicine, Clinical & Laboratory Immunology',
  'Internal Medicine, Critical Care Medicine',
  'Internal Medicine, Endocrinology, Diabetes & Metabolism',
  'Internal Medicine, Gastroenterology',
  'Internal Medicine, Geriatric Medicine',
  'Internal Medicine, Hematology',
  'Internal Medicine, Hematology & Oncology',
  'Internal Medicine, Hospice and Palliative Medicine',
  'Internal Medicine, Infectious Disease',
  'Internal Medicine, Medical Oncology',
  'Internal Medicine, Nephrology',
  'Internal Medicine, Pulmonary Disease',
  'Internal Medicine, Rheumatology',
  'Internal Medicine, Sleep Medicine',
  'Internal Medicine, Sports Medicine',
  'Legal Medicine',
  'Licensed Practical Nurse',
  'Licensed Psychiatric Technician',
  'Marriage & Family Therapist',
  'Massage Therapist',
  'Midwife',
  'Music Therapist',
  'Naturopath',
  'Neurological Surgery',
  'Neuromusculoskeletal Medicine & OMM',
  'Nuclear Medicine, Nuclear Cardiology',
  'Nurse Practitioner',
  'Nurse Practitioner, Acute Care',
  'Nurse Practitioner, Adult Health',
  'Nurse Practitioner, Family',
  'Nurse Practitioner, Gerontology',
  'Nurse Practitioner, Obstetrics & Gynecology',
  'Nurse Practitioner, Pediatrics',
  'Nurse Practitioner, Primary Care',
  'Nurse Practitioner, Psych/Mental Health',
  'Nurse Practitioner, Women’s Health',
  'Nutritionist',
  'Nutritionist, Nutrition, Education',
  'Obstetrics & Gynecology',
  'Obstetrics & Gynecology, Gynecologic Oncology',
  'Obstetrics & Gynecology, Gynecology',
  'Obstetrics & Gynecology, Maternal & Fetal Medicine',
  'Obstetrics & Gynecology, Obstetrics',
  'Obstetrics & Gynecology, Reproductive Endocrinology',
  'Ophthalmology',
  'Oral & Maxillofacial Surgery',
  'Orthopaedic Surgery',
  'Orthopaedic Surgery, Adult Reconstructive Orthopaedic Surgery',
  'Orthopaedic Surgery, Orthopaedic Surgery of the Spine',
  'Orthopaedic Surgery, Pediatric Orthopaedic Surgery',
  'Otolaryngology',
  'Otolaryngology, Otolaryngology/Facial Plastic Surgery',
  'Pain Medicine, Interventional Pain Medicine',
  'Pathology, Anatomic Pathology',
  'Pathology, Anatomic Pathology & Clinical Pathology',
  'Pathology, Blood Banking & Transfusion Medicine',
  'Pathology, Clinical Pathology/Laboratory Medicine',
  'Pathology, Cytopathology',
  'Pathology, Hematology',
  'Pediatrics',
  'Pediatrics, Adolescent Medicine',
  'Pediatrics, Clinical & Laboratory Immunology',
  'Pediatrics, Developmental - Behavioral Pediatrics',
  'Pediatrics, Hospice and Palliative Medicine',
  'Pediatrics, Neonatal-Perinatal Medicine',
  'Pediatrics, Neurodevelopmental Disabilities',
  'Pediatrics, Pediatric Allergy/Immunology',
  'Pediatrics, Pediatric Cardiology',
  'Pediatrics, Pediatric Critical Care Medicine',
  'Pediatrics, Pediatric Emergency Medicine',
  'Pediatrics, Pediatric Endocrinology',
  'Pediatrics, Pediatric Gastroenterology',
  'Pediatrics, Pediatric Hematology-Oncology',
  'Pediatrics, Pediatric Infectious Diseases',
  'Pediatrics, Pediatric Nephrology',
  'Pediatrics, Pediatric Rheumatology',
  'Peer Specialist',
  'Physical Medicine & Rehabilitation',
  'Physical Medicine & Rehabilitation, Hospice and Palliative Medicine',
  'Physical Medicine & Rehabilitation, Neuromuscular Medicine',
  'Physical Medicine & Rehabilitation, Pain Medicine',
  'Physical Medicine & Rehabilitation, Pediatric Rehabilitation Medicine',
  'Physical Medicine & Rehabilitation, Spinal Cord Injury Medicine',
  'Physical Therapist',
  'Physician Assistant',
  'Physician Assistant, Medical',
  'Physician Assistant, Surgical',
  'Plastic Surgery',
  'Plastic Surgery, Plastic Surgery Within the Head and Neck',
  'Plastic Surgery, Surgery of the Hand',
  'Preventive Medicine, Occupational Medicine',
  'Preventive Medicine, Public Health & General Preventive Medicine',
  'Psychiatry & Neurology, Addiction Psychiatry',
  'Psychiatry & Neurology, Behavioral Neurology & Neuropsychiatry',
  'Psychiatry & Neurology, Child & Adolescent Psychiatry',
  'Psychiatry & Neurology, Clinical Neurophysiology',
  'Psychiatry & Neurology, Forensic Psychiatry',
  'Psychiatry & Neurology, Geriatric Psychiatry',
  'Psychiatry & Neurology, Neurology',
  'Psychiatry & Neurology, Neurology with Special Qualifications in Child Neurology',
  'Psychiatry & Neurology, Pain Medicine',
  'Psychiatry & Neurology, Psychiatry',
  'Psychiatry & Neurology, Psychosomatic Medicine',
  'Psychiatry & Neurology, Sleep Medicine',
  'Psychiatry & Neurology, Sports Medicine',
  'Psychoanalyst',
  'Psychologist',
  'Psychologist, Addiction (Substance Use Disorder)',
  'Psychologist, Adult Development & Aging',
  'Psychologist, Clinical',
  'Psychologist, Clinical Child & Adolescent',
  'Psychologist, Cognitive & Behavioral',
  'Psychologist, Counseling',
  'Psychologist, Exercise & Sports',
  'Psychologist, Family',
  'Psychologist, Forensic',
  'Psychologist, Group Psychotherapy',
  'Psychologist, Health',
  'Psychologist, Health Service',
  'Psychologist, Intellectual & Developmental Disabilities',
  'Psychologist, Prescribing (Medical)',
  'Psychologist, Psychoanalysis',
  'Psychologist, Rehabilitation',
  'Psychologist, School',
  'Radiology, Diagnostic Neuroimaging',
  'Radiology, Diagnostic Radiology',
  'Radiology, Neuroradiology',
  'Radiology, Pediatric Radiology',
  'Radiology, Radiological Physics',
  'Radiology, Vascular & Interventional Radiology',
  'Registered Nurse',
  'Registered Nurse, Addiction (Substance Use Disorder)',
  'Registered Nurse, Case Management',
  'Registered Nurse, Community Health',
  'Registered Nurse, Critical Care Medicine',
  'Registered Nurse, General Practice',
  'Registered Nurse, Home Health',
  'Registered Nurse, Obstetric, High-Risk',
  'Registered Nurse, Ophthalmic',
  'Registered Nurse, Psych/Mental Health',
  'Registered Nurse, Psych/Mental Health, Adult',
  'Registered Nurse, School',
  'Registered Nurse, Women’s Health Care, Ambulatory',
  'Rehabilitation Counselor',
  'Rehabilitation Practitioner',
  'Social Worker',
  'Social Worker, Clinical',
  'Social Worker, School',
  'Special Hospital',
  'Specialist',
  'Specialist/Technologist, Athletic Trainer',
  'Specialist/Technologist, Other, EEG',
  'Specialist/Technologist, Other, Electroneurodiagnostic',
  'Speech-Language Pathologist,  ',
  'Student in an Organized Health Care Education/Training Program',
  'Surgery',
  'Surgery, Pediatric Surgery',
  'Surgery, Plastic and Reconstructive Surgery',
  'Surgery, Surgical Critical Care',
  'Surgery, Surgical Oncology',
  'Surgery, Trauma Surgery',
  'Surgery, Vascular Surgery',
  'Technician, Other',
  'Thoracic Surgery (Cardiothoracic Vascular Surgery)',
  'Urology',
  'Urology, Female Pelvic Medicine and Reconstructive Surgery',
  'Urology, Pediatric Urology',
  'Voluntary or Charitable'
];

export const PROFESSIONAL_ORGS = [
  {
    acronym: 'AAPA',
    name: 'American Academy of PAs'
  },
  {
    acronym: 'ADA CERP',
    name: 'American Dental Association’s Continuing Education Recognition Program'
  },
  {
    acronym: 'AMA',
    name: 'American Medical Association'
  },
  {
    acronym: 'ANCC',
    name: 'American Nurses Credentialing Center'
  },
  {
    acronym: 'APA',
    name: 'American Psychological Association'
  },
  {
    acronym: 'ACPE',
    name: 'Accreditation Council for Pharmacy Education'
  },
  {
    acronym: 'ASWB',
    name: 'Association of Social Work Boards'
  },
  {
    acronym: 'CDR',
    name: 'Commission on Dietetic Registration'
  },
  {
    acronym: 'NBCC',
    name: 'National Board for Certified Counselors'
  },
  {
    acronym: 'NYSED - Psychology',
    name: 'New York State Education Department - Psychology'
  },
  {
    acronym: 'NYSED - LCSW/LMSW',
    name: 'New York State Education Department - Social Work'
  },
  {
    acronym: 'NYSED - LMHC',
    name: 'New York State Education Department - Mental Health Counseling'
  }
];

export const STATES = [
  {
    abbreviation: 'AL',
    name: 'Alabama'
  },
  {
    abbreviation: 'AK',
    name: 'Alaska'
  },
  {
    abbreviation: 'AZ',
    name: 'Arizona'
  },
  {
    abbreviation: 'AR',
    name: 'Arkansas'
  },
  {
    abbreviation: 'CA',
    name: 'California'
  },
  {
    abbreviation: 'CO',
    name: 'Colorado'
  },
  {
    abbreviation: 'CT',
    name: 'Connecticut'
  },
  {
    abbreviation: 'DE',
    name: 'Delaware'
  },
  {
    abbreviation: 'DC',
    name: 'District Of Columbia'
  },
  {
    abbreviation: 'FL',
    name: 'Florida'
  },
  {
    abbreviation: 'GA',
    name: 'Georgia'
  },
  {
    abbreviation: 'HI',
    name: 'Hawaii'
  },
  {
    abbreviation: 'ID',
    name: 'Idaho'
  },
  {
    abbreviation: 'IL',
    name: 'Illinois'
  },
  {
    abbreviation: 'IN',
    name: 'Indiana'
  },
  {
    abbreviation: 'IA',
    name: 'Iowa'
  },
  {
    abbreviation: 'KS',
    name: 'Kansas'
  },
  {
    abbreviation: 'KY',
    name: 'Kentucky'
  },
  {
    abbreviation: 'LA',
    name: 'Louisiana'
  },
  {
    abbreviation: 'ME',
    name: 'Maine'
  },
  {
    abbreviation: 'MD',
    name: 'Maryland'
  },
  {
    abbreviation: 'MA',
    name: 'Massachusetts'
  },
  {
    abbreviation: 'MI',
    name: 'Michigan'
  },
  {
    abbreviation: 'MN',
    name: 'Minnesota'
  },
  {
    abbreviation: 'MS',
    name: 'Mississippi'
  },
  {
    abbreviation: 'MO',
    name: 'Missouri'
  },
  {
    abbreviation: 'MT',
    name: 'Montana'
  },
  {
    abbreviation: 'NE',
    name: 'Nebraska'
  },
  {
    abbreviation: 'NV',
    name: 'Nevada'
  },
  {
    abbreviation: 'NH',
    name: 'New Hampshire'
  },
  {
    abbreviation: 'NJ',
    name: 'New Jersey'
  },
  {
    abbreviation: 'NM',
    name: 'New Mexico'
  },
  {
    abbreviation: 'NY',
    name: 'New York'
  },
  {
    abbreviation: 'NC',
    name: 'North Carolina'
  },
  {
    abbreviation: 'ND',
    name: 'North Dakota'
  },
  {
    abbreviation: 'OH',
    name: 'Ohio'
  },
  {
    abbreviation: 'OK',
    name: 'Oklahoma'
  },
  {
    abbreviation: 'OR',
    name: 'Oregon'
  },
  {
    abbreviation: 'PA',
    name: 'Pennsylvania'
  },
  {
    abbreviation: 'RI',
    name: 'Rhode Island'
  },
  {
    abbreviation: 'SC',
    name: 'South Carolina'
  },
  {
    abbreviation: 'SD',
    name: 'South Dakota'
  },
  {
    abbreviation: 'TN',
    name: 'Tennessee'
  },
  {
    abbreviation: 'TX',
    name: 'Texas'
  },
  {
    abbreviation: 'UT',
    name: 'Utah'
  },
  {
    abbreviation: 'VT',
    name: 'Vermont'
  },
  {
    abbreviation: 'VA',
    name: 'Virginia'
  },
  {
    abbreviation: 'WA',
    name: 'Washington'
  },
  {
    abbreviation: 'WV',
    name: 'West Virginia'
  },
  {
    abbreviation: 'WI',
    name: 'Wisconsin'
  },
  {
    abbreviation: 'WY',
    name: 'Wyoming'
  }
];
