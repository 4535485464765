import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg';
import { transparentize } from 'polished';
import { Document as BaseDocument, Page as BasePage } from 'react-pdf';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, FOCUS_STYLES, MOBILE_BREAKPOINT_RANGE } from '../../constants';
import { BLACK_CHARCOAL_COLOR } from '../GlobalStyle';
import LeadingHero from '../heroes/LeadingHero';
import InlineDialog from '../InlineDialog';
import UnstyledButton from '../UnstyledButton';

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    row-gap: var(--space-5);
    align-items: start;
    padding-top: var(--space-7);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    padding: var(--space-5);
  }
`;

export const AttachCertificateModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background-color: ${transparentize(0.3, BLACK_CHARCOAL_COLOR)};
`;

export const AttachmentPreview = styled.img`
  display: block;
  max-width: 100%;
  max-height: 15rem;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid var(--purple-400);
`;

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-dark-dark);
    height: 1.8125rem;
    width: auto;
  `,
  Root: styled(UnstyledButton)`
    display: block;
    margin-left: auto;

    &:focus-visible {
      ${FOCUS_STYLES}
    }
  `
};

export const Dialog = styled.div`
  max-height: 100%;
  padding: var(--space-8);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-modal-regular);
  overflow-y: auto;
  outline: none;

  ${MOBILE_BREAKPOINT_RANGE} {
    max-width: 37rem;
    background-color: var(--gray-light-light);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 37rem;
    background-color: var(--white-light);
  }
`;

export const PdfPreview = {
  Document: styled(BaseDocument)`
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;

    /* stylelint-disable-next-line selector-class-pattern */
    .react-pdf__message--error {
      width: 80%;
      text-align: center;
    }
  `,
  Page: styled(BasePage)`
    box-sizing: content-box;
    display: inline-grid;
    place-content: center;
    height: 100%;
    border: 1px solid var(--purple-400);
  `
};

export const UploadContainer = styled.div`
  padding-left: var(--space-5);
  padding-right: var(--space-5);
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const UploadedLeadingHero = styled(LeadingHero)`
  margin-bottom: var(--space-6);
  padding-left: var(--space-5);
  padding-right: var(--space-5);
`;

export const UploadInstructionText = styled(InlineDialog)`
  margin-top: var(--space-4);
`;

export const UploadLeadingHero = styled(LeadingHero)`
  margin-bottom: var(--space-7);
  padding-left: var(--space-5);
  padding-right: var(--space-5);
`;
