import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const CardTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  margin: 0;
`;

export const ReportCard = styled.div`
  border-radius: 1rem;
  padding: 2.5rem;
  background: var(--white-light);
  ${TEXT_STYLES.bodyCopyLarge};
  color: var(--purple-400);
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;

  ul {
    margin: 0;
  }

  h2 {
    ${TEXT_STYLES.headlineSectionLevel1Regular}
    margin: 0;
  }

  h3 {
    ${TEXT_STYLES.headlineSectionLevel2Large}
    margin: 0;
  }
`;
