import styled from 'styled-components';

import * as S from '../Meter/styles';

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;

interface MeterWrapperProps {
  $labelWidth: number;
}

export const MeterWrapper = styled.div<MeterWrapperProps>`
  display: grid;
  gap: 1rem;
  margin-top: auto;

  ${S.Meter} {
    display: grid;
    grid-gap: 0.5rem;

    ${({ $labelWidth }) => `grid-template-columns: ${$labelWidth}px 1fr 2.875rem;`}
  }

  ${S.Bar} {
    margin-left: unset;
  }

  ${S.Label} {
    width: auto;
    text-align: right;
    justify-content: flex-end;
  }

  ${S.PercentValue} {
    margin-left: unset;
  }
`;
