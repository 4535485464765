import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '../../../../../constants';
import { EDUCATION_MAIN_TOP_PADDING } from '../../constants';

export const Main = styled.main`
  ${MOBILE_BREAKPOINT_RANGE} {
    padding: ${EDUCATION_MAIN_TOP_PADDING.mobile}px 40px 120px;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    display: grid;
    grid-template-columns: 250px 1fr;
    column-gap: 48px;
    align-items: start;
    max-width: 1420px;
    margin-right: auto;
    margin-left: auto;
    padding: ${EDUCATION_MAIN_TOP_PADDING.desktop}rem var(--space-9) var(--space-16);
  }
`;
