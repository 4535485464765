import * as S from './styles';

const TableEmptyState = () => (
  <S.TableEmptyState>
    <S.SearchIcon />
    <div>
      <S.Title>No matches for your search</S.Title>
      <S.Text>Try searching again for a different term.</S.Text>
    </div>
  </S.TableEmptyState>
);

export default TableEmptyState;
