// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import { forwardRef, MutableRefObject } from 'react';
import {
  AriaButtonProps,
  mergeProps,
  TooltipTriggerAria,
  useButton,
  useFocusRing,
  useHover
} from 'react-aria';
import { Link, LinkProps } from 'react-router-dom';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaButtonProps {
  className?: string;
  target?: LinkProps['target'];
  to?: LinkProps['to'];
  tooltipTriggerProps?: TooltipTriggerAria['triggerProps'];
}

const TableLinkedCell = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  ({ className, target, to, tooltipTriggerProps, ...ariaButtonProps }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);

    const { children, href, isDisabled = false } = ariaButtonProps;

    const { buttonProps, isPressed } = useButton({ ...ariaButtonProps }, ref);
    const { focusProps, isFocusVisible } = useFocusRing();
    const { hoverProps, isHovered } = useHover({ isDisabled });

    const sharedProps = {
      ...mergeProps(buttonProps, focusProps, hoverProps, tooltipTriggerProps ?? {}),
      children,
      className: clsx(
        className,
        { 'is-focused': isFocusVisible },
        { 'is-hovered': isHovered },
        { 'is-pressed': isPressed }
      )
    };

    if (isNonEmptyString(href)) {
      return (
        <S.LinkedCell
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          as="a"
          href={href}
          target={target}
        />
      );
    } else if (to !== undefined) {
      return (
        <S.LinkedCell
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          as={Link}
          state={{ referrer: window.location.pathname, referrerSearch: window.location.search }}
          to={to}
        />
      );
    } else {
      return (
        <S.LinkedCell
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLButtonElement>}
        />
      );
    }
  }
);

TableLinkedCell.displayName = 'TableLinkedCell';

export default TableLinkedCell;
