// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { parseDate } from '@internationalized/date';
import { useObjectRef } from '@react-aria/utils';
import { forwardRef, useEffect, useRef } from 'react';
import { AriaDatePickerProps, DateValue, useDatePicker } from 'react-aria';
import { useDatePickerState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import DateField from './components/DateField';
import * as S from './styles';

/*
  TODO: month is not yet officially supported
  but it works, so we can ignore the TS errors for now
*/
// @ts-expect-error
interface Props extends AriaDatePickerProps<DateValue> {
  caption?: string;
  className?: string;
  customErrorMessage?: string;
  granularity?: 'day' | 'hour' | 'minute' | 'month' | 'second';
}

const DatePicker = forwardRef<HTMLInputElement | null, Props>(
  (
    { caption, className, customErrorMessage, isRequired = false, ...ariaDatePickerProps },
    forwardedRef
  ) => {
    const { label } = ariaDatePickerProps;
    // @ts-expect-error
    const state = useDatePickerState(ariaDatePickerProps);

    const inputRef = useObjectRef(forwardedRef);
    const groupRef = useRef<HTMLDivElement>(null);

    const {
      fieldProps,
      groupProps,
      labelProps
      // @ts-expect-error
    } = useDatePicker(ariaDatePickerProps, state, groupRef);

    useEffect(() => {
      if (state.isInvalid) {
        const { day, month, year } =
          state.value !== null ? state.value : { day: 0, month: 0, year: 0 };
        const isACompleteDate = year > 1000 && month > 0 && day > 0;
        inputRef.current?.setCustomValidity(
          customErrorMessage !== undefined && isACompleteDate
            ? customErrorMessage
            : 'This date is invalid.'
        );
      } else {
        inputRef.current?.setCustomValidity('');
      }
    }, [customErrorMessage, inputRef, state]);

    const handleReportValidity = () => {
      inputRef.current?.reportValidity();
    };

    return (
      <S.DatePicker className={className}>
        {isNonEmptyString(label) && (
          <S.Label {...labelProps}>
            {label}
            {isRequired && '*'}
            {isNonEmptyString(caption) && <S.Caption>{caption}</S.Caption>}
          </S.Label>
        )}
        <S.DatePickerValidationContainer>
          {/*
          defaultValue is being wonky here, but onChange is required with value
          So using value with an empty onChange for now <shrug>
          value is being more reliable in returning latest state value
        */}
          <S.DatePickerValidation
            ref={inputRef}
            onChange={() => {}}
            required={isRequired}
            tabIndex={-1}
            value={state.value === null ? '' : state.value.toString()}
          />
        </S.DatePickerValidationContainer>
        <S.Group
          {...groupProps}
          ref={groupRef}
        >
          <DateField
            {...fieldProps}
            defaultValue={
              fieldProps.defaultValue ? parseDate(fieldProps.defaultValue.toString()) : undefined
            }
            displayErrorMessage={handleReportValidity}
            placeholderValue={
              fieldProps.placeholderValue
                ? parseDate(fieldProps.placeholderValue.toString())
                : undefined
            }
            value={fieldProps.value ? parseDate(fieldProps.value.toString()) : undefined}
          />
        </S.Group>
      </S.DatePicker>
    );
  }
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
