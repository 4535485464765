import { ReactComponent as QuestionMarkIcon } from '@material-design-icons/svg/round/help_outline.svg';
import { RESOURCE_CARD_MIN_WIDTH } from 'src/components/Education/resource_card_styles';
import { default as BaseCheckbox } from 'src/components/FormFields/Checkbox';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import UnstyledButton from 'src/components/UnstyledButton';
import styled from 'styled-components';

import { default as BaseSeparator } from '../../../../components/Separator';
import { TEXT_STYLES } from '../../../../constants';

export const AccreditationLabel = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
  opacity: 0.8;
  align-items: center;
  display: flex;
  margin-bottom: 0.75rem;
`;

export const Collections = styled.ul`
  display: grid;
  margin-bottom: 0;
  padding-left: 0;
  grid-template-columns: repeat(auto-fill, minmax(${RESOURCE_CARD_MIN_WIDTH}px, 1fr));
  gap: var(--space-4);
`;

export const FaqButton = styled(UnstyledButton)`
  align-items: center;
  display: flex;
  margin-left: 0.25rem;

  &.is-hovered svg {
    fill: var(--gray-500);
  }
`;

export const FaqLink = styled.a`
  color: var(--gray-500);
`;

export const Filters = {
  CheckboxGroup: styled(CheckboxGroup)`
    .group-label {
      ${TEXT_STYLES.bodyCopyRegular}
      color: var(--gray-800);
      opacity: 0.8;
      margin-bottom: 0.5rem;
    }

    .checkbox-container {
      row-gap: 0.5rem;
    }
  `,
  SectionLabel: styled.div`
    ${TEXT_STYLES.bodyCopyRegular}
    color: var(--gray-800);
    opacity: 0.8;
    margin-bottom: 0.5rem;
  `,
  Separator: styled(BaseSeparator)`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid var(--gray-200);
  `,
  SingleCheckbox: styled(BaseCheckbox)`
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  `
};

export const LoadMoreWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 14rem;
`;

export const OrgAcronym = styled.div`
  font-weight: 500;
`;

export const OrganizationItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrgName = styled.div`
  color: var(--gray-500);
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
`;

export const QuestionMark = styled(QuestionMarkIcon)`
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
`;
