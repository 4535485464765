import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { ReactComponent as ArrowRightIcon } from '@material-design-icons/svg/round/arrow_forward.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import * as Sentry from '@sentry/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FormEventHandler, Key, useEffect, useState } from 'react';
import { AriaDatePickerProps, AriaSelectProps, DateValue, useFilter } from 'react-aria';
import { Item } from 'react-stately';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import useUser from 'src/hooks/useUser';
import { STATES } from 'src/pages/constants';
import { Collection } from 'src/pages/Dashboard/utils';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

const certificateOptions = [
  { backend_key: 'AMA_ACCME', org_acronym: 'AMA' },
  { backend_key: 'ANCC', org_acronym: 'ANCC' },
  { backend_key: 'AAPA', org_acronym: 'AAPA' },
  { backend_key: 'APA', org_acronym: 'APA' },
  { backend_key: 'ASWB', org_acronym: 'ASWB' },
  { backend_key: 'ACPE', org_acronym: 'ACPE' },
  { backend_key: 'RD_DTR', org_acronym: 'CDR' },
  { backend_key: 'ADA_CERP', org_acronym: 'ADA' },
  { backend_key: 'NBCC', org_acronym: 'NBCC' },
  { backend_key: 'NYSED - Psychology', org_acronym: 'NYSED - Psychology' },
  { backend_key: 'NYSED - LCSW/LMSW', org_acronym: 'NYSED - LCSW/LMSW' },
  { backend_key: 'NYSED - LMHC', org_acronym: 'NYSED - LMHC' }
];

interface Props {
  collection?: Collection;
  downloadCertificate: (
    certificateType: string,
    dob: string | undefined,
    licenseType?:
      | 'nabp_id'
      | 'nysed_mental_health_counseling'
      | 'nysed_psychology'
      | 'nysed_social_work',
    licenseNumber?: string
  ) => void;
  isFetching?: boolean;
}

const CertificateSelection = ({ collection, downloadCertificate, isFetching = false }: Props) => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, setUser, user } = useUser();
  const isNYSEDpsychEnabled = useFeatureFlagEnabled('nysed_psych_accreditation');

  const [dateOfBirth, setDateOfBirth] = useState<DateValue>();
  const [licenseNumber, setLicenseNumber] = useState<string | undefined>();
  const [selectedProfessionalGroup, setSelectedProfessionalGroup] = useState<Key | undefined>();
  const [submitIsDisabled, setSubmitIsDisabled] = useState<boolean>(true);
  const [city, setCity] = useState(user.city ?? '');
  const [state, setState] = useState<Key | null>(user.state);
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);

  const updateCertificateSelection: AriaSelectProps<object>['onSelectionChange'] = newValue => {
    setSelectedProfessionalGroup(newValue);
  };

  const updateDateOfBirth: AriaDatePickerProps<DateValue>['onChange'] = newDate => {
    setDateOfBirth(newDate ?? undefined);
  };

  const updateStateSelection: AriaSelectProps<object>['onSelectionChange'] = value => {
    setState(value);
  };

  useEffect(() => {
    if (selectedProfessionalGroup === undefined || state === null || city === '') {
      setSubmitIsDisabled(true);
    } else {
      if (selectedProfessionalGroup === 'ACPE') {
        setSubmitIsDisabled(!dateOfBirth || !isNonEmptyString(licenseNumber));
      } else if (selectedProfessionalGroup === 'NYSED - Psychology') {
        setSubmitIsDisabled(!isNonEmptyString(licenseNumber));
      } else {
        setSubmitIsDisabled(false);
      }
    }
  }, [dateOfBirth, licenseNumber, selectedProfessionalGroup, city, state]);

  const submitCertificateRequest = () => {
    const certificateTypeKey = [...certificateOptions].find(
      cert => cert.org_acronym === selectedProfessionalGroup
    );
    if (!certificateTypeKey) return;

    let dob;

    if (dateOfBirth) {
      const month = dateOfBirth.month.toString();
      const day = dateOfBirth.day.toString();
      const year = dateOfBirth.year.toString();
      dob = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    downloadCertificate(
      certificateTypeKey.backend_key,
      dob,
      selectedProfessionalGroup === 'ACPE'
        ? 'nabp_id'
        : selectedProfessionalGroup === 'NYSED - Psychology'
          ? 'nysed_psychology'
          : selectedProfessionalGroup === 'NYSED - LCSW/LMSW'
            ? 'nysed_social_work'
            : selectedProfessionalGroup === 'NYSED - LMHC'
              ? 'nysed_mental_health_counseling'
              : undefined,
      licenseNumber
    );
  };

  const submitForCertificate: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();

    if (city === '' || state === null) return;

    setIsUpdatingUser(true);

    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile`;
    const options: RequestInit = {
      body: JSON.stringify({
        user: {
          city,
          state
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        if (response.status === 401) {
          openErrorModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }
      const { data } = (await response.json()) as APIProfile;

      const updatedUser = { ...user, city: data.city, state: data.state };
      setUser(updatedUser);

      submitCertificateRequest();
    } catch (error) {
      Sentry.captureException(error);
      openSignedOutModalDialog();
    }
    setIsUpdatingUser(false);
  };

  const accreditingOrganizations =
    isNYSEDpsychEnabled !== true
      ? (collection?.accreditingOrganizations.filter(
          org =>
            org.professionalGroupAcronym !== 'NYSED - Psychology' &&
            org.professionalGroupAcronym !== 'NYSED - LCSW/LMSW' &&
            org.professionalGroupAcronym !== 'NYSED - LMHC'
        ) ?? [])
      : (collection?.accreditingOrganizations ?? []);

  const { contains } = useFilter({ sensitivity: 'base' });

  return (
    <form onSubmit={submitForCertificate}>
      <S.Title>
        Thanks for your answers.
        <br />
        Let’s get your certificate.
      </S.Title>
      <S.Description>
        Select the accrediting organization group you would like to generate a certificate for:
      </S.Description>
      <S.CertificateSelect
        data-cy="professional-group-field"
        // @ts-expect-error
        defaultSelectedKey={selectedProfessionalGroup as Key | null | undefined}
        filter={contains}
        icon={SearchIcon}
        isRequired
        label="Accrediting organization"
        onSelectionChange={updateCertificateSelection as (value: Key | null) => void}
        placeholder="Search for an accrediting organization"
        validationBehavior="native"
      >
        {accreditingOrganizations.map(option => (
          <Item
            key={option.professionalGroupAcronym}
            textValue={`${option.professionalGroup} (${option.professionalGroupAcronym})`}
          >
            <S.OrgAcronym>{option.professionalGroupAcronym}</S.OrgAcronym>
            <S.OrgName>{option.professionalGroup}</S.OrgName>
          </Item>
        ))}
      </S.CertificateSelect>
      {selectedProfessionalGroup === 'ACPE' && (
        <S.DobDatePicker
          data-cy="date-of-birth-field"
          defaultValue={dateOfBirth}
          granularity="day"
          isRequired
          label="Date of birth"
          maxValue={today(getLocalTimeZone())}
          minValue={parseDate('1900-01-01')}
          onChange={updateDateOfBirth}
        />
      )}
      {(selectedProfessionalGroup === 'ACPE' ||
        selectedProfessionalGroup === 'NYSED - Psychology' ||
        selectedProfessionalGroup === 'NYSED - LCSW/LMSW' ||
        selectedProfessionalGroup === 'NYSED - LMHC') && (
        <S.IdNumberField
          data-cy="license-number-field"
          defaultValue={licenseNumber}
          isRequired
          label={selectedProfessionalGroup === 'ACPE' ? 'NABP #' : 'License #'}
          onChange={setLicenseNumber}
          validationBehavior="native"
        />
      )}
      <S.CityInput
        autoComplete="address-level2"
        data-cy="city-field"
        isRequired
        label="City"
        onChange={setCity}
        validationBehavior="native"
        value={city}
      />
      <S.StateSelect
        data-cy="state-field"
        filter={contains}
        icon={SearchIcon}
        isRequired
        label="State"
        onSelectionChange={updateStateSelection as (value: Key | null) => void}
        placeholder="Search for your state"
        // @ts-expect-error
        selectedKey={state as Key | null | undefined}
        validationBehavior="native"
      >
        {STATES.map(({ abbreviation, name }) => (
          <Item
            key={abbreviation}
            textValue={`${abbreviation} (${name})`}
          >
            {abbreviation} ({name})
          </Item>
        ))}
      </S.StateSelect>
      <S.SubmitButton
        data-cy="get-certificate-button"
        isDisabled={submitIsDisabled}
        isLoading={isFetching || isUpdatingUser}
        size="large"
        trailingIcon={ArrowRightIcon}
        type="submit"
      >
        Get certificate
      </S.SubmitButton>
    </form>
  );
};

export default CertificateSelection;
