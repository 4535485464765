import { ReactNode } from 'react';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

type PageTitleVariantTypes = 'h1' | 'h2';

interface Props {
  actionButton?: ReactNode;
  className?: string;
  'data-cy'?: string;
  description?: string;
  id?: string;
  labelComponent?: ReactNode;
  title: string;
  titleVariant: PageTitleVariantTypes;
}

const PageTitle = ({
  actionButton,
  className,
  'data-cy': dataCy,
  description,
  id,
  labelComponent,
  title,
  titleVariant
}: Props) => {
  const Title = titleVariant === 'h1' ? S.TitleH1 : S.TitleH2;

  return (
    <S.TitleContainer
      className={className}
      data-cy={dataCy}
      id={id}
    >
      <Title>
        {title}
        {labelComponent}
      </Title>
      {actionButton !== undefined && <S.ButtonWrapper>{actionButton}</S.ButtonWrapper>}
      {isNonEmptyString(description) && <S.Description>{description}</S.Description>}
    </S.TitleContainer>
  );
};

export default PageTitle;
