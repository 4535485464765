import { Temporal } from '@js-temporal/polyfill';
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/round/download.svg';
import { useEffect, useState } from 'react';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import Button from 'src/components/Buttons/Button';
import Pagination from 'src/components/Pagination';
import Spinner from 'src/components/Spinner';
import EmptyTableState from 'src/components/Table/EmptyState';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import useGetCollectionCompletions from 'src/pages/Dashboard/hooks/useGetCollectionCompletions';

import * as S from './styles';

const COMPLETIONS_PER_PAGE = 5;

const CompletedCollectionsTable = () => {
  const [page, setPage] = useState(1);
  const {
    collectionCompletions,
    isFetching,
    totalCompletionPages,
    updateCollectionCompletionsFilters
  } = useGetCollectionCompletions({
    page: 1,
    per_page: COMPLETIONS_PER_PAGE
  });

  useEffect(() => {
    updateCollectionCompletionsFilters({ page, per_page: COMPLETIONS_PER_PAGE });
  }, [page, updateCollectionCompletionsFilters]);

  if (isFetching) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <>
      <S.EducationTable
        aria-label="Completed collections table"
        renderEmptyState={() => (
          <EmptyTableState
            colSpan={4}
            message="No collections have been completed"
          />
        )}
      >
        <TableHeader>
          <Column key="name">
            <S.TableHeaderCell>NAME</S.TableHeaderCell>
          </Column>
          <Column>
            <S.TableHeaderCell $align="end">YEAR</S.TableHeaderCell>
          </Column>
          <Column>
            <S.TableHeaderCell $align="end">CREDITS/HOURS</S.TableHeaderCell>
          </Column>
          <Column>
            <S.TableHeaderCell>CE CERTIFICATE</S.TableHeaderCell>
          </Column>
        </TableHeader>
        <TableBody>
          {collectionCompletions.map(completion => {
            const { collection } = completion;
            return (
              <Row
                key={completion.id}
                data-cy="completed-collection"
              >
                <Cell>{collection.name}</Cell>
                <Cell>
                  <TableNumberCell>
                    {completion.completedAt !== null
                      ? Temporal.PlainDate.from(completion.completedAt).year
                      : ''}
                  </TableNumberCell>
                </Cell>
                <Cell>
                  <TableNumberCell>{collection.credits ?? 0}</TableNumberCell>
                </Cell>
                <Cell>
                  {collection.isAccredited && collection.canBeEvaluated ? (
                    <Button
                      to={`/dashboard/education/collections/${collection.id}/accreditation-survey`}
                    >
                      Claim CE/CME credit
                    </Button>
                  ) : collection.isAccredited &&
                    collection.evaluation &&
                    collection.certificates.length === 0 ? (
                    <Button
                      to={`/dashboard/education/collections/${collection.id}/accreditation-certificate`}
                    >
                      Generate certificate
                    </Button>
                  ) : collection.isAccredited &&
                    collection.evaluation &&
                    collection.certificates.length > 0 ? (
                    <S.DownloadButton
                      href={`${process.env.REACT_APP_BASE_PATH}${collection.certificates[0]?.file.url}`}
                      target="_blank"
                      trailingIcon={DownloadIcon}
                      variant="outline"
                    >
                      Download
                    </S.DownloadButton>
                  ) : (
                    <S.NotAvailableText>Not available</S.NotAvailableText>
                  )}
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </S.EducationTable>
      <Pagination
        currentPage={page}
        setPage={setPage}
        totalPages={totalCompletionPages}
      />
    </>
  );
};

export default CompletedCollectionsTable;
