import * as S from './styles';

interface Props {
  children: JSX.Element;
  title: string;
}

const ListHighlight = ({ children, title, ...args }: Props) => (
  <S.ListHighlightCard {...args}>
    <S.ListHighlightTitle>{title}</S.ListHighlightTitle>
    {children}
  </S.ListHighlightCard>
);

export default ListHighlight;
