import * as CheckboxStyles from 'src/components/FormFields/Checkbox/styles';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const Description = styled.p`
  color: var(--gray-500);
  margin: 0 0 1rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyRegular}
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyMedium}
  }
`;

export const Header = styled.h3`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin: 0;
`;

export const InputGroup = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: var(--gray-25);

  ${DESKTOP_BREAKPOINT_RANGE} {
    gap: 1rem;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${CheckboxStyles.Label} {
    color: var(--gray-500);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;
