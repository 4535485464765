// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import { forwardRef } from 'react';
import {
  AriaNumberFieldProps,
  mergeProps,
  useFocusRing,
  useLocale,
  useNumberField
} from 'react-aria';
import { useNumberFieldState } from 'react-stately';

import * as S from './styles';

const formatOptions: NonNullable<AriaNumberFieldProps['formatOptions']> = {
  maximumFractionDigits: 0
};
const maxValue: NonNullable<AriaNumberFieldProps['maxValue']> = 100;
const minValue: NonNullable<AriaNumberFieldProps['minValue']> = 0;

interface Props extends Omit<AriaNumberFieldProps, 'formatOptions' | 'maxValue' | 'minValue'> {
  className?: string;
  isInvalid?: boolean;
  suffix: string;
}

const DemographicCompositionNumberField = forwardRef<HTMLInputElement, Props>(
  ({ className, isInvalid = false, suffix, ...otherProps }, forwardedRef) => {
    const { errorMessage, isRequired = false } = otherProps;

    const ariaNumberFieldProps: AriaNumberFieldProps = {
      ...otherProps,
      formatOptions,
      maxValue,
      minValue
    };

    const { locale } = useLocale();
    const state = useNumberFieldState({ ...ariaNumberFieldProps, locale });

    const ref = useObjectRef(forwardedRef);

    const { groupProps, inputProps } = useNumberField(ariaNumberFieldProps, state, ref);

    const { focusProps, isFocused } = useFocusRing();

    return (
      <S.DemographicCompositionNumberField className={className}>
        <S.Group
          {...groupProps}
          $isFocused={isFocused}
          $isInvalid={isInvalid}
          className={isInvalid ? 'is-invalid' : undefined}
        >
          <S.Input
            {...mergeProps(inputProps, focusProps)}
            ref={ref}
            data-hj-allow
            required={isRequired}
          />
          <S.Suffix aria-hidden="true">{suffix}</S.Suffix>
        </S.Group>
        {isInvalid && (
          <S.ErrorMessage>{typeof errorMessage === 'function' ? '' : errorMessage}</S.ErrorMessage>
        )}
      </S.DemographicCompositionNumberField>
    );
  }
);

DemographicCompositionNumberField.displayName = 'DemographicCompositionNumberField';

export default DemographicCompositionNumberField;
