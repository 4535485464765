import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../../constants';

export const Option = styled.li`
  padding: 0.625rem 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: #262626;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-style: italic;
`;
