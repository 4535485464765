import Table from 'src/components/Table';
import styled from 'styled-components';

export const BenchmarkEmptyState = styled.div`
  display: grid;
  height: 2.75rem;
  width: 2rem;
`;

export const ProviderTable = styled(Table)`
  tr th:nth-child(5) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  tr th:nth-child(6) {
    background-color: var(--light-purple-50);
  }

  tr th:nth-child(7) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;
