import { produce } from 'immer';
import { ReactNode } from 'react';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { useLocalStorage } from 'usehooks-ts';

import * as S from './styles';

interface Props {
  children?: ReactNode | string;
  className?: string;
  customIcon?: ReactNode;
  'data-cy'?: string;
  header?: string;
  id: string;
  isBlock?: boolean;
  isDismissable?: boolean;
  isInline?: boolean;
  isSingleLine?: boolean;
  onDismiss?: () => void;
  type?: 'error' | 'info' | 'success' | 'warning';
}

const Alert = ({
  children,
  className,
  customIcon,
  header,
  id,
  isBlock = false,
  isDismissable = true,
  isInline = false,
  isSingleLine = false,
  onDismiss,
  type = 'info',
  ...otherProps
}: Props) => {
  const [dismissedAlerts, setDismissedAlerts] = useLocalStorage<string[]>(
    'dismissedInLineAlerts',
    []
  );

  const handleCloseButtonPress = () => {
    if (onDismiss !== undefined) {
      onDismiss();
    } else {
      setDismissedAlerts(
        produce(dismissedAlerts, draft => {
          draft.push(id);
        })
      );
    }
  };

  return dismissedAlerts.includes(id) ? null : (
    <S.Alert
      $block={isBlock}
      $inline={isInline}
      $type={type}
      animate={{ opacity: 1, x: 0 }}
      className={className}
      exit={{ opacity: 0, x: 100 }}
      initial={{ opacity: 0, x: -10 }}
      role="note"
      {...otherProps}
    >
      {customIcon !== undefined ? (
        customIcon
      ) : type === 'error' ? (
        <S.ErrorIcon aria-hidden="true" />
      ) : type === 'warning' ? (
        <S.WarningIcon aria-hidden="true" />
      ) : type === 'success' ? (
        <S.SuccessIcon aria-hidden="true" />
      ) : (
        <S.InfoIcon aria-hidden="true" />
      )}
      <S.TextBlock $singleLine={isSingleLine}>
        {isNonEmptyString(header) && <S.Header>{header}</S.Header>}
        {children !== undefined && <S.Text>{children}</S.Text>}
      </S.TextBlock>
      {isDismissable && (
        <S.CloseButton
          aria-label="Close"
          onPress={handleCloseButtonPress}
        >
          <S.Close aria-hidden="true" />
        </S.CloseButton>
      )}
    </S.Alert>
  );
};

export default Alert;
