import styled from 'styled-components';

interface BodyRowProps {
  $isAutoPopulated: boolean;
  $isExpanded: boolean;
  $isHovered: boolean;
  $isInvalid: boolean;
}

export const BodyRow = styled.tr<BodyRowProps>`
  all: unset;
  box-sizing: border-box;
  display: grid;
  grid-template-columns:
    2rem minmax(0, 5fr) minmax(0, 4.5fr) minmax(0, 4.5fr) minmax(0, 2fr) 19.775rem
    minmax(0, 2fr) 0fr;
  border: 1px solid transparent;
  border-radius: 0.5rem;

  ${({ $isAutoPopulated, $isExpanded, $isHovered, $isInvalid }) => {
    if ($isInvalid) {
      return `
        background-color: var(--error-50);
        border-color: var(--error-500);
      `;
    }

    if ($isAutoPopulated) {
      if ($isExpanded || $isHovered) {
        return `
          background-color: var(--light-purple-100);
        `;
      } else {
        return `
          background-color: var(--light-purple-25);
        `;
      }
    } else {
      if ($isExpanded || $isHovered) {
        return `
          background-color: var(--gray-100);
        `;
      } else {
        return `
          background-color: var(--gray-50);
        `;
      }
    }
  }}
`;
