import * as S from './styles';

interface Props {
  community: 'BIPOC' | 'LGBQ' | 'TGNC';
  direction?: 'column' | 'row';
  size?: 'large' | 'regular' | 'small';
}

const CommunityIndicators = ({ community, direction = 'row', size = 'regular' }: Props) =>
  community === 'BIPOC' ? (
    <S.CommunityCircles
      $direction={direction}
      $size={size}
    >
      <div style={{ backgroundColor: 'var(--bipoc-first)' }} />
      <div style={{ backgroundColor: 'var(--bipoc-second)' }} />
      <div style={{ backgroundColor: 'var(--bipoc-third)' }} />
    </S.CommunityCircles>
  ) : community === 'LGBQ' ? (
    <S.CommunityCircles
      $direction={direction}
      $size={size}
    >
      <div style={{ backgroundColor: 'var(--lgbq-first)' }} />
      <div style={{ backgroundColor: 'var(--lgbq-second)' }} />
      <div style={{ backgroundColor: 'var(--lgbq-third)' }} />
      <div style={{ backgroundColor: 'var(--lgbq-fourth)' }} />
      <div style={{ backgroundColor: 'var(--lgbq-fifth)' }} />
      <div style={{ backgroundColor: 'var(--lgbq-sixth)' }} />
    </S.CommunityCircles>
  ) : (
    <S.CommunityCircles
      $direction={direction}
      $size={size}
    >
      <div style={{ backgroundColor: 'var(--tgnc-first)' }} />
      <div style={{ backgroundColor: 'var(--tgnc-second)' }} />
    </S.CommunityCircles>
  );
export default CommunityIndicators;
