import checkedIcon from '@material-design-icons/svg/round/check.svg';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  AriaCheckboxGroupItemProps,
  mergeProps,
  useCheckboxGroupItem,
  useFocusRing,
  VisuallyHidden
} from 'react-aria';
import * as S from 'src/components/FormFields/Checkbox/styles';
import { BRAND_TINTS_LIGHT_COLOR } from 'src/components/GlobalStyle';
import { generateStyledBgIcon } from 'src/utils';

import { CheckboxGroupContext } from '..';

interface Props extends AriaCheckboxGroupItemProps {
  className?: string;
  isRequired?: boolean;
}

const GroupCheckbox = ({ className, isRequired = false, ...ariaCheckboxGroupItemProps }: Props) => {
  const { children } = ariaCheckboxGroupItemProps;
  const { state } = useContext(CheckboxGroupContext)!;
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isDisabled, isReadOnly, isSelected } = useCheckboxGroupItem(
    ariaCheckboxGroupItemProps,
    state,
    ref
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  const [checkedIconPath, setCheckedIconPath] = useState<string>('');

  useEffect(() => {
    if (checkedIconPath !== '') return;

    const setIcons = async () => {
      const path = await generateStyledBgIcon(
        checkedIcon,
        BRAND_TINTS_LIGHT_COLOR,
        `stroke-width: 1; stroke: ${BRAND_TINTS_LIGHT_COLOR.replace('#', '%23')}`
      );
      setCheckedIconPath(path);
    };
    setIcons();
  }, [checkedIconPath]);

  return (
    <S.Checkbox
      $checkedIcon={checkedIconPath}
      className={clsx(
        className,
        { 'is-disabled': isDisabled },
        { 'is-focused': isFocusVisible },
        { 'is-selected': isSelected },
        { 'is-read-only': isReadOnly }
      )}
    >
      <VisuallyHidden>
        <input
          required={isRequired}
          {...mergeProps(inputProps, focusProps)}
          ref={ref}
        />
      </VisuallyHidden>
      <S.Input />
      <S.Label>{children}</S.Label>
    </S.Checkbox>
  );
};

export default GroupCheckbox;
