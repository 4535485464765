import styled from 'styled-components';

import { TEXT_STYLES } from '../../constants';
import { default as BaseButton } from '../Buttons/Button';

export const Anchor = styled.a`
  color: inherit;

  &:focus-visible {
    outline-offset: 0.0625rem;
    outline: 0.125rem solid var(--brand-bright);
    border-radius: 0.25rem;
  }
`;

export const Button = styled(BaseButton)`
  align-self: start;
`;

export const Text = styled.p`
  margin-bottom: var(--space-7);
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
`;
