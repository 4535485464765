import { CSSProperties } from 'react';
import { useProgressBar } from 'react-aria';

import * as S from './styles';

const maxValue = 100;
const minValue = 0;

interface Props {
  className?: string;
  value: number;
}

const EducationProgressBar = ({ className, value: valueProp }: Props) => {
  const value = valueProp < minValue ? minValue : valueProp > maxValue ? maxValue : valueProp;
  const label =
    value === minValue ? 'NOT STARTED' : value < maxValue ? 'IN PROGRESS' : 'EDUCATION COMPLETE';

  const { labelProps, progressBarProps } = useProgressBar({ label, maxValue, minValue });

  return (
    <S.EducationProgressBar
      className={className}
      {...progressBarProps}
    >
      <S.Track>
        <S.Bar style={{ '--width': `${Math.round(value)}%` } as CSSProperties} />
      </S.Track>
      {value === minValue ? (
        <S.Label {...labelProps}>{label}</S.Label>
      ) : value < maxValue ? (
        <S.Label {...labelProps}>{label}</S.Label>
      ) : (
        <S.EducationComplete {...labelProps} />
      )}
    </S.EducationProgressBar>
  );
};

export default EducationProgressBar;
