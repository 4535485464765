import { ReactComponent as KeyboardArrowDownIcon } from '@material-design-icons/svg/round/keyboard_arrow_down.svg';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { default as BasePopover } from '../../Popover';

import { ListBox as BaseListBox } from './ListBox';

export const Icon = styled(KeyboardArrowDownIcon)`
  position: absolute;
  top: 0;
  right: calc(0.75rem - 1px);
  bottom: 0;
  width: 1.5rem;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--gray-800);
`;

export const Label = styled.div`
  display: block;
  padding-bottom: var(--space-2);
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
`;

export const ListBox = styled(BaseListBox)<{ $maxListBoxHeight?: string }>`
  ${({ $maxListBoxHeight }) =>
    `max-height: ${$maxListBoxHeight !== undefined ? $maxListBoxHeight : '11rem'}`}
`;

export const Placeholder = styled.span``;

interface PopoverProps {
  $width?: number;
}

export const Popover = styled(BasePopover)<PopoverProps>`
  ${({ $width }) => {
    if ($width !== undefined) {
      return `width: ${$width}px;`;
    }
  }}
`;

export const Select = styled.div`
  position: relative;
  width: 100%;
`;

interface ButtonProps {
  disabled?: boolean;
}

export const Trigger = styled.button<ButtonProps>`
  position: relative;
  width: 100%;
  background-color: var(--white-light);
  line-height: 1;
  text-align: left;
  outline: none;
  height: 3rem;
  padding: calc(0.75rem - 1px) calc(1rem - 1px);
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;

  &::placeholder {
    color: var(--gray-500);
  }

  &:focus {
    border-width: 2px;
    border-color: var(--brand-bright);
    padding: calc(0.75rem - 2px) calc(1rem - 2px);

    ${Icon} {
      right: calc(0.75rem - 2px);
    }

    &::placeholder {
      color: var(--gray-800);
    }
  }

  ${({ disabled = false }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}

  &.is-invalid {
    border-color: var(--error-500);
    color: var(--error-500);

    &::placeholder {
      color: var(--error-500);
    }
  }
`;

export const Value = styled.span``;
