import EducationProgressBar from 'src/components/Education/EducationProgressBar';
import TagGroup from 'src/components/TagGroup';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { default as BaseButton } from '../../Buttons/Button';
import { default as BaseAssignedEducation } from '../AssignedEducation';
import { default as BaseBookmark } from '../Bookmark';

export const Assigned = styled(BaseAssignedEducation)`
  margin-right: 0.645rem;
`;

export const AuthorAndOrganization = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.15rem;
  margin-bottom: 0.75rem;
  ${TEXT_STYLES.bodyCopyMedium}
`;

export const Bookmark = styled(BaseBookmark)``;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const ButtonAndEducationCompleteWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const EducationalResource = styled.li`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid #e6daf2;
  border-radius: 2rem;
`;

export const EducationTagGroup = styled(TagGroup)`
  margin-right: auto;
  align-self: start;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3.75rem;
`;

export const InformationTagGroup = styled(TagGroup)`
  padding: 1rem 0;
`;

export const ProgressBar = styled(EducationProgressBar)`
  margin-top: 1.5rem;
  margin-bottom: 2.25rem;
`;

export const Title = styled.div`
  margin-bottom: 1rem;
  ${TEXT_STYLES.headlineSectionLevel2Med}
`;
