import { type AriaToastRegionProps, useToastRegion } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { AnimatePresence } from 'framer-motion';
import { useRef } from 'react';

import { VioletToast } from '..';
import Toast from '../Toast';

import * as S from './styles';

interface ToastRegionProps extends AriaToastRegionProps {
  placement?: 'bottom' | 'top';
  state: ToastState<VioletToast>;
}

const ToastRegion = ({ placement = 'bottom', state, ...props }: ToastRegionProps) => {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <S.ToastRegion
      {...regionProps}
      ref={ref}
      $placement={placement}
      className="toast-region"
    >
      <AnimatePresence>
        {' '}
        {/* TODO: not needed once aria close works with animations */}
        {state.visibleToasts.map(toast => (
          <Toast
            key={toast.key}
            state={state}
            toast={toast}
          />
        ))}
      </AnimatePresence>
    </S.ToastRegion>
  );
};

export default ToastRegion;
