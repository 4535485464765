import { ToastState } from '@react-stately/toast';
import { FormEventHandler, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Button from 'src/components/Buttons/Button';
import ButtonGroup from 'src/components/Buttons/ButtonGroup';
import PageTitle from 'src/components/PageTitle';
import { VioletToast } from 'src/components/ToastProvider';
import NonClinicalExperienceTable from 'src/pages/components/NonClinicalExperienceTable';
import { NonClinicalExperiencesContext } from 'src/pages/components/NonClinicalExperienceTable/useNonClinicalExperiences';

import useGetNonClinicalExperiences from '../../hooks/useGetNonClinicalExperiences';
import { NonClinicalExperience } from '../../utils';

const AdditionalExperience = () => {
  const { createNonClinicalExperience, deleteNonClinicalExperience, patchNonClinicalExperience } =
    useGetNonClinicalExperiences();

  const toastState = useOutletContext();

  const formRef = useRef<HTMLFormElement>(null);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [originalNonClinicalExperiences, setOriginalNonClinicalExperiences] = useState<
    NonClinicalExperience[]
  >([]);
  const [draftNonClinicalExperiences, setDraftNonClinicalExperiences] = useState<
    NonClinicalExperience[]
  >([]);

  const handleFormSubmit: FormEventHandler = event => {
    event.preventDefault();

    setHasAttemptedSubmit(true);

    if (invalidRowIndices.length > 0) {
      /* Don't attempt submission if any rows are invalid */
      if (!formRef.current) {
        window.scrollTo(0, 0);
      } else {
        formRef.current.reportValidity();
      }
      return;
    }

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    /* Thou may proceed to handle submission */
    setFormIsSubmitting(true);

    let encounteredError = 0;

    /* Save all nonclinical experience entries */
    draftNonClinicalExperiences.forEach(async (experience, index) => {
      if (experience.id === '') {
        /* No id === create */
        try {
          const newExperience = await createNonClinicalExperience(experience);
          if (newExperience) {
            setDraftNonClinicalExperiences(prevState => {
              const newState = [...prevState];
              newState[index] = newExperience;
              return newState;
            });
            setOriginalNonClinicalExperiences(prevState => [...prevState, newExperience]);
          }
        } catch (error) {
          encounteredError++;
        }
      } else if (originalNonClinicalExperiences.find(e => e.id === experience.id)) {
        /* Id exists in nonClinicalExperiences === patch */
        try {
          const changedExperience = await patchNonClinicalExperience(experience);
          if (changedExperience) {
            setDraftNonClinicalExperiences(prevState => {
              const newState = [...prevState];
              newState[index] = changedExperience;
              return newState;
            });
            setOriginalNonClinicalExperiences(prevState => {
              const newState = [...prevState];
              const originalIndex = prevState.findIndex(e => e.id === changedExperience.id);
              newState[originalIndex] = changedExperience;
              return newState;
            });
          }
        } catch (error) {
          encounteredError++;
        }
      }
    });

    originalNonClinicalExperiences.forEach(async experience => {
      if (!draftNonClinicalExperiences.find(e => e.id === experience.id)) {
        /* Id no longer in draftNonClinicalExperiences === delete */
        try {
          await deleteNonClinicalExperience(experience.id);
          setOriginalNonClinicalExperiences(prevState =>
            prevState.filter(e => e.id !== experience.id)
          );
        } catch (error) {
          encounteredError++;
        }
      }
    });

    if (encounteredError > 0) {
      setFormIsSubmitting(false);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );
      return;
    }

    window.scrollTo(0, 0);
    (toastState as ToastState<VioletToast>).add(
      {
        description: 'Your data has been saved.',
        type: 'success'
      },
      { timeout: 8000 }
    );

    setFormIsSubmitting(false);
    setHasAttemptedSubmit(false);
  };

  const memoizedNonClinicalContextValue = useMemo(
    () => ({
      draftNonClinicalExperiences,
      invalidRowIndices,
      originalNonClinicalExperiences,
      setDraftNonClinicalExperiences,
      setInvalidRowIndices,
      setOriginalNonClinicalExperiences
    }),
    [originalNonClinicalExperiences, draftNonClinicalExperiences, invalidRowIndices]
  );

  return (
    <>
      <PageTitle
        title="Additional experience"
        titleVariant="h1"
      />
      <NonClinicalExperiencesContext.Provider value={memoizedNonClinicalContextValue}>
        <form
          ref={formRef}
          noValidate
          onSubmit={handleFormSubmit}
        >
          <NonClinicalExperienceTable hasAttemptedSubmit={hasAttemptedSubmit} />
          <ButtonGroup
            align="right"
            size="large"
          >
            <Button
              data-cy="save-button"
              isLoading={formIsSubmitting}
              size="large"
              type="submit"
            >
              Save
            </Button>
          </ButtonGroup>
        </form>
      </NonClinicalExperiencesContext.Provider>
    </>
  );
};

export default AdditionalExperience;
