import styled from 'styled-components';

import { default as BaseSpinner } from '../../../../../components/Spinner';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '../../../../../constants';
import { MOBILE_HEADER_HEIGHT, SECONDARY_NAVIGATION_HEIGHT } from '../../../constants';

export const Spinner = styled(BaseSpinner)``;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    height: calc(100dvh - ${MOBILE_HEADER_HEIGHT}px - ${SECONDARY_NAVIGATION_HEIGHT.mobile}px);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: calc(100dvh - ${SECONDARY_NAVIGATION_HEIGHT.desktop}px);
  }
`;
