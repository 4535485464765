import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check_circle.svg';
import { Bar, ProgressBar as BaseProgressBar, Track } from 'src/components/UnstyledProgressBar';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const CheckMarkIcon = styled(CheckIcon)`
  fill: var(--success-700);
  width: 1.25rem;
  height: 1.25rem;
`;

export const MiniProgressBarWrapper = styled.div`
  ${TEXT_STYLES.tagLarge}
  display: flex;
  flex-grow: 1;
  color: var(--gray-600);
  gap: 0.25rem;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    align-items: center;
  }
`;

export const ProgressBar = styled(BaseProgressBar)`
  flex-grow: 1;
  border-radius: 999px;
  overflow: hidden;

  ${Bar} {
    width: var(--width);
    height: 100%;
    background-color: var(--purple-400);
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }

  &.completed ${Bar} {
    background-color: var(--success-700);
  }

  ${Track} {
    background-color: var(--gray-200);
    height: 0.75rem;
  }
`;
