import { AnimatePresence } from 'framer-motion';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import ErrorModalDialog from 'src/components/ErrorModalDialog';
import useAnalytics from 'src/hooks/useAnalytics';
import { ErrorModalDialogContext } from 'src/hooks/useOpenErrorModalDialog';

import CommunityBenchmarkImageUrl from './community_benchmarks.png';
import OldSignUpForm from './OldSignUpForm';
import PhoneImageUrl from './phone.png';
import SignUpForm from './SignUpForm';
import * as S from './styles';

const SignUp = () => {
  const { analytics } = useAnalytics();
  const {
    close: closeErrorModalDialog,
    isOpen: errorModalDialogIsOpen,
    open: openErrorModalDialog
  } = useOverlayTriggerState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenErrorModalDialog = useCallback(openErrorModalDialog, []);

  const newSignUpEnabled = useFeatureFlagEnabled('self_service_sign_up');
  const [phoneImageWidth, setPhoneImageWidth] = useState<number | undefined>();

  const phoneImageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    document.title = 'Sign-Up - Violet';
    const pageSpinner = document.querySelector<HTMLDivElement>('#page-spinner');
    const pageSpinnerStyles = document.querySelector<HTMLStyleElement>('#page-spinner-styles');

    // If the App component is mounted a second time, which happens during development because of React Fast Refresh, the page spinner and its styles will already have been removed.
    if (pageSpinner && pageSpinnerStyles) {
      pageSpinner.remove();
      pageSpinnerStyles.remove();
    }
  }, []);

  useEffect(() => {
    if (analytics) {
      analytics.page('sign-up', 'Sign-Up - Violet');
    }
  }, [analytics]);

  const changeImageWidth = new ResizeObserver(() => {
    setPhoneImageWidth(phoneImageRef.current?.getBoundingClientRect().width ?? undefined);
  });
  changeImageWidth.observe(document.body);

  return (
    <ErrorModalDialogContext.Provider value={memoizedOpenErrorModalDialog}>
      <S.SignUpWrapper>
        <S.Form>
          <S.Logo
            color="dark"
            size="regular"
          />
          <S.ContentWrapper>
            <AnimatePresence mode="wait">
              {newSignUpEnabled === true ? <SignUpForm /> : <OldSignUpForm />}
            </AnimatePresence>
          </S.ContentWrapper>
        </S.Form>
        <S.ImageWrapper>
          <S.PhoneImage
            ref={phoneImageRef}
            alt="Phone displaying a video being played from one of Violet's educations."
            onLoad={img => {
              setPhoneImageWidth((img.target as HTMLImageElement).getBoundingClientRect().width);
            }}
            src={PhoneImageUrl}
          />
          <S.CenterImageTextWrapper
            style={{
              width:
                phoneImageWidth !== undefined && phoneImageWidth > 0 ? phoneImageWidth : undefined
            }}
          >
            <S.CommunityBenchmarksImage
              alt="Badges for Violet Community Benchmarks for three communities, BIPOC, LGBQ, and TGNC with differing levels of awareness, proficiency, and excellence."
              src={CommunityBenchmarkImageUrl}
            />
            <S.ImageText>Join 80,000+ providers in building health equity.</S.ImageText>
          </S.CenterImageTextWrapper>
        </S.ImageWrapper>
      </S.SignUpWrapper>
      {errorModalDialogIsOpen && <ErrorModalDialog close={closeErrorModalDialog} />}
    </ErrorModalDialogContext.Provider>
  );
};

export default SignUp;
