import styled from 'styled-components';

export const REPORT_HEIGHT = 1080;
export const REPORT_WIDTH = 1920;

export const ReportContent = styled.div`
  .data-row {
    grid-gap: 3rem;
  }

  .data-row:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

export const ReportTemplate = styled.div`
  background-color: var(--gray-100);
  color: var(--gray-900);
  height: ${REPORT_HEIGHT}px;
  padding: 0 2.5rem 2.5rem;
  width: ${REPORT_WIDTH}px;
`;
