import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Outlet } from 'react-router-dom';
import SecondaryNavigation from 'src/components/SecondaryNavigation';

interface Props {
  isHidden?: boolean;
}

const Wrapper = ({ isHidden = false }: Props) => {
  const pathwaysEnabled = useFeatureFlagEnabled('education_pathways_mvp');

  if (isHidden) return <Outlet />;

  const educationLinks =
    pathwaysEnabled === true
      ? [
          { link: '/dashboard/education/collections', title: 'Collections' },
          { link: '/dashboard/education/pathways', title: 'Pathways' },
          { link: '/dashboard/education/all-education', title: 'All education' }
        ]
      : [
          { link: '/dashboard/education/collections', title: 'Collections' },
          { link: '/dashboard/education/all-education', title: 'All education' }
        ];

  return (
    <>
      <SecondaryNavigation
        linkList={educationLinks}
        navTitle="Education"
      />
      <Outlet />
    </>
  );
};

export default Wrapper;
