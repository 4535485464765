import styled from 'styled-components';

export const ExportContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

export const ReportWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-100);

  &.is-admin {
    padding-bottom: 4.25rem; /* 68px -> height of download bar */
  }
`;
