import Select from 'src/components/FormFields/Select';
import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-8);
  align-items: end;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;

  button {
    align-self: start;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }
`;

export const ChartCard = styled.div`
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 2rem 1.5rem;
`;

export const ChartTitle = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin-bottom: 1.5rem;
`;

export const LanguageDescription = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  margin-bottom: 2rem;
`;

export const LanguagePercentage = styled.span`
  margin-right: 0.25rem;
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 300px;
`;

export const UserTypeSelect = styled(Select)`
  max-width: 12.5rem;

  div {
    color: var(--gray-800);
  }
`;
