import * as S from './styles';

interface Props {
  alignRight?: boolean;
  children: JSX.Element | number | string;
}

/*
  ** alignRight is required
  ** ONLY when used in sorting header columns
  data-align isn't actually used to do anything
  but HeaderCell needs to read the prop
*/
const TableNumberCell = ({ alignRight = true, children, ...args }: Props) => (
  <S.TableNumberCell
    {...args}
    $addPadding={alignRight}
    data-align={alignRight ? 'right' : 'left'}
  >
    {children}
  </S.TableNumberCell>
);

export default TableNumberCell;
