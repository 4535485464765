import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Bar = styled(motion.div)``;

export const Label = styled.span``;

export const LabelAndValueContainer = styled.div``;

export const Track = styled.div``;

export const Value = styled.span``;
