import styled from 'styled-components';

import {
  Bar,
  ProgressBar as BaseProgressBar,
  LabelAndValueContainer,
  Track
} from '../../../components/UnstyledProgressBar';

const thumbLength = 12;
const trackHeight = 2;

export const ProgressBar = styled(BaseProgressBar)`
  ${Bar} {
    position: relative;
    width: var(--width);
    height: 100%;
    background-color: #e03fff;

    &::before {
      content: '';
      position: absolute;
      top: -${(thumbLength - trackHeight) / 2}px;
      right: -${thumbLength / 2}px;
      display: inline-block;
      width: ${thumbLength}px;
      height: ${thumbLength}px;
      background-color: #e03fff;
      border-radius: 100%;
    }
  }

  ${LabelAndValueContainer} {
    display: flex;
    justify-content: space-between;
  }

  ${Track} {
    height: ${trackHeight}px;
    background: #d0d0d0;
  }
`;
