import capitalize from 'lodash/capitalize';
import { Item } from 'react-stately';

import getLevelIcon from './getLevelIcon';

const getLevelTag = (level: string, textOnly = false): ReturnType<typeof Item> => {
  const formattedLevel = capitalize(level);
  const Icon = getLevelIcon(formattedLevel);

  return textOnly ? (
    <>
      <Icon
        aria-hidden="true"
        role="img"
      />
      {formattedLevel}
    </>
  ) : (
    <Item textValue={formattedLevel}>
      <Icon
        aria-hidden="true"
        role="img"
      />
      {formattedLevel}
    </Item>
  );
};

export default getLevelTag;
