import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const EmptyTableCell = styled.td`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
  align-items: center;
  text-align: center;
  height: 100%;
  min-height: 0;
  padding: 0;
`;

export const EmptyTableRow = styled.tr`
  grid-template-columns: 1fr;
  text-align: center;
`;
