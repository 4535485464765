import styled from 'styled-components';

export const DownloadBar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  text-align: right;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
`;
