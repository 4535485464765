import { ReactComponent as CheckmarkIcon } from '@material-design-icons/svg/round/check.svg';
import Button from 'src/components/Buttons/Button';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const Checkmark = styled(CheckmarkIcon)`
  width: var(--space-10);
  height: var(--space-10);

  path {
    fill: var(--brand-bright);
  }
`;

export const Container = styled.div``;

export const Form = styled.form`
  justify-content: start;
`;

export const GradientCircleCheck = styled.div`
  display: flex;
  margin-bottom: var(--space-7);
  background-color: var(--white-light);
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: var(--space-12);
    height: var(--space-12);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    width: var(--space-10);
    height: var(--space-10);
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: start;

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-8);
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    justify-content: center;
    margin-bottom: var(--space-10);
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
`;

export const Layout = styled.div`
  max-width: 896px;
  margin-left: auto;
  margin-right: auto;
`;

export const Main = styled.main`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-left: var(--space-8);
    margin-right: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 592px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Page = styled.div`
  background-color: var(--purple-600);
  color: var(--white-light);
  min-height: 100dvh;
`;

export const Paragraph = styled.p`
  margin-bottom: 0;

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyLarge}
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyRegular}
  }
`;

export const Title = styled.h1`
  color: var(--white-light);

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.headlineStandardLevel1Regular}
    margin-bottom: var(--space-6);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.headlineStandardLevel2BoldLarge}
    margin-bottom: var(--space-5);
  }
`;

export const ViewDashboardButton = styled(Button)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-top: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-top: var(--space-9);
  }
`;
