// TODO: import `isIOS` and `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { isIOS, useObjectRef } from '@react-aria/utils';
import { forwardRef, useEffect, useState } from 'react';
import TextField from 'src/components/FormFields/TextField';

interface Props {
  className?: string;
  defaultValue: string;
}

const PhoneNumberField = forwardRef<HTMLInputElement, Props>(
  ({ className, defaultValue }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);

    const formatNumber = (value: string) => {
      let str = value.replace(/[^0-9]/g, '');

      if (str.length > 10) {
        str = str.substring(0, 10);
      }

      if (isIOS()) {
        return str;
      }

      if (str.length >= 7) {
        str = `(${str[0]}${str[1]}${str[2]}) ${str.substring(3, 6)}-${str.substring(
          6,
          str.length
        )}`;
      } else if (str.length >= 4) {
        str = `(${str[0]}${str[1]}${str[2]}) ${str.substring(3, str.length)}`;
      } else if (str.length === 3) {
        if (value.match(/^\([0-9]{3}\) $/)) {
          str = `(${str[0]}${str[1]}${str[2]}) `;
        }

        if (value.match(/^\([0-9]{3}$/)) {
          str = `${str[0]}${str[1]}${str[2]}`;
        }

        if (value.match(/^[0-9]{3}$/)) {
          str = `(${str[0]}${str[1]}${str[2]}) `;
        }
      }

      return str;
    };

    const [phoneNumber, setPhoneNumber] = useState(formatNumber(defaultValue));

    const handlePhoneNumberChange = (value: string) => {
      setPhoneNumber(formatNumber(value));
    };

    useEffect(() => {
      const phoneNumberField = ref.current;

      if (Boolean(phoneNumberField?.validity.patternMismatch)) {
        phoneNumberField?.setCustomValidity('Please enter a complete phone number.');
      } else {
        phoneNumberField?.setCustomValidity('');
      }
    }, [phoneNumber, ref]);

    return (
      <TextField
        ref={ref}
        autoComplete="tel-national"
        className={className}
        label="Phone number"
        name="phoneNumber"
        onChange={handlePhoneNumberChange}
        pattern={isIOS() ? '^[0-9]{10}$' : '^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$'}
        placeholder="Phone number"
        type="tel"
        value={phoneNumber}
      />
    );
  }
);

PhoneNumberField.displayName = 'PhoneNumberField';

export default PhoneNumberField;
