import * as S from './styles';

interface Props {
  children: JSX.Element;
  filterLabelText?: string;
}

const CommunityFilter = ({ children, filterLabelText }: Props) => (
  <S.CommunityFilter>
    <S.FilterLabel id="filter-label">
      {filterLabelText ?? 'Filter by community Benchmark'}
    </S.FilterLabel>
    {children}
  </S.CommunityFilter>
);

export default CommunityFilter;
