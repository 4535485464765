import { Pathway } from 'src/pages/Dashboard/utils';

import isNonEmptyString from './isNonEmptyString';

/*
  completedStepsCount: number of total completed resources in pathway
  inProgressStep: show next item after completed count as in-progress
                  if incomplete but started collection exists and
                  pathway was manually started
*/

const getPathwayProgress = (pathway?: Pathway) => {
  if (!pathway) return { completedStepsCount: 0, inProgressStep: undefined };

  const incompleteCollection = pathway.resources.find(
    resource =>
      resource.resourceType === 'CourseCollection' &&
      resource.completedAt === null &&
      resource.totalCompletedCourses !== undefined &&
      resource.totalCompletedCourses > 0 &&
      resource.totalCourses !== undefined &&
      resource.totalCourses !== resource.totalCompletedCourses
  );
  const currentTotalCompleted =
    pathway.completion?.totalCompletedResources !== undefined
      ? pathway.completion.totalCompletedResources
      : 0;
  const completedStepsCount = currentTotalCompleted;
  let inProgressStep = undefined;

  if (isNonEmptyString(pathway.completion?.startedAt) && incompleteCollection) {
    /*
      this is equal to the total because
      index < completedStepsCount show as completed
      and index === total will show as in-progress
    */
    inProgressStep = currentTotalCompleted;
  }

  return { completedStepsCount, inProgressStep };
};

export default getPathwayProgress;
