import { AriaMeterProps, useMeter } from 'react-aria';
import PopoverTrigger from 'src/components/PopoverTrigger';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaMeterProps {
  isReport?: boolean;
  labelPopoverText?: string;
  subTitle?: string;
}

const Meter = ({ isReport = false, labelPopoverText, subTitle, ...props }: Props) => {
  const { label, maxValue = 100, minValue = 0, value = 0 } = props;
  const { labelProps, meterProps } = useMeter(props);

  // Calculate the width of the progress bar as a percentage
  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;
  const isNotReport = !isReport;

  return (
    <S.Meter {...meterProps}>
      <div>
        <S.Label
          {...labelProps}
          $isReport={isReport}
        >
          {label}
          {isNotReport && isNonEmptyString(labelPopoverText) && (
            <PopoverTrigger
              content={<S.PopoverContent>{labelPopoverText}</S.PopoverContent>}
              placement="right"
            >
              <S.PopoverButton aria-label="Help">
                <S.HelpIcon
                  aria-hidden="true"
                  role="img"
                />
              </S.PopoverButton>
            </PopoverTrigger>
          )}
        </S.Label>
        {isReport ? <S.Subtitle>{subTitle}</S.Subtitle> : null}
      </div>
      <S.Bar>
        <S.ValueBar style={{ width: barWidth }} />
      </S.Bar>
      <S.PercentValue $isReport={isReport}>{value}%</S.PercentValue>
    </S.Meter>
  );
};

export default Meter;
