import { ReactComponent as QuestionMarkIcon } from '@material-design-icons/svg/round/help_outline.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const HelpIcon = styled(QuestionMarkIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  fill: var(--gray-400);
`;

export const PopoverButton = styled(UnstyledButton)`
  align-items: left;
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-900);

  &.is-focused {
    outline-color: var(--brand-bright);
    outline-offset: 0.25rem;
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  max-width: 18.75rem;
`;
