import { GridNode } from '@react-types/grid';
import { useRef } from 'react';
import { useTableCell, useTableSelectionCheckbox } from 'react-aria';
import { TableState } from 'react-stately';

import TableCheckbox from '../TableCheckbox';

import * as S from './styles';

interface Props {
  cell: GridNode<object>;
  'data-cy'?: string;
  state: TableState<object>;
}

const BodyCheckboxCell = ({ cell, 'data-cy': dataCy, state }: Props) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { checkboxProps } = useTableSelectionCheckbox({ key: cell.parentKey! }, state);

  return (
    <S.BodyCheckboxCell
      {...gridCellProps}
      ref={ref}
      data-cy={dataCy}
    >
      <TableCheckbox {...checkboxProps} />
    </S.BodyCheckboxCell>
  );
};

export default BodyCheckboxCell;
