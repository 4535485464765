import uniq from 'lodash/uniq';
import TooltipTrigger from 'src/components/TooltipTrigger';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  assignments: Bookmark[];
  className?: string;
}

const AssignedEducation = ({ assignments, className }: Props) => {
  const organizations = assignments
    .map(bookmark => bookmark.assigned_by_name)
    .filter(name => isNonEmptyString(name)) as string[];
  const type = assignments[0].resource_type === 'Course' ? 'course' : 'collection';
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction'
  });

  return (
    <TooltipTrigger
      className={className}
      content={
        <S.AssignmentText>
          This {type} has been assigned to you by {formatter.format(uniq(organizations))}
        </S.AssignmentText>
      }
      delay={0}
      theme="light"
    >
      <S.ButtonWrapper>
        <S.AssignedEducation data-cy="assigned-icon" />
      </S.ButtonWrapper>
    </TooltipTrigger>
  );
};

export default AssignedEducation;
