import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import { AriaCheckboxGroupProps } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import BackNext from 'src/components/Buttons/BackNext';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import InputGroup from 'src/components/FormFields/InputGroup';
import OnboardingHero from 'src/components/heroes/OnboardingHero';

import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../hooks/useUser';
import OnboardingLayout from '../components/OnboardingLayout';

import * as S from './styles';

const sexualOrientations = [
  { label: 'Heterosexual', value: 'Heterosexual' },
  { label: 'Gay', value: 'Gay' },
  { label: 'Lesbian', value: 'Lesbian' },
  { label: 'Bisexual', value: 'Bisexual' },
  { label: 'Pansexual', value: 'Pansexual' },
  { label: 'Queer', value: 'Queer' },
  { label: 'Asexual', value: 'Asexual' },
  { label: 'Prefer not to say', value: 'Prefer not to say' }
];

const genderIdentities = [
  { label: 'Cisgender woman', value: 'Cisgender woman' },
  { label: 'Cisgender man', value: 'Cisgender man' },
  { label: 'Transgender woman', value: 'Transgender woman' },
  { label: 'Transgender man', value: 'Transgender man' },
  { label: 'Non-binary', value: 'Non-binary' },
  { label: 'Genderqueer', value: 'Genderqueer' },
  { label: 'Agender', value: 'Agender' },
  { label: 'Intersex', value: 'Intersex' },
  { label: 'Prefer not to say', value: 'Prefer not to say' }
];

const raceEthnicities = [
  { label: 'Native American or Alaskan Native', value: 'Native American or Alaskan Native' },
  { label: 'Asian', value: 'Asian' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'Hispanic or Latine/x', value: 'Hispanic or Latine/x' },
  { label: 'Native Hawaiian or Pacific Islander', value: 'Native Hawaiian or Pacific Islander' },
  { label: 'White', value: 'White' },
  { label: 'Middle Eastern or North African', value: 'Middle Eastern or North African' },
  { label: 'Prefer not to say', value: 'Prefer not to say' }
];

const Identity = () => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const functionToCallAfterUserUpdateRef = useRef<() => void>();
  const { bearerToken, setUser, user } = useUser();

  const [genderIdentity, setGenderIdentity] = useState<string[]>(user.user_info.gender_identity);
  const [sexualOrientation, setSexualOrientation] = useState<string[]>(
    user.user_info.sexual_orientation
  );
  const [raceEthnicity, setRaceEthnicity] = useState<string[]>(user.user_info.race_ethnicity);

  const navigate = useNavigate();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const inLineAlertId = 'yw3Zkftr,R'; // Generated using `pwgen -s 10 1`.

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();

    setFormIsSubmitting(true);

    let url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/users/onboard`;

    let options: RequestInit = {
      body: JSON.stringify({
        user: {
          id: user.id,
          user_info: {
            gender_identity: genderIdentity,
            race_ethnicity: raceEthnicity,
            sexual_orientation: sexualOrientation
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      let response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersOnboard;

      setUser(data);

      if (user.is_clinical) {
        functionToCallAfterUserUpdateRef.current = () => {
          navigate('/onboarding/cultural-competencies');
        };
      } else {
        url = `${process.env.REACT_APP_API_BASE_PATH}/users/complete_onboarding`;

        options = {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST'
        };

        response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        setUser({ ...data, completed_onboarding: true });

        navigate('/onboarding/submitted');
      }
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  };

  const handleGenderIdentityChange: AriaCheckboxGroupProps['onChange'] = genderIdentitySet => {
    setGenderIdentity(genderIdentitySet);
  };

  const handleSexualOrientationChange: AriaCheckboxGroupProps['onChange'] =
    sexualOrientationSet => {
      setSexualOrientation(sexualOrientationSet);
    };

  const handleRaceEthnicityChange: AriaCheckboxGroupProps['onChange'] = raceEthnicitySet => {
    setRaceEthnicity(raceEthnicitySet);
  };

  let onboardingCopy = 'Next, we’ll gather some information about how you identify.';
  if (user.is_clinical) {
    onboardingCopy +=
      ' We understand that your lived experience likely shaped you into the provider you are today, and that’s why it factors directly into your Benchmarks. Once we get to know you better, we’ll delve into your experience with specific communities.';
  }

  return (
    <OnboardingLayout progressBarValue={1}>
      <OnboardingHero
        copy={onboardingCopy}
        graphicType="identities"
        header="Identities."
        isClinical={user.is_clinical}
      />
      <AnimatePresence>
        <S.AlertBar
          header="Your responses are private to you."
          id={inLineAlertId}
        >
          This data is shown in the aggregate to help your organization’s administrator understand
          the diversity of their team, without ever revealing your name or personal information.
        </S.AlertBar>
      </AnimatePresence>
      <S.FormContainer onSubmit={handleFormSubmit}>
        <S.Grid>
          <InputGroup
            copy="You can select multiple or prefer not to say."
            header="Which of the following best describes your race/ethnicity?*"
          >
            <CheckboxGroup
              aria-label="Which of the following best describes your race/ethnicity?*"
              data-cy="race-ethnicity-field"
              defaultValue={raceEthnicity}
              direction="vertical"
              isRequired
              onChange={handleRaceEthnicityChange}
              validationBehavior="native"
            >
              {raceEthnicities.map(option => (
                <GroupCheckbox
                  key={option.label}
                  name="selectedRaceEthnicity"
                  value={option.value}
                >
                  {option.label}
                </GroupCheckbox>
              ))}
            </CheckboxGroup>
          </InputGroup>

          <InputGroup
            copy="You can select multiple or prefer not to say."
            header="What best describes your sexual orientation?*"
          >
            <CheckboxGroup
              aria-label="What best describes your sexual orientation?*"
              data-cy="sexual-orientation-field"
              defaultValue={sexualOrientation}
              direction="vertical"
              isRequired
              onChange={handleSexualOrientationChange}
              validationBehavior="native"
            >
              {sexualOrientations.map(option => (
                <GroupCheckbox
                  key={option.label}
                  name="selectedSexualOrientations"
                  value={option.value}
                >
                  {option.label}
                </GroupCheckbox>
              ))}
            </CheckboxGroup>
          </InputGroup>

          <InputGroup
            copy="You can select multiple or prefer not to say."
            header="How do you describe your gender identity?*"
          >
            <CheckboxGroup
              aria-label="How do you describe your gender identity?*"
              data-cy="gender-identity-field"
              defaultValue={genderIdentity}
              direction="vertical"
              isRequired
              onChange={handleGenderIdentityChange}
              validationBehavior="native"
            >
              {genderIdentities.map(option => (
                <GroupCheckbox
                  key={option.label}
                  name="selectedSexualOrientations"
                  value={option.value}
                >
                  {option.label}
                </GroupCheckbox>
              ))}
            </CheckboxGroup>
          </InputGroup>
        </S.Grid>

        <BackNext
          backTo="/onboarding/information"
          nextIsLoading={formIsSubmitting}
          nextLabel={user.is_clinical ? 'Next: BIPOC competencies' : 'Submit'}
        />
      </S.FormContainer>
    </OnboardingLayout>
  );
};

export default Identity;
