import { useRef } from 'react';
import {
  FocusScope,
  useDialog,
  useModal,
  useNumberFormatter,
  useOverlay,
  usePreventScroll
} from 'react-aria';
import Button from 'src/components/Buttons/Button';
import ExpandableText from 'src/components/ExpandableText';
import { OrganizationOverview } from 'src/pages/Dashboard/utils';

import VioRequirements from '../VioRequirements';

import * as S from './styles';
import PathToVioImage from './VioModalImage.png';

interface Props {
  close: () => void;
  organizationOverview?: OrganizationOverview;
}

const VioModal = ({ close, organizationOverview }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  usePreventScroll();
  const { modalProps } = useModal();
  const isVerified = organizationOverview?.verifiedInclusive === true;

  const { dialogProps, titleProps } = useDialog({}, ref);

  const handleCloseButtonPress = () => {
    close();
  };

  const goToReminders = () => {
    close();
    const usersTable = document.getElementById('users-section');
    if (usersTable) {
      usersTable.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <S.VioModalDialog {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
          data-cy="vio-modal-dialog"
        >
          <S.CloseButton onPress={handleCloseButtonPress}>
            <S.CloseIcon
              aria-hidden
              role="img"
            />
          </S.CloseButton>
          <S.VioImage
            alt="Graphic shows the Verified Inclusive Organization badge"
            src={PathToVioImage}
          />
          <S.DialogContent>
            <S.TextBlock>
              <S.Title {...titleProps}>Verified Inclusive Organization</S.Title>
              <S.VioAlert
                data-cy="vio-status-alert"
                header={isVerified ? 'Verified' : 'Not verified'}
                id="vio-status"
                isDismissable={false}
                isSingleLine
                type={isVerified ? 'success' : 'warning'}
              >
                <>
                  Verified Inclusive Providers:{' '}
                  <S.StrongCount>
                    {commaNumFormatter.format(
                      organizationOverview !== undefined
                        ? organizationOverview.userMetrics.benchmarked.total
                        : 0
                    )}
                  </S.StrongCount>
                </>
              </S.VioAlert>
              {organizationOverview !== undefined && (
                <VioRequirements organizationOverview={organizationOverview} />
              )}
              <ExpandableText
                sectionId="vio-meaning"
                title="What does this mean?"
              >
                {isVerified ? (
                  <>
                    Your organization is being recognized as a leader in culturally responsive care!
                    This verification signals that your team is delivering quality,
                    identity-centered care that exceeds industry standards. By prioritizing cultural
                    competence, you’re contributing to a more inclusive health care system,
                    improving patient experiences, and driving better health outcomes for diverse
                    communities.
                  </>
                ) : (
                  <>
                    The Verified Inclusive Organization designation reflects your organization’s
                    efforts toward achieving cultural competence. However, based on current data,
                    your organization is not yet meeting one or both of the criteria for
                    verification. We’ve outlined potential solutions below to help you close the
                    gap.
                    <br />
                    <br />
                    By cultivating a culturally competent care team, your organization plays a vital
                    role in creating a more inclusive healthcare system, improving patient
                    experiences, and driving better health outcomes for diverse populations.
                  </>
                )}
              </ExpandableText>
              <ExpandableText
                sectionId="vio-benefits"
                title={isVerified ? 'How does this benefit my organization?' : 'Why get verified?'}
              >
                {isVerified ? (
                  <S.List>
                    <li>
                      Receive enhanced reimbursement rates or bonuses from Medicaid and value-based
                      payer contracts
                    </li>
                    <li>
                      Market your achievements in building health equity to engage both patients and
                      internal stakeholders
                    </li>
                    <li>
                      Secure more contracts from values-aligned payers by showcasing your
                      demonstrated commitment to inclusive health care
                    </li>
                  </S.List>
                ) : (
                  <S.List>
                    <li>
                      Receive enhanced reimbursements from Medicaid and value-based payer contracts
                    </li>
                    <li>
                      Use verification as a shared goal to drive improvements in care quality and
                      accountability across your organization
                    </li>
                    <li>
                      Market your achievements in building health equity to engage both patients and
                      internal stakeholders
                    </li>
                    <li>
                      Secure more contracts from values-aligned payers by showcasing your
                      demonstrated commitment to inclusive health care in RFPs
                    </li>
                  </S.List>
                )}
              </ExpandableText>
              <ExpandableText
                sectionId="vio-next-steps"
                title="Next steps"
              >
                {isVerified ? (
                  <>
                    Claim your Verified Inclusive Organization badge and start proudly displaying it
                    in your marketing materials and on your public website. Please refer to our
                    brand guidelines for proper use. Additionally, download your organization’s
                    Inclusivity Scorecard to share with internal teams or showcase to external
                    partners, highlighting your efforts in delivering culturally responsive care.
                  </>
                ) : (
                  <>
                    There are a few things you can do to move closer to becoming verified Inclusive.
                    We recommend the following
                    <S.NumList>
                      <li>
                        Remind providers within your organization to join Violet by navigating to
                        the ‘Users’ section under the Overview tab
                      </li>
                      <li>Contact support@joinviolet.com to add or remove users as needed</li>
                      <li>
                        Prompt providers to complete or update their profiles with additional
                        relevant experiences that contribute to their culturally responsive skill
                        set
                      </li>
                      <li>
                        Encourage your team to engage with cultural competence education through
                        Violet's library of CE/CME accredited courses, or with other educational
                        resources.
                      </li>
                    </S.NumList>
                  </>
                )}
              </ExpandableText>
            </S.TextBlock>
          </S.DialogContent>
          {isVerified ? (
            <S.ButtonContainer>
              <Button
                data-cy="view-scorecard-btn"
                fullWidth
                size="large"
                target="_blank"
                to="/reports/provider/scorecard"
                variant="outline"
              >
                View scorecard
              </Button>
              <Button
                data-cy="download-vio-badge-btn"
                fullWidth
                href="http://www.joinviolet.com/verified-inclusive-organization-marketing-guidelines"
                size="large"
                target="_blank"
                variant="primary"
              >
                Download VIO badge
              </Button>
            </S.ButtonContainer>
          ) : (
            <S.ButtonContainer>
              <Button
                data-cy="education-engagement-btn"
                fullWidth
                size="large"
                to="/dashboard/my-organization/education-engagement?order_by%5Bcolumn%5D=user_name&order_by%5Bdir%5D=asc&resource_type=course_collection"
                variant="outline"
              >
                View engagement
              </Button>
              <Button
                data-cy="reminders-btn"
                fullWidth
                onPress={goToReminders}
                size="large"
                variant="primary"
              >
                Send invite reminders
              </Button>
            </S.ButtonContainer>
          )}
        </S.Dialog>
      </FocusScope>
    </S.VioModalDialog>
  );
};
export default VioModal;
