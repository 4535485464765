import styled from 'styled-components';

import { ReactComponent as PlainLogotypeImage } from './logotype-plain.svg';
import { ReactComponent as BaseLogotypeImage } from './logotype.svg';

interface LogotypeProps {
  $color: string;
  $size: string;
}

export const Logotype = styled(BaseLogotypeImage)<LogotypeProps>`
  width: auto;

  ${({ $size }) => {
    if ($size === 'regular') {
      return `
        height: var(--space-7);
      `;
    } else {
      return `
        height: var(--space-6);
      `;
    }
  }}
  ${({ $color }) => {
    if ($color === 'dark') {
      return `
        color: var(--purple-400);
      `;
    } else if ($color === 'green') {
      return `
        color: var(--green);
      `;
    } else {
      return `
        color: var(--brand-tints-light);
      `;
    }
  }}
`;

export const LogotypePlain = styled(PlainLogotypeImage)<LogotypeProps>`
  width: auto;

  ${({ $size }) => {
    if ($size === 'regular') {
      return `
        height: var(--space-7);
      `;
    } else {
      return `
        height: var(--space-6);
      `;
    }
  }}
  ${({ $color }) => {
    if ($color === 'dark') {
      return `
        color: var(--purple-400);
      `;
    } else if ($color === 'green') {
      return `
        color: var(--green);
      `;
    } else {
      return `
        color: var(--brand-tints-light);
      `;
    }
  }}
`;
