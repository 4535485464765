import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import styled from 'styled-components';

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--white-light);
  `,
  Root: styled(UnstyledButton)`
    align-self: end;

    &.is-focused {
      outline-offset: 0.0625rem;
      outline: 0.125rem solid var(--brand-bright);
      border-radius: 0.25rem;
    }
  `
};

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 0.75rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  overflow-y: auto;
  outline: none;
`;
