import { cssVar, transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { default as BaseLogotype } from '../../../components/Logotype';
import {
  DESKTOP_BREAKPOINT_RANGE,
  FOCUS_STYLES,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../constants';

export const Footer = styled.footer`
  display: flex;
  align-items: end;

  ${MOBILE_BREAKPOINT_RANGE} {
    padding-bottom: var(--space-8);
    ${TEXT_STYLES.bodyCopySmall}
    color: var(--brand-tints-light);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    padding-bottom: 2.25rem;
    ${TEXT_STYLES.bodyCopyXXSmall}
    ${() => `
      color: ${transparentize(0.4, `${cssVar('--brand-tints-light')}`)};
    `}
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    justify-content: center;
  }
`;

export const Layout = styled.div`
  display: grid;
  min-height: 100dvh;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-rows: 4rem 1fr 4rem;
    row-gap: var(--space-10);
    padding-right: var(--space-8);
    padding-left: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-rows: 7rem 1fr 7rem;
    row-gap: var(--space-11);
    max-width: 64rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: var(--space-11);
    padding-left: var(--space-11);
  }
`;

export const LogoImage = styled.img`
  display: block;

  ${MOBILE_BREAKPOINT_RANGE} {
    width: 3rem;
    margin-bottom: var(--space-7);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-7);
  }
`;

export const LogoLink = styled(Link)`
  &:focus-visible {
    ${FOCUS_STYLES}
  }
`;

export const Logotype = styled(BaseLogotype)`
  display: block;
`;

export const Main = styled.main`
  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 37rem;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Page = styled.div`
  background-color: var(--purple-600);
`;

export const Paragraph = styled.p`
  ${TEXT_STYLES.bodyCopyLarge}

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-8);
    color: var(--brand-tints-light);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-12);
    color: var(--gray-light-light);
  }
`;

export const Title = styled.h1`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-5);
    ${TEXT_STYLES.headlineStandardLevel2BoldLarge}
    color: var(--brand-tints-light);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-6);
    ${TEXT_STYLES.headlineStandardLevel1Regular}
    color: var(--gray-light-light);
  }
`;
