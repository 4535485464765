import { MOBILE_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

interface ContainerProps {
  $size: 'dense' | 'regular' | 'small';
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border-radius: 0.5rem;
  padding: var(--space-11);
  background-color: var(--gray-50);

  ${({ $size }) => {
    if ($size === 'small') {
      return `
        padding: var(--space-8);
      `;
    } else if ($size === 'dense') {
      return `
        padding: var(--space-9) var(--space-11);
      `;
    } else {
      return `
        padding: var(--space-11);
      `;
    }
  }}

  ${MOBILE_BREAKPOINT_RANGE} {
    padding: var(--space-9) var(--space-8);
  }
`;
