import HelpPopoverTrigger from 'src/components/HelpPopoverTrigger';

import * as S from './styles';

interface Props {
  className?: string;
}

const WhatEducationToAddPopover = ({ className }: Props) => (
  <HelpPopoverTrigger
    className={className}
    label="Here’s what to add."
  >
    <S.ListLabel>Include:</S.ListLabel>
    <S.IconItemList>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        Individual academic courses or trainings specifically related to cultural competence and
        directly addressing BIPOC, LGBQ, or TGNC communities.
      </S.IconListItem>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        CE/CME coursework and other professional development.
      </S.IconListItem>
    </S.IconItemList>
    <S.ListLabel>Further guidance:</S.ListLabel>
    <ul>
      <S.ListItem>List full course names and issuing organizations.</S.ListItem>
      <S.ListItem>For university courses, enter credits, not hours.</S.ListItem>
    </ul>
    <S.ListLabel>Don't include:</S.ListLabel>
    <S.IconItemList>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        Trainings or courses completed more than 5 years ago.
      </S.IconListItem>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        Entire undergraduate, master's, or PhD programs.
      </S.IconListItem>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        Residencies, practicums, and internships. These belong in the work experience section of
        your profile.
      </S.IconListItem>
    </S.IconItemList>
  </HelpPopoverTrigger>
);

export default WhatEducationToAddPopover;
