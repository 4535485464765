import { RefObject, useEffect, useRef } from 'react';
import { AriaSliderThumbProps, mergeProps, useFocusRing, useSliderThumb } from 'react-aria';
import { SliderState } from 'react-stately';

import * as S from './styles';

interface Props extends AriaSliderThumbProps {
  'data-cy'?: string;
  maxValue?: number;
  state: SliderState;
  trackRef: RefObject<HTMLDivElement>;
}

const Thumb = ({
  'aria-errormessage': ariaErrorMessage,
  'data-cy': dataCy,
  index = 0,
  maxValue = 1,
  state,
  trackRef,
  validationState
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    inputProps,
    thumbProps: { style, ...thumbProps }
  } = useSliderThumb(
    { 'aria-errormessage': ariaErrorMessage, index, inputRef, trackRef, validationState },
    state
  );
  const { focusProps, isFocusVisible } = useFocusRing();

  useEffect(() => {
    if (validationState === 'invalid') {
      inputRef.current!.setCustomValidity(ariaErrorMessage!);
    } else {
      inputRef.current!.setCustomValidity('');
    }
  }, [ariaErrorMessage, validationState]);

  return (
    <S.Thumb
      {...thumbProps}
      $isActive={state.isThumbDragging(index)}
      $isFocused={isFocusVisible}
      $maxValue={maxValue}
      $thumbIndex={parseInt(state.getThumbValueLabel(index))}
      $valueIsSelected={state.values[0] !== 0}
    >
      <S.VisuallyHidden>
        <input
          ref={inputRef}
          {...mergeProps(inputProps, focusProps)}
          data-cy={dataCy}
        />
      </S.VisuallyHidden>
    </S.Thumb>
  );
};

export default Thumb;
