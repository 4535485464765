import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '../../../constants';
import Button from '../Button';

export const BackNext = styled.div`
  ${MOBILE_BREAKPOINT_RANGE} {
    padding-top: var(--space-10);
    padding-bottom: var(--space-14);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    padding-top: var(--space-7);
    padding-bottom: var(--space-16);
  }
`;

export const SkipButton = styled(Button)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-right: 0.5rem;
  }
`;
