import { RefObject } from 'react';
import { useLocation } from 'react-router-dom';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import ConditionalWrap from '../ConditionalWrap';

import * as S from './styles';

interface Props {
  linkList: {
    children?: {
      exact?: boolean;
      hideCondition?: boolean;
      link: string;
      onClick?: () => void;
      title: string;
    }[];
    exact?: boolean;
    hideCondition?: boolean;
    link: string;
    onClick?: () => void;
    parentLink?: string;
    title: string;
  }[];
  linksRef?: RefObject<HTMLDivElement>;
  navTitle: string;
}

const SecondaryNavigation = ({ linkList, linksRef, navTitle }: Props) => {
  const location = useLocation();
  const { isInDesktopBreakpointRange } = useBreakpointRange();

  const selectedParentLink = linkList.find(link => {
    const isExact = link.exact !== undefined ? link.exact : false;
    const isParent = link.parentLink !== undefined;
    if (isParent) {
      return location.pathname.includes(link.parentLink ?? '');
    } else {
      return (
        link.link === location.pathname ||
        (isExact && location.pathname === link.link.split('?')[0]) ||
        (!isExact && location.pathname.includes(link.link.split('?')[0]))
      );
    }
  });

  return (
    <S.SecondaryNavigation.Root $hasChildren={selectedParentLink?.children !== undefined}>
      <ConditionalWrap
        condition={isInDesktopBreakpointRange}
        wrapper={children => (
          <S.SecondaryNavigation.Wrapper>
            <S.SecondaryNavigation.Text>{navTitle}</S.SecondaryNavigation.Text>
            {children}
          </S.SecondaryNavigation.Wrapper>
        )}
      >
        <S.SecondaryNavigation.Links
          ref={linksRef}
          data-cy="secondary-nav-links"
        >
          {linkList.map(link => {
            if (link.hideCondition !== undefined && link.hideCondition) return null;
            return (
              <S.SecondaryNavigation.Link
                key={link.link}
                className={selectedParentLink?.link === link.link ? 'is-selected' : undefined}
                data-label={link.title}
                onClick={link.onClick}
                to={link.link}
              >
                <S.SecondaryNavigation.LinkText>{link.title}</S.SecondaryNavigation.LinkText>
              </S.SecondaryNavigation.Link>
            );
          })}
        </S.SecondaryNavigation.Links>
      </ConditionalWrap>
      {selectedParentLink?.children && (
        <S.ChildNavigation>
          <S.ChildNavigationWrapper data-cy="children-nav">
            {selectedParentLink.children.map(child => {
              const isExact = child.exact !== undefined ? child.exact : false;
              if (child.hideCondition !== undefined && child.hideCondition) return null;
              return (
                <S.ChildLink
                  key={child.link}
                  className={
                    (isExact &&
                      location.pathname ===
                        `${selectedParentLink.parentLink}${child.link.split('?')[0]}`) ||
                    (!isExact &&
                      location.pathname.includes(
                        `${selectedParentLink.parentLink}${child.link.split('?')[0]}`
                      ))
                      ? 'is-selected'
                      : undefined
                  }
                  data-label={child.title}
                  onClick={child.onClick}
                  to={`${selectedParentLink.parentLink}${child.link}`}
                >
                  <S.ChildLinkText>{child.title}</S.ChildLinkText>
                </S.ChildLink>
              );
            })}
          </S.ChildNavigationWrapper>
        </S.ChildNavigation>
      )}
    </S.SecondaryNavigation.Root>
  );
};

export default SecondaryNavigation;
