import { AnimatePresence } from 'framer-motion';
import { cloneDeep } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CollectionCard from 'src/components/Education/CollectionCard';
import CourseCard from 'src/components/Education/CourseCard';
import OldCollectionCard from 'src/components/Education/OldCollectionCard';
import OldCourseCard from 'src/components/Education/OldCourseCard';
import Spinner from 'src/components/Spinner';
import useUser from 'src/hooks/useUser';

import useGetCollections from '../../hooks/useGetCollections';
import useGetEducationalResources from '../../hooks/useGetEducationalResources';
import { Collection } from '../../utils';

import * as S from './styles';

const OldDashboardEducation = () => {
  const { user } = useUser();
  const newDashboardEnabled = useFeatureFlagEnabled('new_education_dashboard');
  const inLineAlertIds = ['LVGq0A9AC2', 'GU5DjPXT3L', '3E)FLvQqTZ']; // Generated using `pwgen -s 10 2`.

  /*
    TODO: When BE ordering is complete, we can remove orderedCollections
    BE requires some rearchitecting to do complex order we desire,
    so handling on FE for now
    Add param per_page: 6
    Add param for order (per BE on what name will be)
  */
  const { collections: incompleteCollections, isFetching: incompleteCollectionsIsFetching } =
    useGetCollections({ assigned: false, completed: false });
  const { collections: assignedCollections, isFetching: assignedCollectionsIsFetching } =
    useGetCollections({ assigned: true, completed: false });
  const {
    educationalResources: assignedEducationalResources,
    isFetching: assignedEducationalResourcesIsFetching,
    refetchEducationalResources
  } = useGetEducationalResources({ completed: false, viewAssignedEducationIsSelected: true });
  const [orderedAssignedCollections, setOrderedAssignedCollections] = useState<Collection[] | null>(
    null
  );
  const [orderedIncompleteCollections, setOrderedIncompleteCollections] = useState<
    Collection[] | null
  >(null);

  useEffect(() => {
    if (assignedCollectionsIsFetching || incompleteCollectionsIsFetching) return;

    const orderCollections = (unorderedCollections: Collection[]) => {
      const clonedUnorderedCollections = cloneDeep(unorderedCollections);

      const bookmarkedCollections = clonedUnorderedCollections
        .filter(col => col.bookmarks.length > 0)
        .sort((a, b) => b.progress - a.progress);

      const nonBookmarkedCollections = clonedUnorderedCollections.filter(
        col => !bookmarkedCollections.includes(col)
      );

      const inProgressCollections = nonBookmarkedCollections
        .filter(col => col.progress > 0)
        .sort((a, b) => b.progress - a.progress);

      const notStartedCollections = nonBookmarkedCollections
        .filter(col => col.progress === 0)
        .sort((a, b) => {
          if (a.published === b.published) {
            return 0;
          } else if (a.published === 'pinned' && b.published === 'published') {
            return -1;
          } else {
            return 1;
          }
        });

      return [...bookmarkedCollections, ...inProgressCollections, ...notStartedCollections];
    };

    setOrderedAssignedCollections(orderCollections(assignedCollections));
    setOrderedIncompleteCollections(orderCollections(incompleteCollections).splice(0, 6));
  }, [
    assignedCollections,
    assignedCollectionsIsFetching,
    incompleteCollections,
    incompleteCollectionsIsFetching
  ]);

  const getCollectionCard = (collection: Collection) =>
    newDashboardEnabled === true ? (
      <CollectionCard
        key={collection.id}
        collection={collection}
      />
    ) : (
      <OldCollectionCard
        key={collection.id}
        collection={collection}
      />
    );

  const isLoading =
    assignedEducationalResourcesIsFetching ||
    orderedAssignedCollections === null ||
    orderedIncompleteCollections === null;

  if (isLoading) {
    return <Spinner withWrapper />;
  }

  return (
    <>
      {assignedEducationalResources.length === 0 &&
        user.active_assignments_count > 0 &&
        user.active_assignments_count === user.completed_active_assignments_count && (
          <>
            <S.Subtitle id="assigned-to-you">Assigned to you</S.Subtitle>
            <AnimatePresence>
              <S.AlertBar
                data-cy="completed-all-assigned-education-alert"
                header="Nice work!"
                id={inLineAlertIds[2]}
                isDismissable={false}
                type="success"
              >
                You’ve completed all assigned education.
              </S.AlertBar>
            </AnimatePresence>
          </>
        )}
      {(assignedEducationalResources.length !== 0 || orderedAssignedCollections.length !== 0) && (
        <>
          <S.Subtitle id="assigned-to-you">Assigned to you</S.Subtitle>
          <AnimatePresence>
            <S.AlertBar
              header="You have outstanding course assignments."
              id={inLineAlertIds[0]}
            >
              To view all assigned courses, including those you've completed, click the 'Assigned
              collections/courses' option under the Saved education filter on the{' '}
              <Link to="/dashboard/education/collections">Collections</Link> or{' '}
              <Link to="/dashboard/education/all-education">All education</Link> pages. For more
              details, click the assignment icon on a course or collection card.
            </S.AlertBar>
          </AnimatePresence>
          <S.Cards>
            {orderedAssignedCollections.map(getCollectionCard)}
            {assignedEducationalResources.map(assignedEducationalResource =>
              newDashboardEnabled === true ? (
                <CourseCard
                  key={assignedEducationalResource.id}
                  course={assignedEducationalResource}
                  refetchEducationalResources={async () => {
                    await refetchEducationalResources(
                      { viewAssignedEducationIsSelected: true },
                      true
                    );
                  }}
                />
              ) : (
                <OldCourseCard
                  key={assignedEducationalResource.id}
                  educationalResource={assignedEducationalResource}
                  refetchEducationalResources={async () => {
                    await refetchEducationalResources(
                      { viewAssignedEducationIsSelected: true },
                      true
                    );
                  }}
                />
              )
            )}
          </S.Cards>
        </>
      )}
      <S.Subtitle>Collections</S.Subtitle>
      {orderedIncompleteCollections.length !== 0 && (
        <>
          <AnimatePresence>
            <S.AlertBar id={inLineAlertIds[1]}>
              Our collections are curated curriculums to quickly build knowledge and skills in a
              core competency.
            </S.AlertBar>
          </AnimatePresence>
          <S.Cards>{orderedIncompleteCollections.map(getCollectionCard)}</S.Cards>
        </>
      )}
    </>
  );
};
export default OldDashboardEducation;
