import clsx from 'clsx';
import { useRef } from 'react';
import { mergeProps, useHover, useOption } from 'react-aria';
import { ComboBoxState, Node } from 'react-stately';

import * as S from './styles';

interface Props {
  isSelected?: boolean;
  item: Node<object>;
  state: ComboBoxState<object>;
}

const Option = ({ isSelected = false, item, state }: Props) => {
  const ref = useRef<HTMLLIElement>(null);
  const { hoverProps, isHovered } = useHover({});
  const { isFocused, optionProps } = useOption({ key: item.key }, state, ref);

  return (
    <S.Option
      {...mergeProps(hoverProps, optionProps)}
      ref={ref}
      className={clsx(
        { 'is-focused': isFocused },
        { 'is-hovered': isHovered },
        { 'is-selected': isSelected }
      )}
    >
      {item.rendered}
      {isSelected && <S.CheckedIcon aria-hidden="true" />}
    </S.Option>
  );
};

export default Option;
