import { ReactComponent as MyOrganizationIcon } from '@material-design-icons/svg/round/groups.svg';
import { ReactComponent as HomeIcon } from '@material-design-icons/svg/round/home.svg';
import { ReactComponent as MyNetworkIcon } from '@material-design-icons/svg/round/language.svg';
import { ReactComponent as SignOutIcon } from '@material-design-icons/svg/round/logout.svg';
import { ReactComponent as ProfileIcon } from '@material-design-icons/svg/round/person.svg';
import { ReactComponent as EducationIcon } from '@material-design-icons/svg/round/school.svg';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { OverlayContainer, useButton, useFocusRing, useOverlayTrigger } from 'react-aria';
import { Outlet, useLocation } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import TooltipTrigger from 'src/components/TooltipTrigger';
import { useUpdateEffect } from 'usehooks-ts';

import { FADE_OUT_TRANSITION_DURATION_MS } from '../../constants';
import useBreakpointRange from '../../hooks/useBreakpointRange';
import useUser from '../../hooks/useUser';

import MobileNavigation from './MobileNavigation';
import * as S from './styles';

const MotionComponent = motion(MobileNavigation);

interface Props {
  isHidden?: boolean;
}

const Navigation = ({ isHidden = false }: Props) => {
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();

  const location = useLocation();

  const { user } = useUser();

  const isNetworkManager = user.member_roles.includes('network_manager');
  const isSuperUser = user.member_roles.includes('superuser');

  const state = useOverlayTriggerState({});

  const overlayRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const { overlayProps, triggerProps } = useOverlayTrigger({ type: 'dialog' }, state, triggerRef);

  const { buttonProps } = useButton(triggerProps, triggerRef);
  const { focusProps, isFocusVisible } = useFocusRing();

  useUpdateEffect(() => {
    if (state.isOpen) {
      state.close();
    }
  }, [location.pathname]);

  if (isHidden) return <Outlet />;

  return (
    <S.Layout $offWhiteBackground={location.pathname.includes('my-network')}>
      <S.Header $menuIsOpen={state.isOpen}>
        <S.Logotype.Root to="/dashboard">
          <S.Logotype.Image
            color="light"
            hideReserved
            size="small"
          />
        </S.Logotype.Root>
        {isInMobileBreakpointRange && (
          <>
            <S.MenuButton.Root
              {...buttonProps}
              {...focusProps}
              ref={triggerRef}
              $menuIsOpen={state.isOpen}
              className={clsx({ 'is-focused': isFocusVisible })}
            >
              <S.MenuButton.MenuIcon />
              <S.MenuButton.MenuOpenIcon />
            </S.MenuButton.Root>
            <AnimatePresence>
              {state.isOpen && (
                <OverlayContainer>
                  <MotionComponent
                    {...overlayProps}
                    ref={overlayRef}
                    animate={{ scale: 1 }}
                    exit={{
                      scale: 1,
                      transition: { duration: FADE_OUT_TRANSITION_DURATION_MS / 1000 }
                    }}
                    isNetworkManager={isNetworkManager}
                    isSuperUser={isSuperUser}
                    onClose={state.close}
                  />
                </OverlayContainer>
              )}
            </AnimatePresence>
          </>
        )}
        {isInDesktopBreakpointRange && (
          <S.Navigation.Root>
            {isNetworkManager ? null : (
              <TooltipTrigger
                className={location.pathname === '/dashboard' ? 'is-selected' : undefined}
                content={<S.TooltipText>Home</S.TooltipText>}
                delay={0}
                placement="right"
                theme="light"
              >
                <S.Navigation.Link
                  className={location.pathname === '/dashboard' ? 'is-selected' : undefined}
                  data-cy="home-link"
                  to="/dashboard"
                >
                  <HomeIcon />
                </S.Navigation.Link>
              </TooltipTrigger>
            )}
            {isSuperUser && isNetworkManager && (
              <TooltipTrigger
                className={
                  location.pathname.startsWith('/dashboard/my-organization')
                    ? 'is-selected'
                    : undefined
                }
                content={<S.TooltipText>My organization</S.TooltipText>}
                delay={0}
                placement="right"
                theme="light"
              >
                <S.Navigation.Link
                  className={
                    location.pathname.startsWith('/dashboard/my-organization')
                      ? 'is-selected'
                      : undefined
                  }
                  data-cy="my-organization-link"
                  to="/dashboard/my-organization"
                >
                  <MyOrganizationIcon />
                </S.Navigation.Link>
              </TooltipTrigger>
            )}
            {isNetworkManager && (
              <TooltipTrigger
                className={
                  location.pathname.startsWith('/dashboard/my-network') ? 'is-selected' : undefined
                }
                content={<S.TooltipText>My network</S.TooltipText>}
                delay={0}
                placement="right"
                theme="light"
              >
                <S.Navigation.Link
                  className={
                    location.pathname.startsWith('/dashboard/my-network')
                      ? 'is-selected'
                      : undefined
                  }
                  data-cy="my-network-link"
                  to="/dashboard/my-network"
                >
                  <MyNetworkIcon />
                </S.Navigation.Link>
              </TooltipTrigger>
            )}
            <TooltipTrigger
              className={
                location.pathname.startsWith('/dashboard/education') ? 'is-selected' : undefined
              }
              content={<S.TooltipText>Education</S.TooltipText>}
              delay={0}
              placement="right"
              theme="light"
            >
              <S.Navigation.Link
                className={
                  location.pathname.startsWith('/dashboard/education') ? 'is-selected' : undefined
                }
                data-cy="education-link"
                to="/dashboard/education/collections"
              >
                <EducationIcon />
              </S.Navigation.Link>
            </TooltipTrigger>
            <TooltipTrigger
              className={
                location.pathname.startsWith('/dashboard/profile') ? 'is-selected' : undefined
              }
              content={<S.TooltipText>Profile</S.TooltipText>}
              delay={0}
              placement="right"
              theme="light"
            >
              <S.Navigation.Link
                className={
                  location.pathname.startsWith('/dashboard/profile') ? 'is-selected' : undefined
                }
                data-cy="profile-link"
                to="/dashboard/profile/information"
              >
                <ProfileIcon />
              </S.Navigation.Link>
            </TooltipTrigger>
            {isSuperUser && !isNetworkManager && (
              <TooltipTrigger
                className={
                  location.pathname.startsWith('/dashboard/my-organization')
                    ? 'is-selected'
                    : undefined
                }
                content={<S.TooltipText>My organization</S.TooltipText>}
                delay={0}
                placement="right"
                theme="light"
              >
                <S.Navigation.Link
                  className={
                    location.pathname.startsWith('/dashboard/my-organization')
                      ? 'is-selected'
                      : undefined
                  }
                  data-cy="my-organization-link"
                  to="/dashboard/my-organization"
                >
                  <MyOrganizationIcon />
                </S.Navigation.Link>
              </TooltipTrigger>
            )}
            <TooltipTrigger
              content={<S.TooltipText>Sign out</S.TooltipText>}
              delay={0}
              placement="right"
              theme="light"
            >
              <S.Navigation.Link
                data-cy="sign-out-link"
                to="/sign-out"
              >
                <SignOutIcon />
              </S.Navigation.Link>
            </TooltipTrigger>
          </S.Navigation.Root>
        )}
      </S.Header>
      <Outlet />
    </S.Layout>
  );
};

export default Navigation;
