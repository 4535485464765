import { useNumberFormatter } from 'react-aria';
import { Cell, Column, Row, SortDescriptor, TableBody, TableHeader } from 'react-stately';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { STATES } from 'src/pages/constants';
import { NetworkProvidersLocation } from 'src/pages/Dashboard/utils';

import * as Shared from '../styles';

import * as S from './styles';

interface Props {
  currentPageLocations: number;
  handleLocationsTableSortChange?: (descriptor: SortDescriptor) => void;
  handleSetPageLocations: (page: number) => void;
  hideNameColumn?: boolean;
  networkLocations: NetworkProvidersLocation[];
  networkLocationsTotalPages: number;
  sortDescriptorLocations?: SortDescriptor;
}

export const ServiceAreasTable = ({
  currentPageLocations,
  handleLocationsTableSortChange,
  handleSetPageLocations,
  hideNameColumn = false,
  networkLocations,
  networkLocationsTotalPages,
  sortDescriptorLocations
}: Props) => {
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });
  return (
    <>
      <S.LocationTable
        $showAllColumns={!hideNameColumn}
        aria-label="Table of service area locations"
        data-cy="network-service-area-table"
        onSortChange={handleLocationsTableSortChange}
        sortDescriptor={sortDescriptorLocations}
      >
        <TableHeader>
          <Column
            key="name"
            allowsSorting
            /* @ts-expect-error */
            exclude={hideNameColumn}
          >
            Service area
          </Column>
          <Column
            key="state"
            allowsSorting
          >
            {hideNameColumn ? 'Service area' : 'State'}
          </Column>
          <Column
            key="provider_count"
            allowsSorting
          >
            <TableNumberCell alignRight>Provider count</TableNumberCell>
          </Column>
          <Column
            key="bipoc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
          </Column>
          <Column
            key="lgbq_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
          </Column>
          <Column
            key="tgnc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
          </Column>
        </TableHeader>
        <TableBody>
          {networkLocations.map(location => (
            <Row
              key={`${location.name}-${location.state}`}
              data-cy="network-service-area-row"
            >
              <Cell
                // @ts-expect-error
                exclude={hideNameColumn}
              >
                {location.name}
              </Cell>
              <Cell>
                {hideNameColumn
                  ? STATES.find(state => state.abbreviation === location.state)?.name
                  : location.state}
              </Cell>
              <Cell>
                <TableNumberCell data-cy="service-area-total-providers">
                  {commaNumFormatter.format(location.totalProviders)}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="bipoc-inclusivity-percentage">
                  {`${location.inclusivity.bipoc.percentage}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="lgbq-inclusivity-percentage">
                  {`${location.inclusivity.lgbq.percentage}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="tgnc-inclusivity-percentage">
                  {`${location.inclusivity.tgnc.percentage}%`}
                </TableNumberCell>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </S.LocationTable>
      <Shared.TablePagination
        currentPage={currentPageLocations}
        setPage={handleSetPageLocations}
        totalPages={networkLocationsTotalPages}
      />
    </>
  );
};
