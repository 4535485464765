import { Temporal } from '@js-temporal/polyfill';
import { ReactComponent as OrganizationIcon } from '@material-design-icons/svg/round/apartment.svg';
import { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useRef, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useOutletContext } from 'react-router-dom';
import { Cell, Column, Item, Row, TableBody, TableHeader } from 'react-stately';
import Badge from 'src/components/Badge';
import BooleanIcon from 'src/components/BooleanIcon';
import Button from 'src/components/Buttons/Button';
import ViewDetailsButton from 'src/components/Buttons/ViewDetailsButton';
import HelpPopoverTrigger from 'src/components/HelpPopoverTrigger';
import Label from 'src/components/Label';
import PageTitle from 'src/components/PageTitle';
import CommunityGraphs from 'src/components/Reporting/CommunityGraphs';
import DataCard from 'src/components/Reporting/DataCard';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Spinner from 'src/components/Spinner';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { VioletToast } from 'src/components/ToastProvider';
import useAnalytics from 'src/hooks/useAnalytics';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import useUser from 'src/hooks/useUser';
import { STATES } from 'src/pages/constants';
import { NETWORK_INCLUSIVITY_URL_W_PARAMS } from 'src/pages/Dashboard/MyNetwork/Wrapper';
import { downloadCsv } from 'src/utils/downloadCsv';
import { downloadPdf } from 'src/utils/downloadPdf';
import { downloadPng } from 'src/utils/downloadPng';

import useGetNetworkLocations from '../hooks/useGetNetworkLocations';
import useGetNetworkOrgMembers from '../hooks/useGetNetworkOrgMembers';
import useGetNetworkOverview from '../hooks/useGetNetworkOverview';

import * as S from './styles';

const MyNetwork = () => {
  // SEARCH PARAMS: If you need to implement search params,
  // please use "useSearchParams"
  const { analytics } = useAnalytics();
  const networkOverviewReportEnabled = useFeatureFlagEnabled('network_overview_report_export');
  const isVioEnabled = useFeatureFlagEnabled('verified_inclusive_org');

  const { bearerToken, user } = useUser();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'network_manager'
  )?.organization.name;
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const toastState = useOutletContext();
  const [isExporting, setIsExporting] = useState(false);

  const { isFetching: isFetchingOverview, networkOverview } = useGetNetworkOverview();
  const { isFetching: isFetchingLocation, networkLocations } = useGetNetworkLocations({
    order_by: {
      column: 'provider_count',
      dir: 'desc'
    },
    page: 1,
    per_page: 5
  });
  const { isFetching: isFetchingNetworkOrgMembers, networkOrgMembers } = useGetNetworkOrgMembers({
    order_by: {
      column: 'total_providers',
      dir: 'desc'
    },
    page: 1,
    per_page: 5
  });
  const compactNumFormatter = useNumberFormatter({ notation: 'compact' });
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  const generateReportTitle = () => {
    if (networkOverview === undefined) {
      return '';
    }
    const date = new Date();
    const formattedDate = date
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
      .replace(/\//g, '-');
    return `Violet_NetworkOverview_${organizationName?.replaceAll(' ', '')}_${formattedDate}`;
  };

  const networkOverviewReportRef = useRef<HTMLDivElement>(null);

  const handleDownloadButtonClick = async (downloadType: number | string): Promise<void> => {
    if (downloadType === 'png') {
      await downloadPng(networkOverviewReportRef.current as HTMLElement, generateReportTitle());
    } else if (downloadType === 'pdf') {
      await downloadPdf(networkOverviewReportRef.current as HTMLElement, generateReportTitle());
    }

    const { organization } = user.organization_memberships.find(
      membership => membership.member_role === 'network_manager'
    )!;

    analytics?.track('Report Downloaded', {
      organization_id: organization.id,
      organization_name: organization.name,
      report_format: downloadType,
      report_name: 'Network overview'
    });
  };

  const handleExportVIOButtonClick = async () => {
    setIsExporting(true);
    const downloadingToastKey = (toastState as ToastState<VioletToast>).add({
      cancelable: false,
      customIcon: (
        <S.ToastSpinner
          backgroundColor="#d1e9ff"
          color="#1570ef"
        />
      ),
      description: 'Your report is downloading.',
      type: 'info'
    });

    const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/networks/organization_members/export`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { organization } = user.organization_memberships.find(
        membership => membership.member_role === 'network_manager'
      )!;

      const formattedDate = Temporal.Now.plainDateISO().toLocaleString('en-US');
      const organizationName = organization.name.replace(/\s/g, '');

      await downloadCsv(
        response,
        `Violet_${organizationName}_Verified-Inclusive-Organization-Report_${formattedDate}.csv`
      );

      (toastState as ToastState<VioletToast>).close(downloadingToastKey);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Download complete.',
          type: 'success'
        },
        { timeout: 8000 }
      );
    } catch (error) {
      Sentry.captureException(error);
      (toastState as ToastState<VioletToast>).close(downloadingToastKey);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Download failed.',
          type: 'error'
        },
        { timeout: 8000 }
      );
    }
    setIsExporting(false);
  };

  return (
    <>
      <S.HiddenOffscreenReportRender ref={networkOverviewReportRef}>
        {networkOverview && <S.OverviewReport networkOverview={networkOverview} />}
      </S.HiddenOffscreenReportRender>
      <PageTitle
        actionButton={
          isVioEnabled === true ? (
            <Button
              data-cy="export-vio-button"
              isDisabled={isExporting}
              isLoading={isExporting}
              onPress={handleExportVIOButtonClick}
            >
              Export
            </Button>
          ) : networkOverviewReportEnabled === true ? (
            <S.ExportButtonWithMenu
              data-cy="export-network-overview-button"
              label="Export"
              matchButtonWidth
              onAction={handleDownloadButtonClick}
            >
              <Item key="pdf">PDF</Item>
              <Item key="png">PNG</Item>
            </S.ExportButtonWithMenu>
          ) : undefined
        }
        data-cy="network-overview-title-container"
        description="Inclusivity and diversity insights are based on the overall cultural competencies and demographics of providers within the network. Violet generates these insights using a proprietary benchmarking framework, in conjunction with providers' self-reported identity disclosures."
        labelComponent={
          <Label
            data-cy="network-label"
            icon={OrganizationIcon}
          >
            {organizationName ?? ''}
          </Label>
        }
        title="Overview"
        titleVariant="h1"
      />
      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <S.DataWrapper data-cy="network-overview">
          {isVioEnabled === true ? (
            <S.NetworkMetricRow layout="fifths">
              <DataHighlight
                data-cy="total-network-organizations"
                detailText="Provider organizations"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalNetworkOrganizations ?? 0
                )}
                popoverContent="Total number of provider organizations linked to your network, based on the roster provided."
                variant="small"
              />
              <DataHighlight
                data-cy="total-network-verified-organizations"
                detailText="Verified Inclusive Organizations"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalVioNetworkOrganizations ?? 0
                )}
                popoverContent={
                  <>
                    <p>
                      Total number of organizations within your network that have an active
                      partership with Violet and meet the following criteria for verification:
                    </p>
                    <ul>
                      <li>
                        At least 30% of a provider’s clinical staff (including licensed
                        professionals, pre-licensed individuals, and interns) must onboard with
                        Violet, submitting their profiles to receive Benchmarks.
                      </li>
                      <li>
                        Of those, 40% must achieve an Awareness Benchmark in at least one of the
                        three communities—BIPOC, LGBQ, or TGNC.
                      </li>
                    </ul>
                  </>
                }
                variant="small"
              />
              <DataHighlight
                data-cy="total-network-providers"
                detailText="Total providers"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalNetworkProviders ?? 0
                )}
                popoverContent="Total number of clinical providers linked to your network, based on your provided roster or inferred from third-party data. Clinical users include all licensed and pre-licensed providers, as well as interns actively delivering care."
                variant="small"
              />
              <DataHighlight
                data-cy="total-network-providers-benchmarks"
                detailText="Providers with a Benchmark"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
                )}
                popoverContent="Includes all providers who have earned a Violet Benchmark, either through claims data analysis or by completing their profile on Violet to achieve Verified Inclusive Provider status."
                variant="small"
              />
              <DataHighlight
                data-cy="total-active-network-providers"
                detailText="Providers on Violet"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalActiveNetworkProviders ?? 0
                )}
                popoverContent="Total number of providers who have completed their Violet profiles in order to be benchmarked."
                variant="small"
              />
            </S.NetworkMetricRow>
          ) : (
            <DataReportRow layout="quarters">
              <DataHighlight
                data-cy="total-network-providers"
                detailText="Providers"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalNetworkProviders ?? 0
                )}
                variant="small"
              />
              <DataHighlight
                data-cy="total-network-organizations"
                detailText="Provider organizations"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalNetworkOrganizations ?? 0
                )}
                variant="small"
              />
              <DataHighlight
                data-cy="total-network-providers-benchmarks"
                detailText="Inclusive providers"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
                )}
                variant="small"
              />
              <DataHighlight
                data-cy="total-active-network-providers"
                detailText="On Violet"
                numberHighlight={compactNumFormatter.format(
                  networkOverview?.totalActiveNetworkProviders ?? 0
                )}
                variant="small"
              />
            </DataReportRow>
          )}
          <DataReportRow layout="fifty-fifty">
            <DataCard data-cy="benchmarks-community-graphs">
              <DataCardHeader
                badgeColor="gray"
                badgeText="All providers"
                description="This chart represents network providers who have had their cultural competence assessed and benchmarked by Violet. It includes both providers that are active on the platform and providers whose Benchmarks are inferred through data analytics."
                title="Cultural competencies"
                titleVariant="h3"
              />
              <CommunityGraphs
                data={networkOverview?.networkInclusivityPercentages}
                dataType="benchmarks"
                linkToPage={NETWORK_INCLUSIVITY_URL_W_PARAMS}
              />
            </DataCard>
            <DataCard data-cy="demographics-community-graphs">
              <DataCardHeader
                badgeColor="green"
                badgeText="On Violet"
                description="This chart represents active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
                title="Diversity"
                titleVariant="h3"
              />
              <CommunityGraphs
                data={networkOverview?.networkDiversityPercentages}
                dataType="demographics"
                linkToPage="/dashboard/my-network/inclusivity/diversity"
              />
            </DataCard>
          </DataReportRow>
        </S.DataWrapper>
      )}

      <S.TableWrapper>
        <S.SingleTable>
          <DataCardHeader
            description="This table displays the density of inclusive providers by geographic service area. Click on a service area to view more details."
            title="Service areas"
          />
          {isFetchingLocation ? (
            <Spinner withWrapper />
          ) : networkLocations && networkLocations.length > 0 ? (
            <>
              <S.OverviewLocationTable
                aria-label="Table of service area locations"
                data-cy="network-service-area-table"
                hasLinkedRows
              >
                <TableHeader>
                  <Column key="state">Service area</Column>
                  <Column key="provider_count">
                    <TableNumberCell alignRight>Provider count</TableNumberCell>
                  </Column>
                  <Column key="bipoc_inclusivity">
                    <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
                  </Column>
                  <Column key="lgbq_inclusivity">
                    <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
                  </Column>
                  <Column key="tgnc_inclusivity">
                    <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
                  </Column>
                </TableHeader>
                <TableBody>
                  {networkLocations.map(location => (
                    <Row
                      key={location.state}
                      data-cy="network-service-area-row"
                    >
                      <Cell>
                        <TableLinkedCell
                          data-cy="service-area-state-filter-link"
                          to={`${NETWORK_INCLUSIVITY_URL_W_PARAMS}&states%5B%5D=${location.state}`}
                        >
                          {STATES.find(state => state.abbreviation === location.state)?.name}
                        </TableLinkedCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="service-area-total-providers">
                          {commaNumFormatter.format(location.totalProviders)}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="bipoc-inclusivity-percentage">
                          {`${location.inclusivity.bipoc.percentage}%`}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="lgbq-inclusivity-percentage">
                          {`${location.inclusivity.lgbq.percentage}%`}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="tgnc-inclusivity-percentage">
                          {`${location.inclusivity.tgnc.percentage}%`}
                        </TableNumberCell>
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </S.OverviewLocationTable>
              <ViewDetailsButton
                data-cy="service-area-view-details-button"
                linkToPage={NETWORK_INCLUSIVITY_URL_W_PARAMS}
                overrideText="View all service areas"
              />
            </>
          ) : (
            <S.EmptyState>No service areas found.</S.EmptyState>
          )}
        </S.SingleTable>
        <S.SingleTable>
          <DataCardHeader
            description="This table displays the largest provider organizations within your network. Click on an organization for information about their inclusivity breakdown."
            title="Provider organizations"
          />
          {isFetchingNetworkOrgMembers ? (
            <Spinner withWrapper />
          ) : networkOrgMembers && networkOrgMembers.length > 0 ? (
            <>
              <S.ProviderOrganizationsTable
                aria-label="Provider organizations data"
                hasLinkedRows
              >
                <TableHeader>
                  <Column>Name</Column>
                  <Column>
                    <TableNumberCell alignRight>
                      {isVioEnabled === true ? 'Providers' : 'Provider count'}
                    </TableNumberCell>
                  </Column>
                  <Column>
                    <S.CenterColumn>On Violet</S.CenterColumn>
                  </Column>
                  {/* @ts-expect-error */}
                  <Column exclude={isVioEnabled !== true}>
                    {isVioEnabled === true && (
                      <S.CenterColumn>
                        Verified Inclusive
                        <HelpPopoverTrigger
                          inline
                          showBorder
                          size="small"
                          type="info"
                        >
                          <p>Violet’s criteria for Verified Inclusive Organizations:</p>
                          <ul>
                            <li>
                              At least 30% of a provider’s clinical staff (including licensed
                              professionals, pre-licensed individuals, and interns) must onboard
                              with Violet, submitting their profiles to receive Benchmarks.
                            </li>
                            <li>
                              Of those, 40% must achieve an Awareness Benchmark in at least one of
                              the three communities—BIPOC, LGBQ, or TGNC.
                            </li>
                          </ul>
                        </HelpPopoverTrigger>
                      </S.CenterColumn>
                    )}
                  </Column>
                </TableHeader>
                <TableBody>
                  {networkOrgMembers.map(member => (
                    <Row
                      key={member.id}
                      data-cy="provider-organization-row"
                    >
                      <Cell>
                        {isVioEnabled === true ? (
                          <S.LinkToOrg
                            data-cy="provider-organization-link"
                            to={`${NETWORK_INCLUSIVITY_URL_W_PARAMS}&organizations%5B%5D=${member.id}`}
                          >
                            {member.name}
                          </S.LinkToOrg>
                        ) : (
                          <TableLinkedCell
                            data-cy="provider-organization-link"
                            to={`${NETWORK_INCLUSIVITY_URL_W_PARAMS}&organizations%5B%5D=${member.id}`}
                          >
                            {member.name}
                          </TableLinkedCell>
                        )}
                      </Cell>
                      <Cell>
                        <TableNumberCell>
                          {commaNumFormatter.format(member.totalProviders)}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <S.CenterDataCell>
                          {isVioEnabled === true ? (
                            <BooleanIcon
                              aria-label={member.onViolet ? 'On Violet' : 'Not yet on Violet'}
                              data-cy="org-on-violet"
                              hideFalseIcon
                              value={member.onViolet}
                            />
                          ) : (
                            <Badge
                              color={member.onViolet ? 'green' : 'red'}
                              size="small"
                            >
                              {member.onViolet ? 'Yes' : 'No'}
                            </Badge>
                          )}
                        </S.CenterDataCell>
                      </Cell>
                      {/* @ts-expect-error */}
                      <Cell exclude={isVioEnabled !== true}>
                        {isVioEnabled === true && (
                          <S.CenterDataCell>
                            <BooleanIcon
                              aria-label={
                                member.verifiedInclusive
                                  ? 'Verified Inclusive Organization'
                                  : 'Not yet verified'
                              }
                              data-cy="verified-inclusive"
                              hideFalseIcon
                              value={member.verifiedInclusive}
                            />
                          </S.CenterDataCell>
                        )}
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </S.ProviderOrganizationsTable>
              <ViewDetailsButton
                data-cy="providers-organizations-view-details-button"
                linkToPage={NETWORK_INCLUSIVITY_URL_W_PARAMS}
                overrideText="View all organizations"
              />
            </>
          ) : (
            <S.EmptyState>No organizations found.</S.EmptyState>
          )}
        </S.SingleTable>
      </S.TableWrapper>
    </>
  );
};

export default MyNetwork;
