import { filterNameToKey, keyToFilterName } from 'src/utils/filterUtils';

const organizationKeysToFilterNames = [
  {
    filterName: 'Provider',
    key: 'full_name'
  },
  {
    filterName: 'Language',
    key: 'language'
  },
  {
    filterName: 'Community',
    key: 'has_scored'
  }
];

export const organizationFiltersToSearch = (key: string, value: string) =>
  filterNameToKey(organizationKeysToFilterNames, key, value);

export const organizationSearchToFilters = (key: string, value: string) =>
  keyToFilterName(organizationKeysToFilterNames, key, value);
