import { GridNode } from '@react-types/grid';
import clsx from 'clsx';
import { PropsWithChildren, useRef } from 'react';
import { mergeProps, useFocusRing, useHover, useTableRow } from 'react-aria';
import { TableState } from 'react-stately';

import * as S from './styles';

interface Props {
  hasLinks?: boolean;
  item: GridNode<object>;
  onRowClick?: (key: string) => void;
  state: TableState<object>;
}

const BodyRow = ({
  children,
  hasLinks = false,
  item,
  onRowClick,
  state
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const isSelected = state.selectionManager.isSelected(item.key);
  const onClickAction = onRowClick === undefined ? (_key: string) => {} : onRowClick;
  const { isPressed, rowProps } = useTableRow(
    {
      node: item
    },
    state,
    ref
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  const { hoverProps, isHovered } = useHover({});

  return (
    <S.BodyRow
      className={clsx(
        { 'has-row-action': onRowClick !== undefined },
        { 'has-links': hasLinks },
        { 'is-hovered': isHovered },
        { 'is-focused': isFocusVisible },
        { 'is-selected': isSelected },
        { 'is-pressed': isPressed }
      )}
      {...mergeProps(rowProps, hoverProps, focusProps, item.props)}
      ref={ref}
      onClick={() => onClickAction(item.key.toString())}
    >
      {children}
    </S.BodyRow>
  );
};

export default BodyRow;
