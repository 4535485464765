import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

export const DataReportRow = styled.div<DataReportRowProps>`
  display: grid;
  grid-gap: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${({ $direction, $layout }) => {
      if ($direction === 'horizontal') {
        if ($layout === 'single') {
          return 'grid-template-columns: 1fr';
        } else if ($layout === 'fifty-fifty') {
          return 'grid-template-columns: 2fr 2fr;';
        } else if ($layout === 'thirds') {
          return 'grid-template-columns: 1fr 1fr 1fr;';
        } else if ($layout === 'thirds-last-wide') {
          return 'grid-template-columns: 1fr 1fr 1.5fr;';
        } else if ($layout === 'two-thirds') {
          return 'grid-template-columns: 2fr 1fr;';
        } else if ($layout === 'fifths') {
          return 'grid-template-columns: 1fr 1fr 1fr 1fr 1fr;';
        } else {
          return 'grid-template-columns: 1fr 1fr 1fr 1fr;';
        }
      } else {
        return 'grid-template-rows: min-content';
      }
    }}
  }
`;

interface DataReportRowProps {
  $direction: 'horizontal' | 'vertical';
  $layout:
    | 'fifths'
    | 'fifty-fifty'
    | 'quarters'
    | 'single'
    | 'thirds-last-wide'
    | 'thirds'
    | 'two-thirds';
}
