import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';

const EducationalResource = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();

  const params = useParams<{ educationalResourceId: string }>();
  const { educationalResourceId } = params;
  const [searchParams] = useSearchParams();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken } = useUser();

  useEffect(() => {
    (async () => {
      const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/users/dashboard/courses/${educationalResourceId}`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const {
          data: { link }
        } = (await response.json()) as APIUsersDashboardCoursesCourseId;

        const prepend = link.includes('?') ? '&' : '?';
        const fullLink =
          searchParams.get('redirect_uri') !== null
            ? `${link}${prepend}redirect_uri=${searchParams.get('redirect_uri')}`
            : link;
        window.location.replace(fullLink);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    })();
  }, [
    bearerToken,
    educationalResourceId,
    openErrorModalDialog,
    openSignedOutModalDialog,
    searchParams
  ]);

  return null;
};

export default EducationalResource;
