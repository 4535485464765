import { ReactComponent as WarningIcon } from '@material-design-icons/svg/round/warning.svg';
import { VisuallyHidden } from 'react-aria';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import UnstyledButton from '../../UnstyledButton';

export const Caption = styled.div`
  margin-top: var(--space-1);
  ${TEXT_STYLES.bodyCopySmall}
`;

export const DateErrorContainer = styled(UnstyledButton)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const DateErrorIcon = styled(WarningIcon)`
  width: 1.125rem;
  height: auto;
  fill: var(--app-status-error);
`;

export const DateField = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  display: flex;
  width: 100%;
  height: 2.25rem;
  align-items: center;
  padding: calc(0.5rem - 1px) calc(1rem - 1px);
  border: 1px solid var(--gray-dark-medium);
  background-color: var(--white-light);
  border-radius: 0.25rem;

  &.is-focus-within {
    border-width: 2px;
    border-color: var(--brand-bright);
    padding: calc(0.5rem - 2px) calc(1rem - 2px);
  }

  &.is-invalid {
    border-color: var(--app-status-error);

    &.is-focus-within {
      border-width: 2px;
      border-color: var(--app-status-error);
      padding: calc(0.5rem - 2px) calc(1rem - 2px);
    }
  }
`;

export const DatePicker = styled.div`
  position: relative;

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 8.25rem;
  }
`;

export const DatePickerValidation = styled.input`
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const DatePickerValidationContainer = styled(VisuallyHidden)`
  bottom: 0;
  left: 1.5rem;
`;

export const DateWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
`;

export const Group = styled.div`
  display: flex;
`;

export const Label = styled.label`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
  display: block;
  margin-bottom: var(--space-2);
`;

export const Segment = styled.div`
  text-align: end;

  &.placeholder {
    color: var(--gray-dark-dark);
    text-transform: uppercase;
  }

  &:focus-visible {
    outline-width: 0;
    background-color: var(--gray-light-medium);
  }
`;
