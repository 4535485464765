import { cloneElement, PropsWithChildren, ReactElement, useRef } from 'react';
import { Overlay, useModalOverlay, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import Dialog from './Dialog';
import * as S from './styles';

interface Props {
  triggerElement: ReactElement;
}

const ModalDialog = ({ children, triggerElement }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  const state = useOverlayTriggerState({});
  const { overlayProps, triggerProps } = useOverlayTrigger({ type: 'dialog' }, state);
  const { modalProps, underlayProps } = useModalOverlay({ isDismissable: true }, state, ref);

  return (
    <>
      {cloneElement(triggerElement, triggerProps)}
      {state.isOpen && (
        <Overlay>
          <S.Underlay {...underlayProps}>
            <S.Modal
              {...modalProps}
              ref={ref}
            >
              <Dialog
                {...overlayProps}
                aria-label="Proficiency definitions"
                state={state}
              >
                {children}
              </Dialog>
            </S.Modal>
          </S.Underlay>
        </Overlay>
      )}
    </>
  );
};

export default ModalDialog;
