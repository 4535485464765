import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back.svg';
import { ReactComponent as ArrowForwardIcon } from '@material-design-icons/svg/round/arrow_forward.svg';
import { AriaButtonProps } from 'react-aria';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import UnstyledButton from '../UnstyledButton';

const arrowStyles = `
  width: 20px;
  height: 20px;
  fill: currentColor;
`;

export const ArrowBack = styled(ArrowBackIcon)`
  ${arrowStyles}
`;

export const ArrowNext = styled(ArrowForwardIcon)`
  ${arrowStyles}
`;

interface PageItemProps {
  $itemType: 'arrow' | 'page';
}

export const PageItem = styled.li<PageItemProps>`
  height: 2.5rem;

  ${({ $itemType }) => {
    if ($itemType === 'page') {
      return `
        border-left: 1px solid var(--gray-200);
        border-right: 1px solid var(--gray-200);
      `;
    }
  }}
`;

interface LinkProps extends AriaButtonProps {
  $itemType: 'arrow' | 'page';
}

export const PageItemWrapper = styled.div`
  display: flex;
`;

export const PageLink = styled(UnstyledButton)<LinkProps>`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-500);
  align-items: center;
  justify-content: center;
  display: flex;

  &.is-active {
    color: var(--purple-400);
    background-color: var(--light-purple-50);
    font-weight: bold;
  }

  &.is-disabled {
    color: var(--gray-300);

    &:hover {
      cursor: not-allowed;
      background: var(--gray-100);
    }
  }

  &.is-hovered {
    background: var(--gray-100);
  }

  ${({ $itemType }) => {
    if ($itemType === 'arrow') {
      return `
        padding: 0.65rem 0.6rem;
      `;
    } else {
      return `
        padding: 0.65rem 1rem;
      `;
    }
  }}
`;

export const Pagination = styled.ul`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
`;
