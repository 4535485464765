import styled from 'styled-components';

export const UnstyledToggleButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  &.is-focused {
    outline: revert;
  }
`;
