import clsx from 'clsx';
import { useRef } from 'react';
import { AriaSearchFieldProps, useSearchField } from 'react-aria';
import { useSearchFieldState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaSearchFieldProps {
  caption?: string;
  className?: string;
}

const SearchField = ({ caption, className, ...ariaSearchFieldProps }: Props) => {
  const { description, isDisabled, isRequired = false, label } = ariaSearchFieldProps;

  const ref = useRef<HTMLInputElement>(null);

  const state = useSearchFieldState(ariaSearchFieldProps);

  const {
    descriptionProps,
    errorMessageProps,
    inputProps,
    isInvalid,
    labelProps,
    validationErrors
  } = useSearchField(ariaSearchFieldProps, state, ref);

  return (
    <S.SearchField className={clsx(className, { 'is-disabled': isDisabled })}>
      {isNonEmptyString(label) && (
        <S.Label {...labelProps}>
          {label}
          {isRequired && '*'}
          {isNonEmptyString(caption) && <S.Caption>{caption}</S.Caption>}
        </S.Label>
      )}
      <S.Container>
        <S.Input
          ref={ref}
          data-hj-allow
          {...inputProps}
        />
        <S.Icon aria-hidden="true" />
      </S.Container>
      {isInvalid ? (
        <S.ErrorMessage {...errorMessageProps}>{validationErrors.join(' ')}</S.ErrorMessage>
      ) : (
        isNonEmptyString(description) && (
          <S.Description {...descriptionProps}>{description}</S.Description>
        )
      )}
    </S.SearchField>
  );
};

export default SearchField;
