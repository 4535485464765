import Confetti from 'react-confetti';

const shapes = ['circle', 'star', 'rectangle'];

function drawCircle(ctx: CanvasRenderingContext2D, x: number, y: number) {
  ctx.beginPath();
  ctx.arc(x, y, 12, 0, 2 * Math.PI);
  ctx.fill();
}

function drawStar(
  ctx: CanvasRenderingContext2D,
  cx: number,
  cy: number,
  spikes: number,
  outerRadius: number,
  innerRadius: number
) {
  let rot = (Math.PI / 2) * 3;
  let x = cx;
  let y = cy;
  const step = Math.PI / spikes;

  ctx.beginPath();
  ctx.moveTo(cx, cy - outerRadius);
  for (let i = 0; i < spikes; i++) {
    x = cx + Math.cos(rot) * outerRadius;
    y = cy + Math.sin(rot) * outerRadius;
    ctx.lineTo(x, y);
    rot += step;

    x = cx + Math.cos(rot) * innerRadius;
    y = cy + Math.sin(rot) * innerRadius;
    ctx.lineTo(x, y);
    rot += step;
  }
  ctx.lineTo(cx, cy - outerRadius);
  ctx.closePath();
  ctx.fill();
}

interface This {
  h: number;
  newRan: boolean;
  shape: string;
  vx: number;
  vy: number;
  w: number;
}

function drawParticles(this: This, ctx: CanvasRenderingContext2D) {
  function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  if (!this.newRan) {
    this.w = getRandomInt(10, 30);
    this.h = getRandomInt(10, 30);
    this.newRan = true;
    this.shape = shapes[Math.floor(Math.random() * shapes.length)];
  }
  switch (this.shape) {
    case 'rectangle':
      ctx.fillRect(this.vx, this.vy, this.w, this.h);
      break;
    case 'circle':
      drawCircle(ctx, this.vx, this.vy);
      break;
    case 'star':
      drawStar(ctx, this.vx, this.vy, 5, 24, 12);
      break;
    case 'default':
      drawCircle(ctx, this.vx, this.vy);
      break;
  }
  ctx.restore();
}

const colors = [
  '#4f025e',
  '#83039c',
  '#e03fff',
  '#ee98ff',
  '#f4baff',
  '#ff80a3',
  '#ffaac2',
  '#ffcddb',
  '#ffcf26',
  '#ffdf6d',
  '#ffe99d',
  '#0fe289',
  '#70edb8',
  '#51d6ff',
  '#b7eeff',
  '#efeeef'
];

interface Props {
  height?: number;
  width?: number;
}

const VioletConfetti = ({ height, width }: Props) => (
  <Confetti
    colors={colors}
    data-cy="confetti"
    drawShape={drawParticles}
    gravity={0.08}
    height={height}
    initialVelocityY={{ max: 5, min: 0 }}
    numberOfPieces={120}
    width={width}
  />
);

export default VioletConfetti;
