import { Fragment, useRef } from 'react';
import { useProgressBar } from 'react-aria';

import * as S from './styles';

interface Props {
  className?: string;
  completedSteps: number;
  'data-cy'?: string;
  inProgressStep?: number;
  label?: string;
  totalSteps: number;
}

const MiniProgressBar = ({
  className,
  completedSteps,
  'data-cy': dataCy,
  inProgressStep,
  label,
  totalSteps
}: Props) => {
  const progressBar = useRef<HTMLDivElement>(null);
  const { progressBarProps } = useProgressBar({
    'aria-label': label ?? 'Progress',
    maxValue: totalSteps,
    minValue: 0
  });

  return (
    <S.ProgressBar
      data-cy={dataCy}
      {...progressBarProps}
      ref={progressBar}
      className={className}
    >
      {Array.from({ length: totalSteps }, (_, index) => (
        <Fragment key={`progress-block-${index}`}>
          <S.ProgressItem key={`step-${index}`}>
            <S.ProgressCircle
              className={
                index < completedSteps
                  ? 'complete'
                  : inProgressStep !== undefined && index === inProgressStep
                    ? 'in-progress'
                    : 'incomplete'
              }
              data-cy={`progress-circle-${index}`}
            >
              {index < completedSteps && <S.CheckIcon />}
            </S.ProgressCircle>
          </S.ProgressItem>
          {index < totalSteps - 1 && (
            <S.Divider
              key={`divider-${index}`}
              className={index < completedSteps - 1 ? 'green' : 'gray'}
            />
          )}
        </Fragment>
      ))}
    </S.ProgressBar>
  );
};

export default MiniProgressBar;
