import * as S from './styles';

interface Props {
  error?: Error;
}

const ErrorState = ({ error }: Props) => {
  const errorMessage = () => {
    if (error?.message.includes('SSO Error') === true) {
      return (
        <S.Hero
          copy="We’re aware of your login issue and will reach out to your organization administrator to resolve. We apologize for this inconvenience."
          header="SSO error."
          level={2}
          subcopy={
            <>
              If you continue to encounter this error, please contact{' '}
              <a href="mailto:support@joinviolet.com">support@joinviolet.com</a>
            </>
          }
        />
      );
    } else {
      return (
        <S.Hero
          copy="Please refresh this page."
          header="An error has occurred."
          level={2}
          subcopy={
            <>
              If you continue to encounter this error, please contact{' '}
              <a href="mailto:support@joinviolet.com">support@joinviolet.com</a>
            </>
          }
        />
      );
    }
  };

  return (
    <S.ErrorState data-cy="error-state">
      <S.Icon />
      {errorMessage()}
    </S.ErrorState>
  );
};

export default ErrorState;
