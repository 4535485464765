import { useCallback, useMemo } from 'react';
import useUser from 'src/hooks/useUser';
import useApiRequest, { filtersToParams } from 'src/pages/Dashboard/hooks/useApiRequest';

import { OrganizationAssignmentUsersFilters } from './useGetOrganizationAssignmentUsers';

type OrganizationAssignmentBulkReminderFilters = Omit<
  OrganizationAssignmentUsersFilters,
  'order_by' | 'page' | 'per_page'
>;

export interface APIOrganizationAssignmentBulkRemindersResponse {
  data?: {
    message: string;
    user_count: number;
  }; // would be undefined if response fails
}

interface UsePostOrganizationAssignmentBulkReminder {
  (): {
    sendAssignmentBulkReminder: (
      assignmentId: string,
      filters: OrganizationAssignmentBulkReminderFilters,
      organizationId: string
    ) => Promise<APIOrganizationAssignmentBulkRemindersResponse | undefined>;
  };
}

const usePostOrganizationAssignmentBulkReminder: UsePostOrganizationAssignmentBulkReminder = () => {
  const { reportError } = useApiRequest();
  const { bearerToken } = useUser();
  const fetchOptions: RequestInit = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${bearerToken}`
      },
      method: 'POST'
    }),
    [bearerToken]
  );

  const sendAssignmentBulkReminder = useCallback(
    async (
      assignmentId: string,
      filters: OrganizationAssignmentBulkReminderFilters,
      organizationId: string
    ) => {
      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/organizations/${organizationId}/assignments/${assignmentId}/send_bulk_reminder${params ? `?${params}` : ''}`;

      try {
        const response = await fetch(url, fetchOptions);

        if (response.ok) {
          const data = (await response.json()) as APIOrganizationAssignmentBulkRemindersResponse;
          return data;
        } else {
          const json = (await response.json()) as { errors?: string[] };
          reportError(json.errors);
        }
      } catch (error) {
        reportError(error);
      }
    },
    [reportError, fetchOptions]
  );

  return {
    sendAssignmentBulkReminder
  };
};

export default usePostOrganizationAssignmentBulkReminder;
