import clsx from 'clsx';

import * as S from './styles';

interface Props {
  ariaLabel?: string;
  className?: string;
  'data-cy'?: string;
  hideFalseIcon?: boolean;
  value: boolean;
}

const BooleanIcon = ({
  ariaLabel,
  className,
  'data-cy': dataCy,
  hideFalseIcon = false,
  value
}: Props) =>
  value ? (
    <S.CheckIcon
      aria-label={ariaLabel}
      className={clsx(className, 'checked')}
      data-cy={dataCy}
    />
  ) : hideFalseIcon ? (
    <S.DashIcon
      aria-label={ariaLabel}
      className={clsx(className, 'not-applicable')}
      data-cy={dataCy}
    />
  ) : (
    <S.XIcon
      aria-label={ariaLabel}
      className={clsx(className, 'unchecked')}
      data-cy={dataCy}
    />
  );

export default BooleanIcon;
