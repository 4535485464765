import Button from 'src/components/Buttons/Button';
import TextField from 'src/components/FormFields/TextField';
import UnstyledButton from 'src/components/UnstyledButton';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const BodyText = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
`;

export const EmailField = styled(TextField)`
  margin-top: 1rem;
`;

export const LoginLink = styled(UnstyledButton)`
  color: var(--purple-400);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SmallText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-800);
  margin-top: 0.5rem;
`;

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

export const Title = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel2BoldRegular}
  margin: 0 0 0.5rem;
`;
