import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

const borderWidth = '1px';
const borderWidthWhenFocused = '2px';
const horizontalPadding = '0.75rem';
const verticalPadding = '0.5rem';

export const DemographicCompositionNumberField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

export const Group = styled.div<GroupProps>`
  display: flex;
  border-style: solid;
  border-radius: 0.375rem;
  overflow: hidden;

  ${({ $isFocused, $isInvalid }) => {
    if ($isFocused) {
      return `
        border-width: ${borderWidthWhenFocused};
        border-color: ${$isInvalid ? 'var(--error-500)' : 'var(--gray-200)'};

        ${Input} {
          padding:
            calc(${verticalPadding} - (${borderWidthWhenFocused} - ${borderWidth}))
            ${horizontalPadding}
            calc(${verticalPadding} - (${borderWidthWhenFocused} - ${borderWidth}))
            calc(${horizontalPadding} - (${borderWidthWhenFocused} - ${borderWidth}));
        }

        ${Suffix} {
          padding:
            calc(${verticalPadding} - (${borderWidthWhenFocused} - ${borderWidth}))
            calc(${horizontalPadding} - (${borderWidthWhenFocused} - ${borderWidth}))
            calc(${verticalPadding} - (${borderWidthWhenFocused} - ${borderWidth}))
            ${horizontalPadding};
        }
      `;
    } else {
      return `
        border-width: ${borderWidth};
        border-color: ${$isInvalid ? 'var(--error-500)' : 'var(--gray-200)'};

        ${Input} {
          padding: ${verticalPadding} ${horizontalPadding};
        }

        ${Suffix} {
          padding: ${verticalPadding} ${horizontalPadding};
        }
      `;
    }
  }}
`;

interface GroupProps {
  $isFocused: boolean;
  $isInvalid: boolean;
}

export const Input = styled.input`
  flex-grow: 1;
  width: 3rem;
  max-width: 3rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  border: none;
  outline: none;
`;

export const Suffix = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  background-color: var(--gray-200);
  color: var(--gray-800);
  white-space: nowrap;
`;
