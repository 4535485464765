import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg';
import { ReactComponent as InfoIcon } from '@material-design-icons/svg/round/info.svg';
import Checkbox from 'src/components/FormFields/Checkbox';
import NumberField from 'src/components/Table/TableNumberField';
import UnstyledButton from 'src/components/UnstyledButton';
import {
  DESKTOP_BREAKPOINT_RANGE,
  FOCUS_STYLES,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from 'src/constants';
import styled from 'styled-components';

interface DeleteButtonIconProps {
  $fill: string;
}

interface NestedTableHeaderCellProps {
  $center?: boolean;
}

interface BodyCellProps {
  $area?: string;
  $center: boolean;
  $mobileAlignment?: 'left' | 'right';
}

export const AveragePanelSizeField = styled(NumberField)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 81.75%;

    input {
      text-align: start !important;
    }
  }
`;

export const BodyCell = styled.td<BodyCellProps>`
  all: unset;
  box-sizing: border-box;

  &:first-child {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
  }

  &:last-child {
    grid-column: 1 / -1;
  }

  &:not(:first-child, :last-child) {
    padding: 0.75rem;
  }

  ${({ $center = false }) =>
    $center &&
    `
    display: grid;
    place-content: center;
    height: 2.25rem;
    box-sizing: content-box;
  `}
`;

export const BodyFieldElement = styled.div<BodyCellProps>`
  all: unset;
  box-sizing: border-box;

  &.current-role-cell {
    max-width: 7.5rem;
    align-self: start;
    margin-top: 1.75rem;
  }

  &.header-cell {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    min-height: unset;
  }

  &:last-child {
    grid-column: 1 / -1;
    min-height: 0;
  }

  &:not(:first-child, :last-child) {
    padding: 0.75rem;
  }

  ${({ $center = false }) =>
    $center &&
    `
    display: grid;
    place-content: center;
    height: 2.25rem;
    box-sizing: content-box;
  `}

  ${({ $area }) =>
    $area !== undefined &&
    `
    grid-area: ${$area};
  `}

  ${({ $mobileAlignment }) => {
    if ($mobileAlignment === 'left') {
      return `
        justify-content: start;
      `;
    } else if ($mobileAlignment === 'right') {
      return `
        justify-content: end;
      `;
    }
  }}
`;

export const CheckboxLabel = styled.span`
  ${TEXT_STYLES.formsLabelMediumSmallCaps}
  color: var(--purple-400);
`;

export const CurrentCheckbox = styled(Checkbox)``;

export const DateEnDash = styled.span`
  position: absolute;
  top: 0.6rem;
  left: 50%;
  transform: translateX(-50%);

  ${MOBILE_BREAKPOINT_RANGE} {
    display: none;
  }
`;

export const DateFields = styled.div`
  display: grid;
  column-gap: 1.25rem;
  position: relative;

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
    row-gap: 0.75rem;

    > div > div:first-child {
      ${TEXT_STYLES.formsLabelMediumSmallCaps}
      color: var(--purple-400);
      flex-grow: 1;
    }
  }
`;

interface NestedTableRootProps {
  $isVisible: boolean;
}

export const DeleteButton = {
  Icon: styled(DeleteIcon)<DeleteButtonIconProps>`
    display: block;
    /* stylelint-disable-next-line scss/operator-no-unspaced */
    fill: ${({ $fill }) => $fill};
  `,
  Root: styled(UnstyledButton)`
    &.is-focused {
      ${FOCUS_STYLES}
    }
  `
};

export const ExpandButton = {
  Icon: styled.svg`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  `,
  Root: styled(UnstyledButton)`
    display: flex;
    ${TEXT_STYLES.buttonTextRegular}
    color: var(--purple-400);
    align-items: center;

    &.is-focused {
      ${FOCUS_STYLES}
    }

    &.is-disabled {
      fill: var(--error-700);
      color: var(--error-700);
    }
  `
};

export const InfoButton = {
  Icon: styled(InfoIcon)`
    display: block;
    width: 1rem;
    height: 1rem;
    fill: currentcolor;
  `,
  Root: styled(UnstyledButton)`
    &.is-focused {
      ${FOCUS_STYLES}
    }
  `
};

export const Label = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 0.13rem;
  ${TEXT_STYLES.formsLabelMediumSmallCaps}
  color: var(--purple-400);
`;

export const NestedBodyElement = styled.div`
  padding: 0.75rem;

  & > div:first-child {
    margin-bottom: 0.5rem;
  }
`;

export const NestedCard = styled.div<NestedTableRootProps>`
  ${({ $isVisible }) => {
    if ($isVisible) {
      return `
        display: block;
      `;
    } else {
      return `
        display: none;
      `;
    }
  }}
`;

export const NestedTable = {
  Body: styled.tbody`
    all: unset;
    box-sizing: border-box;
    display: block;
  `,
  BodyCell: styled.td`
    all: unset;
    box-sizing: border-box;
    padding: 0.75rem;
  `,
  BodyRow: styled.tr`
    all: unset;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  `,
  Header: styled.thead`
    all: unset;
    box-sizing: border-box;
    display: block;
  `,
  HeaderCell: styled.th<NestedTableHeaderCellProps>`
    all: unset;
    box-sizing: border-box;
    padding: 0.375rem 0.75rem;

    ${({ $center = false }) =>
      $center &&
      `
      text-align: center;
    `}
  `,
  HeaderRow: styled.tr`
    all: unset;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `,
  Root: styled.table<NestedTableRootProps>`
    all: unset;
    box-sizing: border-box;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    ${({ $isVisible }) => {
      if ($isVisible) {
        return `
        display: block;
      `;
      } else {
        return `
        display: none;
      `;
      }
    }}
  `
};

export const PercentageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    > div:first-child {
      justify-content: start;
    }

    > div:last-child {
      justify-content: end;
    }
  }

  @media (width <= 500px) {
    display: flex;
    flex-wrap: wrap;

    > div {
      justify-content: start !important;
      width: 100%;
    }
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  width: 18.75rem;
`;
