import { ReactComponent as ArrowForwardIcon } from '@material-design-icons/svg/round/arrow_forward.svg';
import styled from 'styled-components';

import UnstyledButton from '../../components/UnstyledButton';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../constants';

import { ReactComponent as EmptyState1 } from './images/empty-state-1.svg';
import { ReactComponent as EmptyState2 } from './images/empty-state-2.svg';

export const Benchmarks = {
  Description: {
    Anchor: styled.a`
      text-decoration: none;
    `,
    Root: styled.div`
      ${TEXT_STYLES.bodyCopyRegular}
      color: var(--gray-800);

      ${MOBILE_BREAKPOINT_RANGE} {
        margin-bottom: var(--space-3);
        padding-top: var(--space-7);
        padding-right: var(--space-7);
        padding-left: var(--space-7);
      }

      ${DESKTOP_BREAKPOINT_RANGE} {
        max-width: 54rem;
        margin-bottom: var(--space-2);
        padding-right: var(--space-6);
        padding-left: var(--space-6);
      }
    `
  },
  KeepGrowing: {
    Buttons: styled.div`
      display: grid;

      ${MOBILE_BREAKPOINT_RANGE} {
        row-gap: var(--space-3);
      }

      ${DESKTOP_BREAKPOINT_RANGE} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: var(--space-2);
      }
    `,
    Root: styled.div`
      display: grid;
      padding: 1rem 1rem 1rem 1.5rem;
      background-color: #fbfbfb;
      border-radius: 2rem;

      ${MOBILE_BREAKPOINT_RANGE} {
        row-gap: var(--space-7);
        margin-top: var(--space-3);
      }

      ${DESKTOP_BREAKPOINT_RANGE} {
        grid-template-columns: auto 1fr;
        column-gap: var(--space-10);
        align-items: center;
        margin-top: var(--space-2);
      }
    `,
    Text: styled.div`
      ${TEXT_STYLES.headlineSectionLevel2Regular}
      color: var(--gray-800);
    `
  },
  Root: styled.div`
    display: grid;

    ${MOBILE_BREAKPOINT_RANGE} {
      row-gap: var(--space-5);
      margin-bottom: var(--space-4);
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      row-gap: var(--space-4);
      margin-bottom: var(--space-3);
      padding: var(--space-8) var(--space-4) var(--space-4);
      border: 1px solid #e6daf2;
      border-radius: 2rem;
    }
  `
};

export const EmptyState = {
  Anchor: styled.a`
    color: inherit;
  `,
  Image1: styled(EmptyState1)`
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  `,
  Image2: styled(EmptyState2)`
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  `,
  Root: styled.div`
    margin-bottom: 33px;
    padding: 1.875rem 1.5625rem 2.8125rem;
    background-color: #f8f0f9;
    border-radius: 0.625rem;
  `,
  Text: styled.p`
    ${TEXT_STYLES.bodyCopyRegular}
    margin-bottom: 3.25rem;
    text-align: center;
  `
};

export const ExploreMoreCollectionsLink = {
  CtaText: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Root: styled(UnstyledButton)`
    margin-top: 0.75rem;
    display: grid;
    border-radius: 1.5rem;
    background: var(--light-purple-25);
    transition:
      color 0.3s,
      background-color 0.3s;

    ${MOBILE_BREAKPOINT_RANGE} {
      align-content: space-between;
      justify-items: start;
      row-gap: 1.75rem;
      min-height: 229px;
      padding: 1.875rem 1.875rem 1.5625rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      grid-template-columns: 1fr auto;
      align-items: center;
      column-gap: 2.125rem;
      min-height: 136px;
      padding-right: 1.75rem;
      padding-left: 1.25rem;
    }

    &.is-hovered {
      color: white;
      background-color: #b266c0;

      ${() => {
        const { styledComponentId: viewAllIconWrapperClassName } =
          ExploreMoreCollectionsLink.ViewAllIconWrapper as { styledComponentId: string };

        return `
          .${viewAllIconWrapperClassName} {
            background-color: white;

            & > svg > path {
              fill: #e03fff;
              stroke: #e03fff;
            }
          }
        `;
      }}
    }

    &.is-pressed {
      background-color: #83039c;
    }
  `,
  Subtitle: styled.p`
    ${TEXT_STYLES.bodyCopyRegular}
    margin: 0;
  `,
  Text: styled.p`
    ${TEXT_STYLES.buttonTextLarge}
    margin-bottom: 0;

    ${MOBILE_BREAKPOINT_RANGE} {
      margin-top: 0.875rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      margin-top: 0.6875rem;
    }
  `,
  Title: styled.h3`
    ${TEXT_STYLES.headlineSectionLevel1Regular}
    margin-bottom: 1.5rem;
  `,
  ViewAllIcon: styled(ArrowForwardIcon)`
    height: auto;

    & > path {
      transition:
        fill 0.3s,
        stroke 0.3s;
      stroke-width: 0;
      fill: var(--white-light);
    }

    ${MOBILE_BREAKPOINT_RANGE} {
      width: 1.5rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      width: 3.25rem;
    }
  `,
  ViewAllIconWrapper: styled.div`
    display: grid;
    place-content: center;
    background-color: var(--purple-400);
    border-radius: 100%;
    transition: background-color 0.3s;

    ${MOBILE_BREAKPOINT_RANGE} {
      width: 33px;
      height: 33px;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      width: 80px;
      height: 80px;
    }
  `
};

export const Main = styled.main`
  max-width: 1420px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${MOBILE_BREAKPOINT_RANGE} {
    padding: var(--space-7) var(--space-5) 120px;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    padding: var(--space-8) var(--space-9) var(--space-16);
  }
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100dvh;
`;

export const Subtitle = styled.h2`
  ${TEXT_STYLES.headlineStandardLevel2Regular}
  color: var(--purple-400);

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-5);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-4);
  }
`;

export const Title = styled.h1`
  ${TEXT_STYLES.displaySubheadlineRegBold}
  color: var(--purple-400);
  margin-top: 0.25rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-7);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-3);
  }
`;

export const YourBenchmarksDescription = {
  Anchor: styled.a`
    color: inherit;
  `,
  Root: styled.p`
    margin-bottom: var(--space-7);
    ${TEXT_STYLES.bodyCopyRegular}
  `
};

export const YourNextStepLink = {
  Root: styled(UnstyledButton)`
    margin-bottom: var(--space-7);
    background-color: #f8f0f9;
    transition:
      color 0.3s,
      background-color 0.3s;

    ${MOBILE_BREAKPOINT_RANGE} {
      display: block;
      padding: 1.5rem 2rem;
      border-radius: 0.625rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      column-gap: 2.125rem;
      padding: 2.375rem 2.4375rem 2.375rem 2.6875rem;
      border-radius: 0.4375rem;
    }

    &.is-hovered {
      color: var(--white-light);
      background-color: #b266c0;

      ${() => {
        const { styledComponentId: startNowIconWrapperClassName } =
          YourNextStepLink.StartNowIconWrapper as { styledComponentId: string };

        return `
          .${startNowIconWrapperClassName} {
            background-color: var(--white-light);

            & > svg > path {
              fill: #e03fff;
              stroke: #e03fff;
            }
          }
        `;
      }}
    }

    &.is-pressed {
      background-color: #83039c;
    }
  `,
  StartNow: styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    column-gap: 17px;

    ${MOBILE_BREAKPOINT_RANGE} {
      justify-content: center;
    }
  `,
  StartNowIcon: styled(ArrowForwardIcon)`
    width: 23px;
    height: auto;

    & > path {
      transition:
        fill 0.3s,
        stroke 0.3s;
    }
  `,
  StartNowIconWrapper: styled.div`
    display: grid;
    place-content: center;
    width: 49px;
    height: 49px;
    background-color: #e03fff;
    border-radius: 100%;
    transition: background-color 0.3s;
  `,
  StartNowText: styled.div`
    ${TEXT_STYLES.bodyCopyLarge}
    margin-bottom: 0.125rem;
  `,
  Text: styled.p`
    ${MOBILE_BREAKPOINT_RANGE} {
      ${TEXT_STYLES.bodyCopyRegular}
      margin-bottom: 1.5rem;
      text-align: center;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      ${TEXT_STYLES.bodyCopyLarge}
      margin-bottom: 0;
    }
  `,
  Title: styled.p`
    ${TEXT_STYLES.headlineStandardLevel2BoldRegular}

    ${MOBILE_BREAKPOINT_RANGE} {
      margin-bottom: 1rem;
      text-align: center;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      margin-bottom: 0.6875rem;
    }
  `
};
