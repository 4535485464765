export interface NetworkProviderOrganization {
  id: string;
  name: string;
}

export const loadNetworkOrgMemberName = async (orgId: string, bearerToken: string) => {
  if (!orgId) {
    return '';
  }
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };

  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_PATH}/networks/organization_members/search?id=${orgId}`,
    { ...fetchOptions }
  );
  const json = (await res.json()) as { data: NetworkProviderOrganization[] };

  const resources = json.data;

  return resources[0].name;
};
