import { useEffect } from 'react';

import * as S from './styles';

const Invitation = () => {
  useEffect(() => {
    const pageSpinner = document.querySelector<HTMLDivElement>('#page-spinner');
    const pageSpinnerStyles = document.querySelector<HTMLStyleElement>('#page-spinner-styles');

    // If the App component is mounted a second time, which happens during development because of React Fast Refresh, the page spinner and its styles will already have been removed.
    if (pageSpinner && pageSpinnerStyles) {
      pageSpinner.remove();
      pageSpinnerStyles.remove();
    }
  }, []);

  return (
    <S.Invitation>
      <S.Icon />
      <S.Hero
        copy="Protecting your information is important to us. To keep your account secure, we’ve sent you a new link to join Violet and complete your profile."
        header="Your invite link has expired."
        level={2}
        subcopy={
          <>
            Didn’t receive a new email? Please reach out to{' '}
            <a href="mailto:support@joinviolet.com">support@joinviolet.com</a>
          </>
        }
      />
    </S.Invitation>
  );
};

export default Invitation;
