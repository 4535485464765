import Table from 'src/components/Table';
import styled from 'styled-components';

export const NetworkOrganizationsTable = styled(Table)<{ $vioEnabled: boolean }>`
  tr {
    display: grid;
    ${({ $vioEnabled }) => {
      if ($vioEnabled) {
        return `grid-template-columns: 1.25fr 1fr 0.5fr 1fr 0.7fr 0.7fr 0.7fr;`;
      } else {
        return `grid-template-columns: 1.5fr 1fr 0.75fr 1fr 1fr 1fr;`;
      }
    }}
  }

  tbody {
    display: grid;
    gap: 0.5rem;

    tr {
      border-radius: 0.5rem;
      height: 3.75rem; /* 60px */
      align-items: center;
    }
  }

  ${({ $vioEnabled }) => {
    if ($vioEnabled) {
      return `
      tr th:nth-child(5) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  tr th:nth-child(6) {
    background-color: var(--light-purple-50);
  }

  tr th:nth-child(7) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
      `;
    } else {
      return `
tr th:nth-child(4) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  tr th:nth-child(5) {
    background-color: var(--light-purple-50);
  }

  tr th:nth-child(6) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }      `;
    }
  }}
`;
