import { Children, cloneElement } from 'react';

import * as S from './styles';

interface Props {
  align?: 'left' | 'right' | 'space-between';
  children: JSX.Element | JSX.Element[];
  className?: string;
  direction?: 'column' | 'row';
  size?: 'large' | 'regular' | 'small';
}

const ButtonGroup = ({
  align = 'left',
  children,
  className,
  direction = 'row',
  size = 'regular'
}: Props) => (
  <S.ButtonGroup className={`${className} ${direction} ${size} ${align}`}>
    {Children.map(children, (child, index) => cloneElement(child, { key: index, size }))}
  </S.ButtonGroup>
);

export default ButtonGroup;
