import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { FormEventHandler, useState } from 'react';
import { AriaTextFieldProps } from 'react-aria';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

const OldSignUpForm = () => {
  const { loginWithRedirect } = useAuth0();

  const [isSending, setIsSending] = useState<boolean>(false);
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSignUpButton: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    if (!emailAddress || emailAddress.trim() === '') {
      setErrorMessage('Please enter an email address.');
      return;
    }
    setIsSending(true);

    const url: RequestInfo = `${
      process.env.REACT_APP_API_V2_BASE_PATH
    }/profile/sign_up?email=${emailAddress.trim().replaceAll('+', '%2B')}`;

    const options: RequestInit = {
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        const responseBody = (await response.json()) as { error?: string };
        const errorMessage = responseBody['error']!;
        if (errorMessage === 'Email not found or not in created status') {
          setErrorMessage(
            'This email is not associated with an organization on Violet. Make sure your email address is correct, or reach out to your administrator to get access.'
          );
        } else if (errorMessage === 'Email already associated with an existing activated account') {
          setErrorMessage(
            'This email is associated with an existing account, please log in or reach out to support@joinviolet.com.'
          );
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      } else {
        setErrorMessage(undefined);
        setEmailSuccess(true);
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again or contact support@joinviolet.com.');
      Sentry.captureException(error);
    }
    setIsSending(false);
  };

  const handleEmailAddressChange: AriaTextFieldProps['onChange'] = value => {
    setErrorMessage(undefined);
    setEmailAddress(value);
  };

  return emailSuccess ? (
    <>
      <S.Title>Check your inbox.</S.Title>
      <S.BodyText data-cy="success-response">
        Your security comes first, so we’ve sent a link to {emailAddress}. If you don’t see it, be
        sure to check your spam folder.
      </S.BodyText>
    </>
  ) : (
    <form onSubmit={handleSignUpButton}>
      <S.Title>Create your account.</S.Title>
      <S.BodyText>
        Start building culturally responsive skills and contribute to a health care system that’s
        equitable for all.
      </S.BodyText>

      <S.EmailField
        aria-label="Email address"
        data-cy="email-field"
        errorMessage={errorMessage}
        isInvalid={isNonEmptyString(errorMessage)}
        onChange={handleEmailAddressChange}
        placeholder="Email address"
        type="email"
        validationBehavior="native"
        value={emailAddress}
      />
      <S.SubmitButton
        data-cy="submit-button"
        fullWidth
        isLoading={isSending}
        type="submit"
        variant="primary"
      >
        Submit
      </S.SubmitButton>
      <S.SmallText>
        Already have an account?{' '}
        <S.LoginLink onPress={() => loginWithRedirect({})}>Log in</S.LoginLink> now.
      </S.SmallText>
    </form>
  );
};

export default OldSignUpForm;
