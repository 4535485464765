import { ReactComponent as DownloadIcon } from '@material-symbols/svg-400/rounded/download.svg';
import { ReactComponent as ForwardMailIcon } from '@material-symbols/svg-400/rounded/outgoing_mail-fill.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { ToastState } from '@react-stately/toast';
import { Sortable } from '@react-types/shared';
import { useEffect, useRef, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { Item, Key, SortDescriptor, SortDirection, useOverlayTriggerState } from 'react-stately';
import ButtonWithMenu from 'src/components/Buttons/ButtonWithMenu';
import FilterDropdown from 'src/components/FilterDropdown';
import SearchField from 'src/components/FormFields/SearchField';
import Select from 'src/components/FormFields/Select';
import Pagination from 'src/components/Pagination';
import Spinner from 'src/components/Spinner';
import { VioletToast } from 'src/components/ToastProvider';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import useGetOrganizationAssignment from 'src/pages/Dashboard/hooks/useGetOrganizationAssignment';
import useGetOrganizationAssignmentUsers from 'src/pages/Dashboard/hooks/useGetOrganizationAssignmentUsers';
import useGetOrganizationAssignmentUsersCsv from 'src/pages/Dashboard/hooks/useGetOrganizationAssignmentUsersCsv';
import usePostOrganizationAssignmentBulkReminder, {
  APIOrganizationAssignmentBulkRemindersResponse
} from 'src/pages/Dashboard/hooks/usePostOrganizationAssignmentBulkReminder';
import { downloadCsv } from 'src/utils/downloadCsv';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import AssignmentEducationEngagementMetrics from './AssignmentEducationEngagementMetrics';
import AssignmentEmailReminderConfirmation from './AssignmentEmailReminderConfirmation';
import AssignmentMetaData from './AssignmentMetaData';
import AssignmentUserTable from './AssignmentUserTable';
import * as S from './styles';

interface Props {
  assignmentId: string;
  close: () => void;
  organizationId: string;
}

const ASSIGNMENT_USERS_PER_PAGE = 10;

const SEARCH_TO_FILTER_MAP = {
  'assignment_completed=false': 'Assignment status:Incomplete',
  'assignment_completed=true': 'Assignment status:Complete',
  'completed_onboarding=false': 'Completed onboarding:No',
  'completed_onboarding=true': 'Completed onboarding:Yes',
  'is_clinical=false': 'User type:Non-clinical',
  'is_clinical=true': 'User type:Clinical'
};

const FILTER_TO_SEARCH_MAP = Object.fromEntries(
  Object.entries(SEARCH_TO_FILTER_MAP).map(([key, value]) => [value, key])
);

const searchToFilters = (key: string, value: string) => {
  const searchKey = `${key}=${value}` as keyof typeof SEARCH_TO_FILTER_MAP;
  if (SEARCH_TO_FILTER_MAP[searchKey]) {
    return SEARCH_TO_FILTER_MAP[searchKey];
  } else {
    let mappedKey;
    switch (key) {
      case 'user_name':
        mappedKey = 'User name';
        break;
      case 'email':
        mappedKey = 'Email';
        break;
      default:
        mappedKey = key;
        break;
    }
    return `${mappedKey}:${value}`;
  }
};

const AssignmentModal = ({ assignmentId, close, organizationId }: Props) => {
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const toastState = useOutletContext();
  const {
    close: closeEmailReminderConfirmationModal,
    isOpen: emailReminderConfirmationModalIsOpen,
    open: openEmailReminderConfirmationModal
  } = useOverlayTriggerState({});

  const [isExporting, setIsExporting] = useState(false);
  const [isSendingReminders, setIsSendingReminders] = useState(false);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('user_order_by[column]') ?? 'completed_at',
    direction:
      searchParams.get('user_order_by[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('user_order_by[dir]')!
          ] as SortDirection)
  });
  const [page, setPage] = useState(Number(searchParams.get('user_page')) || 1);
  const [userName, setUserName] = useState<string>(searchParams.get('user_name') ?? '');
  const [email, setEmail] = useState<string>(searchParams.get('email') ?? '');
  const [isClinical, setIsClinical] = useState<string | 'false' | 'true'>(
    searchParams.get('is_clinical') ?? ''
  );
  const [assignmentCompleted, setAssignmentCompleted] = useState<string | 'false' | 'true'>(
    searchParams.get('assignment_completed') ?? ''
  );
  const [completedOnboarding, setCompletedOnboarding] = useState<string | 'false' | 'true'>(
    searchParams.get('completed_onboarding') ?? ''
  );
  const draftState = useRef<{
    assignment_completed: string | 'false' | 'true';
    completed_onboarding: string | 'false' | 'true';
    email: string;
    is_clinical: string | 'false' | 'true';
    userName: string;
  }>({
    assignment_completed: searchParams.get('assignment_completed') ?? '',
    completed_onboarding: searchParams.get('completed_onboarding') ?? '',
    email: searchParams.get('email') ?? '',
    is_clinical: searchParams.get('is_clinical') ?? '',
    userName: searchParams.get('user_name') ?? ''
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .map(([key, value]) => searchToFilters(key, value) as Key)
    )
  );

  const { assignment, isFetchingOrgAssignment, updateAssignmentFilters } =
    useGetOrganizationAssignment(organizationId, assignmentId, {
      assignment_completed: searchParams.get('assignment_completed') ?? undefined,
      completed_onboarding: searchParams.get('completed_onboarding') ?? undefined,
      email: searchParams.get('email') ?? undefined,
      is_clinical: searchParams.get('is_clinical') ?? undefined,
      user_name: searchParams.get('user_name') ?? undefined
    });
  const {
    isFetchingOrgAssignmentUsers,
    totalAssignmentUserPages,
    updateAssignmentUsersFilters,
    users
  } = useGetOrganizationAssignmentUsers(organizationId, assignmentId, {
    assignment_completed: searchParams.get('assignment_completed') ?? undefined,
    completed_onboarding: searchParams.get('completed_onboarding') ?? undefined,
    email: searchParams.get('email') ?? undefined,
    is_clinical: searchParams.get('is_clinical') ?? undefined,
    order_by: {
      column: searchParams.get('user_order_by[column]') ?? 'completed_at',
      dir: (searchParams.get('user_order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'desc'
    },
    page: searchParams.get('user_page') !== null ? Number(searchParams.get('user_page')) : 1,
    per_page: ASSIGNMENT_USERS_PER_PAGE,
    user_name: searchParams.get('user_name') ?? undefined
  });
  const { fetchAssignmentUsersCsv } = useGetOrganizationAssignmentUsersCsv();
  const { sendAssignmentBulkReminder } = usePostOrganizationAssignmentBulkReminder();

  const handlePageChange = (page: number) => {
    setPage(page);
    refreshURLparams(sortDescriptor, page, appliedFilters);
  };

  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    const hasChangedColumn = column !== sortDescriptor.column;
    let newDirection = hasChangedColumn ? 'descending' : direction;
    // below override default sort for certain columns
    if (
      hasChangedColumn &&
      (column === 'full_name' ||
        column === 'is_clinical' ||
        column === 'status' ||
        column === 'email')
    ) {
      newDirection = 'ascending';
    } else if (hasChangedColumn && (column === 'completed_at' || column === 'assignment_status')) {
      newDirection = 'descending';
    }
    setSortDescriptor({ column, direction: newDirection });
    if (page > 1) {
      setPage(1);
    }
    refreshURLparams({ column, direction: newDirection }, page, appliedFilters);
  };

  const handleUserNameChange = (value: string) => {
    draftState.current.userName = value;
  };

  const handleEmailChange = (value: string) => {
    draftState.current.email = value;
  };

  const handleIsClinicalChange = (value: Key) => {
    draftState.current.is_clinical = value.toString();
  };

  const handleAssignmentCompletedChange = (value: Key) => {
    draftState.current.assignment_completed = value.toString();
  };

  const handleCompletedOnboardingChange = (value: Key) => {
    draftState.current.completed_onboarding = value.toString();
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('User name:')) {
        setUserName('');
        draftState.current.userName = '';
      } else if (keyString.includes('Email:')) {
        setEmail('');
        draftState.current.email = '';
      } else if (keyString.includes('User type:')) {
        setIsClinical('');
        draftState.current.is_clinical = '';
      } else if (keyString.includes('Assignment status:')) {
        setAssignmentCompleted('');
        draftState.current.assignment_completed = '';
      } else if (keyString.includes('Completed onboarding:')) {
        setCompletedOnboarding('');
        draftState.current.completed_onboarding = '';
      }
    });
    if (page > 1) {
      setPage(1);
    }
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(sortDescriptor, 1, updatedFilters);
  };

  const preserveChanges = () => {
    setUserName(draftState.current.userName);
    setEmail(draftState.current.email);
    setIsClinical(draftState.current.is_clinical);
    setAssignmentCompleted(draftState.current.assignment_completed);
    setCompletedOnboarding(draftState.current.completed_onboarding);

    const updatedFilters = [
      `User name:${draftState.current.userName}`,
      `Email:${draftState.current.email}`,
      `User type:${draftState.current.is_clinical === 'true' ? 'Clinical' : draftState.current.is_clinical === 'false' ? 'Non-clinical' : ''}`,
      `Assignment status:${draftState.current.assignment_completed === 'true' ? 'Complete' : draftState.current.assignment_completed === 'false' ? 'Incomplete' : ''}`,
      `Completed onboarding:${draftState.current.completed_onboarding === 'true' ? 'Yes' : draftState.current.completed_onboarding === 'false' ? 'No' : ''}`
    ].filter(entry => !entry.includes('null') && isNonEmptyString(entry.split(':')[1]));
    setAppliedFilters(new Set(updatedFilters));
    if (page > 1) {
      setPage(1);
    }
    refreshURLparams(sortDescriptor, 1, new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    if (page > 1) {
      setPage(1);
    }
    setUserName('');
    setEmail('');
    setIsClinical('');
    setAssignmentCompleted('');
    setCompletedOnboarding('');
    setAppliedFilters(new Set());
    draftState.current = {
      assignment_completed: '',
      completed_onboarding: '',
      email: '',
      is_clinical: '',
      userName: ''
    };
    refreshURLparams(sortDescriptor, 1, new Set());
  };

  const refreshURLparams = (order: SortDescriptor, page: number, updatedFilters: Set<Key>) => {
    let filterString = Array.from(updatedFilters)
      .join('&')
      .replace('User name:', 'user_name=')
      .replace('Email:', 'email=');
    Object.entries(FILTER_TO_SEARCH_MAP).forEach(([key, value]) => {
      const regex = new RegExp(key, 'g');
      filterString = filterString.replace(regex, value);
    });
    /*
      We have to use pushSate here because this view is opened with pushState
      and using navigate would go to before the view was opened, skipping filter states
    */
    window.history.pushState(
      null,
      document.title,
      `/dashboard/my-organization/assignments/${assignmentId}?user_page=${page}&user_order_by[column]=${order.column}&user_order_by[dir]=${order.direction === 'ascending' ? 'asc' : 'desc'}${filterString ? `&${filterString}` : ''}`
    );
  };

  const downloadAssignmentUsersCSV = async () => {
    setIsExporting(true);

    try {
      const file = await fetchAssignmentUsersCsv(
        assignmentId,
        {
          assignment_completed:
            assignmentCompleted === ''
              ? undefined
              : (assignmentCompleted as 'false' | 'true' | undefined),
          completed_onboarding:
            completedOnboarding === ''
              ? undefined
              : (completedOnboarding as 'false' | 'true' | undefined),
          email,
          is_clinical: isClinical,
          user_name: userName
        },
        organizationId
      );

      if (file !== undefined) {
        const startDate =
          assignment?.startDate !== undefined ? assignment.startDate.toString() : undefined;
        const endDate =
          assignment?.endDate !== undefined ? assignment.endDate.toString() : undefined;
        await downloadCsv(
          file,
          `${assignment?.resourceName}${startDate !== undefined ? ` - ${startDate}` : ''}${endDate !== undefined ? `- ${endDate}` : ''}.csv`
        );
      }

      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
    }
  };

  const handleSendBulkReminders = async () => {
    setIsSendingReminders(true);

    try {
      const data = (await sendAssignmentBulkReminder(
        assignmentId,
        {
          assignment_completed:
            assignmentCompleted === ''
              ? undefined
              : (assignmentCompleted as 'false' | 'true' | undefined),
          completed_onboarding:
            completedOnboarding === ''
              ? undefined
              : (completedOnboarding as 'false' | 'true' | undefined),
          email,
          is_clinical: isClinical,
          user_name: userName
        },
        organizationId
      )) as APIOrganizationAssignmentBulkRemindersResponse | { errors?: string[] };
      if ('user_count' in data) {
        (toastState as ToastState<VioletToast>).add(
          {
            description: `Reminders sent successfully to ${data.user_count} users`,
            type: 'success'
          },
          { timeout: 8000 }
        );
      } else {
        throw new Error('Failed to send reminders');
      }
      setIsSendingReminders(false);
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description:
            'Reminders failed to send. If you continue to get this message, please contact support@joinviolet.com',
          type: 'error'
        },
        { timeout: 8000 }
      );
      setIsSendingReminders(false);
    }
  };

  const handleActionButtonSelection = (key: Key) => {
    if (key === 'export') {
      if (isExporting) {
        return;
      } else {
        downloadAssignmentUsersCSV();
      }
    } else {
      if (isSendingReminders) {
        return;
      } else {
        openEmailReminderConfirmationModal();
      }
    }
  };

  useEffect(() => {
    updateAssignmentUsersFilters({
      assignment_completed: assignmentCompleted,
      completed_onboarding: completedOnboarding,
      email,
      is_clinical: isClinical,
      order_by: {
        column: sortDescriptor.column.toString(),
        dir: sortDescriptor.direction === 'ascending' ? 'asc' : 'desc'
      },
      page,
      per_page: ASSIGNMENT_USERS_PER_PAGE,
      user_name: userName
    });
    updateAssignmentFilters({
      assignment_completed: assignmentCompleted,
      completed_onboarding: completedOnboarding,
      email,
      is_clinical: isClinical,
      user_name: userName
    });
  }, [
    sortDescriptor,
    page,
    email,
    assignmentCompleted,
    completedOnboarding,
    isClinical,
    userName,
    updateAssignmentUsersFilters,
    updateAssignmentFilters
  ]);

  useEffect(() => {
    /* Add listener for if URL changes
     *  to know if details modal should be closed
     *  when user navigates back in browser
     */
    const eventListener = () => {
      const latestSearchParams = new URLSearchParams(window.location.search);
      setAppliedFilters(
        new Set(
          Array.from(latestSearchParams.entries())
            .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
            .map(([key, value]) => searchToFilters(key, value) as Key)
        )
      );
      draftState.current = {
        assignment_completed: latestSearchParams.get('assignment_completed') ?? '',
        completed_onboarding: latestSearchParams.get('completed_onboarding') ?? '',
        email: latestSearchParams.get('email') ?? '',
        is_clinical: latestSearchParams.get('is_clinical') ?? '',
        userName: latestSearchParams.get('user_name') ?? ''
      };
      setSortDescriptor({
        column: latestSearchParams.get('user_order_by[column]') ?? 'completed_at',
        direction:
          latestSearchParams.get('user_order_by[dir]') === null
            ? 'descending'
            : ({ asc: 'ascending', desc: 'descending' }[
                latestSearchParams.get('user_order_by[dir]')!
              ] as SortDirection)
      });
      setPage(Number(latestSearchParams.get('user_page')) || 1);
      setAssignmentCompleted(latestSearchParams.get('assignment_completed') ?? '');
      setCompletedOnboarding(latestSearchParams.get('completed_onboarding') ?? '');
      setEmail(latestSearchParams.get('email') ?? '');
      setIsClinical(latestSearchParams.get('is_clinical') ?? '');
      setUserName(latestSearchParams.get('user_name') ?? '');
    };
    window.addEventListener('popstate', eventListener);

    return () => {
      // Clear listener when modal is unmounted
      window.removeEventListener('popstate', eventListener);
    };
    // run once -- rerunning this effect will break the listener
  }, []);

  return (
    <S.FullOverlay>
      <S.AnimationWrapper
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: width }}
        initial={{ opacity: 0, x: width }}
        transition={{ duration: 0.6 }}
      >
        <S.FullModal
          data-cy="assignment-modal"
          isDismissable
          isOpen
          onClose={close}
          title="Assignment details & user progress"
        >
          {isFetchingOrgAssignment ? (
            <Spinner withWrapper />
          ) : assignment !== undefined ? (
            <div>
              <S.AssignmentTitle data-cy="assignment-name">
                {assignment.resourceName}
              </S.AssignmentTitle>
              <AssignmentMetaData assignment={assignment} />

              <S.FiltersAndActionsWrapper>
                <FilterDropdown>
                  <FilterDropdown.Filters onApplyChanges={preserveChanges}>
                    <SearchField
                      aria-label="Search user"
                      data-cy="filter-by-name"
                      defaultValue={userName}
                      onChange={handleUserNameChange}
                      placeholder="Search user"
                    />
                    <SearchField
                      aria-label="Search email"
                      data-cy="filter-by-email"
                      defaultValue={email}
                      onChange={handleEmailChange}
                      placeholder="Search email"
                    />
                    <Select
                      aria-label="Filter by user type"
                      data-cy="filter-by-user-type"
                      defaultSelectedKey={isClinical}
                      onSelectionChange={handleIsClinicalChange}
                      placeholder="Filter by user type"
                    >
                      <Item key="">All user types</Item>
                      <Item key="true">Clinical</Item>
                      <Item key="false">Non-clinical</Item>
                    </Select>
                    <Select
                      aria-label="Filter by completion status"
                      data-cy="filter-by-completion-status"
                      defaultSelectedKey={assignmentCompleted}
                      onSelectionChange={handleAssignmentCompletedChange}
                      placeholder="Filter by completion status"
                    >
                      <Item key="">All completion statuses</Item>
                      <Item key="true">Complete</Item>
                      <Item key="false">Incomplete</Item>
                    </Select>
                    <Select
                      aria-label="Filter by onboarding"
                      data-cy="filter-by-onboarding"
                      defaultSelectedKey={completedOnboarding}
                      onSelectionChange={handleCompletedOnboardingChange}
                      placeholder="Filter by onboarding"
                    >
                      <Item key="">All onboarded statuses</Item>
                      <Item key="true">Onboarded</Item>
                      <Item key="false">Not onboarded</Item>
                    </Select>
                  </FilterDropdown.Filters>
                  <FilterDropdown.Tags
                    onClear={handleClearFilters}
                    onRemove={handleRemoveFilter}
                  >
                    {Array.from(appliedFilters).map(key => (
                      <Item key={key}>
                        {key.toString().split(':')[0]}: {key.toString().split(':')[1]}
                      </Item>
                    ))}
                  </FilterDropdown.Tags>
                </FilterDropdown>

                <ButtonWithMenu
                  data-cy="assignment-actions"
                  hideIconGap
                  label="Actions"
                  onAction={handleActionButtonSelection}
                  placement="bottom end"
                  trailingIcon={S.VerticalDotIcon}
                  variant="plain"
                >
                  <Item
                    key="export"
                    textValue="Export CSV"
                  >
                    <S.OptionWrapper>
                      <DownloadIcon />
                      Export CSV
                    </S.OptionWrapper>
                  </Item>
                  <Item
                    key="email"
                    textValue="Send reminders"
                  >
                    <S.OptionWrapper>
                      <ForwardMailIcon />
                      Send reminders
                    </S.OptionWrapper>
                  </Item>
                </ButtonWithMenu>
              </S.FiltersAndActionsWrapper>

              <S.SectionTitle>Engagement metrics</S.SectionTitle>
              <AssignmentEducationEngagementMetrics assignment={assignment} />
              <S.SectionTitle>Users</S.SectionTitle>
              {isFetchingOrgAssignmentUsers ? (
                <Spinner withWrapper />
              ) : (
                <>
                  <AssignmentUserTable
                    handleTableSortChange={handleTableSortChange}
                    sortDescriptor={sortDescriptor}
                    users={users}
                  />
                  <Pagination
                    currentPage={page}
                    setPage={handlePageChange}
                    totalPages={totalAssignmentUserPages}
                  />
                </>
              )}
            </div>
          ) : (
            <div>
              <S.AssignmentTitle data-cy="assignment-error">
                Assignment could not be loaded.
              </S.AssignmentTitle>
            </div>
          )}
        </S.FullModal>
      </S.AnimationWrapper>
      {emailReminderConfirmationModalIsOpen && (
        <AssignmentEmailReminderConfirmation
          close={closeEmailReminderConfirmationModal}
          onSendReminders={handleSendBulkReminders}
        />
      )}
    </S.FullOverlay>
  );
};

export default AssignmentModal;
