import { ReactComponent as BookmarkIcon } from '@material-design-icons/svg/filled/bookmark.svg';
import { ReactComponent as BookmarkBorderIcon } from '@material-design-icons/svg/outlined/bookmark_border.svg';
import { AriaToggleButtonProps } from 'react-aria';
import TooltipTrigger from 'src/components/TooltipTrigger';

import * as S from './styles';

interface Props {
  className?: string;
  isDisabled: NonNullable<AriaToggleButtonProps['isDisabled']>;
  isSelected: NonNullable<AriaToggleButtonProps['isSelected']>;
  onPress: NonNullable<AriaToggleButtonProps['onPress']>;
}

const Bookmark = ({ className, isDisabled, isSelected, onPress }: Props) => (
  <TooltipTrigger
    content={<S.Tooltip>{isSelected ? 'Click to remove bookmark' : 'Click to bookmark'}</S.Tooltip>}
    delay={0}
    theme="light"
  >
    <S.Bookmark
      className={className}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onPress={onPress}
    >
      {isSelected ? <BookmarkIcon data-cy="bookmark-icon" /> : <BookmarkBorderIcon />}
    </S.Bookmark>
  </TooltipTrigger>
);

export default Bookmark;
