import { ReactComponent as CloseIconSvg } from '@material-symbols/svg-400/rounded/close.svg';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../constants';
import UnstyledButton from '../UnstyledButton';

export const ButtonContainer = styled.div`
  border-top: 2px solid #f5f5f5;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled(UnstyledButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  right: 1rem;
  border-radius: 6.25rem;
  background: var(--gray-100);
  color: var(--gray-500);
  padding: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;

  &.is-hovered {
    background: var(--gray-200);
  }

  &.is-pressed {
    background: var(--gray-300);
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 2px;
  }
`;

export const CloseIcon = styled(CloseIconSvg)`
  fill: currentcolor;
  width: 1rem;
  height: 1rem;
`;

export const Dialog = styled.div`
  position: relative;
  max-height: 100%;
  border-radius: 1.5rem;
  background-color: var(--white-light);
  overflow-y: auto;
  outline: none;
  border: 1px solid var(--gray-200);

  ${MOBILE_BREAKPOINT_RANGE} {
    max-width: 37rem;
    background-color: var(--white-light);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 37rem;
    background-color: var(--white-light);
  }
`;

export const PathwayAnnouncementModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background: rgba(29, 41, 57, 0.5);
`;

export const PathwaysImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 1.5rem 1.5rem 0 0;
`;

export const Text = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
`;

export const TextBlock = styled.div`
  padding: 1rem 1.5rem 1.5rem;
  display: grid;
  row-gap: 1rem;
  color: var(--gray-800);
`;

export const Title = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Bold}
  margin: 0;
  color: var(--gray-800);
`;
