import { Item } from 'react-stately';

import getContentMediumIcon from './getContentMediumIcon';

const getContentMediumTag = (contentMedium: string): ReturnType<typeof Item> => {
  const Icon = getContentMediumIcon(contentMedium);

  return (
    <Item textValue={contentMedium}>
      <Icon
        aria-hidden="true"
        role="img"
      />
      {contentMedium}
    </Item>
  );
};

export default getContentMediumTag;
