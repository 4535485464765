import { rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '../../constants';

/* eslint-disable sort-exports/sort-exports */
export const WHITE_LIGHT_COLOR = '#ffffff';

export const BIPOC_FIRST_COLOR = '#000000';
export const BIPOC_SECOND_COLOR = '#b16f3f';
export const BIPOC_THIRD_COLOR = '#805434';

export const LGBQ_FIRST_COLOR = '#ff1d26';
export const LGBQ_SECOND_COLOR = '#fe931f';
export const LGBQ_THIRD_COLOR = '#ffcf26';
export const LGBQ_FOURTH_COLOR = '#06BD01';
export const LGBQ_FIFTH_COLOR = '#011898';
export const LGBQ_SIXTH_COLOR = '#750087';

export const TGNC_FIRST_COLOR = '#ff80a3';
export const TGNC_SECOND_COLOR = '#51d6ff';

export const BRAND_BRIGHT_COLOR = '#e03fff';
export const BRAND_BRIGHT_2_COLOR = '#ee30ff';
export const BRAND_TINTS_LIGHT_COLOR = '#fdfafe';
export const BRAND_TINTS_LIGHT_25_COLOR = '#f8f0ff';
export const BRAND_TINTS_LIGHT_50_COLOR = '#f1dcf9';
export const BRAND_TINTS_LIGHT_100_COLOR = '#edd2f8';
export const BRAND_SHADES_DARK_2_COLOR = '#440052';

export const GRAY_LIGHT_LIGHT_COLOR = '#f5f5f5';
export const GRAY_LIGHT_MEDIUM_COLOR = '#efeeef';
export const GRAY_LIGHT_DARK_COLOR = '#e1e0e1';

export const GRAY_DARK_LIGHT_COLOR = '#c8c7c8';
export const GRAY_DARK_MEDIUM_COLOR = '#9b9b9b';
export const GRAY_DARK_DARK_COLOR = '#6f6f6f';

export const BLACK_CHARCOAL_COLOR = '#434243';
export const BLACK_SOFTBLACK_COLOR = '#2c2c2c';
export const BLACK_BLACK_COLOR = '#161616';

export const APP_STATUS_ERROR_COLOR = '#d70a0a';
export const APP_STATUS_WARNING_COLOR = '#fce93d';
export const APP_STATUS_SUCCESS_COLOR = '#0fe289';

export const ACTION_CRITICAL_DEFAULT = '#d82c0d';
export const ACTION_CRITICAL_HOVERED = '#bc2200';
export const ACTION_CRITICAL_PRESSED = '#a21b00';

export const ACTION_COMPLETE_DEFAULT = '#1fd326';

export const PURPLE_25_COLOR = '#e6cdeb';
export const PURPLE_50_COLOR = '#d6abde';
export const PURPLE_100_COLOR = '#c181cd';
export const PURPLE_200_COLOR = '#ac57bd';
export const PURPLE_300_COLOR = '#982dac';
export const PURPLE_400_COLOR = '#83039c';
export const PURPLE_500_COLOR = '#6d0382';
export const PURPLE_600_COLOR = '#570268';
export const PURPLE_700_COLOR = '#41014e';
export const PURPLE_800_COLOR = '#2c0134';
export const PURPLE_900_COLOR = '#1a011f';

export const BRIGHT_PURPLE_25_COLOR = '#f9d9ff';
export const BRIGHT_PURPLE_50_COLOR = '#f5bfff';
export const BRIGHT_PURPLE_100_COLOR = '#ef9fff';
export const BRIGHT_PURPLE_200_COLOR = '#ea7fff';
export const BRIGHT_PURPLE_300_COLOR = '#e55fff';
export const BRIGHT_PURPLE_400_COLOR = '#e03fff';
export const BRIGHT_PURPLE_500_COLOR = '#bb34d4';
export const BRIGHT_PURPLE_600_COLOR = '#952aaa';
export const BRIGHT_PURPLE_700_COLOR = '#701f80';
export const BRIGHT_PURPLE_800_COLOR = '#4b1555';
export const BRIGHT_PURPLE_900_COLOR = '#2d0d33';

export const LIGHT_PURPLE_25_COLOR = '#fbf6fe';
export const LIGHT_PURPLE_50_COLOR = '#f9f0fd';
export const LIGHT_PURPLE_100_COLOR = '#f6e8fb';
export const LIGHT_PURPLE_200_COLOR = '#f3e1fa';
export const LIGHT_PURPLE_300_COLOR = '#f0d9f9';
export const LIGHT_PURPLE_400_COLOR = '#edd2f8';
export const LIGHT_PURPLE_500_COLOR = '#c5afcf';
export const LIGHT_PURPLE_600_COLOR = '#9e8ca5';
export const LIGHT_PURPLE_700_COLOR = '#76697c';
export const LIGHT_PURPLE_800_COLOR = '#4f4653';
export const LIGHT_PURPLE_900_COLOR = '#2f2a32';

export const GRAY_5_COLOR = '#e0e0e0';
export const GRAY_25_COLOR = '#fcfcfd';
export const GRAY_50_COLOR = '#f9fafb';
export const GRAY_100_COLOR = '#f2f4f7';
export const GRAY_200_COLOR = '#eaecf0';
export const GRAY_300_COLOR = '#d0d5dd';
export const GRAY_400_COLOR = '#98a2b3';
export const GRAY_500_COLOR = '#667085';
export const GRAY_600_COLOR = '#475467';
export const GRAY_700_COLOR = '#344054';
export const GRAY_800_COLOR = '#1d2939';
export const GRAY_900_COLOR = '#101828';

export const ORANGE_25_COLOR = '#fffaf5';
export const ORANGE_50_COLOR = '#fff6ed';
export const ORANGE_100_COLOR = '#ffead5';
export const ORANGE_200_COLOR = '#fddcab';
export const ORANGE_300_COLOR = '#feb273';
export const ORANGE_400_COLOR = '#fd853a';
export const ORANGE_500_COLOR = '#fb6514';
export const ORANGE_600_COLOR = '#ec4a0a';
export const ORANGE_700_COLOR = '#c4320a';
export const ORANGE_800_COLOR = '#9c2a10';
export const ORANGE_900_COLOR = '#7e2410';

export const BLUE_25_COLOR = '#f5faff';
export const BLUE_50_COLOR = '#eff8ff';
export const BLUE_100_COLOR = '#d1e9ff';
export const BLUE_200_COLOR = '#b2ddff';
export const BLUE_300_COLOR = '#84caff';
export const BLUE_400_COLOR = '#53b1fd';
export const BLUE_500_COLOR = '#2e90fa';
export const BLUE_600_COLOR = '#1570ef';
export const BLUE_700_COLOR = '#175cd3';
export const BLUE_800_COLOR = '#1849a9';
export const BLUE_900_COLOR = '#194185';

export const ERROR_25_COLOR = '#fffbfa';
export const ERROR_50_COLOR = '#fef3f2';
export const ERROR_100_COLOR = '#fee4e2';
export const ERROR_200_COLOR = '#fecdca';
export const ERROR_300_COLOR = '#fda29b';
export const ERROR_400_COLOR = '#f97066';
export const ERROR_500_COLOR = '#f04438';
export const ERROR_600_COLOR = '#d92d20';
export const ERROR_700_COLOR = '#b42318';
export const ERROR_800_COLOR = '#912018';
export const ERROR_900_COLOR = '#7a271a';

export const WARNING_25_COLOR = '#fffcf5';
export const WARNING_50_COLOR = '#fffaeb';
export const WARNING_100_COLOR = '#fef0c7';
export const WARNING_200_COLOR = '#fedf89';
export const WARNING_300_COLOR = '#fec84b';
export const WARNING_400_COLOR = '#fdb022';
export const WARNING_500_COLOR = '#f79009';
export const WARNING_600_COLOR = '#dc6803';
export const WARNING_700_COLOR = '#b54708';
export const WARNING_800_COLOR = '#93370d';
export const WARNING_900_COLOR = '#7a2e0e';

export const SUCCESS_25_COLOR = '#f6fef9';
export const SUCCESS_50_COLOR = '#ecfdf3';
export const SUCCESS_100_COLOR = '#d1fadf';
export const SUCCESS_200_COLOR = '#a6f4c5';
export const SUCCESS_300_COLOR = '#6ce9a6';
export const SUCCESS_400_COLOR = '#32d583';
export const SUCCESS_500_COLOR = '#12b76a';
export const SUCCESS_600_COLOR = '#039855';
export const SUCCESS_700_COLOR = '#027a48';
export const SUCCESS_800_COLOR = '#05603a';
export const SUCCESS_900_COLOR = '#054f31';

export const BLUE_COLOR = '#96c9e9';
export const CORAL_COLOR = '#b47d79';
export const YELLOW_COLOR = '#cfc27f';
export const PINK_COLOR = '#eb9fce';
export const VIOLET_COLOR = '#b961cd';
export const WIRE_COLOR = '#7b61ff';
export const LIME_COLOR = '#a6dbd6';
export const FOAM_COLOR = '#508883';
export const GREEN_COLOR = '#134a45';
export const BROWN_COLOR = '#605c59';
export const GRAY_COLOR = '#40494f';
export const LIGHT_BROWN_COLOR = '#bdb5af';
export const NEW_WHITE_COLOR = '#f7f7f7';
export const BACKGROUND_GRAY_COLOR = '#e5e8eb';
export const PLACEHOLDER_GRAY_COLOR = '#e7e7e7';
/* eslint-enable sort-exports/sort-exports */

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Recoleta Alt';
    src: url('/RecoletaAlt-Regular.woff2') format('woff2'), url('/RecoletaAlt-Regular.woff') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Recoleta Alt';
    src: url('/RecoletaAlt-SemiBold.woff2') format('woff2'), url('/RecoletaAlt-SemiBold.woff') format('woff');
    font-weight: 600;
  }

  :root {
    /* stylelint-disable custom-property-empty-line-before */
    --white-light: ${WHITE_LIGHT_COLOR};

    --bipoc-first: ${BIPOC_FIRST_COLOR};
    --bipoc-second: ${BIPOC_SECOND_COLOR};
    --bipoc-third: ${BIPOC_THIRD_COLOR};

    --lgbq-first: ${LGBQ_FIRST_COLOR};
    --lgbq-second: ${LGBQ_SECOND_COLOR};
    --lgbq-third: ${LGBQ_THIRD_COLOR};
    --lgbq-fourth: ${LGBQ_FOURTH_COLOR};
    --lgbq-fifth: ${LGBQ_FIFTH_COLOR};
    --lgbq-sixth: ${LGBQ_SIXTH_COLOR};

    --tgnc-first: ${TGNC_FIRST_COLOR};
    --tgnc-second: ${TGNC_SECOND_COLOR};

    --brand-bright: ${BRAND_BRIGHT_COLOR};
    --brand-bright2: ${BRAND_BRIGHT_2_COLOR};
    --brand-tints-light: ${BRAND_TINTS_LIGHT_COLOR};
    --brand-tints-light25: ${BRAND_TINTS_LIGHT_25_COLOR};
    --brand-tints-light50: ${BRAND_TINTS_LIGHT_50_COLOR};
    --brand-tints-light100: ${BRAND_TINTS_LIGHT_100_COLOR};
    --brand-shades-dark2: ${BRAND_SHADES_DARK_2_COLOR};

    --gray-light-light: ${GRAY_LIGHT_LIGHT_COLOR};
    --gray-light-medium: ${GRAY_LIGHT_MEDIUM_COLOR};
    --gray-light-dark: ${GRAY_LIGHT_DARK_COLOR};

    --gray-dark-light: ${GRAY_DARK_LIGHT_COLOR};
    --gray-dark-medium: ${GRAY_DARK_MEDIUM_COLOR};
    --gray-dark-dark: ${GRAY_DARK_DARK_COLOR};

    --black-charcoal: ${BLACK_CHARCOAL_COLOR};
    --black-softblack: ${BLACK_SOFTBLACK_COLOR};
    --black-black: ${BLACK_BLACK_COLOR};

    --app-status-error: ${APP_STATUS_ERROR_COLOR};
    --app-status-warning: ${APP_STATUS_WARNING_COLOR};
    --app-status-success: ${APP_STATUS_SUCCESS_COLOR};

    --action-critical-default: ${ACTION_CRITICAL_DEFAULT};
    --action-critical-hovered: ${ACTION_CRITICAL_HOVERED};
    --action-critical-pressed: ${ACTION_CRITICAL_PRESSED};

    --action-complete-default: ${ACTION_COMPLETE_DEFAULT};

    --purple-25: ${PURPLE_25_COLOR};
    --purple-50: ${PURPLE_50_COLOR};
    --purple-100: ${PURPLE_100_COLOR};
    --purple-200: ${PURPLE_200_COLOR};
    --purple-300: ${PURPLE_300_COLOR};
    --purple-400: ${PURPLE_400_COLOR};
    --purple-500: ${PURPLE_500_COLOR};
    --purple-600: ${PURPLE_600_COLOR};
    --purple-700: ${PURPLE_700_COLOR};
    --purple-800: ${PURPLE_800_COLOR};
    --purple-900: ${PURPLE_900_COLOR};

    --bright-purple-25: ${BRIGHT_PURPLE_25_COLOR};
    --bright-purple-50: ${BRIGHT_PURPLE_50_COLOR};
    --bright-purple-100: ${BRIGHT_PURPLE_100_COLOR};
    --bright-purple-200: ${BRIGHT_PURPLE_200_COLOR};
    --bright-purple-300: ${BRIGHT_PURPLE_300_COLOR};
    --bright-purple-400: ${BRIGHT_PURPLE_400_COLOR};
    --bright-purple-500: ${BRIGHT_PURPLE_500_COLOR};
    --bright-purple-600: ${BRIGHT_PURPLE_600_COLOR};
    --bright-purple-700: ${BRIGHT_PURPLE_700_COLOR};
    --bright-purple-800: ${BRIGHT_PURPLE_800_COLOR};
    --bright-purple-900: ${BRIGHT_PURPLE_900_COLOR};

    --light-purple-25: ${LIGHT_PURPLE_25_COLOR};
    --light-purple-50: ${LIGHT_PURPLE_50_COLOR};
    --light-purple-100: ${LIGHT_PURPLE_100_COLOR};
    --light-purple-200: ${LIGHT_PURPLE_200_COLOR};
    --light-purple-300: ${LIGHT_PURPLE_300_COLOR};
    --light-purple-400: ${LIGHT_PURPLE_400_COLOR};
    --light-purple-500: ${LIGHT_PURPLE_500_COLOR};
    --light-purple-600: ${LIGHT_PURPLE_600_COLOR};
    --light-purple-700: ${LIGHT_PURPLE_700_COLOR};
    --light-purple-800: ${LIGHT_PURPLE_800_COLOR};
    --light-purple-900: ${LIGHT_PURPLE_900_COLOR};

    --gray-5: ${GRAY_5_COLOR};
    --gray-25: ${GRAY_25_COLOR};
    --gray-50: ${GRAY_50_COLOR};
    --gray-100: ${GRAY_100_COLOR};
    --gray-200: ${GRAY_200_COLOR};
    --gray-300: ${GRAY_300_COLOR};
    --gray-400: ${GRAY_400_COLOR};
    --gray-500: ${GRAY_500_COLOR};
    --gray-600: ${GRAY_600_COLOR};
    --gray-700: ${GRAY_700_COLOR};
    --gray-800: ${GRAY_800_COLOR};
    --gray-900: ${GRAY_900_COLOR};

    --orange-25: ${ORANGE_25_COLOR};
    --orange-50: ${ORANGE_50_COLOR};
    --orange-100: ${ORANGE_100_COLOR};
    --orange-200: ${ORANGE_200_COLOR};
    --orange-300: ${ORANGE_300_COLOR};
    --orange-400: ${ORANGE_400_COLOR};
    --orange-500: ${ORANGE_500_COLOR};
    --orange-600: ${ORANGE_600_COLOR};
    --orange-700: ${ORANGE_700_COLOR};
    --orange-800: ${ORANGE_800_COLOR};
    --orange-900: ${ORANGE_900_COLOR};

    --blue-25: ${BLUE_25_COLOR};
    --blue-50: ${BLUE_50_COLOR};
    --blue-100: ${BLUE_100_COLOR};
    --blue-200: ${BLUE_200_COLOR};
    --blue-300: ${BLUE_300_COLOR};
    --blue-400: ${BLUE_400_COLOR};
    --blue-500: ${BLUE_500_COLOR};
    --blue-600: ${BLUE_600_COLOR};
    --blue-700: ${BLUE_700_COLOR};
    --blue-800: ${BLUE_800_COLOR};
    --blue-900: ${BLUE_900_COLOR};

    --error-25: ${ERROR_25_COLOR};
    --error-50: ${ERROR_50_COLOR};
    --error-100: ${ERROR_100_COLOR};
    --error-200: ${ERROR_200_COLOR};
    --error-300: ${ERROR_300_COLOR};
    --error-400: ${ERROR_400_COLOR};
    --error-500: ${ERROR_500_COLOR};
    --error-600: ${ERROR_600_COLOR};
    --error-700: ${ERROR_700_COLOR};
    --error-800: ${ERROR_800_COLOR};
    --error-900: ${ERROR_900_COLOR};

    --warning-25: ${WARNING_25_COLOR};
    --warning-50: ${WARNING_50_COLOR};
    --warning-100: ${WARNING_100_COLOR};
    --warning-200: ${WARNING_200_COLOR};
    --warning-300: ${WARNING_300_COLOR};
    --warning-400: ${WARNING_400_COLOR};
    --warning-500: ${WARNING_500_COLOR};
    --warning-600: ${WARNING_600_COLOR};
    --warning-700: ${WARNING_700_COLOR};
    --warning-800: ${WARNING_800_COLOR};
    --warning-900: ${WARNING_900_COLOR};

    --success-25: ${SUCCESS_25_COLOR};
    --success-50: ${SUCCESS_50_COLOR};
    --success-100: ${SUCCESS_100_COLOR};
    --success-200: ${SUCCESS_200_COLOR};
    --success-300: ${SUCCESS_300_COLOR};
    --success-400: ${SUCCESS_400_COLOR};
    --success-500: ${SUCCESS_500_COLOR};
    --success-600: ${SUCCESS_600_COLOR};
    --success-700: ${SUCCESS_700_COLOR};
    --success-800: ${SUCCESS_800_COLOR};
    --success-900: ${SUCCESS_900_COLOR};

    --blue: ${BLUE_COLOR};
    --coral: ${CORAL_COLOR};
    --yellow: ${YELLOW_COLOR};
    --pink: ${PINK_COLOR};
    --violet: ${VIOLET_COLOR};
    --wire: ${WIRE_COLOR};
    --lime: ${LIME_COLOR};
    --foam: ${FOAM_COLOR};
    --green: ${GREEN_COLOR};
    --brown: ${BROWN_COLOR};
    --gray: ${GRAY_COLOR};
    --light-brown: ${LIGHT_BROWN_COLOR};
    --new-white: ${NEW_WHITE_COLOR};
    --background-gray: ${BACKGROUND_GRAY_COLOR};
    --placeholder-gray: ${PLACEHOLDER_GRAY_COLOR};

    --elevation-basic-regular: 0 8px 24px 8px ${rgba(GRAY_DARK_DARK_COLOR, 0.2)};
    --elevation-basic-small: 0 4px 12px 4px ${rgba(GRAY_DARK_DARK_COLOR, 0.2)};
    --elevation-basic-medium: 0 12px 24px 8px ${rgba(GRAY_DARK_DARK_COLOR, 0.2)};
    --elevation-modal-regular: 0 0 24px ${rgba(BLACK_CHARCOAL_COLOR, 0.4)};

    ${MOBILE_BREAKPOINT_RANGE} {
      --space-1: 0.125rem;
      --space-2: 0.25rem;
      --space-3: 0.5rem;
      --space-4: 0.75rem;
      --space-5: 1rem;
      --space-6: 1.25rem;
      --space-7: 1.5rem;
      --space-8: 2rem;
      --space-9: 2.5rem;
      --space-10: 3rem;
      --space-11: 3.5rem;
      --space-12: 4rem;
      --space-13: 4.5rem;
      --space-14: 5rem;
      --space-15: 6rem;
      --space-16: 6.75rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      --space-1: 0.25rem;
      --space-2: 0.5rem;
      --space-3: 0.75rem;
      --space-4: 1rem;
      --space-5: 1.25rem;
      --space-6: 1.5rem;
      --space-7: 2rem;
      --space-8: 2.5rem;
      --space-9: 3rem;
      --space-10: 3.5rem;
      --space-11: 4rem;
      --space-12: 4.5rem;
      --space-13: 5rem;
      --space-14: 6rem;
      --space-15: 6.75rem;
      --space-16: 7.5rem;
    }
  }

  html {
    line-height: 1.2; /* This is the default line height used in figma. */
  }

  body {
    font-family: niveau-grotesk, sans-serif;
    background-color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--gray-800);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin-top: 0;
  }

  input:not([type="checkbox"], [type="radio"]) {
    /* Removes the top inner shadow from <input> in iOS Safari (tested on iOS Safari 13 and 14). */
    -webkit-appearance: none; /* stylelint-disable-line */
  }

  input,
  textarea {
    &::placeholder {
      opacity: 1; /* The initial placeholder opacity is not 1 in Firefox. */
    }
  }
`;

export default GlobalStyle;
