import HelpPopoverTrigger from 'src/components/HelpPopoverTrigger';

import * as S from './styles';

interface Props {
  className?: string;
}

const WhatWorkExperienceToAddPopover = ({ className }: Props) => (
  <HelpPopoverTrigger
    className={className}
    label="Here’s what to add."
  >
    <S.ListLabel>Include:</S.ListLabel>
    <S.IconItemList>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        Care delivery experience in which you served BIPOC, LGBQ, and TGNC patients or clients,
        including your current position.
      </S.IconListItem>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        Relevant experience includes, but is not limited to clinical practice, internships,
        practicums, and patient or health coordination roles.
      </S.IconListItem>
    </S.IconItemList>
    <S.ListLabel>Don't include:</S.ListLabel>
    <S.IconItemList>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        Volunteering, advocacy, teaching, leadership, or research experience. You'll have the
        opportunity to add these in the following section.
      </S.IconListItem>
    </S.IconItemList>
  </HelpPopoverTrigger>
);

export default WhatWorkExperienceToAddPopover;
