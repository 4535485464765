import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as CommunitiesIcon } from '@material-design-icons/svg/round/group.svg';
import { ReactComponent as AccreditationIcon } from '@material-design-icons/svg/round/school.svg';
import clsx from 'clsx';
import { Variants } from 'framer-motion';
import { useOverlayTriggerState } from 'react-stately';
import MiniPercentageProgressBar from 'src/components/MiniPercentageProgressBar';
import PopoverTrigger from 'src/components/PopoverTrigger';
import { Collection } from 'src/pages/Dashboard/utils';
import { getHoursAndMinutes } from 'src/utils/getHoursAndMinutes';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import BeginEvaluationModalDialog from '../BeginEvaluationModalDialog';
import ResourceCardHeader from '../ResourceCardHeader';

import * as S from './styles';

interface Props {
  collection: Collection;
  variants?: Variants;
}

const CollectionCard = ({ collection, variants }: Props) => {
  const {
    close: closeBeginEvaluationModalDialog,
    isOpen: beginEvaluationModalDialogIsOpen,
    open: openBeginEvaluationModalDialog
  } = useOverlayTriggerState({});

  const downloadUrl =
    collection.certificates.length > 0
      ? `${process.env.REACT_APP_BASE_PATH}${collection.certificates[0]?.file.url}`
      : undefined;

  const { hours, minutes } = getHoursAndMinutes(collection.hours);

  const handleStartAssessmentButtonPress = () => {
    openBeginEvaluationModalDialog();
  };

  const showAccreditationAssessment = collection.canBeEvaluated && collection.isAccredited;
  const showDownloadButton =
    collection.isAccredited && downloadUrl !== undefined && collection.progress === 100;

  const communitiesTagText = collection.communities
    .map(community => community.toUpperCase())
    .join('/');

  return (
    <>
      <S.CollectionCard
        data-cy={clsx('collection-card', { 'accredited-collection-card': collection.isAccredited })}
        variants={variants}
      >
        <S.CardLabel>Collection</S.CardLabel>
        <S.CardContent>
          <ResourceCardHeader
            bookmarks={collection.bookmarks}
            completedAt={collection.completedAt}
            id={collection.id}
            isNew={collection.isNew}
            isPopular={collection.isPopular}
            resourceType="CourseCollection"
          />
          <S.Title to={`/dashboard/education/collections/${collection.id}`}>
            {collection.title}
          </S.Title>
          <S.Description>{collection.description}</S.Description>
          <S.Tags data-cy="collection-tags">
            {isNonEmptyString(communitiesTagText) && (
              <S.Tag
                aria-label="Community tags"
                data-cy="communities-tag"
              >
                <CommunitiesIcon
                  aria-hidden="true"
                  role="img"
                />
                {communitiesTagText}
              </S.Tag>
            )}
            {collection.isAccredited && (
              <S.Tag
                aria-label="CE/CME accredited"
                data-cy="accredited-tag"
              >
                <AccreditationIcon
                  aria-hidden="true"
                  role="img"
                />
                CE/CME accredited
                <PopoverTrigger
                  content={
                    <S.PopoverContent>
                      <S.PopoverTitle>
                        Approved accrediting organizations
                        <S.PopoverCredits>
                          <AccreditationIcon
                            aria-hidden
                            role="img"
                          />
                          {collection.credits}
                        </S.PopoverCredits>
                      </S.PopoverTitle>
                      <S.PopoverAccreditingOrganizationList>
                        {collection.externalAccreditationReferences.map(
                          ({ accrediting_organizations }) =>
                            accrediting_organizations?.map(accrediting_organization => (
                              <S.PopoverAccreditingOrganizationItem
                                key={accrediting_organization.professional_group_acronym}
                              >
                                {accrediting_organization.professional_group} (
                                {accrediting_organization.professional_group_acronym})
                              </S.PopoverAccreditingOrganizationItem>
                            ))
                        )}
                      </S.PopoverAccreditingOrganizationList>
                    </S.PopoverContent>
                  }
                  placement="top"
                  theme="light"
                >
                  <S.FaqButton aria-label="Question Mark">
                    <S.QuestionMark
                      aria-hidden
                      role="img"
                    />
                  </S.FaqButton>
                </PopoverTrigger>
                {(hours > 0 || minutes > 0) && (
                  <S.TimeTag>
                    {hours > 0 && `${hours} hour${hours === 1 ? '' : 's'}`}
                    {hours > 0 && minutes > 0 && ' '}
                    {minutes > 0 && `${minutes} min${minutes === 1 ? '' : 's'}`}
                  </S.TimeTag>
                )}
              </S.Tag>
            )}
            <S.Tag
              aria-label="Progress"
              className="progress-tag"
              data-cy="progress-tag"
            >
              {collection.progress === 100 ? 'Completed' : 'Progress'}
              <MiniPercentageProgressBar
                aria-label="Collection progress"
                percentage={collection.progress}
              />
            </S.Tag>
          </S.Tags>
          <S.ButtonWrapper
            $isAccredited={
              showAccreditationAssessment || showDownloadButton || collection.evaluation !== null
            }
          >
            {showAccreditationAssessment && (
              <S.Button
                data-cy="claim-ce-cme-credit-button"
                fullWidth
                onPress={handleStartAssessmentButtonPress}
                size="regular"
                variant="primary"
              >
                Claim CE/CME credit
              </S.Button>
            )}
            {showDownloadButton && (
              <S.DownloadButton
                data-cy="download-certificate-button"
                fullWidth
                href={downloadUrl}
                size="regular"
                target="_blank"
                trailingIcon={DownloadIcon}
                variant="primary"
              >
                Download certificate
              </S.DownloadButton>
            )}
            {collection.isAccredited && collection.evaluation && downloadUrl === undefined && (
              <S.DownloadButton
                data-cy="generate-certificate-button"
                fullWidth
                size="regular"
                to={`/dashboard/education/collections/${collection.id}/accreditation-certificate`}
                variant="primary"
              >
                Generate certificate
              </S.DownloadButton>
            )}
            {collection.completedAt === null || collection.completedAt === undefined ? (
              <S.Button
                fullWidth
                size="regular"
                to={`/dashboard/education/collections/${collection.id}`}
                variant={collection.progress === 0 ? 'primary' : 'outline'}
              >
                {collection.progress === 0 ? 'Start' : 'Resume'}
              </S.Button>
            ) : (
              <S.Button
                data-cy="view-button"
                fullWidth
                size="regular"
                to={`/dashboard/education/collections/${collection.id}`}
                variant="outline"
              >
                View
              </S.Button>
            )}
          </S.ButtonWrapper>
        </S.CardContent>
      </S.CollectionCard>
      {beginEvaluationModalDialogIsOpen && (
        <BeginEvaluationModalDialog
          close={closeBeginEvaluationModalDialog}
          collectionId={collection.id}
        />
      )}
    </>
  );
};

export default CollectionCard;
