import { ReactNode } from 'react';

import DataEmptyState from '../DataEmptyState';

import * as S from './styles';

interface Props {
  'data-cy'?: string;
  detailText: string;
  isEmptyState?: boolean;
  numberHighlight: number | string;
  percent?: number;
  popoverContent?: ReactNode;
  variant: 'large' | 'number-and-percent' | 'small';
}

const DataHighlight = ({
  detailText,
  isEmptyState = false,
  numberHighlight,
  percent,
  popoverContent,
  variant = 'small',
  ...args
}: Props) =>
  variant === 'number-and-percent' && percent !== undefined ? (
    <S.SingleDataCard {...args}>
      <S.NumberHighlight>
        {isEmptyState ? (
          <DataEmptyState />
        ) : (
          <>
            {`${percent.toFixed(0)}%`}
            <S.ParenthesesText>{` (${numberHighlight}) `}</S.ParenthesesText>
          </>
        )}
      </S.NumberHighlight>

      <S.DataHighlightDescriptionText>
        {detailText}
        {popoverContent !== undefined && (
          <S.Tooltip
            inline
            placement="bottom"
            showBorder
            size="small"
          >
            {popoverContent}
          </S.Tooltip>
        )}
      </S.DataHighlightDescriptionText>
    </S.SingleDataCard>
  ) : variant === 'large' ? (
    <S.SingleReportDataCard>
      <S.LargeNumberHighlight>
        {isEmptyState ? <DataEmptyState /> : numberHighlight}
      </S.LargeNumberHighlight>
      <S.LargeDataHighlightDescriptionText>
        {detailText}
        {popoverContent !== undefined && (
          <S.Tooltip
            inline
            placement="bottom"
            showBorder
            size="small"
          >
            {popoverContent}
          </S.Tooltip>
        )}
      </S.LargeDataHighlightDescriptionText>
    </S.SingleReportDataCard>
  ) : (
    <S.SingleDataCard {...args}>
      <S.NumberHighlight>
        {isEmptyState ? <DataEmptyState /> : numberHighlight}
        {percent !== undefined && (
          <S.PercentageValue
            aria-label={`${percent.toFixed(0)} percent`}
          >{` (${percent.toFixed(0)}%) `}</S.PercentageValue>
        )}
      </S.NumberHighlight>
      <S.DataHighlightDescriptionText>
        {detailText}
        {popoverContent !== undefined && (
          <S.Tooltip
            inline
            placement="bottom"
            showBorder
            size="small"
          >
            {popoverContent}
          </S.Tooltip>
        )}
      </S.DataHighlightDescriptionText>
    </S.SingleDataCard>
  );

export default DataHighlight;
