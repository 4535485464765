import { useCallback, useEffect, useState } from 'react';

import useUser from '../../../hooks/useUser';
import { Collection, generateCollection } from '../utils';

import useApiRequest from './useApiRequest';

type FetchCollection = (skipSettingIsFetching?: boolean) => Promise<void>;

type RefetchFetchCollection = FetchCollection;

interface UseGetCollection {
  (id: string): {
    collection: Collection | undefined;
    isFetching: boolean;
    refetchCollection: RefetchFetchCollection;
  };
}

const useGetCollection: UseGetCollection = id => {
  const { user } = useUser();

  const [collection, setCollection] = useState<Collection | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [collectionId, setCollectionId] = useState<string>(id);
  const { getRequest, reportError } = useApiRequest();

  const fetchCollection: FetchCollection = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!collectionId || collectionId === '') return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/course_collections/${collectionId}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APICourseCollectionsCollectionId['data'];
        };
        setCollection(data !== undefined ? generateCollection(data, user) : undefined);
      } catch (error) {
        reportError(error);
      }

      if (!skipSettingIsFetching) {
        setIsFetching(false);
      }
    },
    [collectionId, user, getRequest, reportError]
  );

  useEffect(() => {
    (async () => {
      await fetchCollection();
    })();
    setCollectionId(id);
  }, [collectionId, fetchCollection, id]);

  const refetchCollection: RefetchFetchCollection = useCallback(
    async (skipSettingIsFetching = false) => {
      await fetchCollection(skipSettingIsFetching);
    },
    [fetchCollection]
  );

  return { collection, isFetching, refetchCollection };
};

export default useGetCollection;
