import Logotype from 'src/components/Logotype';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import BackgroundImageUrl from './full_background.png';

export const CenterImageTextWrapper = styled.div`
  text-align: center;
`;

export const CommunityBenchmarksImage = styled.img`
  max-width: 100%;
  margin-top: 12%;
  margin-bottom: 0.8125rem;

  @media (orientation: landscape) {
    height: calc(100vh / 12);
    width: auto;
  }

  @media (orientation: portrait) {
    width: calc(100vw / 4.5);
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const Form = styled.div`
  background-color: #fbfbfb;
  padding: 2.5rem;
  color: var(--gray-800);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  ${MOBILE_BREAKPOINT_RANGE} {
    border-radius: 0.5rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-width: 25rem;
    max-width: 47rem;
  }
`;

export const ImageText = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  text-align: center;
  color: var(--white-light);
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  background: url(${BackgroundImageUrl}), #640283;
  background-repeat: no-repeat;
  background-position: top left;

  ${MOBILE_BREAKPOINT_RANGE} {
    display: none;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: 100%;
  }

  @media (orientation: landscape) {
    padding: 2.5rem 2.5rem 2.5rem calc(100vh * 0.175);
    background-size: calc(100vh * 1.65);
  }

  @media (orientation: portrait) {
    padding: 2.5rem 2.5rem 2.5rem calc(100vw * 0.125);
    background-size: calc(100vw * 1.65);
  }
`;

export const Logo = styled(Logotype)`
  margin-bottom: 6.25rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    display: none;
  }
`;

export const PhoneImage = styled.img`
  max-width: 100%;

  @media (orientation: landscape) {
    height: calc(100vh / 1.75);
    width: auto;
  }

  @media (orientation: portrait) {
    width: calc(100vw / 2.75);
    height: auto;
  }
`;

export const SignUpWrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
    background: url(${BackgroundImageUrl}), #640283;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: calc(100vh * 2);
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: 2fr 3fr;
    display: grid;
  }
`;
