/*
  This function is used to set the page title
  and returns a category based on the pathname
  for analytics purposes.
*/
const getPageTitleAndCategory = (pathname: string) => {
  let category = '';
  if (pathname.startsWith('/onboarding')) {
    category = 'onboarding';
    if (pathname.includes('information')) {
      document.title = 'Onboarding - Personal Information';
    } else if (pathname.includes('identity')) {
      document.title = 'Onboarding - Identities';
    } else if (pathname.includes('cultural-competencies')) {
      document.title = 'Onboarding - Communities';
    } else if (pathname.includes('education-experience')) {
      document.title = 'Onboarding - Education History';
    } else if (pathname.includes('work-experience')) {
      document.title = 'Onboarding - Work Experience';
    } else if (pathname.includes('further-experience')) {
      document.title = 'Onboarding - Self Assessment';
    } else if (pathname.includes('submitted')) {
      document.title = 'Onboarding - Submitted';
    } else {
      document.title = 'Onboarding';
    }
  } else if (pathname.startsWith('/dashboard/profile')) {
    category = 'profile';
    if (pathname.includes('information')) {
      document.title = 'Profile - Personal Information';
    } else if (pathname.includes('cultural-competencies')) {
      document.title = 'Profile - Cultural Competencies';
    } else if (pathname.includes('certifications')) {
      document.title = 'Profile - Education';
    } else if (pathname.includes('work-experience')) {
      document.title = 'Profile - Work Experience';
    } else {
      document.title = 'Profile';
    }
  } else if (pathname.startsWith('/dashboard/my-organization')) {
    category = 'my_org';
    if (pathname.includes('cultural-competencies')) {
      document.title = 'My Organization - Cultural Competencies';
    } else if (pathname.includes('education')) {
      document.title = 'My Organization - Education Engagement';
    } else if (pathname.includes('demographics')) {
      document.title = 'My Organization - Demographics';
    } else {
      document.title = 'My Organization - Overview';
    }
  } else if (pathname.startsWith('/dashboard/my-network')) {
    category = 'network';
    if (pathname.includes('inclusivity')) {
      document.title = 'My Network - Inclusivity';
    } else if (pathname.includes('education-engagement')) {
      document.title = 'My Network - Education Engagement';
    } else if (pathname.includes('demographics')) {
      document.title = 'My Network - Diversity';
    } else {
      document.title = 'My Network - Overview';
    }
  } else if (pathname.startsWith('/dashboard/education/collections')) {
    category = 'education';
    document.title = 'Education - Collections';
  } else if (pathname.startsWith('/dashboard/education/pathways')) {
    category = 'education';
    document.title = 'Education - Pathways';
  } else if (pathname.startsWith('/dashboard/education')) {
    category = 'education';
    document.title = 'Education';
  } else if (pathname.startsWith('/dashboard')) {
    category = 'dashboard';
    document.title = 'Dashboard';
  }
  return category;
};

export default getPageTitleAndCategory;
