import { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  'data-cy'?: string;
  hideBackground?: boolean;
  hideBorder?: boolean;
}

const DataContainer = ({
  children,
  hideBackground = false,
  hideBorder = false,
  ...args
}: PropsWithChildren<Props>) => (
  <S.DataContainer
    $hideBackground={hideBackground}
    $hideBorder={hideBorder}
    {...args}
  >
    {children}
  </S.DataContainer>
);

export default DataContainer;
