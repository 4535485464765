import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const Copy = styled.p`
  color: var(--gray-600);
  ${TEXT_STYLES.bodyCopyRegular}

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-7);
  }
`;

export const Graphic = styled.div`
  margin-bottom: 2rem;
  display: flex;

  ${MOBILE_BREAKPOINT_RANGE} {
    justify-content: center;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    justify-content: end;
  }

  svg {
    display: block;
    width: 100%;
    max-width: 26.25rem;
    height: auto;
  }
`;

export const Header = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel2BoldLarge}
  color: var(--purple-400);

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-4);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: var(--space-3);
  }
`;

interface OnboardingHeroProps {
  $hasGraphic: boolean;
}

export const OnboardingHero = styled.div<OnboardingHeroProps>`
  display: grid;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-rows: 1fr;
  }

  ${({ $hasGraphic }) =>
    $hasGraphic &&
    `
     ${DESKTOP_BREAKPOINT_RANGE} {
       grid-template-columns: 1fr 1fr;
       grid-gap: 2.375rem;
     }
   `}
`;

interface TextBlockProps {
  $hasGraphic: boolean;
}

export const TextBlock = styled.div<TextBlockProps>`
  ${({ $hasGraphic }) =>
    $hasGraphic &&
    `
    ${DESKTOP_BREAKPOINT_RANGE} {
      max-width: 32rem;
    }
  `}
`;
