import { useRef } from 'react';
import { AriaTagGroupProps, useTagGroup } from 'react-aria';
import { useListState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';
import Tag from './Tag';

interface Props<T> extends AriaTagGroupProps<T> {
  className?: string;
  'data-cy'?: string;
  size?: 'large' | 'small';
  variant?: 'blue' | 'dark' | 'gray' | 'green' | 'light';
}

const TagGroup = <T extends object>({
  className,
  'data-cy': dataCy,
  size = 'small',
  variant = 'gray',
  ...ariaTagGroupProps
}: Props<T>) => {
  const { label } = ariaTagGroupProps;

  const ref = useRef<HTMLDivElement>(null);
  const state = useListState(ariaTagGroupProps);
  const { gridProps, labelProps } = useTagGroup(ariaTagGroupProps, state, ref);

  return (
    <S.TagGroup
      className={className}
      data-cy={dataCy}
    >
      {isNonEmptyString(label) && <S.Label {...labelProps}>{label}</S.Label>}
      <S.Grid
        {...gridProps}
        ref={ref}
      >
        {Array.from(state.collection).map(item => (
          <Tag
            key={item.key}
            item={item}
            size={size}
            state={state}
            variant={variant}
          />
        ))}
      </S.Grid>
    </S.TagGroup>
  );
};

export default TagGroup;
