import * as S from './styles';

interface Props {
  colSpan: number;
  message: string;
}

const EmptyTableState = ({ colSpan, message }: Props) => (
  <S.EmptyTableRow data-cy="empty-state">
    <S.EmptyTableCell
      align="center"
      colSpan={colSpan}
    >
      <p>{message}</p>
    </S.EmptyTableCell>
  </S.EmptyTableRow>
);

export default EmptyTableState;
