import { ReactComponent as ForwardToInboxIcon } from '@material-design-icons/svg/round/forward_to_inbox.svg';
import Button from 'src/components/Buttons/Button';
import LeadingHero from 'src/components/heroes/LeadingHero';
import styled from 'styled-components';

export const GreenText = styled.span`
  color: var(--app-status-success);
  margin-right: 0.25rem;
`;

export const Hero = styled(LeadingHero)`
  max-width: 34rem;

  h1,
  h2,
  p,
  a {
    color: var(--white-light);
  }
`;

export const Icon = styled(ForwardToInboxIcon)`
  width: 3rem;
  height: auto;
  margin-bottom: var(--space-4);
  fill: var(--white-light);
`;

export const Invitation = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  height: 100dvh;
  padding-left: var(--space-6);
  padding-right: var(--space-6);
  text-align: center;
  background-color: var(--purple-600);
`;

export const SendButton = styled(Button)`
  margin-top: var(--space-11);
`;

export const Success = styled.div`
  margin-top: var(--space-11);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;

  svg {
    fill: var(--app-status-success);
    margin-right: var(--space-2);
  }
`;
