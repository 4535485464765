import { Sortable } from '@react-types/shared';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Key, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortDescriptor, SortDirection } from 'react-stately';
import PageTitle from 'src/components/PageTitle';
import Spinner from 'src/components/Spinner';
import useGetNetworkLocations from 'src/pages/Dashboard/hooks/useGetNetworkLocations';
import useGetNetworkOverview from 'src/pages/Dashboard/hooks/useGetNetworkOverview';

import { NETWORK_INCLUSIVITY_URL_W_PARAMS } from '../../Wrapper';
import { NetworkOverviewSection } from '../NetworkOverview';
import { ServiceAreasTable } from '../Tables/ServiceAreasTable';

import ServiceAreaFilters from './ServiceAreaFilters';
import * as S from './styles';

const ServiceAreas = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasServiceAreasEnabled = useFeatureFlagEnabled('network_granular_geography');

  useEffect(() => {
    if (hasServiceAreasEnabled === false) {
      navigate(NETWORK_INCLUSIVITY_URL_W_PARAMS, { replace: true });
    }
  }, [hasServiceAreasEnabled, navigate]);

  /**** TABLE STATE ****/
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column:
      searchParams.get('order_by[column]') !== null
        ? (searchParams.get('order_by[column]') as 'provider_count' | 'state')
        : 'provider_count',
    direction:
      searchParams.get('order_by[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });

  /**** TABLE HANDLERS ****/
  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSearchParams(params => {
      params.set('order_by[column]', column.toString());
      params.set('order_by[dir]', direction === 'ascending' ? 'asc' : 'desc');
      params.set('page', '1');
      return params;
    });
  };

  const handleSetPage = (page: number) => {
    setSearchParams(params => {
      params.set('page', page.toString());
      return params;
    });
  };

  /**** HELPER FUNCTIONS ****/
  const mapLocationsFromKeysToAPIObject = (locations: Iterable<Key>) =>
    Array.from(locations).map(location => {
      const type = location.toString().split('|')[0];
      const childType = type.toLowerCase();
      if (childType === 'state') {
        return {
          [childType]: location.toString().split('|')[1]
        };
      } else {
        return {
          [childType]: location.toString().split('|')[1],
          state: location.toString().split('|')[2]
        };
      }
    });

  const getCommonFilters = useCallback(
    () => ({
      'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
      locations: mapLocationsFromKeysToAPIObject(searchParams.get('locations[]')?.split(',') ?? []),
      org_is_on_violet: searchParams.get('org_is_on_violet') === 'true' ? true : undefined,
      'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
      'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
      verified_inclusive: searchParams.get('verified_inclusive') === 'true' ? true : undefined
    }),
    [searchParams]
  );

  /**** INITIAL DATA FETCH ****/
  const { isFetching, networkLocations, networkLocationsTotalPages, updateNetworkLocationFilters } =
    useGetNetworkLocations({
      ...getCommonFilters(),
      order_by: {
        column: (searchParams.get('order_by[column]') !== null
          ? searchParams.get('order_by[column]')
          : 'provider_count') as 'provider_count' | 'state',
        dir: searchParams.get('order_by[dir]') === 'asc' ? 'asc' : 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1', 10)
    });

  const {
    isFetching: isFetchingOverview,
    networkOverview,
    updateNetworkOverviewFilters
  } = useGetNetworkOverview({ ...getCommonFilters() });

  /* refetch data when searchParams changes */
  useEffect(() => {
    updateNetworkLocationFilters({
      ...getCommonFilters(),
      order_by: {
        column: (searchParams.get('order_by[column]') !== null
          ? searchParams.get('order_by[column]')
          : 'provider_count') as 'provider_count' | 'state',
        dir: searchParams.get('order_by[dir]') === 'asc' ? 'asc' : 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1', 10)
    });

    updateNetworkOverviewFilters({
      ...getCommonFilters()
    });
  }, [searchParams, updateNetworkLocationFilters, updateNetworkOverviewFilters, getCommonFilters]);

  /* sets the current page and sort descriptor when searchParams changes */
  useEffect(() => {
    setCurrentPage(Number(searchParams.get('page') ?? '1'));
    setSortDescriptor({
      column: (searchParams.get('order_by[column]') !== null
        ? searchParams.get('order_by[column]')
        : 'provider_count') as 'provider_count' | 'state',
      direction:
        searchParams.get('order_by[dir]') === null
          ? 'descending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by[dir]')!
            ] as SortDirection)
    });
  }, [searchParams]);

  return (
    <>
      <PageTitle
        description="Explore provider inclusivity by geographic region to meet state reporting needs and optimize network performance. Toggle between city, county, and zip code views, and apply filters to better understand your network. Download detailed reports to share insights and drive data-informed decisions for your team."
        title="Service areas"
        titleVariant="h1"
      />
      <ServiceAreaFilters sortDescriptor={sortDescriptor} />

      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <NetworkOverviewSection
          networkOverview={networkOverview}
          showDiversity
          showVIOMetrics
        />
      )}
      <S.TableWrapper>
        <S.ExpandCollapseTextWrapper>
          <S.ExpandCollapseTitle>Service areas</S.ExpandCollapseTitle>
          <S.ExpandCollapseDescription>
            This table displays the density of inclusive providers by geographic service area.
          </S.ExpandCollapseDescription>
        </S.ExpandCollapseTextWrapper>
        {isFetching ? (
          <Spinner withWrapper />
        ) : networkLocations && networkLocations.length > 0 ? (
          <ServiceAreasTable
            currentPageLocations={currentPage}
            handleLocationsTableSortChange={handleTableSortChange}
            handleSetPageLocations={handleSetPage}
            networkLocations={networkLocations}
            networkLocationsTotalPages={networkLocationsTotalPages}
            sortDescriptorLocations={sortDescriptor}
          />
        ) : (
          <S.EmptyState data-cy="network-service-area-empty-state">
            No service areas found.
          </S.EmptyState>
        )}
      </S.TableWrapper>
    </>
  );
};

export default ServiceAreas;
