// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import { forwardRef } from 'react';
import { AriaNumberFieldProps, useLocale, useNumberField } from 'react-aria';
import { useNumberFieldState } from 'react-stately';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaNumberFieldProps {
  className?: string;
  hideRequiredIndicator?: boolean;
  isInvalid?: boolean;
}

const NumberField = forwardRef<HTMLInputElement, Props>(
  (
    { className, hideRequiredIndicator = false, isInvalid = false, ...ariaNumberFieldProps },
    forwardedRef
  ) => {
    const { errorMessage, isRequired = false, label } = ariaNumberFieldProps;

    const { locale } = useLocale();
    const state = useNumberFieldState({ ...ariaNumberFieldProps, locale });

    const ref = useObjectRef(forwardedRef);

    const { errorMessageProps, groupProps, inputProps, labelProps } = useNumberField(
      ariaNumberFieldProps,
      state,
      ref
    );

    return (
      <S.NumberField className={className}>
        <S.Group {...groupProps}>
          {isNonEmptyString(label) && (
            <S.Label {...labelProps}>
              {label}
              {isRequired && !hideRequiredIndicator && '*'}
            </S.Label>
          )}
          <S.Input
            {...inputProps}
            ref={ref}
            className={isInvalid ? 'is-invalid' : undefined}
            data-hj-allow
            required={isRequired}
          />
        </S.Group>
        {isInvalid && (
          <S.ErrorMessage {...errorMessageProps}>
            {typeof errorMessage === 'function' ? null : errorMessage}
          </S.ErrorMessage>
        )}
      </S.NumberField>
    );
  }
);

NumberField.displayName = 'NumberField';

export default NumberField;
