import { filterNameToKey, keyToFilterName } from 'src/utils/filterUtils';

const networkKeysToFilterNames = [
  {
    filterName: 'State',
    key: 'states[]'
  },
  {
    filterName: 'Organization',
    key: 'organizations[]'
  },
  {
    filterName: 'Specialty',
    key: 'specialties[]'
  },
  {
    filterName: 'Provider',
    key: 'user_name'
  },
  {
    filterName: 'Range',
    key: 'date_range'
  },
  {
    filterName: 'Name',
    key: 'resource_name'
  },
  {
    filterName: 'Community',
    key: 'benchmark_communities[]'
  },
  {
    filterName: 'Community',
    key: 'communities[]'
  },
  {
    filterName: 'Location',
    key: 'locations[]'
  },
  {
    filterName: 'Organizations on Violet',
    key: 'org_is_on_violet'
  },
  {
    filterName: 'Verified Inclusive Organizations',
    key: 'verified_inclusive'
  }
];

export const networkFiltersToSearch = (key: string, value: string) => {
  const isEducationPage = window.location.pathname.includes('education-engagement');
  if (isEducationPage && key === 'Community') {
    return ['communities[]', value];
  }
  return filterNameToKey(networkKeysToFilterNames, key, value);
};

export const networkSearchToFilters = (key: string, value: string) =>
  keyToFilterName(networkKeysToFilterNames, key, value);
