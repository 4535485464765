import { ReactComponent as KeyboardArrowDownIcon } from '@material-design-icons/svg/round/keyboard_arrow_down.svg';
import TagGroup from 'src/components/TagGroup';
import UnstyledButton from 'src/components/UnstyledButton';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { default as BasePopover } from '../../Popover';

import ListBox from './ListBox';

export const Icon = styled(KeyboardArrowDownIcon)`
  bottom: 0;
  width: 1.5rem;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--gray-800);
  transition: transform 0.2s;

  &.is-closed {
    transform: rotate(180deg);
    transition: transform 0.2s;
  }
`;

export const Label = styled.div`
  display: block;
  padding-bottom: var(--space-2);
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
`;

export const MultiSelect = styled.div`
  position: relative;
  width: 100%;
`;

export const OpenCloseButton = styled(UnstyledButton)`
  position: absolute;
  top: 0.75rem;
  right: calc(0.75rem - 1px);
  width: 1.5rem;
`;

interface PopoverProps {
  $width?: number;
}

interface ListBoxProps {
  $maxWidth?: string;
}

export const OptionsDropdown = styled(ListBox)<ListBoxProps>`
  max-height: 250px;
  overflow-y: auto;

  ${({ $maxWidth }) => $maxWidth !== undefined && `max-width: ${$maxWidth};`}
`;

export const Popover = styled(BasePopover)<PopoverProps>`
  ${({ $width }) => {
    if ($width !== undefined) {
      return `width: ${$width}px;`;
    }
  }}
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectedTags = styled(TagGroup)`
  margin-top: 0.5rem;
`;
