import { ReactComponent as AddCircleIcon } from '@material-design-icons/svg/round/add_circle.svg';
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg';
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { FocusScope, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria';
import { pdfjs } from 'react-pdf';

import { Course } from '../../pages/components/EducationExperienceForm';
import Button from '../Buttons/Button';

import * as S from './styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  close: () => void;
  course: Course | null;
  onAttachmentUpload: (attachment: File | null, attachmentUrl: string) => void;
}

const AttachCertificateModalDialog = ({ close, course, onAttachmentUpload }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps, titleProps } = useDialog({}, ref);

  const uploadAttachmentInputRef = useRef<HTMLInputElement>(null);

  const existingAttachment = course?.attachment;
  const existingAttachmentUrl = course?.attachmentUrl;

  const [attachment, setAttachment] = useState<File | null | undefined>(existingAttachment);
  const [attachmentUrl, setAttachmentUrl] = useState<string | undefined>(existingAttachmentUrl);
  const [isUpdating, setIsUpdating] = useState(existingAttachment !== null);
  const [fullAttachmentUrl, setFullAttachmentUrl] = useState<string | undefined>(attachmentUrl);

  const handleAddAttachmentButtonPress = () => {
    uploadAttachmentInputRef.current!.click();
  };

  const handleCloseButtonPress = () => {
    close();
  };

  const handleRemoveAttachmentButtonPress = () => {
    onAttachmentUpload(null, '');
    close();
  };

  const handleReplaceButtonPress = () => {
    uploadAttachmentInputRef.current!.click();
  };

  const handleSaveButtonPress = () => {
    onAttachmentUpload(attachment!, attachmentUrl!);
    close();
  };

  const handleUploadAttachmentInputChange: InputHTMLAttributes<HTMLInputElement>['onChange'] =
    event => {
      const { files } = event.target;
      if (files) {
        setAttachment(files[0]);
        setAttachmentUrl(URL.createObjectURL(files[0]));
      }
      setIsUpdating(false);
    };

  useEffect(() => {
    if (attachmentUrl?.startsWith('blob') === true) {
      setFullAttachmentUrl(attachmentUrl);
    } else if (
      attachmentUrl?.startsWith('http://') === true ||
      attachmentUrl?.startsWith('https://') === true
    ) {
      // urls for our test suite are full URLs
      setFullAttachmentUrl(attachmentUrl);
    } else {
      setFullAttachmentUrl(`${process.env.REACT_APP_BASE_PATH}${attachmentUrl}`);
    }
  }, [attachmentUrl]);

  return (
    <S.AttachCertificateModalDialog {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
        >
          <S.CloseButton.Root onPress={handleCloseButtonPress}>
            <S.CloseButton.Icon aria-hidden="true" />
          </S.CloseButton.Root>
          {attachment && attachmentUrl !== undefined ? (
            <>
              <S.UploadedLeadingHero
                copy={isUpdating ? 'Replace or remove attachment.' : ' '}
                header={isUpdating ? 'Update certificate.' : 'Save and close.'}
                headerProps={titleProps}
                level={2}
              />
              {attachment.type === 'application/pdf' ? (
                <S.PdfPreview.Document
                  data-cy="attachment-preview"
                  error="Your PDF was uploaded, but a preview image failed to be generated at this time."
                  file={fullAttachmentUrl}
                  loading=""
                >
                  <S.PdfPreview.Page
                    height={240}
                    loading=""
                    onLoadError={console.error}
                    pageNumber={1}
                    renderAnnotationLayer={false}
                    renderMode="canvas"
                    renderTextLayer={false}
                  />
                </S.PdfPreview.Document>
              ) : (
                <S.AttachmentPreview
                  alt=""
                  data-cy="attachment-preview"
                  src={fullAttachmentUrl}
                />
              )}
              <S.Actions>
                {isUpdating ? (
                  <>
                    <Button
                      onPress={handleReplaceButtonPress}
                      trailingIcon={AddCircleIcon}
                    >
                      Replace
                    </Button>
                    <input
                      ref={uploadAttachmentInputRef}
                      accept="application/pdf, image/jpeg, image/png"
                      data-cy="upload-attachment-input"
                      hidden
                      onChange={handleUploadAttachmentInputChange}
                      onClick={event => {
                        event.currentTarget.value = '';
                      }}
                      type="file"
                    />
                    <Button
                      data-cy="remove-attachment-button"
                      onPress={handleRemoveAttachmentButtonPress}
                      trailingIcon={DeleteIcon}
                      variant="outline"
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <Button
                    data-cy="save-attachment-button"
                    onPress={handleSaveButtonPress}
                  >
                    Save
                  </Button>
                )}
              </S.Actions>
            </>
          ) : (
            <>
              <S.UploadLeadingHero
                copy="To help you track your continuing education history, please attach a certificate if available."
                header="Attach certificate."
                headerProps={titleProps}
                level={2}
              />
              <S.UploadContainer>
                <Button
                  onPress={handleAddAttachmentButtonPress}
                  trailingIcon={AddCircleIcon}
                >
                  Add Attachment
                </Button>
                <input
                  ref={uploadAttachmentInputRef}
                  accept="application/pdf, image/jpeg, image/png"
                  data-cy="upload-attachment-input"
                  hidden
                  onChange={handleUploadAttachmentInputChange}
                  onClick={event => {
                    event.currentTarget.value = '';
                  }}
                  type="file"
                />
                <S.UploadInstructionText>
                  Select a .jpeg, .png, or .pdf no larger than 10MB.
                </S.UploadInstructionText>
              </S.UploadContainer>
            </>
          )}
        </S.Dialog>
      </FocusScope>
    </S.AttachCertificateModalDialog>
  );
};
export default AttachCertificateModalDialog;
