import Button from 'src/components/Buttons/Button';
import ComboBox from 'src/components/FormFields/ComboBox';
import DatePicker from 'src/components/FormFields/DatePicker';
import TextField from 'src/components/FormFields/TextField';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const CertificateSelect = styled(ComboBox)`
  div {
    color: var(--gray-800);
  }

  ul {
    min-height: 6rem;

    /* keeps dropdown within window height even when no other inputs */
    max-height: calc(100dvh / 3.5);
  }
`;

export const CityInput = styled(TextField)`
  margin-top: 0.625rem;

  label {
    color: var(--gray-800);
  }
`;

export const Description = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
  margin-bottom: var(--space-6);
`;

export const DobDatePicker = styled(DatePicker)`
  max-width: 100%;
  margin-top: 0.625rem;

  label {
    color: var(--gray-800);
  }
`;

export const IdNumberField = styled(TextField)`
  margin-top: 0.625rem;

  label {
    color: var(--gray-800);
  }
`;

export const OrgAcronym = styled.div`
  font-weight: 500;
`;

export const OrgName = styled.div`
  color: var(--gray-500);
`;

export const StateSelect = styled(ComboBox)`
  margin-top: 0.625rem;

  label {
    color: var(--gray-800);
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: var(--space-8);
`;

// headlineStandardLevel2Regular corresponds to StandardSubheadlineReg
export const Title = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel2Regular}
  color: var(--purple-600);
`;
