import styled from 'styled-components';

interface TableNumberCellProps {
  $addPadding: boolean;
}

export const TableNumberCell = styled.div<TableNumberCellProps>`
  text-align: right;
  width: 100%;

  ${({ $addPadding }) =>
    $addPadding &&
    `
    padding-right: 1.25rem;
  `}
`;
