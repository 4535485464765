import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const Copy = styled.p`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-600);
  margin: 0;
`;

export const SectionHero = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.h3`
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  color: var(--gray-600);
  margin: 0 0 var(--space-3);
`;
