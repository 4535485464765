import * as S from './styles';

const EmptyState = () => (
  <S.EmptyState>
    <S.Image />
    <div>
      <S.Title>No matches for your search</S.Title>
      <S.Text>Try searching again for a different term.</S.Text>
    </div>
  </S.EmptyState>
);

export default EmptyState;
