import { ReactComponent as CheckCircleIcon } from '@material-design-icons/svg/round/check.svg';
import styled from 'styled-components';

const progressBarThumbLength = 1.25;

export const CheckIcon = styled(CheckCircleIcon)`
  fill: #fff;
  width: 1.25rem;
  height: 1.25rem;
`;

export const Divider = styled.div`
  height: 0.125rem;
  border-radius: 0.125rem;
  width: 100%;
  min-width: 0.25rem;
  align-self: start;
  margin-top: 0.6rem;
  margin-left: -1px;
  margin-right: -1px;

  &.green {
    background: var(--success-600);
  }

  &.gray {
    background: var(--gray-400);
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (width <= 800px) {
    align-items: start;
  }
`;

export const ProgressCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: calc(${progressBarThumbLength}rem - 4px);
  height: calc(${progressBarThumbLength}rem - 4px);
  box-sizing: content-box;
  position: relative;
  z-index: 1;

  &.complete {
    border: 2px solid var(--success-600);
    background-color: var(--success-600);
  }

  &.in-progress {
    border: 2px solid var(--success-600);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: var(--success-600);
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &.incomplete {
    border: 2px solid var(--gray-400);
  }
`;

export const ProgressItem = styled.div`
  align-items: center;
  display: flex;
  width: calc(${progressBarThumbLength}rem + 0.25rem);
  flex-direction: column;
`;
