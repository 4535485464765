import { Dispatch, SetStateAction } from 'react';
import { AriaNumberFieldProps, AriaSliderProps } from 'react-aria';

import useBreakpointRange from '../../../hooks/useBreakpointRange';

import NumberField from './NumberField';
import { Slider } from './Slider';
import * as S from './styles';

interface Props {
  'aria-label'?: AriaSliderProps['aria-label'];
  className?: string;
  'data-cy'?: string;
  isRequired?: boolean;
  label?: AriaSliderProps['label'];
  labelType?: 'copy-header' | 'form-header';
  maxValue: AriaSliderProps['maxValue'];
  minValue: AriaSliderProps['minValue'];
  setValue: Dispatch<number[] | number> | Dispatch<SetStateAction<number>>;
  value: number[] | number;
}

const SliderAndNumberField = ({
  'aria-label': ariaLabel,
  className,
  'data-cy': dataCy,
  isRequired = false,
  label,
  labelType = 'copy-header',
  maxValue,
  minValue,
  setValue,
  value
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  const handleNumberFieldChange: AriaNumberFieldProps['onChange'] = newValue => {
    setValue(newValue);
  };

  const handleSliderChange: AriaSliderProps['onChange'] = newValue => {
    setValue(newValue as number);
  };

  if (isInMobileBreakpointRange) {
    return (
      <S.NumberAndSliderContainer>
        <NumberField
          aria-label={ariaLabel}
          className={className}
          data-cy={dataCy}
          isRequired={isRequired}
          label={label}
          labelType={labelType}
          maxValue={maxValue}
          minValue={minValue}
          onChange={handleNumberFieldChange}
          value={value as number}
        />
        <Slider
          aria-label={ariaLabel}
          className={className}
          data-cy={dataCy}
          isRequired={isRequired}
          label={label}
          labelType={labelType}
          maxValue={maxValue}
          minValue={minValue}
          onChange={handleSliderChange}
          value={value}
        />
      </S.NumberAndSliderContainer>
    );
  } else {
    return (
      <Slider
        aria-label={ariaLabel}
        className={className}
        data-cy={dataCy}
        isRequired={isRequired}
        label={label}
        labelType={labelType}
        maxValue={maxValue}
        minValue={minValue}
        onChange={handleSliderChange}
        value={value}
      />
    );
  }
};

export default SliderAndNumberField;
