import { forwardRef } from 'react';
import { useNumberFormatter } from 'react-aria';
import DonutChart from 'src/components/DonutChart';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import InclusivityScorecardHeader from 'src/components/Reporting/InclusivityScorecardHeader';
import ReportWrapper from 'src/components/Reporting/ReportWrapper';
import {
  OrganizationEducationOverview,
  OrganizationEducationTopResource,
  OrganizationOverview
} from 'src/pages/Dashboard/utils';
import { STRINGS } from 'src/pages/Reports/InclusivityScorecard/InclusivityScorecardReport/strings';

import * as S from './styles';

interface Props {
  educationOverview: OrganizationEducationOverview;
  organizationOverview: OrganizationOverview;
  topResources: OrganizationEducationTopResource[];
}

const InclusivityScorecardReport = forwardRef<HTMLDivElement, Props>(
  ({ educationOverview, organizationOverview, topResources }, forwardedRef) => {
    /* "X Total engaged" = total # of onboarded providers (clinical users)
     "X% Inclusive"  = % of invited onboarded providers with any Benchmark
     Other portion = 100 - X% Inclusive */
    const donutChartDenominator = organizationOverview.totalClinicalMembersOnboarded;
    const donutChartInclusiveNumerator = organizationOverview.totalProvidersWithBenchmarks;
    const donutChartInclusivePercentage =
      (donutChartInclusiveNumerator / donutChartDenominator) * 100;

    /* "X% (X) Engagement rate" = % of clinical users who have completed onboarding */
    const engagementRateDenominator = organizationOverview.totalClinicalMembersInvited;
    const engagementRateNumerator = organizationOverview.totalClinicalMembersOnboarded;
    const engagementRatePercentage = (engagementRateNumerator / engagementRateDenominator) * 100;
    const commaNumFormatter = useNumberFormatter({
      maximumFractionDigits: 0,
      useGrouping: true
    });
    const commaAndDecimalNumFormatter = useNumberFormatter({
      maximumFractionDigits: 2,
      useGrouping: true
    });

    return (
      <ReportWrapper
        ref={forwardedRef}
        headerComponent={
          <InclusivityScorecardHeader
            organization={organizationOverview.organizationName}
            reportTitle="Inclusivity Scorecard"
            /* eslint-disable react/jsx-sort-props */
            bipocPercentage={organizationOverview.providerInclusivityPercentages.bipoc}
            lgbqPercentage={organizationOverview.providerInclusivityPercentages.lgbq}
            tgncPercentage={organizationOverview.providerInclusivityPercentages.tgnc}
            /* eslint-enable react/jsx-sort-props */
          />
        }
      >
        <S.InclusivityScorecardBodyContent>
          {/* COLUMN 1 */}
          <S.TextReportCard>
            <S.TextReportCardTitle>{STRINGS.UnderstandingThisReportTitle}</S.TextReportCardTitle>
            <S.TextReportCardDescription>
              {STRINGS.UnderstandingThisReportDescription}
            </S.TextReportCardDescription>

            <S.CardTitle>Definitions</S.CardTitle>

            <S.TextReportCardDefinition>
              <ul>
                <S.SpacingLi>
                  <strong>Inclusive</strong> {STRINGS.UnderstandingThisReportDefinitions.Inclusive}
                </S.SpacingLi>
                <S.CommunitiesSpacingLi>
                  <strong>BIPOC</strong> {STRINGS.UnderstandingThisReportDefinitions.BIPOC}
                </S.CommunitiesSpacingLi>
                <li>
                  <strong>LGBQ</strong> {STRINGS.UnderstandingThisReportDefinitions.LGBQ}
                </li>
                <li>
                  <strong>TGNC</strong> {STRINGS.UnderstandingThisReportDefinitions.TGNC}
                </li>
                <S.SpacingLi>
                  <strong>Demographics</strong>{' '}
                  {STRINGS.UnderstandingThisReportDefinitions.Demographics}
                </S.SpacingLi>
                <S.SpacingLi>
                  <strong>Engagement</strong>{' '}
                  {STRINGS.UnderstandingThisReportDefinitions.Engagement}
                </S.SpacingLi>
                <S.SpacingLi>
                  <strong>Quality improvement</strong>{' '}
                  {STRINGS.UnderstandingThisReportDefinitions['Quality improvement']}
                </S.SpacingLi>
              </ul>
            </S.TextReportCardDefinition>
          </S.TextReportCard>

          {/* COLUMN 2 */}
          <S.ChartsColumnReportCard>
            <S.InclusivityReportCard title="Inclusivity of care team">
              <S.ChartDataReportRow>
                <S.ChartReportDataCard>
                  <DataCardHeader
                    title="Cultural competencies"
                    titleVariant="h3-report"
                  />
                  <S.CommunityGraphNoSpacing>
                    <S.InclusivityCommunityGraphs
                      data={organizationOverview.providerInclusivityPercentages}
                      dataType="benchmarks"
                      isReport
                    />
                  </S.CommunityGraphNoSpacing>
                </S.ChartReportDataCard>
                <S.ChartReportDataCard>
                  <DonutChart
                    centerNumber={commaNumFormatter.format(
                      organizationOverview.totalClinicalMembersOnboarded
                    )}
                    centerText="Total Engaged"
                    data={[
                      {
                        x: `${commaNumFormatter.format(organizationOverview.totalProvidersWithBenchmarks)} Inclusive`,
                        y: donutChartInclusivePercentage
                      },
                      { x: '', y: 100 - donutChartInclusivePercentage }
                    ]}
                    title="Inclusive provider distribution"
                  />
                </S.ChartReportDataCard>
              </S.ChartDataReportRow>
            </S.InclusivityReportCard>

            <S.InclusivityReportCard title="Organization overview">
              <S.ChartDataReportRow>
                <S.ChartReportDataCard>
                  <DataCardHeader
                    title="Demographics"
                    titleVariant="h3-report"
                  />
                  <S.CommunityGraphNoSpacing>
                    <S.InclusivityCommunityGraphs
                      data={organizationOverview.providerDiversityPercentages}
                      dataType="demographics"
                      isReport
                    />
                  </S.CommunityGraphNoSpacing>
                </S.ChartReportDataCard>

                <S.HighlightGroupingContainer>
                  <S.HighlightDataReportRow>
                    <S.InclusivityDataHighlight
                      detailText="Providers"
                      numberHighlight={commaNumFormatter.format(
                        organizationOverview.totalClinicalMembersInvited
                      )}
                      variant="small"
                    />
                    <S.InclusivityDataHighlight
                      detailText="Non-clinical staff"
                      numberHighlight={commaNumFormatter.format(
                        organizationOverview.totalNonClinicalMembersInvited
                      )}
                      variant="small"
                    />
                  </S.HighlightDataReportRow>
                  <S.InclusivityDataHighlight
                    detailText="Engagement rate"
                    numberHighlight={commaNumFormatter.format(
                      organizationOverview.totalClinicalMembersOnboarded
                    )}
                    percent={engagementRatePercentage}
                    variant="number-and-percent"
                  />
                </S.HighlightGroupingContainer>
              </S.ChartDataReportRow>
            </S.InclusivityReportCard>
          </S.ChartsColumnReportCard>

          {/* COLUMN 3 */}
          <S.HighlightsReportCard title="Quality improvement">
            <S.HighlightGroupingContainer>
              <S.HighlightDataReportRow>
                <S.InclusivityDataHighlight
                  detailText="Courses completed"
                  numberHighlight={commaNumFormatter.format(
                    educationOverview.educationCompletedCount
                  )}
                  variant="small"
                />
                <S.InclusivityDataHighlight
                  detailText="Hours learning"
                  numberHighlight={commaAndDecimalNumFormatter.format(
                    educationOverview.educationHours
                  )}
                  variant="small"
                />
              </S.HighlightDataReportRow>
              <S.InclusivityDataHighlight
                detailText="CE/CME credits earned"
                numberHighlight={commaAndDecimalNumFormatter.format(
                  educationOverview.ceCreditsEarned
                )}
                variant="small"
              />

              <S.TopListHighlight title="Top collections">
                <S.TopCoursesList>
                  {topResources.length > 0 ? (
                    topResources.map(collection => (
                      <S.TopCourseItem key={`collection-${collection.id}`}>
                        <S.TopCourseItemNumber />
                        <S.TopCourseItemText>{collection.title}</S.TopCourseItemText>
                      </S.TopCourseItem>
                    ))
                  ) : (
                    <S.TopCourseItem key="collections-none">N/A</S.TopCourseItem>
                  )}
                </S.TopCoursesList>
              </S.TopListHighlight>
            </S.HighlightGroupingContainer>
          </S.HighlightsReportCard>
        </S.InclusivityScorecardBodyContent>
      </ReportWrapper>
    );
  }
);

InclusivityScorecardReport.displayName = 'InclusivityScorecardReport';

export default InclusivityScorecardReport;
