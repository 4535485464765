import HelpPopoverTrigger from 'src/components/HelpPopoverTrigger';

import * as S from './styles';

interface Props {
  className?: string;
}

const WhatNonClinicalExperienceToAddPopover = ({ className }: Props) => (
  <HelpPopoverTrigger
    className={className}
    label="Here’s what to add."
  >
    <S.ListLabel>Include:</S.ListLabel>
    <S.IconItemList>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>
          <strong>Volunteering:</strong> Community service, mutual aid work.
        </span>
      </S.IconListItem>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>
          <strong>Advocacy:</strong> Policy, grassroots organizing, community engagement.
        </span>
      </S.IconListItem>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>
          <strong>Teaching:</strong> Continuing education courses, college classes, didactics.
        </span>
      </S.IconListItem>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>
          <strong>Research:</strong> Principal investigator, academic research, publications,
          clinical trials.
        </span>
      </S.IconListItem>
      <S.IconListItem>
        <S.CheckCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>
          <strong>Leadership:</strong> Board membership, ERG leadership, committees.
        </span>
      </S.IconListItem>
    </S.IconItemList>
    <S.ListLabel>Don't include:</S.ListLabel>
    <S.IconItemList>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>Clinical work experience</span>
      </S.IconListItem>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>Lived experience — self, family, or friends</span>
      </S.IconListItem>
      <S.IconListItem>
        <S.XCircleIcon
          aria-hidden="true"
          role="img"
        />
        <span>Traveling or study abroad</span>
      </S.IconListItem>
    </S.IconItemList>
  </HelpPopoverTrigger>
);

export default WhatNonClinicalExperienceToAddPopover;
