interface Props {
  month?: string;
  year?: string;
}

export const isValidMonthAndYear = ({ month, year }: Props): boolean =>
  year !== undefined &&
  year.length === 4 &&
  /^\d+$/.test(year) &&
  month !== undefined &&
  Number(month) > 0 &&
  Number(month) < 13;
