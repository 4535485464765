import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { default as BasePopover } from '../../Popover';
import UnstyledButton from '../../UnstyledButton';

export const Caption = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  margin-top: var(--space-1);
`;

export const ComboBox = styled.div`
  &.is-disabled {
    ${() => `
      ${Description} {
        color: var(--gray-dark-light);
      }
    `}

    ${() => `
      ${Input} {
        border-color: var(--gray-200);

        &::placeholder {
          color: var(--gray-500);
        }
      }
    `}
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Description = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  margin-top: 0.5rem;
  color: var(--gray-dark-dark);
`;

export const DropdownButton = styled(UnstyledButton)`
  position: absolute;
  top: 0;
  right: calc(0.75rem - 1px);
  bottom: 0;
  height: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  fill: #333;

  &.is-disabled {
    fill: var(--gray-dark-light);
  }
`;

export const ErrorMessage = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  margin-top: 0.5rem;
  color: var(--app-status-error);
`;

export const Icon = styled.svg`
  position: absolute;
  top: 0;
  right: calc(1rem - 1px);
  bottom: 0;
  width: 1.5rem;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  fill: #333;
`;

export const Input = styled.input`
  ${TEXT_STYLES.formsLabelRegular}
  width: 100%;
  height: 3rem;
  padding: calc(0.75rem - 1px) calc(3rem - 1px) calc(0.75rem - 1px) calc(1rem - 1px);
  color: inherit;
  background-color: var(--white-light);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  outline: none;

  &::placeholder {
    color: var(--gray-500);
  }

  &:focus {
    padding: calc(0.75rem - 2px) calc(3rem - 2px) calc(0.75rem - 2px) calc(1rem - 2px);
    background-position: right calc(1rem - 2px) center;
    border-width: 2px;
    border-color: var(--brand-bright);

    &::placeholder {
      color: var(--gray-800);
    }
  }
`;

export const Label = styled.label`
  ${TEXT_STYLES.bodyCopyRegular}
  display: block;
  padding-bottom: 0.5rem;
  color: var(--gray-800);
`;

interface PopoverProps {
  $width?: number;
}

export const Popover = styled(BasePopover)<PopoverProps>`
  ${({ $width }) => {
    if ($width !== undefined) {
      return `width: ${$width}px;`;
    }
  }}
`;
