import * as S from './styles';

interface Props {
  className?: string;
}

const EducationComplete = ({ className, ...otherProps }: Props) => (
  <S.EducationComplete
    className={className}
    {...otherProps}
  >
    <S.CheckIcon />
    EDUCATION COMPLETE
  </S.EducationComplete>
);

export default EducationComplete;
