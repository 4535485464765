import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.row {
    /* left aligned row */
    &.left {
      justify-content: start;

      &.regular a,
      &.regular button,
      &.large a,
      &.large button {
        margin-right: var(--space-4);
      }

      &.small a,
      &.small button {
        margin-right: var(--space-3);
      }
    }

    /* right aligned row */
    &.right {
      justify-content: end;

      &.regular a,
      &.regular button,
      &.large a,
      &.large button {
        margin-left: var(--space-4);
      }

      &.small a,
      &.small button {
        margin-left: var(--space-3);
      }
    }

    /* space between aligned row */
    &.space-between {
      justify-content: space-between;
    }
  }

  &.column {
    flex-direction: column;

    &.regular a,
    &.regular button,
    &.large a,
    &.large button {
      margin-bottom: var(--space-4);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.small a,
    &.small button {
      margin-bottom: var(--space-3);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
