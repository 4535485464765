import { ReactComponent as ScheduleIcon } from '@material-design-icons/svg/round/schedule.svg';
import { ReactComponent as VerifiedIcon } from '@material-design-icons/svg/round/verified.svg';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useLocation } from 'react-router-dom';
import { Item, useOverlayTriggerState } from 'react-stately';
import BeginEvaluationModalDialog from 'src/components/Education/BeginEvaluationModalDialog';
import getContentMediumTag from 'src/utils/getContentMediumTag';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../hooks/useUser';
import { EducationalResource as EducationalResourceInterface } from '../../../pages/Dashboard/utils';
import CourseDetailsModalDialog from '../CourseDetailsModalDialog';
import EducationCompletedModalDialog from '../EducationCompletedModalDialog';

import * as S from './styles';

interface Props {
  educationalResource: EducationalResourceInterface;
  refetchEducationalResources: (skipSettingIsFetching?: boolean) => Promise<void>;
}

const OldCourseCard = ({ educationalResource, refetchEducationalResources }: Props) => {
  const location = useLocation();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, user } = useUser();

  const params = new URLSearchParams(window.location.search);

  const {
    close: closeCourseDetailsModalDialog,
    isOpen: courseDetailsModalDialogIsOpen,
    open: openCourseDetailsModalDialog
  } = useOverlayTriggerState({
    defaultOpen:
      window.location.pathname === `/dashboard/education/all-education/${educationalResource.id}`
  });

  const {
    close: closeEducationCompletedModalDialog,
    isOpen: educationCompletedModalDialogIsOpen,
    open: openEducationCompletedModalDialog
  } = useOverlayTriggerState({});

  const {
    close: closeBeginEvaluationModalDialog,
    isOpen: beginEvaluationModalDialogIsOpen,
    open: openBeginEvaluationModalDialog
  } = useOverlayTriggerState({});

  const isAssigned = educationalResource.assignments.length > 0;

  const [bookmarkId, setBookmarkId] = useState(educationalResource.bookmarkId);
  const [bookmarkIsDisabled, setBookmarkIsDisabled] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(educationalResource.bookmarkId !== null);
  /*
    Non-scormcloud courses get this attribute from the response when they manually mark a course complete.
    Scormcloud courses we get this from a redirect titled isFirstCompletion that will set as true in the URL
  */
  const [isFirstCompletedEducation, setIsFirstCompletedEducation] = useState(
    params.get('isFirstCompletion') !== null ? params.get('isFirstCompletion') === 'true' : false
  );

  const { courseCollections } = educationalResource;
  const [accreditedCollectionId, setAccreditedCollectionId] = useState<string>();
  const [showEvaluationPrompt, setShowEvaluationPrompt] = useState<boolean>();
  const [previousUrl, setPreviousUrl] = useState<string>();

  useEffect(() => {
    const collectionNeedingEval = courseCollections.find(
      collection =>
        collection.is_accredited &&
        !collection.is_evaluated &&
        user.is_clinical &&
        collection.is_completed
    );
    setAccreditedCollectionId(collectionNeedingEval?.id);
  }, [accreditedCollectionId, courseCollections, user]);

  useEffect(() => {
    if (courseDetailsModalDialogIsOpen && isNonEmptyString(accreditedCollectionId)) {
      setShowEvaluationPrompt(true);
    } else {
      setShowEvaluationPrompt(false);
    }
  }, [accreditedCollectionId, courseDetailsModalDialogIsOpen, openBeginEvaluationModalDialog]);

  const handleBookmarkPress = async () => {
    setBookmarkIsDisabled(true);

    if (isBookmarked) {
      setIsBookmarked(false);

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/bookmarks/${bookmarkId}`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        },
        method: 'DELETE'
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        setBookmarkId(null);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
        setIsBookmarked(true);
      }
    } else {
      setIsBookmarked(true);

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/bookmarks`;

      const options: RequestInit = {
        body: JSON.stringify({
          bookmark: {
            resource_id: educationalResource.id,
            resource_type: 'Course'
          }
        }),
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data } = (await response.json()) as APIBookmarks;
        setBookmarkId(data.id);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
        setIsBookmarked(false);
      }
    }

    setBookmarkIsDisabled(false);
  };

  const handleCloseCourseDetailsModalDialog = () => {
    closeCourseDetailsModalDialog();

    if (
      window.location.pathname === `/dashboard/education/all-education/${educationalResource.id}`
    ) {
      if (previousUrl === undefined) {
        // default = no previous history
        window.history.pushState(null, document.title, '/dashboard/education/all-education');
      } else {
        window.history.pushState(null, document.title, previousUrl);
      }
    }

    setPreviousUrl(undefined);
  };

  const handleViewMoreButtonPress = () => {
    openCourseDetailsModalDialog();

    if (window.location.pathname.includes('/dashboard/education/all-education')) {
      setPreviousUrl(`${location.pathname}${location.search}${location.hash}`);
      window.history.pushState(
        null,
        document.title,
        `/dashboard/education/all-education/${educationalResource.id}`
      );
    }
  };

  const handleOpenEvaluationModalDialog = (collectionId: string) => {
    setAccreditedCollectionId(collectionId);
    openBeginEvaluationModalDialog();
  };

  return (
    <>
      <S.EducationalResource
        key={educationalResource.id}
        data-cy="course-card"
      >
        <S.Header>
          {(educationalResource.isNew || educationalResource.isPopular) && (
            <S.EducationTagGroup
              aria-label="Education tags"
              data-cy={`education-${educationalResource.isNew ? 'new' : 'popular'}-tag`}
              variant={educationalResource.isNew ? 'green' : 'blue'}
            >
              {educationalResource.isNew ? (
                <Item textValue="New">New</Item>
              ) : (
                <Item textValue="Trending">Trending</Item>
              )}
            </S.EducationTagGroup>
          )}
          {isAssigned && <S.Assigned assignments={educationalResource.assignments} />}
          <S.Bookmark
            isDisabled={bookmarkIsDisabled}
            isSelected={isBookmarked}
            onPress={handleBookmarkPress}
          />
        </S.Header>
        <S.Title>{educationalResource.title}</S.Title>
        <S.AuthorAndOrganization>
          <div>
            <VisuallyHidden>{'Taught by '}</VisuallyHidden>
            {educationalResource.author}
            {educationalResource.organization && educationalResource.author && ', '}
            <VisuallyHidden>{'Presented by '}</VisuallyHidden>
            {educationalResource.organization}
          </div>
        </S.AuthorAndOrganization>
        <S.ButtonAndEducationCompleteWrapper>
          <S.InformationTagGroup
            aria-label="Content medium, number of credits, and duration"
            variant="light"
          >
            {getContentMediumTag(educationalResource.contentMedium)}
            <Item textValue={educationalResource.credits}>
              <VerifiedIcon
                aria-hidden="true"
                role="img"
              />
              {educationalResource.credits}
            </Item>
            <Item textValue={educationalResource.length}>
              <ScheduleIcon
                aria-hidden="true"
                role="img"
              />
              {educationalResource.length}
            </Item>
          </S.InformationTagGroup>
          <S.ProgressBar
            value={
              educationalResource.completionStatus === 'complete' ||
              educationalResource.completionStatus === 'rated'
                ? 100
                : 0
            }
          />
          <S.Button
            data-cy="view-button"
            data-educational-resource-title={educationalResource.title}
            onPress={handleViewMoreButtonPress}
            size="large"
          >
            View
          </S.Button>
        </S.ButtonAndEducationCompleteWrapper>
      </S.EducationalResource>
      {courseDetailsModalDialogIsOpen && (
        <CourseDetailsModalDialog
          close={handleCloseCourseDetailsModalDialog}
          educationalResource={educationalResource}
          openBeginEvaluationModalDialog={
            showEvaluationPrompt === true ? openBeginEvaluationModalDialog : undefined
          }
          openEducationCompletedModalDialog={(isFirstCompletedEducation = false) => {
            setIsFirstCompletedEducation(isFirstCompletedEducation);
            openEducationCompletedModalDialog();
          }}
          refetchEducationalResources={refetchEducationalResources}
        />
      )}
      {educationCompletedModalDialogIsOpen && (
        <EducationCompletedModalDialog
          close={closeEducationCompletedModalDialog}
          educationalResource={educationalResource}
          isFirstCompletedEducation={isFirstCompletedEducation}
          openBeginEvaluationModalDialog={handleOpenEvaluationModalDialog}
          refetchEducationalResources={refetchEducationalResources}
        />
      )}
      {isNonEmptyString(accreditedCollectionId) && beginEvaluationModalDialogIsOpen && (
        <BeginEvaluationModalDialog
          close={closeBeginEvaluationModalDialog}
          collectionId={accreditedCollectionId!}
        />
      )}
    </>
  );
};

export default OldCourseCard;
