import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useUser from '../../../hooks/useUser';
import { Collection, generateCollection } from '../utils';

import useApiRequest from './useApiRequest';

export const INITIAL_NUMBER_OF_LISTED_COLLECTIONS = 20;
export const MAX_NUMBER_OF_COLLECTIONS_TO_FETCH_NEXT = 20;

interface Filters {
  accrediting_organizations?: string[];
  assigned?: boolean;
  bookmarked?: boolean;
  can_be_evaluated?: boolean;
  communities?: string[];
  completed?: boolean;
  is_accredited?: boolean;
  is_new?: boolean;
  is_popular?: boolean;
  level?: string[];
  page?: number;
  per_page?: number;
  searchValue?: string;
  specialties?: string[];
  status?: string[];
}

interface CleanFilters extends Filters {
  'accrediting_organizations[]'?: string[];
  'communities[]'?: string[];
  'level[]'?: string[];
  name?: string;
  'specialties[]'?: string[];
  'status[]'?: string[];
}

type FetchCollections = (
  filters?: Filters,
  skipSettingIsFetching?: boolean,
  numberOfListedEducationalResources?: number
) => Promise<void>;

type RefetchCollections = FetchCollections;

interface UseGetCollections {
  (filters: Filters): {
    collections: Collection[];
    hasFetchedAll: boolean;
    isFetching: boolean;
    isFetchingMore: boolean;
    refetchCollections: RefetchCollections;
    updateCollectionsFilters: (newFilters: Partial<Filters>) => void;
  };
}

const useGetCollections: UseGetCollections = filters => {
  const { user } = useUser();
  const { getRequest, reportError } = useApiRequest();

  const [collections, setCollections] = useState<Collection[]>([]);
  const [hasFetchedAll, setHasFetchedAll] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [query, setQuery] = useState<Filters>(filters);

  const fetchCollections: FetchCollections = useCallback(
    async (
      filters,
      skipSettingIsFetching = false,
      numberOfListedCollections = INITIAL_NUMBER_OF_LISTED_COLLECTIONS
    ) => {
      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      if (numberOfListedCollections !== INITIAL_NUMBER_OF_LISTED_COLLECTIONS) {
        setIsFetchingMore(true);
      }

      /*
      Clean filters of undefined values so that they don't get passed to the API.
      Note: bookmarked and assigned are a special case and need to pass the
      false value to the API.
    */
      const cleanFilters: CleanFilters = { ...filters };
      const booleanKeysToRejectFalse = ['is_accredited', 'is_new', 'is_popular'];
      Object.keys(cleanFilters).forEach(key => {
        const filterKey = key as keyof Filters;
        const filterValue = cleanFilters[filterKey];

        if (filterValue === undefined) {
          delete cleanFilters[filterKey];
        }
        if (booleanKeysToRejectFalse.includes(filterKey) && filterValue === false) {
          delete cleanFilters[filterKey];
        }
      });

      if (cleanFilters.accrediting_organizations) {
        cleanFilters['accrediting_organizations[]'] = cleanFilters.accrediting_organizations;
        delete cleanFilters.accrediting_organizations;
      }
      if (cleanFilters.specialties) {
        cleanFilters['specialties[]'] = cleanFilters.specialties;
        delete cleanFilters.specialties;
      }
      if (cleanFilters.level) {
        cleanFilters['level[]'] = cleanFilters.level;
        delete cleanFilters.level;
      }
      if (cleanFilters.communities) {
        cleanFilters.communities = cleanFilters.communities.map(community =>
          community.toLowerCase()
        );
        cleanFilters['communities[]'] = cleanFilters.communities;
        delete cleanFilters.communities;
      }
      if (cleanFilters.status) {
        cleanFilters['status[]'] = cleanFilters.status;
        delete cleanFilters.status;
      }
      if (isNonEmptyString(cleanFilters.searchValue)) {
        cleanFilters.name = cleanFilters.searchValue;
        delete cleanFilters.searchValue;
      }

      const params = new URLSearchParams();

      for (const key in cleanFilters) {
        const value = cleanFilters[key as keyof CleanFilters];
        if (Array.isArray(value)) {
          value.forEach(item => params.append(key, item));
        } else if (
          typeof value === 'string' ||
          typeof value === 'boolean' ||
          typeof value === 'number'
        ) {
          params.append(key, String(value));
        }
      }

      const finalParams = params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']');
      const pageParams = finalParams.includes('per_page')
        ? ''
        : `?per_page=${numberOfListedCollections}`;
      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/course_collections${pageParams}${finalParams ? (pageParams.includes('?') ? `&${finalParams}` : `?${finalParams}`) : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APICourseCollections['data'];
          meta?: APICourseCollections['meta'];
        };

        const fetchedCollections =
          data !== undefined ? data.map(fields => generateCollection(fields, user)) : [];
        setCollections(fetchedCollections);
        setHasFetchedAll(
          meta !== undefined ? fetchedCollections.length === meta.total_count : true
        );
      } catch (error) {
        reportError(error);
      }

      if (!skipSettingIsFetching) {
        setIsFetching(false);
      }

      if (numberOfListedCollections !== INITIAL_NUMBER_OF_LISTED_COLLECTIONS) {
        setIsFetchingMore(false);
      }
    },
    [getRequest, user, reportError]
  );

  const refetchCollections: RefetchCollections = useCallback(
    async (
      filters,
      skipSettingIsFetching = false,
      numberOfListedEducationalResources = INITIAL_NUMBER_OF_LISTED_COLLECTIONS
    ) => {
      await fetchCollections(filters, skipSettingIsFetching, numberOfListedEducationalResources);
    },
    [fetchCollections]
  );

  const updateCollectionsFilters = (newFilters: Partial<Filters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof Filters] === undefined ? delete newFilters[key as keyof Filters] : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    fetchCollections(newFilters);
  };

  useDeepCompareEffect(() => {
    (async () => {
      await fetchCollections(filters);
    })();
  }, [fetchCollections, filters]);

  return {
    collections,
    hasFetchedAll,
    isFetching,
    isFetchingMore,
    refetchCollections,
    updateCollectionsFilters
  };
};

export default useGetCollections;
