import styled from 'styled-components';

export const Label = styled.label`
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  background-color: #fff;
  height: 2.25rem;
  padding: 0.5rem;
  align-items: center;
  gap: 0.25rem;
  display: inline-flex;
  color: var(--gray-500);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.04rem;
  text-transform: capitalize;
`;

export const LeadingIcon = styled.svg`
  height: 1.25rem;
  width: auto;
  fill: currentcolor;
`;
