import TagGroup from 'src/components/TagGroup';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { default as BaseButton } from '../../Buttons/Button';
import { default as BaseAssignedEducation } from '../AssignedEducation';
import { default as BaseBookmark } from '../Bookmark';
import EducationProgressBar from '../EducationProgressBar';

export const Assigned = styled(BaseAssignedEducation)`
  margin-right: 0.645rem;
`;

export const Bookmark = styled(BaseBookmark)``;

export const Button = styled(BaseButton)``;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  align-items: end;
  justify-content: end;

  ${({ $isAccredited = false }) => {
    if ($isAccredited) {
      return `
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: 1fr 5.25rem;
      `;
    }
  }}
`;

interface ButtonWrapperProps {
  $isAccredited?: boolean;
}

export const CollectionCard = styled.li`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid #e6daf2;
  border-radius: 2rem;
`;

export const Description = styled.p`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
  flex-grow: 1;
  margin-bottom: 0.75rem;
`;

export const DownloadButton = styled(BaseButton)``;

export const EducationTagGroup = styled(TagGroup)`
  margin-right: auto;
  align-self: start;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3.75rem;
`;

export const ProgressBar = styled(EducationProgressBar)`
  margin-top: 1.5rem;
  margin-bottom: 2.25rem;
`;

export const Tags = styled.div`
  gap: 0.5rem 0.75rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
`;

export const Title = styled.div`
  margin-bottom: 1rem;
  ${TEXT_STYLES.headlineSectionLevel2Med}
`;
