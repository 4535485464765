import styled from 'styled-components';

import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

interface CheckboxProps {
  $checkedIcon: string;
}

export const Checkbox = styled.label<CheckboxProps>`
  display: flex;
  column-gap: 0.5rem;

  &:not(.is-disabled, .is-read-only) {
    cursor: pointer;
  }

  ${({ $checkedIcon }) => `
    align-items: center;

    &.has-helper-text {
      align-items: start;
    }

    &:not(.is-selected) {
      ${Input} {
        border-color: var(--purple-400);
      }
    }

    &:not(.is-selected, .is-read-only, .is-disabled):hover {
      ${Input} {
        border-color: var(--purple-600);
      }
    }

    &.is-disabled,
    &.is-read-only {
      ${Input} {
        border-color: var(--gray-300);
      }
    }

    &.is-disabled {
      cursor: not-allowed;
    }

    &.is-focused {
      ${Input} {
        outline-offset: 1px;
        outline: 2px solid var(--bright-purple-400);
      }

      &:not(.is-selected, .is-disabled, .is-read-only) {
        ${Input} {
          border-color: var(--purple-600);
        }
      }

      &.is-selected:not(.is-disabled, .is-read-only) {
        ${Input} {
          border-color: var(--purple-400);
        }
      }

      &.is-read-only {
        ${Input} {
          outline: 0;
        }
      }
    }

    &.is-selected {
      ${Input} {
        background-color: var(--purple-400);
        background-image: ${$checkedIcon};
        background-repeat: no-repeat;
        background-position: center;
      }

      &.is-disabled,
      &.is-read-only {
        ${Input} {
          background-color: var(--gray-300);
        }
      }
    }

    &.is-invalid {
      ${Input} {
        border-color: var(--error-400);
        background-color: var(--error-50);
      }

      &.is-selected {
        ${Input} {
          background-color: var(--error-400);
        }
      }

      &.is-disabled,
      &.is-read-only {
        ${Input} {
          background-color: var(--gray-300);
          border-color: var(--gray-300);
        }
      }
    }

    ${Input} {
      margin-top: 0.15rem;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.25rem;
      border-width: 2px;
    }

    ${Label} {
      ${TEXT_STYLES.formsLabelRegular}

      ${MOBILE_BREAKPOINT_RANGE} {
        font-size: 0.9375rem;
      }
    }

    &.is-selected {
      ${Input} {
        background-size: 0.9rem;
      }
    }
  `}
`;

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

export const Helper = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-500);
`;

export const Input = styled.div`
  flex-shrink: 0;
  border: 1px solid var(--purple-400);
  border-radius: 0.125rem;
  background-color: var(--white-light);
`;

export const Label = styled.div``;
