import { AnimatePresence, motion } from 'framer-motion';
import PopoverTrigger from 'src/components/PopoverTrigger';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import * as S from './styles';

interface Props {
  badges: {
    imageSrc: string;
    level: 'Awareness' | 'Excellence' | 'Proficiency';
    notYetAwarded: boolean;
  }[];
  communityAcronym: 'BIPOC' | 'LGBQ' | 'TGNC';
  earnedAtDate: string;
  score: number;
}

const BenchmarkBar = ({ badges, communityAcronym, earnedAtDate, score }: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  return (
    <S.BenchmarkBar
      key={`${communityAcronym}-benchmark-bar`}
      className={`${communityAcronym}-benchmark-bar`}
      data-cy="benchmark-bar"
    >
      <S.CommunityTextWrapper>
        <S.CommunityAcronym>{communityAcronym}</S.CommunityAcronym>
        <S.CommunityDescription>
          {
            {
              BIPOC: 'Black, Indigenous, and People of Color',
              LGBQ: 'Lesbian, Gay, Bisexual, and Queer',
              TGNC: isInMobileBreakpointRange
                ? 'Transgender & Gender Non-Conforming'
                : 'Transgender and Gender Non-Conforming'
            }[communityAcronym]
          }
        </S.CommunityDescription>
      </S.CommunityTextWrapper>
      <S.ProgressBarContainer>
        <S.ProgressBar
          key={`${communityAcronym}-progress-bar`}
          animated
          aria-label={`${communityAcronym} Benchmark progress`}
          data-cy={`${communityAcronym}-progress-bar`}
          maxValue={100}
          minValue={0}
          value={score}
        />
        <div />
        {badges.map((badge, index) => (
          <AnimatePresence>
            <motion.div
              key={`${communityAcronym}-benchmark-section-container`}
              animate={{ opacity: 1, transform: 'scale(1)', transformOrigin: 'center' }}
              initial={{ opacity: 0, transform: 'scale(0)', transformOrigin: 'center' }}
              transition={{ delay: index * 0.5, duration: 1 }}
            >
              <PopoverTrigger
                key={`${communityAcronym}-${badge.level}`}
                content={
                  <S.Popover>
                    {badge.notYetAwarded ? (
                      <>
                        <S.Header>
                          <S.PopoverTitle>Not yet awarded</S.PopoverTitle>
                        </S.Header>
                        <S.PopoverText>
                          Based on the information you’ve input, you have not met the minimum
                          requirements to achieve the {badge.level} Benchmark. Complete additional
                          Violet collections or add relevant experience to your profile.
                        </S.PopoverText>
                      </>
                    ) : (
                      <>
                        <S.Header>
                          <S.PopoverTitle>{badge.level}</S.PopoverTitle>
                          <S.EarnedAtDate>
                            <S.CheckCircleIcon />
                            {`Earned ${earnedAtDate}`}
                          </S.EarnedAtDate>
                        </S.Header>
                        <S.PopoverText>
                          {badge.level === 'Awareness' &&
                            `
                        This Benchmark is awarded to providers in the top 50% of their specialty.
                        Culturally aware providers recognize inequities in health care for various identities, socio-economic statuses, and communities.
                        These providers are committed to building health equity by acknowledging that identities and culture deeply matter in health care.
                      `}
                          {badge.level === 'Proficiency' &&
                            `
                        This Benchmark is awarded to providers in the top 25% of their specialty.
                        Culturally proficient providers are able to speak to identities and cultures in their care delivery.
                        They are actively building health equity by creating safer environments and guiding patients on their unique health journeys.
                      `}
                          {badge.level === 'Excellence' &&
                            `
                        This Benchmark is awarded to providers in the top 5% of their specialty.
                        Culturally excellent providers are advocates for communities.
                        They have extensive professional experience working directly with patients with specific and intersecting cultural identities.
                        Culturally excellent providers are generally pioneers and leading the industry in building health equity for the communities they specialize in.
                      `}
                        </S.PopoverText>
                      </>
                    )}
                  </S.Popover>
                }
                placement="top left"
              >
                <S.BenchmarkWrapper>
                  <S.Benchmark
                    alt={
                      badge.notYetAwarded
                        ? 'Benchmark not yet awarded'
                        : `${communityAcronym} ${badge.level} Benchmark`
                    }
                    height={50}
                    src={badge.imageSrc}
                  />
                  <S.BenchmarkLabel>{badge.level}</S.BenchmarkLabel>
                </S.BenchmarkWrapper>
              </PopoverTrigger>
            </motion.div>
          </AnimatePresence>
        ))}
      </S.ProgressBarContainer>
    </S.BenchmarkBar>
  );
};

export default BenchmarkBar;
