import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const Body = styled.tbody`
  all: unset;
  box-sizing: border-box;
  display: grid;
  row-gap: 0.5rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-width: 65rem;
  }
`;

export const BodyMobile = styled.div`
  all: unset;
  box-sizing: border-box;
  display: grid;
  row-gap: 0.5rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-width: 65rem;
  }
`;

interface CardRowProps {
  $isAutoPopulated: boolean;
  $isExpanded: boolean;
  $isInvalid: boolean;
}

export const CardRow = styled.div<CardRowProps>`
  all: unset;
  box-sizing: border-box;
  display: grid;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  grid-template-areas:
    'expand-collapse expand-collapse delete'
    'job-title job-title job-title'
    'organization organization organization'
    'department department department'
    'current-role dates dates'
    'nested-table nested-table nested-table';

  label {
    ${TEXT_STYLES.formsLabelMediumSmallCaps}
    color: var(--purple-600);
  }

  ${({ $isAutoPopulated, $isExpanded, $isInvalid }) => {
    if ($isInvalid) {
      return `
        background-color: var(--error-50);
        border-color: var(--error-500);
      `;
    }

    if ($isAutoPopulated) {
      if ($isExpanded) {
        return `
          background-color: var(--light-purple-100);
        `;
      } else {
        return `
          background-color: var(--light-purple-25);
        `;
      }
    } else {
      if ($isExpanded) {
        return `
          background-color: var(--gray-50);
        `;
      } else {
        return `
          background-color: var(--gray-25);
        `;
      }
    }
  }}
`;

export const EmptyCard = styled.div`
  all: unset;
  box-sizing: border-box;
  text-align: center;
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-700);
`;

export const EmptyState = {
  BodyCell: styled.td`
    all: unset;
    box-sizing: border-box;
    ${TEXT_STYLES.formsLabelRegular}
    color: var(--gray-700);
  `,
  BodyRow: styled.tr`
    all: unset;
    box-sizing: border-box;
    display: grid;
    place-content: center;
    width: 100%;
    height: 3.75rem;
    padding: 0.75rem;
    background-color: var(--gray-25);
  `
};

export const ExpandableEditableCard = styled.div`
  all: unset;
  box-sizing: border-box;
  display: block;
  padding: 0.625rem;
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  overflow-x: auto;
`;

export const ExpandableEditableTable = styled.table`
  all: unset;
  box-sizing: border-box;
  display: block;
  padding: 0.625rem;
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  overflow-x: auto;
`;

export const Header = styled.thead`
  all: unset;
  box-sizing: border-box;
  display: block;

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-width: 65rem;
  }
`;

interface HeaderCellProps {
  $center: boolean;
}

export const HeaderCell = styled.th<HeaderCellProps>`
  all: unset;
  box-sizing: border-box;
  ${TEXT_STYLES.formsLabelMediumSmallCaps}
  color: var(--purple-400);
  white-space: nowrap;

  &:not(:last-child) {
    padding: 0.75rem;
  }

  ${({ $center = false }) =>
    $center &&
    `
    text-align: center;
  `}
`;

export const HeaderRow = styled.tr`
  all: unset;
  box-sizing: border-box;
  display: grid;
  grid-template-columns:
    2rem minmax(0, 5fr) minmax(0, 4.5fr) minmax(0, 4.5fr) minmax(0, 2fr) 19.775rem
    minmax(0, 2fr) 0fr;
`;
