import { ReactComponent as QuestionMarkIcon } from '@material-design-icons/svg/round/help_outline.svg';
import { ReactComponent as InfoCircleIcon } from '@material-symbols/svg-400/rounded/info-fill.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const HelpIcon = styled(QuestionMarkIcon)`
  fill: var(--gray-400);
`;

export const InfoIcon = styled(InfoCircleIcon)`
  fill: var(--purple-400);
`;

export const PopoverButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-500);

  ${DESKTOP_BREAKPOINT_RANGE} {
    &:not(.inline) {
      margin-top: 1.4rem;

      ${HelpIcon} {
        margin-left: 0.25rem;
      }
    }

    &.inline {
      margin-left: 0.25rem;
    }
  }

  &.is-focused {
    outline-color: var(--brand-bright);
    outline-offset: 0.25rem;
  }

  &.small svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  max-width: 18.75rem;

  strong {
    ${TEXT_STYLES.tooltipTitle}
    color: var(--gray-700);
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong + ul {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;
