import Button from '../Button';

import * as S from './styles';

interface Props {
  'data-cy'?: string;
  linkToPage: string;
  overrideText?: string;
}

const ViewDetailsButton = ({
  'data-cy': dataCy = 'view-details-button',
  linkToPage,
  overrideText = 'View details'
}: Props) => (
  <S.ButtonWrapper>
    <Button
      data-cy={dataCy}
      to={linkToPage}
      variant="plain"
    >
      {overrideText}
    </Button>
  </S.ButtonWrapper>
);

export default ViewDetailsButton;
