import { ReactComponent as MentalHealthIcon } from '@material-design-icons/svg/filled/psychology.svg';
import capitalize from 'lodash/capitalize';
import { FC, SVGProps } from 'react';
import { Item } from 'react-stately';
import styled from 'styled-components';

/* TODO: if migrate to material symbols, can be imported */
import { ReactComponent as PhysicalHealthIcon } from './svgs/physical_therapy.svg';

const PsychologyIcon = styled(MentalHealthIcon)`
  transform: scaleX(-1);
`;

const getSpecialtyTag = (specialty: string): ReturnType<typeof Item> => {
  let Icon: FC<SVGProps<SVGSVGElement>>;
  const formattedSpecialty = capitalize(specialty).replaceAll('_', ' ');

  if (formattedSpecialty === 'Physical health') {
    Icon = PhysicalHealthIcon;
  } else if (formattedSpecialty === 'Mental health') {
    Icon = PsychologyIcon as FC<SVGProps<SVGSVGElement>>;
  } else {
    throw new Error('Invalid `specialty` value.');
  }

  return (
    <Item
      key={specialty}
      textValue={formattedSpecialty}
    >
      <Icon
        aria-hidden="true"
        role="img"
      />
      {formattedSpecialty}
    </Item>
  );
};

export default getSpecialtyTag;
