import { ReactComponent as AssignedEducationIcon } from '@material-design-icons/svg/filled/assignment_turned_in.svg';
import UnstyledButton from 'src/components/UnstyledButton';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const AssignedEducation = styled(AssignedEducationIcon)`
  fill: var(--purple-400);
`;

export const AssignmentText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-700);
`;

export const ButtonWrapper = styled(UnstyledButton)`
  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    border-radius: 0.25rem;
  }
`;
