import * as S from './styles';

interface Props {
  className?: string;
  color: 'dark' | 'green' | 'light';
  hideReserved?: boolean;
  size: 'regular' | 'small';
}

const Logotype = ({ className, color, hideReserved, size }: Props) =>
  (hideReserved ?? false) ? (
    <S.LogotypePlain
      $color={color}
      $size={size}
      className={className}
    />
  ) : (
    <S.Logotype
      $color={color}
      $size={size}
      className={className}
    />
  );

export default Logotype;
