import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import { Key, useEffect, useState } from 'react';
import { Item, useAsyncList } from 'react-stately';
import ComboBox from 'src/components/FormFields/ComboBox';
import useUser from 'src/hooks/useUser';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { NetworkProviderOrganization } from 'src/utils/loadNetworkOrgMemberName';

interface Props {
  ariaLabel?: string;
  className?: string;
  dataCy?: string;
  handleSelectionChange: (key: Key | null) => void;
  label?: string;
  placeholder?: string;
  selectedKey?: Key;
}

const NetworkOrganizationFilter = ({
  ariaLabel,
  className,
  dataCy,
  handleSelectionChange,
  label,
  placeholder,
  selectedKey,
  ...args
}: Props) => {
  const { bearerToken } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const [currentSelectedName, setCurrentSelectedName] = useState('');

  const networkProviderOrganizationList = useAsyncList({
    async load({ filterText, signal }) {
      const queryString = filterText !== '' ? `&name=${filterText}` : '';
      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_PATH}/networks/organization_members/search${
          queryString ? `?${queryString}` : ''
        }`,
        { ...fetchOptions, signal }
      );
      const json = (await res.json()) as { data: NetworkProviderOrganization[] };

      const resources = json.data;

      return {
        items: resources
      };
    }
  });

  useEffect(() => {
    if (networkProviderOrganizationList.items.length === 0) {
      // Don't set from URL params if we've already set the initial value or results aren't yet loaded
      return;
    }
    // Set initial text from selectedKey
    if (selectedKey === undefined || selectedKey === '') {
      return;
    }
    const match = (networkProviderOrganizationList.items as NetworkProviderOrganization[]).find(
      org => org.id === selectedKey
    );
    if (match && currentSelectedName !== match.name) {
      networkProviderOrganizationList.setFilterText(match.name);
      setCurrentSelectedName(match.name);
    }
  }, [
    selectedKey,
    networkProviderOrganizationList.items,
    networkProviderOrganizationList,
    currentSelectedName
  ]);

  return (
    <ComboBox
      allowsCustomValue
      aria-label={ariaLabel}
      className={className}
      data-cy={dataCy}
      icon={SearchIcon}
      inputValue={networkProviderOrganizationList.filterText}
      items={networkProviderOrganizationList.items as NetworkProviderOrganization[]}
      label={label}
      onInputChange={(value: string) => {
        networkProviderOrganizationList.setFilterText(value);
        if (!isNonEmptyString(value)) {
          handleSelectionChange('');
          networkProviderOrganizationList.setFilterText('');
        }
      }}
      onSelectionChange={handleSelectionChange}
      placeholder={placeholder}
      // @ts-expect-error
      selectedKey={selectedKey as Key | null | undefined}
      {...args}
    >
      {item => (
        <Item
          key={(item as NetworkProviderOrganization).id}
          textValue={(item as NetworkProviderOrganization).name}
        >
          {(item as NetworkProviderOrganization).name}
        </Item>
      )}
    </ComboBox>
  );
};

export default NetworkOrganizationFilter;
