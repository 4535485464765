import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNumberFormatter } from 'react-aria';
import { Cell, Column, Row, SortDescriptor, TableBody, TableHeader } from 'react-stately';
import Badge from 'src/components/Badge';
import BooleanIcon from 'src/components/BooleanIcon';
import HelpPopoverTrigger from 'src/components/HelpPopoverTrigger';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { NetworkOrganizationMember } from 'src/pages/Dashboard/utils';

import * as Shared from '../styles';

import * as S from './styles';

interface Props {
  currentPageOrganizations: number;
  handleOrganizationsTableSortChange?: (descriptor: SortDescriptor) => void;
  handleSetPageOrganizations: (page: number) => void;
  networkOrganizationsTotalPages: number;
  networkOrgMembers: NetworkOrganizationMember[];
  sortDescriptorOrganizations?: SortDescriptor;
}

export const NetworkMembersTable = ({
  currentPageOrganizations,
  handleOrganizationsTableSortChange,
  handleSetPageOrganizations,
  networkOrganizationsTotalPages,
  networkOrgMembers,
  sortDescriptorOrganizations
}: Props) => {
  const isVioEnabled = useFeatureFlagEnabled('verified_inclusive_org');
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });
  return (
    <>
      <S.NetworkOrganizationsTable
        $vioEnabled={isVioEnabled === true}
        aria-label="Table of provider organizations"
        data-cy="network-organization-members-table"
        onSortChange={handleOrganizationsTableSortChange}
        sortDescriptor={sortDescriptorOrganizations}
      >
        <TableHeader>
          <Column
            key="name"
            allowsSorting
          >
            Provider Organization
          </Column>
          <Column
            key="total_providers"
            allowsSorting
          >
            <TableNumberCell alignRight>Provider count</TableNumberCell>
          </Column>
          <Column
            key="on_violet"
            allowsSorting
          >
            <Shared.CenterColumn>On Violet</Shared.CenterColumn>
          </Column>
          <Column
            key="verified_inclusive"
            allowsSorting
            // @ts-expect-error
            exclude={isVioEnabled !== true}
          >
            {isVioEnabled === true && (
              <Shared.CenterColumn>
                Verified Inclusive
                <HelpPopoverTrigger
                  inline
                  showBorder
                  size="small"
                  type="info"
                >
                  <p>Violet’s criteria for Verified Inclusive Organizations:</p>
                  <ul>
                    <li>
                      At least 30% of a provider’s clinical staff (including licensed professionals,
                      pre-licensed individuals, and interns) must onboard with Violet, submitting
                      their profiles to receive Benchmarks.
                    </li>
                    <li>
                      Of those, 40% must achieve an Awareness Benchmark in at least one of the three
                      communities—BIPOC, LGBQ, or TGNC.
                    </li>
                  </ul>
                </HelpPopoverTrigger>
              </Shared.CenterColumn>
            )}
          </Column>
          <Column
            key="bipoc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
          </Column>
          <Column
            key="lgbq_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
          </Column>
          <Column
            key="tgnc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
          </Column>
        </TableHeader>
        <TableBody>
          {networkOrgMembers.map(member => (
            <Row
              key={member.id}
              data-cy="network-organization-member-row"
            >
              <Cell>{member.name}</Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-total-providers">
                  {commaNumFormatter.format(member.totalProviders)}
                </TableNumberCell>
              </Cell>
              <Cell>
                <Shared.CenterColumn data-cy="organization-member-on-violet">
                  {isVioEnabled === true ? (
                    <BooleanIcon
                      aria-label={member.onViolet ? 'On Violet' : 'Not yet on Violet'}
                      data-cy="org-on-violet"
                      hideFalseIcon
                      value={member.onViolet}
                    />
                  ) : (
                    <Badge
                      color={member.onViolet ? 'green' : 'red'}
                      size="small"
                    >
                      {member.onViolet ? 'Yes' : 'No'}
                    </Badge>
                  )}
                </Shared.CenterColumn>
              </Cell>
              {/* @ts-expect-error */}
              <Cell exclude={isVioEnabled !== true}>
                {isVioEnabled === true && (
                  <Shared.CenterColumn>
                    <BooleanIcon
                      aria-label={
                        member.verifiedInclusive
                          ? 'Verified Inclusive Organization'
                          : 'Not yet verified'
                      }
                      data-cy="verified-inclusive"
                      hideFalseIcon
                      value={member.verifiedInclusive}
                    />
                  </Shared.CenterColumn>
                )}
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-bipoc-inclusivity-percentage">
                  {`${member.inclusivityPercentages.bipoc}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-lgbq-inclusivity-percentage">
                  {`${member.inclusivityPercentages.lgbq}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-tgnc-inclusivity-percentage">
                  {`${member.inclusivityPercentages.tgnc}%`}
                </TableNumberCell>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </S.NetworkOrganizationsTable>
      <Shared.TablePagination
        currentPage={currentPageOrganizations}
        setPage={handleSetPageOrganizations}
        totalPages={networkOrganizationsTotalPages}
      />
    </>
  );
};
