import clsx from 'clsx';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';

import * as S from './styles';

interface Props {
  currentPage: number;
  setPage: (page: number) => void;
  totalPages: number;
}

const Pagination = ({ currentPage, setPage, totalPages }: Props) => {
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);
  const [pageList, setPageList] = useState<number[]>([]);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  useEffect(() => {
    setPageList(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [totalPages]);

  useEffect(() => {
    const visiblePages = [
      1,
      totalPages > 1 ? 2 : null,
      totalPages >= 3 ? 3 : null,
      previousPage,
      nextPage,
      currentPage,
      totalPages - 2,
      totalPages - 1,
      totalPages
    ].filter(page => page !== null && page > 0 && page <= totalPages);
    const finalVisPages = uniq(visiblePages) as number[];
    setVisiblePages(finalVisPages.sort((a, b) => a - b));
  }, [pageList, currentPage, nextPage, previousPage, totalPages]);

  useEffect(() => {
    const next = currentPage + 1;
    const prev = currentPage - 1;

    setNextPage(next <= totalPages ? next : null);
    setPreviousPage(prev > 0 ? prev : null);
  }, [currentPage, totalPages]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <S.PaginationWrapper>
      <S.Pagination>
        <S.PageItem $itemType="arrow">
          <S.PageLink
            $itemType="arrow"
            aria-label={
              previousPage !== null
                ? `Go to previous page (${previousPage})`
                : 'Previous page, disabled'
            }
            data-cy="previous-page"
            isDisabled={previousPage === null}
            onPress={() => (previousPage !== null ? handlePageChange(previousPage) : null)}
          >
            <S.ArrowBack />
          </S.PageLink>
        </S.PageItem>
        {visiblePages.map((page, index) => {
          const prevInList = visiblePages[index - 1];
          const prevInListDiff = prevInList && prevInList > 0 ? page - prevInList : 0;
          return (
            <S.PageItemWrapper key={`page-container-${page}`}>
              {prevInListDiff > 1 && (
                <S.PageItem
                  key={`page${page - 1}`}
                  $itemType="page"
                >
                  <S.PageLink
                    $itemType="page"
                    aria-label="Show more pages"
                    data-cy="pagination-number"
                    onPress={() => handlePageChange(page - 1)}
                  >
                    ...
                  </S.PageLink>
                </S.PageItem>
              )}
              <S.PageItem
                key={`page${page}`}
                $itemType="page"
              >
                <S.PageLink
                  $itemType="page"
                  aria-label={
                    page === currentPage ? `Page ${page}, current page` : `Go to page ${page}`
                  }
                  className={clsx({ 'is-active': page === currentPage })}
                  data-cy="pagination-number"
                  onPress={() => handlePageChange(page)}
                >
                  {page}
                </S.PageLink>
              </S.PageItem>
            </S.PageItemWrapper>
          );
        })}
        <S.PageItem $itemType="arrow">
          <S.PageLink
            $itemType="arrow"
            aria-label={nextPage !== null ? `Go to next page (${nextPage})` : 'Next page, disabled'}
            data-cy="next-page"
            isDisabled={nextPage === null}
            onPress={() => (nextPage !== null ? handlePageChange(nextPage) : null)}
          >
            <S.ArrowNext />
          </S.PageLink>
        </S.PageItem>
      </S.Pagination>
    </S.PaginationWrapper>
  );
};

export default Pagination;
