import clsx from 'clsx';
import { useRef } from 'react';
import { useMenuItem } from 'react-aria';
import { Node, TreeState } from 'react-stately';

import * as S from './styles';

interface Props {
  item: Node<object>;
  state: TreeState<object>;
}

const MenuItem = ({ item, state }: Props) => {
  const ref = useRef<HTMLLIElement>(null);
  const { isDisabled, isFocused, isPressed, isSelected, menuItemProps } = useMenuItem(
    { key: item.key },
    state,
    ref
  );

  return (
    <S.MenuItem
      {...menuItemProps}
      ref={ref}
      className={clsx({
        'is-disabled': isDisabled,
        'is-focused': isFocused,
        'is-pressed': isPressed,
        'is-selected': isSelected
      })}
    >
      {item.rendered}
    </S.MenuItem>
  );
};

export default MenuItem;
