import { useRef } from 'react';
import { FocusScope, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import useUser from 'src/hooks/useUser';

import Button from '../Buttons/Button';

import PathToPathwaysImage from './pathway-announcements.png';
import * as S from './styles';

interface Props {
  close: () => void;
  setPathwayAnnouncementModalAsViewed: () => void;
}

const PathwayAnnouncementModalDialog = ({ close, setPathwayAnnouncementModalAsViewed }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );

  usePreventScroll();
  const { modalProps } = useModal();
  const { user } = useUser();
  const navigate = useNavigate();

  const { dialogProps, titleProps } = useDialog({}, ref);

  const handleCloseButtonPress = () => {
    setPathwayAnnouncementModalAsViewed();
    close();
  };

  const handleExploreButtonPress = () => {
    setPathwayAnnouncementModalAsViewed();
    navigate('/dashboard/education/pathways');
  };

  return (
    <S.PathwayAnnouncementModalDialog {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
          data-cy="pathway-announcement-modal-dialog"
        >
          <S.CloseButton onPress={handleCloseButtonPress}>
            <S.CloseIcon
              aria-hidden
              role="img"
            />
          </S.CloseButton>
          <S.PathwaysImage
            alt="Graphic illustrating examples of learning pathways"
            src={PathToPathwaysImage}
          />
          <S.TextBlock>
            <S.Title {...titleProps}>New: Learning pathways</S.Title>
            {user.is_clinical ? (
              <S.Text>
                Whether your focus is on addressing the unique needs of a specific community (e.g.,
                Improving mental health in TGNC communities), targeting a particular health
                disparity (e.g., Addressing diabetes disparities), or mastering foundational skills
                (e.g., Getting started with cultural competence), learning pathways provide the
                structured support you need to succeed.
              </S.Text>
            ) : (
              <S.Text>
                Cultural sensitivity is vital at every step of a patient's care journey. Our curated
                learning pathways empower all health care staff in delivering effective care to
                culturally diverse patients.
              </S.Text>
            )}
          </S.TextBlock>
          <S.ButtonContainer>
            <Button
              data-cy="close-button"
              onPress={handleCloseButtonPress}
              size="large"
              variant="outline"
            >
              Close
            </Button>
            <Button
              data-cy="explore-pathways-button"
              onPress={handleExploreButtonPress}
              size="large"
              variant="primary"
            >
              Explore pathways
            </Button>
          </S.ButtonContainer>
        </S.Dialog>
      </FocusScope>
    </S.PathwayAnnouncementModalDialog>
  );
};
export default PathwayAnnouncementModalDialog;
