import { ReactComponent as MyOrganizationIcon } from '@material-design-icons/svg/round/groups.svg';
import { ReactComponent as HomeIcon } from '@material-design-icons/svg/round/home.svg';
import { ReactComponent as MyNetworkIcon } from '@material-design-icons/svg/round/language.svg';
import { ReactComponent as SignOutIcon } from '@material-design-icons/svg/round/logout.svg';
import { ReactComponent as ProfileIcon } from '@material-design-icons/svg/round/person.svg';
import { ReactComponent as EducationIcon } from '@material-design-icons/svg/round/school.svg';
import { useIsPresent } from 'framer-motion';
import { forwardRef, RefObject, useEffect, useState } from 'react';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll
} from 'react-aria';
import { useUpdateEffect } from 'usehooks-ts';

import * as S from './styles';

interface MobileNavigationProps {
  isNetworkManager: boolean;
  isSuperUser: boolean;
  onClose: () => void;
}

const MobileNavigation = forwardRef<HTMLDivElement, MobileNavigationProps>(
  ({ isNetworkManager, isSuperUser, onClose, ...otherProps }, ref) => {
    const isPresent = useIsPresent();

    const [isOpen, setIsOpen] = useState(false);

    usePreventScroll({ isDisabled: !isOpen });

    const { overlayProps } = useOverlay({ isOpen, onClose }, ref as RefObject<HTMLDivElement>);

    const { modalProps } = useModal();

    const { dialogProps, titleProps } = useDialog({}, ref as RefObject<HTMLDivElement>);

    useUpdateEffect(() => {
      if (process.env.REACT_APP_ENV === 'development') return;

      const hubspotMessagesIframeContainerElement = document.getElementById(
        'hubspot-messages-iframe-container'
      );

      if (hubspotMessagesIframeContainerElement) {
        hubspotMessagesIframeContainerElement.style.zIndex = isOpen ? '1' : '';
      }
    }, [isOpen]);

    useEffect(() => {
      setIsOpen(isPresent);
    }, [isPresent]);

    return (
      <FocusScope
        contain
        restoreFocus
      >
        <S.MobileNavigation.Root
          {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
          ref={ref}
          isOpen={isOpen}
        >
          <S.MobileNavigation.MenuText {...titleProps}>MENU</S.MobileNavigation.MenuText>
          <S.MobileNavigation.Links>
            {!isNetworkManager && (
              <S.MobileNavigation.Link to="/dashboard">
                <HomeIcon />
                Home
              </S.MobileNavigation.Link>
            )}
            {isSuperUser && isNetworkManager && (
              <S.MobileNavigation.Link to="/dashboard/my-organization">
                <MyOrganizationIcon />
                My organization
              </S.MobileNavigation.Link>
            )}
            {isNetworkManager && (
              <S.MobileNavigation.Link to="/dashboard/my-network">
                <MyNetworkIcon />
                My network
              </S.MobileNavigation.Link>
            )}
            <S.MobileNavigation.Link to="/dashboard/education/collections">
              <EducationIcon />
              Education
            </S.MobileNavigation.Link>
            <S.MobileNavigation.Link to="/dashboard/profile/information">
              <ProfileIcon />
              Profile
            </S.MobileNavigation.Link>
            {isSuperUser && !isNetworkManager && (
              <S.MobileNavigation.Link to="/dashboard/my-organization">
                <MyOrganizationIcon />
                My organization
              </S.MobileNavigation.Link>
            )}
            <S.MobileNavigation.Link to="/sign-out">
              <SignOutIcon />
              Sign out
            </S.MobileNavigation.Link>
          </S.MobileNavigation.Links>
          <DismissButton onDismiss={onClose} />
        </S.MobileNavigation.Root>
      </FocusScope>
    );
  }
);

MobileNavigation.displayName = 'MobileNavigation';

export default MobileNavigation;
