import { forwardRef } from 'react';
import CommunityGraphs from 'src/components/Reporting/CommunityGraphs';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import NetworkReportHeader from 'src/components/Reporting/NetworkReportHeader';
import ReportCard from 'src/components/Reporting/ReportCard';
import ReportWrapper from 'src/components/Reporting/ReportWrapper';
import { NetworkOverview } from 'src/pages/Dashboard/utils';

interface NetworkOverviewReportProps {
  networkOverview: NetworkOverview;
}

const NetworkOverviewReport = forwardRef<HTMLDivElement, NetworkOverviewReportProps>(
  ({ networkOverview }, forwardedRef) => {
    const compactNumFormatter = Intl.NumberFormat('en', { notation: 'compact' });
    return (
      <ReportWrapper
        ref={forwardedRef}
        headerComponent={
          <NetworkReportHeader
            description="Inclusivity insights are based on the overall cultural competence and diversity of your network. Violet generates these insights using a proprietary benchmarking framework, enabling an objective assessment of inclusivity that spans from the network as a whole to individual providers."
            title={`${
              networkOverview.organizationName !== undefined
                ? `${networkOverview.organizationName} `
                : ''
            }Network Overview`}
          />
        }
      >
        <DataReportRow layout="quarters">
          <DataHighlight
            detailText="Providers"
            numberHighlight={compactNumFormatter.format(networkOverview.totalNetworkProviders)}
            variant="large"
          />
          <DataHighlight
            detailText="Provider organizations"
            numberHighlight={compactNumFormatter.format(networkOverview.totalNetworkOrganizations)}
            variant="large"
          />
          <DataHighlight
            detailText="Inclusive providers"
            numberHighlight={compactNumFormatter.format(
              networkOverview.totalNetworkProvidersWithBenchmarks
            )}
            variant="large"
          />
          <DataHighlight
            detailText="Active providers"
            numberHighlight={compactNumFormatter.format(
              networkOverview.totalActiveNetworkProviders
            )}
            variant="large"
          />
        </DataReportRow>
        <DataReportRow layout="fifty-fifty">
          <ReportCard>
            <DataCardHeader
              badgeColor="gray"
              badgeText="All providers"
              description="This chart represents providers within the network who have had their cultural competence assessed and benchmarked by Violet. This includes active providers (onboarded directly on the platform) and non-active providers (via data analytics)."
              title="Benchmarks"
              titleVariant="h3-scorecard"
            />
            <CommunityGraphs
              data={networkOverview.networkInclusivityPercentages}
              dataType="benchmarks"
              isReport
            />
          </ReportCard>
          <ReportCard>
            <DataCardHeader
              badgeColor="gray"
              badgeText="Active only"
              description="This chart represents active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
              title="Demographics"
              titleVariant="h3-scorecard"
            />
            <CommunityGraphs
              data={networkOverview.networkDiversityPercentages}
              dataType="demographics"
              isReport
            />
          </ReportCard>
        </DataReportRow>
      </ReportWrapper>
    );
  }
);

NetworkOverviewReport.displayName = 'NetworkOverviewReport';

export default NetworkOverviewReport;
