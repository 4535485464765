import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';

export const Buttons = styled.div`
  ${MOBILE_BREAKPOINT_RANGE} {
    display: grid;
    row-gap: 1.125rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    display: grid;
    justify-content: end;
    margin-top: 3rem;
  }
`;

export const FormSection1 = styled.div`
  display: grid;
  margin-bottom: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    row-gap: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    column-gap: var(--space-4);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`;

export const FormSection2 = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const FormSection3 = styled.div`
  display: grid;
  margin-bottom: 2rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    gap: 3.125rem;
    grid-template-columns: 1fr;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }

  @media (min-width >= 768px) and (max-width <= 900px) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const Label = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
  margin-bottom: 0.75rem;
`;
