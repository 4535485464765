import isArray from 'lodash/isArray';
import { useCallback } from 'react';
import useAnalytics from 'src/hooks/useAnalytics';

const useFilterAnalytics = () => {
  const { analytics } = useAnalytics();

  const handleFilterAddedAnalytics = useCallback(
    (
      page: 'All education' | 'Collections' | 'Pathways',
      filterType: string,
      value: string[] | string
    ) => {
      analytics?.track('Filter Added', {
        page,
        type: filterType,
        value: isArray(value) ? value.join(', ') : value
      });
    },
    [analytics]
  );

  return { handleFilterAddedAnalytics };
};

export default useFilterAnalytics;
