import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useIsFirstRender } from 'usehooks-ts';

import {
  generateOrganizationEducationTopResources,
  OrganizationEducationTopResource
} from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationEducationTopResources = (
  filters: OrganizationEducationTopResourcesFilters
) => Promise<void>;

interface OrganizationEducationTopResourcesFilters {
  'communities[]'?: string[] | null;
  date_range?: 'last_12_months' | 'last_30_days' | 'last_90_days' | 'year_to_date';
  is_clinical?: boolean;
  resource_name?: string;
  resource_type?: 'course_collection' | 'course';
  user_name?: string;
}

interface UseGetOrganizationEducationTopResources {
  (
    organizationId: string,
    filters: OrganizationEducationTopResourcesFilters
  ): {
    isFetchingOrgEducationTopResources: boolean;
    topResources: OrganizationEducationTopResource[] | undefined;
    updateEducationTopResourcesFilters: (
      newFilters: OrganizationEducationTopResourcesFilters
    ) => void;
  };
}

const useGetOrganizationEducationTopResources: UseGetOrganizationEducationTopResources = (
  organizationId,
  filters
) => {
  const isFirstRender = useIsFirstRender();
  const { getRequest, reportError } = useApiRequest();

  const [topResources, setTopResources] = useState<OrganizationEducationTopResource[]>();
  const [isFetchingOrgEducationTopResources, setIsFetchingOrgEducationTopResources] =
    useState(false);
  const [query, setQuery] = useState<OrganizationEducationTopResourcesFilters>(filters);

  const fetchOrganizationEducationTopResources: FetchOrganizationEducationTopResources =
    useCallback(
      async filters => {
        if (!organizationId) return;
        setIsFetchingOrgEducationTopResources(true);

        const params = filtersToParams(filters);

        const url: RequestInfo = `${
          process.env.REACT_APP_API_V2_BASE_PATH
        }/organizations/${organizationId}/educations/top_resources${params ? `?${params}` : ''}`;

        try {
          const { data } = (await getRequest(url)) as {
            data: APIOrganizationsOrganizationIdEducationsTopResources['data'] | undefined;
          };

          setTopResources(
            data !== undefined ? generateOrganizationEducationTopResources(data) : undefined
          );
        } catch (error) {
          reportError(error);
        }

        setIsFetchingOrgEducationTopResources(false);
      },
      [organizationId, getRequest, reportError]
    );

  useEffect(() => {
    if (!isFirstRender) return;
    (async () => {
      await fetchOrganizationEducationTopResources(filters);
    })();
  }, [fetchOrganizationEducationTopResources, filters, isFirstRender]);

  const updateEducationTopResourcesFilters = (
    newFilters: Partial<OrganizationEducationTopResourcesFilters>
  ) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationEducationTopResourcesFilters] === undefined
        ? delete newFilters[key as keyof OrganizationEducationTopResourcesFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    fetchOrganizationEducationTopResources(newFilters);
  };

  return {
    isFetchingOrgEducationTopResources,
    topResources,
    updateEducationTopResourcesFilters
  };
};

export default useGetOrganizationEducationTopResources;
