import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '../../../../../constants';

interface MainProps {
  $noBottomPadding: boolean;
}

export const Main = styled.main<MainProps>`
  ${MOBILE_BREAKPOINT_RANGE} {
    padding-top: 45px;
    padding-right: 1rem;
    padding-left: 1rem;

    ${({ $noBottomPadding }) =>
      !$noBottomPadding &&
      `
      padding-bottom: 120px;
    `}
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 1420px;
    padding: var(--space-8) var(--space-9) var(--space-16);
    margin-right: auto;
    margin-left: auto;
  }
`;
