import { Link } from 'react-router-dom';
import TagGroup from 'src/components/TagGroup';
import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import {
  METADATA_M_BREAKPOINT_RANGE,
  METADATA_S_BREAKPOINT_RANGE
} from 'src/pages/Dashboard/Education/Pathways/Pathway/PathwayMetaData/styles';
import styled from 'styled-components';

import { RESOURCE_CARD_STYLES } from '../resource_card_styles';

export const ProgressBarTag = styled.div`
  ${TEXT_STYLES.tagLarge}
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--gray-600);
`;

export const ResourceBody = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  ${METADATA_S_BREAKPOINT_RANGE} {
    flex-direction: column-reverse;
  }
`;

export const ResourceContent = styled.div`
  max-width: 30rem;
`;

export const ResourceDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-600);
`;

export const ResourceItem = styled.li`
  border: 1px solid var(--gray-200);
  border-bottom-width: 0;
  background: #fff;

  &.first,
  &.first .collection,
  &.first .course {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &.last {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-width: 1px;
  }
`;

export const ResourceLabel = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
  font-weight: 500;
  border-bottom: 1px solid var(--gray-200);
  padding: 0.5rem 1rem;

  &.course {
    background: var(--warning-25);
  }

  &.collection {
    background: var(--blue-25);
  }
`;

export const ResourceProgress = styled.div`
  ${DESKTOP_BREAKPOINT_RANGE} {
    min-width: 10.9375rem;
  }

  ${METADATA_S_BREAKPOINT_RANGE} {
    margin-bottom: 0.5rem;
  }
`;

export const ResourceTagGroup = styled(TagGroup)`
  div {
    ${METADATA_M_BREAKPOINT_RANGE} {
      margin-left: auto;
      align-self: end;
    }
  }
`;

export const ResourceTitle = styled(Link)`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
  margin-bottom: 0.25rem;
  text-decoration: none;
`;

export const TagItem = styled.li`
  ${RESOURCE_CARD_STYLES.cardTagItem}
`;

export const Tags = styled.ul`
  ${RESOURCE_CARD_STYLES.cardTags}
  margin-top: 1rem;
  width: auto;
  max-width: 100%;
  display: inline-flex;
  margin-bottom: 0;
`;
