import { motion } from 'framer-motion';
import Spinner from 'src/components/Spinner';
import UnstyledButton from 'src/components/UnstyledButton';
import { BREAKPOINT, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const Carousel = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 1.5rem;
  background: var(--light-purple-25);
`;

export const CarouselContent = styled(motion.div)`
  width: 100%;
  display: grid;
  gap: 0.75rem;
  place-content: center;
  overflow: hidden;
  min-height: 22.5rem;
`;

export const CarouselSpinner = styled(Spinner)`
  margin: 2rem 0 1rem;
`;

export const CarouselSpinnerContainer = styled(motion.div)`
  height: 100%;
  min-height: 22rem;
  width: 100%;
  display: grid;
  place-content: center;
`;

export const Tab = styled.span`
  display: flex;
  padding: 0.625rem 1rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;

  &.active {
    border-radius: 0.75rem;
    background: var(--light-purple-200);
  }
`;

// eslint-disable-next-line sort-exports/sort-exports
export const NavButton = styled(UnstyledButton)`
  display: flex;
  height: 3rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  color: var(--purple-400);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  font-size: 1.0625rem;

  &.active {
    border-bottom: 3px solid var(--purple-400);
  }

  &.is-hovered ${Tab} {
    border-radius: 0.75rem;
    background: var(--light-purple-200);
  }

  &.is-focused {
    outline: 0;

    ${Tab} {
      border-radius: 0.75rem;
      background: var(--light-purple-200);
      outline: 2px solid var(--bright-purple-400);
      outline-offset: 2px;
    }
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  align-self: center;
`;

export const ResourceList = styled(motion.ul)`
  display: grid;
  gap: 1.5rem;
  padding: 0;
  margin: 0;

  @media (width > 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (width > ${BREAKPOINT}px) and (width <= 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }
`;

export const TabDescriptionText = styled(motion.div)`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--purple-400);
  text-align: center;
  max-width: 60rem;
  padding: 0 2rem;
  justify-self: center;
`;
