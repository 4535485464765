import { OverlayContainer } from 'react-aria';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalOverlay = styled(OverlayContainer)`
  > div {
    /* one more than that applied to full screen modals */
    z-index: 101;
  }
`;

export const Text = styled.p`
  margin-bottom: 2.5rem;
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-700);
`;
