import { DialogAria } from 'react-aria';

import * as S from './styles';

interface Props {
  className?: string;
  copy: JSX.Element | string;
  header: JSX.Element | string;
  headerProps?: DialogAria['titleProps'];
  level: 1 | 2;
  subcopy?: JSX.Element | string;
}

const LeadingHero = ({ className, copy, header, headerProps, level = 1, subcopy }: Props) => (
  <S.LeadingHero className={className}>
    {level === 1 ? (
      <>
        <S.H1Header {...headerProps}>{header}</S.H1Header>

        <S.H2Copy>{copy}</S.H2Copy>
      </>
    ) : (
      <>
        <S.H2Header {...headerProps}>{header}</S.H2Header>

        <S.RegularCopy>{copy}</S.RegularCopy>
      </>
    )}
    {subcopy !== undefined && <S.SubCopy>{subcopy}</S.SubCopy>}
  </S.LeadingHero>
);
export default LeadingHero;
