import Alert from 'src/components/Alert';
import Button from 'src/components/Buttons/Button';
import styled from 'styled-components';

export const AddExperienceButton = styled(Button)`
  margin: var(--space-7) var(--space-6) var(--space-7) 0;
`;

export const InLineAlert = styled(Alert)`
  margin-bottom: 1rem;
`;
