import { ReactComponent as AddCircleIcon } from '@material-design-icons/svg/round/add_circle.svg';
import { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import { produce } from 'immer';
import jsonToFormData from 'json-form-data';
import differenceBy from 'lodash/differenceBy';
import mime from 'mime';
import { nanoid } from 'nanoid';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { OverlayContainer, VisuallyHidden } from 'react-aria';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import Button from 'src/components/Buttons/Button';
import ButtonGroup from 'src/components/Buttons/ButtonGroup';
import PopoverTrigger from 'src/components/PopoverTrigger';
import Spinner from 'src/components/Spinner';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { VioletToast } from 'src/components/ToastProvider';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import useUser from 'src/hooks/useUser';
import { useEffectOnce } from 'usehooks-ts';

import AttachCertificateModalDialog from '../../../components/AttachCertificateModalDialog';
import BackNext from '../../../components/Buttons/BackNext';

import EducationEntryRow from './EducationEntryRow';
import * as S from './styles';
import WhatEducationToAddPopover from './WhatEducationToAddPopover';

export interface Course {
  attachment: File | null;
  attachmentUrl: string;
  communities: string[];
  credits: number;
  id: string;
  isReadOnly: boolean;
  name: string;
  organization: string;
  year: number;
}

const getInitialCourseState = (): Course => ({
  attachment: null,
  attachmentUrl: '',
  communities: [],
  credits: NaN,
  id: nanoid(),
  isReadOnly: false,
  name: '',
  organization: '',
  year: NaN
});

const EducationExperienceForm = () => {
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const { bearerToken, setUser, user } = useUser();
  const navigate = useNavigate();
  const [hasRendered, setHasRendered] = useState(false);
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const isOnboarding = window.location.href.includes('onboarding');
  const toastState = useOutletContext();

  const edHistorySkipVariant = useFeatureFlagVariantKey('education_history_skip');

  const checkValidityOfEducationRowsRef = useRef<(() => boolean)[]>([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState(0);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [fileTooLarge, setFileTooLarge] = useState(false);
  const [requestTooLarge, setRequestTooLarge] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const functionToCallAfterUserUpdateRef = useRef<() => void>();

  const [courses, setCourses] = useState<Course[]>(
    user.user_info.educations.length === 0
      ? user.is_clinical
        ? [getInitialCourseState()]
        : []
      : user.user_info.educations.map(education => ({
          attachment: null,
          attachmentUrl: education.image.url ?? '',
          communities: education.communities,
          credits: education.credits ? Number(education.credits) : NaN,
          id: education.id,
          isReadOnly: education.course_id !== null,
          name: education.course_name,
          organization: education.organization,
          year: education.year
        }))
  );
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const [courseInModal, setCourseInModal] = useState<Course | null>(null);

  const handleAddEducationButtonPress = () => {
    setCourses([...courses, getInitialCourseState()]);
    setExpandedRowIndex(courses.length + 1);
    refreshInvalidRowIndices();
  };

  const handleCourseAttachmentUpload =
    (course: Course | null) => (attachment: File | null, attachmentUrl: string) => {
      if (course === null) {
        return;
      }
      const index = courses.findIndex(c => c.id === course.id);
      setCourses(
        produce(courses, draft => {
          draft[index].attachment = attachment;
          draft[index].attachmentUrl = attachmentUrl;
        })
      );
    };

  const {
    close: closeAttachCertificateModalDialog,
    isOpen: attachCertificateDialogIsOpen,
    open: openAttachCertificateModalDialog
  } = useOverlayTriggerState({});

  const handleCourseAttachmentButtonPress = () => {
    openAttachCertificateModalDialog();
  };

  const handleCloseAttachCertificateModalDialog = () => {
    closeAttachCertificateModalDialog();
  };

  const handleCourseCreditsChange = (index: number) => (value: number) => {
    setCourses(
      produce(courses, draft => {
        draft[index].credits = value;
      })
    );
  };

  const handleCourseDelete = (index: number) => () => {
    setCourses(
      produce(courses, draft => {
        draft.splice(index, 1);
      })
    );
  };

  const handleCourseNameChange = (index: number) => (value: string) => {
    setCourses(
      produce(courses, draft => {
        draft[index].name = value;
      })
    );
  };

  const handleCourseOrganizationChange = (index: number) => (value: string) => {
    setCourses(
      produce(courses, draft => {
        draft[index].organization = value;
      })
    );
  };

  const handleCourseYearChange = (index: number) => (value: number) => {
    setCourses(
      produce(courses, draft => {
        draft[index].year = value;
      })
    );
  };

  const handleSelectedCommunitiesChange = (index: number) => (value: string[]) => {
    setCourses(
      produce(courses, draft => {
        draft[index].communities = value;
      })
    );
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    setShouldSubmit(true);
  };

  const submit = useCallback(async () => {
    const form = formRef.current!;
    setHasAttemptedSubmit(true);

    if (
      !form.checkValidity() ||
      courses.some(
        (course: Course) =>
          !course.name || !course.organization || isNaN(course.year) || isNaN(course.credits)
      )
    ) {
      form.reportValidity();
      return;
    }

    setFormIsSubmitting(true);

    try {
      /*
       ** TODO: refactor to easily switch between onboarding vs user profile save API endpoint
       */
      const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/users/onboard`;

      const formData = jsonToFormData(
        {
          user: {
            id: user.id,
            user_info: {
              educations: [
                ...courses.map(course => ({
                  _delimiter: 0,
                  communities: course.communities,
                  course_name: course.name,
                  credits: course.credits,
                  ...(course.id.length === 36 && { id: course.id }),
                  image: course.attachment,
                  organization: course.organization,
                  user_info_id: user.user_info.id,
                  year: course.year
                })),
                ...differenceBy(user.user_info.educations, courses, 'id').map(education => ({
                  _delimiter: 0,
                  _destroy: true,
                  id: education.id
                }))
              ],
              id: user.user_info.id,
              user_id: user.id
            }
          }
        },
        {
          includeNullValues: true,
          initialFormData: new FormData(),
          mapping: value => {
            if (typeof value === 'boolean') {
              return value ? '1' : '0';
            }

            return value as Blob | string;
          },
          showLeafArrayIndexes: false
        }
      );

      const options: RequestInit = {
        body: formData,
        headers: {
          Authorization: `Bearer ${bearerToken}`
        },
        method: 'PATCH'
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        const json = (await response.json()) as { 'user_info.educations.image'?: string[] };
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else if (response.status === 413) {
          setRequestTooLarge(true);
          setFormIsSubmitting(false);
          return;
        } else if (
          response.status === 422 &&
          json['user_info.educations.image']!.toString().includes(
            'File size should be less than 10 MB'
          )
        ) {
          setFileTooLarge(true);
          setFormIsSubmitting(false);
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersOnboard;

      setUser(data);

      functionToCallAfterUserUpdateRef.current = () => {
        if (isOnboarding) {
          navigate('/onboarding/work-experience');
        } else {
          window.scrollTo(0, 0);
          (toastState as ToastState<VioletToast>).add(
            {
              description: 'Your data has been saved.',
              type: 'success'
            },
            { timeout: 8000 }
          );
        }
      };
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check the page for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  }, [
    bearerToken,
    courses,
    navigate,
    openErrorModalDialog,
    openSignedOutModalDialog,
    setUser,
    user,
    isOnboarding,
    toastState
  ]);

  const refreshInvalidRowIndices = () => {
    const invalidRows = checkValidityOfEducationRowsRef.current
      .map((checkValidityOfEducationRow, index) => (checkValidityOfEducationRow() ? null : index))
      .filter((index): index is number => index !== null);
    setInvalidRowIndices(invalidRows);
  };

  useEffectOnce(() => {
    (async () => {
      try {
        const attachments: { attachment: File; courseIndex: number }[] = [];

        for (let courseIndex = 0; courseIndex < courses.length; courseIndex++) {
          const course = courses[courseIndex];

          if (course.attachmentUrl !== '') {
            const isFullUrl =
              course.attachmentUrl.includes('https://') || course.attachmentUrl.includes('http://');
            // for Cypress, we use a placeholder url
            const response = await fetch(
              `${isFullUrl ? '' : process.env.REACT_APP_BASE_PATH}${course.attachmentUrl}`
            );

            if (!response.ok) {
              if (response.status === 401) {
                openSignedOutModalDialog();
                return;
              } else {
                throw new Error(`${response.status} (${response.statusText})`);
              }
            }

            const data = await response.blob();

            const name = course.attachmentUrl.split('/').pop()!;
            const extension = name.split('.').pop()!;

            attachments.push({
              attachment: new File([data], name, { type: mime.getType(extension)! }),
              courseIndex
            });
          }
        }

        setCourses(
          produce(courses, draft => {
            attachments.forEach(attachment => {
              draft[attachment.courseIndex].attachment = attachment.attachment;
            });
          })
        );
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }

      setIsLoading(false);
    })();
  });

  useEffect(() => {
    if (!shouldSubmit) return;

    setShouldSubmit(false);
    submit();
  }, [shouldSubmit, submit]);

  useEffect(() => {
    refreshInvalidRowIndices();
    if (courses.length === 0) {
      return;
    }
    setHasRendered(true);
  }, [courses, hasRendered]);

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <form
      ref={formRef}
      noValidate
      onSubmit={handleFormSubmit}
    >
      <AnimatePresence>
        {fileTooLarge && (
          <S.FormAlert
            data-cy="file-too-large-alert"
            header="There was an issue with one of your attachments."
            id="file-too-large-alert"
            isDismissable
            onDismiss={() => setFileTooLarge(false)}
            type="error"
          >
            One of the files is over 10 MB. Please try again with a smaller file or save without the
            attachment for now.
          </S.FormAlert>
        )}
        {requestTooLarge && (
          <S.FormAlert
            data-cy="request-too-large-alert"
            header="There was an issue with your attachments."
            id="request-too-large-alert"
            isDismissable
            onDismiss={() => setRequestTooLarge(false)}
            type="error"
          >
            The combined size of the attachments you are uploading is too large. Please try saving
            with a few attachments at a time or save without the attachments for now.
          </S.FormAlert>
        )}
      </AnimatePresence>
      <S.EditableEducationTable
        aria-label="Education experience table"
        emptyMessage={
          user.is_clinical
            ? 'You have not added any education history.'
            : 'You have not completed any educations.'
        }
        expandedRowIndex={expandedRowIndex}
        hasAttemptedSubmit={hasAttemptedSubmit}
        invalidRowIndices={invalidRowIndices}
      >
        <thead>
          <tr>
            <th>
              {isInMobileBreakpointRange && <VisuallyHidden>EXPAND / COLLAPSE</VisuallyHidden>}
            </th>
            <th key="name">COURSE NAME</th>
            <th>ORGANIZATION</th>
            <th>
              <TableNumberCell alignRight>YEAR</TableNumberCell>
            </th>
            <th>
              <TableNumberCell alignRight>CREDITS/HOURS</TableNumberCell>
            </th>
            <th>
              <PopoverTrigger
                content={
                  <S.PopoverContent>
                    Please indicate which communities were the primary focus in your coursework or
                    training. If your education did not focus on BIPOC, LGBQ or TGNC communities,
                    you do not need to include it.
                  </S.PopoverContent>
                }
              >
                <S.PopoverButton>
                  COMMUNITIES OF FOCUS
                  <S.HelpIcon />
                </S.PopoverButton>
              </PopoverTrigger>
            </th>
            <th>
              <S.CenterText>ATTACHMENT</S.CenterText>
            </th>
            <th>
              <VisuallyHidden>DELETE</VisuallyHidden>
            </th>
            <th>
              {isInMobileBreakpointRange && <VisuallyHidden>EXPANDED CONTENT</VisuallyHidden>}
            </th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <EducationEntryRow
              key={course.id || index}
              checkValidityRef={checkValidityOfEducationRowsRef}
              course={course}
              data-cy="course-row"
              handleExpandCollapseClick={index =>
                setExpandedRowIndex(expandedRowIndex === index ? -1 : index)
              }
              index={index}
              isExpanded={
                index === expandedRowIndex ||
                (invalidRowIndices.includes(index) && hasAttemptedSubmit)
              }
              isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
              onCommunitiesChange={value => handleSelectedCommunitiesChange(index)(value)}
              onCreditsChange={value => handleCourseCreditsChange(index)(value)}
              onDelete={() => handleCourseDelete(index)()}
              onNameChange={value => handleCourseNameChange(index)(value)}
              onOrganizationChange={value => handleCourseOrganizationChange(index)(value)}
              onYearChange={value => handleCourseYearChange(index)(value)}
              openCertificateModalDialog={course => {
                setCourseInModal(course);
                handleCourseAttachmentButtonPress();
              }}
            />
          ))}
        </tbody>
      </S.EditableEducationTable>
      {attachCertificateDialogIsOpen && (
        <OverlayContainer>
          <AttachCertificateModalDialog
            close={handleCloseAttachCertificateModalDialog}
            course={courseInModal}
            onAttachmentUpload={handleCourseAttachmentUpload(courseInModal)}
          />
        </OverlayContainer>
      )}
      {user.is_clinical ? (
        <S.ClinicalActions>
          <S.AddEducationButton
            data-cy="add-education-button"
            onPress={handleAddEducationButtonPress}
            trailingIcon={AddCircleIcon}
            variant="plain"
          >
            Add education
          </S.AddEducationButton>
          {!isOnboarding && <WhatEducationToAddPopover />}
        </S.ClinicalActions>
      ) : (
        <S.Spacer />
      )}
      {isOnboarding ? (
        <BackNext
          backTo="/onboarding/cultural-competencies#TGNC"
          includeSkip={edHistorySkipVariant === 'test'}
          nextIsLoading={formIsSubmitting}
          nextLabel="Next: Work experience"
          skipTo="/onboarding/work-experience"
        />
      ) : (
        <ButtonGroup
          align="right"
          size="large"
        >
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </ButtonGroup>
      )}
    </form>
  );
};

export default EducationExperienceForm;
