import { useCallback, useEffect, useState } from 'react';

import { generatePathway, generatePathwayCompletion, Pathway } from '../utils';

import useApiRequest from './useApiRequest';

type FetchPathway = (skipSettingIsFetching?: boolean) => Promise<void>;

type RefetchFetchPathway = FetchPathway;

type StartPathway = FetchPathway;

interface UseGetPathway {
  (id: string): {
    isFetching: boolean;
    pathway: Pathway | undefined;
    refetchPathway: RefetchFetchPathway;
    startPathway: StartPathway;
  };
}

const useGetPathway: UseGetPathway = id => {
  const [pathway, setPathway] = useState<Pathway | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [pathwayId, setPathwayId] = useState<string>(id);
  const { getRequest, postRequest, reportError } = useApiRequest();

  const fetchPathway: FetchPathway = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!pathwayId || pathwayId === '') return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/pathways/${pathwayId}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APIPathwaysShow['data'];
        };
        setPathway(data !== undefined ? generatePathway(data) : undefined);
      } catch (error) {
        reportError(error);
      }

      if (!skipSettingIsFetching) {
        setIsFetching(false);
      }
    },
    [pathwayId, getRequest, reportError]
  );

  useEffect(() => {
    (async () => {
      await fetchPathway();
    })();
    setPathwayId(id);
  }, [pathwayId, fetchPathway, id]);

  const refetchPathway: RefetchFetchPathway = useCallback(
    async (skipSettingIsFetching = false) => {
      await fetchPathway(skipSettingIsFetching);
    },
    [fetchPathway]
  );

  const startPathway: StartPathway = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!pathwayId || pathwayId === '') return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/pathways/${pathwayId}/start`;

      try {
        const { data } = (await postRequest(url, {})) as {
          data?: APIPathwaysStart['data'];
        };
        if (data !== undefined) {
          const updatedPathway = {
            ...pathway,
            completion: generatePathwayCompletion(data)
          } as Pathway;
          setPathway(updatedPathway);
        }
      } catch (error) {
        reportError(error);
      }

      if (!skipSettingIsFetching) {
        setIsFetching(false);
      }
    },
    [pathway, pathwayId, postRequest, reportError]
  );

  return { isFetching, pathway, refetchPathway, startPathway };
};

export default useGetPathway;
