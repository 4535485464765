import InlineDialog from 'src/components/InlineDialog';
import PopoverTrigger from 'src/components/PopoverTrigger';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import ModalDialog from './ModalDialog';
import * as S from './styles';

const ProficiencyPopover = () => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  const content = (
    <S.DefinitionTable>
      <tbody>
        <S.DefinitionRow>
          <S.DefinitionTitle>Excellent</S.DefinitionTitle>
          <S.Definition>
            Has complete fluency in the language and is skilled at incorporating appropriate medical
            terminology and concepts into communication.
          </S.Definition>
        </S.DefinitionRow>
        <S.DefinitionRow>
          <S.DefinitionTitle>Very good</S.DefinitionTitle>
          <S.Definition>
            Can speak fluently and accurately through all health care work needs. Rarely hindered in
            any task requiring this language.
          </S.Definition>
        </S.DefinitionRow>
        <S.DefinitionRow>
          <S.DefinitionTitle>Good</S.DefinitionTitle>
          <S.Definition>
            Able to speak with sufficient accuracy and vocabulary to have effective conversations on
            most topics related to health.
          </S.Definition>
        </S.DefinitionRow>
        <S.DefinitionRow>
          <S.DefinitionTitle>Fair</S.DefinitionTitle>
          <S.Definition>
            Meets basic conversational needs and gets the gist of most everyday conversations. Has
            difficulty communicating health care concepts.
          </S.Definition>
        </S.DefinitionRow>
        <S.DefinitionRow>
          <S.DefinitionTitle>Elementary</S.DefinitionTitle>
          <S.Definition>
            Satisfies basic needs and minimum courtesy requirements. Unable to understand or
            communicate most health care concepts.
          </S.Definition>
        </S.DefinitionRow>
      </tbody>
    </S.DefinitionTable>
  );

  const inlineDialog = (
    <InlineDialog
      situation="alert"
      state="info"
    >
      Proficiency definitions
    </InlineDialog>
  );

  if (isInMobileBreakpointRange) {
    return (
      <ModalDialog triggerElement={<S.TriggerButton>{inlineDialog}</S.TriggerButton>}>
        {content}
      </ModalDialog>
    );
  } else {
    return (
      <PopoverTrigger
        content={content}
        placement="top left"
        theme="light"
      >
        <S.TriggerButton>{inlineDialog}</S.TriggerButton>
      </PopoverTrigger>
    );
  }
};

export default ProficiencyPopover;
