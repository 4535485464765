import { forwardRef } from 'react';
import { AriaTextFieldProps } from 'react-aria';

import * as S from './styles';

interface Props extends AriaTextFieldProps {
  caption?: string;
  className?: string;
  displayTooltip?: boolean;
  hideRequiredIndicator?: boolean;
  isExpandableMultiline?: boolean;
  isMultiline?: boolean;
  size?: number;
}

const TableTextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>((props, ref) => (
  <S.TableTextField
    {...props}
    ref={ref}
    size={props.isReadOnly === true ? (props.value?.length ?? undefined) : props.size}
  />
));

TableTextField.displayName = 'TableTextField';

export default TableTextField;
