import { FC, forwardRef, SVGProps } from 'react';
import { AriaComboBoxProps, Filter } from 'react-aria';

import * as S from './styles';

interface Props extends AriaComboBoxProps<object> {
  caption?: string;
  className?: string;
  filter?: Filter['contains'] | Filter['endsWith'] | Filter['startsWith'];
  hideRequiredIndicator?: boolean;
  icon?: FC<SVGProps<SVGSVGElement>>;
  showDropdownButton?: boolean;
}

const TableComboBox = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <S.TableComboBox
    {...props}
    ref={ref}
  />
));

TableComboBox.displayName = 'TableTextField';

export default TableComboBox;
