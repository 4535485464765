import { useMediaQuery } from 'usehooks-ts';

import { BREAKPOINT } from '../constants';

const useBreakpointRange = () => {
  const isInDesktopBreakpointRange = useMediaQuery(`(min-width: ${BREAKPOINT}px)`);
  const isInMobileBreakpointRange = useMediaQuery(`(max-width: ${BREAKPOINT - 1}px)`);

  return {
    isInDesktopBreakpointRange,
    isInMobileBreakpointRange
  };
};

export default useBreakpointRange;
