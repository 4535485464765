import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const H1Header = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel1Regular}
  margin-top: 0;
  margin-bottom: var(--space-5);
  color: var(--purple-400);
`;

export const H2Copy = styled.h2`
  ${TEXT_STYLES.bodyCopyLarge}
  font-weight: normal;
  color: var(--gray-600);
  margin: 0;
`;

export const H2Header = styled.h2`
  ${TEXT_STYLES.headlineStandardLevel2BoldRegular}
  margin-top: 0;
  margin-bottom: var(--space-4);
  color: var(--purple-400);
`;

export const LeadingHero = styled.div``;

export const RegularCopy = styled.h2`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-600);
  margin: 0;
`;

export const SubCopy = styled.p`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-600);
  margin-top: var(--space-4);
  margin-bottom: 0;
`;
