import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MOBILE_HEADER_HEIGHT } from '../../../pages/Dashboard/constants';

interface MobileNavigationRootProps {
  isOpen: boolean;
}

export const MobileNavigation = {
  Link: styled(Link)`
    align-items: center;
    color: var(--white-light);
    display: grid;
    font-size: 2rem;
    font-weight: 500;
    grid-template-columns: 5.25rem 1fr;
    line-height: 1.4;
    text-decoration: unset;

    & > svg {
      justify-self: center;
      fill: var(--white-light);
      height: 2.75rem;
      width: auto;
    }
  `,
  Links: styled.nav`
    display: grid;
    gap: 1.625rem;
    margin-left: -1.0625rem;
    margin-right: -1.0625rem;
  `,
  MenuText: styled.div`
    margin-bottom: 2.8125rem;
    font-size: 1.1875rem;
    font-weight: 700;
    line-height: 1.11;
    letter-spacing: 0.245em;
    color: var(--white-light);
  `,
  Root: styled.div<MobileNavigationRootProps>`
    position: fixed;
    top: ${MOBILE_HEADER_HEIGHT}px;
    width: 100%;
    height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
    padding: 2.75rem 2.0625rem;
    background-color: var(--purple-400);
    z-index: 2;
    outline: none;

    ${({ isOpen }) => {
      const { styledComponentId: linkClassName } = MobileNavigation.Link as {
        styledComponentId: string;
      };

      if (isOpen) {
        return `
          left: 0;
          opacity: 1;
          transition: left 0.4s, opacity 0.4s;

          .${linkClassName} {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.6s, transform 0.6s;

            &:nth-child(2) {
              transition-delay: 0.15s, 0.15s;
            }

            &:nth-child(3) {
              transition-delay: 0.3s, 0.3s;
            }

            &:nth-child(4) {
              transition-delay: 0.45s, 0.45s;
            }

            &:nth-child(5) {
              transition-delay: 0.6s, 0.6s;
            }

            &:nth-child(6) {
              transition-delay: 0.75s, 0.75s;
            }
          }
        `;
      } else {
        return `
          left: 100%;
          opacity: 0;
          transition: left 0.3s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.6s;

          .${linkClassName} {
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 0.4s, transform 0.6s;
          }
        `;
      }
    }}
  `
};
