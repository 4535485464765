import { ReactComponent as BaseSearchIcon } from '@material-design-icons/svg/round/search.svg';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { BRAND_BRIGHT_COLOR } from '../../../../components/GlobalStyle';
import { TEXT_STYLES } from '../../../../constants';

export const SearchIcon = styled(BaseSearchIcon)`
  width: 5.625rem;
  height: auto;
  padding: 1.25rem;
  background-color: ${transparentize(0.8, BRAND_BRIGHT_COLOR)};
  border-radius: 100%;
`;

export const TableEmptyState = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 28px;
  align-items: center;
  margin-top: var(--space-8);
  padding: 44px 49px;
  background-color: rgba(235, 235, 235, 0.6);
  border-radius: 0.625rem;
`;

export const Text = styled.p`
  margin-bottom: 0;
  ${TEXT_STYLES.bodyCopyLarge}
`;

export const Title = styled.h3`
  margin-bottom: 12px;
  ${TEXT_STYLES.headlineSectionLevel2Regular}
`;
