import checkedIcon from '@material-design-icons/svg/round/check.svg';
// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import {
  AriaCheckboxProps,
  mergeProps,
  useCheckbox,
  useFocusRing,
  VisuallyHidden
} from 'react-aria';
import { useToggleState } from 'react-stately';
import { generateStyledBgIcon } from 'src/utils';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import { BRAND_TINTS_LIGHT_COLOR } from '../../GlobalStyle';

import * as S from './styles';

interface Props extends AriaCheckboxProps {
  className?: string;
  hasError?: boolean;
  helpText?: string;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className, hasError = false, helpText, ...ariaCheckboxProps }, forwardedRef) => {
    const { children, isRequired = false } = ariaCheckboxProps;
    const [checkedIconPath, setCheckedIconPath] = useState<string>('');

    const ref = useObjectRef(forwardedRef);

    const { focusProps, isFocusVisible } = useFocusRing();
    const state = useToggleState(ariaCheckboxProps);
    const { inputProps, isDisabled, isInvalid, isReadOnly, isSelected, validationErrors } =
      useCheckbox(ariaCheckboxProps, state, ref);

    useEffect(() => {
      if (checkedIconPath !== '') return;

      const setIcons = async () => {
        const path = await generateStyledBgIcon(
          checkedIcon,
          BRAND_TINTS_LIGHT_COLOR,
          `stroke-width: 1; stroke: ${BRAND_TINTS_LIGHT_COLOR.replace('#', '%23')}`
        );
        setCheckedIconPath(path);
      };
      setIcons();
    }, [checkedIconPath]);

    return (
      <>
        <S.Checkbox
          $checkedIcon={checkedIconPath}
          className={clsx(
            className,
            { 'is-invalid': hasError },
            { 'is-disabled': isDisabled },
            { 'is-focused': isFocusVisible },
            { 'is-selected': isSelected },
            { 'is-read-only': isReadOnly },
            { 'has-helper-text': isNonEmptyString(helpText) }
          )}
        >
          <VisuallyHidden>
            <input
              {...mergeProps(inputProps, focusProps)}
              ref={ref}
              required={isRequired}
            />
          </VisuallyHidden>
          <S.Input />
          {children !== undefined && (
            <S.Label>
              {children}
              {isRequired && '*'}
              {isNonEmptyString(helpText) && <S.Helper>{helpText}</S.Helper>}
            </S.Label>
          )}
        </S.Checkbox>
        {isInvalid && <S.ErrorMessage>{validationErrors.join(' ')}</S.ErrorMessage>}
      </>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
