import { useNumberFormatter } from 'react-aria';
import DataContainer from 'src/components/Reporting/DataContainer';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import { OrganizationAssignmentDetails } from 'src/pages/Dashboard/utils';

interface Props {
  assignment: OrganizationAssignmentDetails;
}

const AssignmentEducationEngagementMetrics = ({ assignment }: Props) => {
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  return (
    <DataContainer data-cy="assignment-metrics">
      <DataReportRow layout="fifths">
        <DataHighlight
          data-cy="assignment-users-assigned"
          detailText="Assigned users"
          numberHighlight={commaNumFormatter.format(assignment.totalMemberCount)}
          variant="small"
        />
        <DataHighlight
          data-cy="assignment-completions"
          detailText="Completions"
          numberHighlight={commaNumFormatter.format(assignment.completionsTotalCount)}
          variant="small"
        />
        <DataHighlight
          data-cy="assignment-incomplete"
          detailText="Incomplete"
          numberHighlight={commaNumFormatter.format(assignment.incompleteCount)}
          variant="small"
        />
        <DataHighlight
          data-cy="assignment-completions-all"
          detailText="completions (All users)"
          numberHighlight={`${assignment.percentageCompleted}%`}
          variant="small"
        />
        <DataHighlight
          data-cy="assignment-completions-active"
          detailText="completions (Active users)"
          numberHighlight={`${assignment.percentageCompletedOnboarded}%`}
          variant="small"
        />
      </DataReportRow>
    </DataContainer>
  );
};

export default AssignmentEducationEngagementMetrics;
